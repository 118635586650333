import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, message } from 'antd';
import { SafetyCertificateOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { userStore } from '@/stores/userStore';
import { GetMobileCodeButtonStyle } from '@/views/login/mobileLoginPart/mobileLoginPart';
import { forgetPassword, Login } from '@greendev/common';
import { LoginButtonProps, LoginButtonStyle, LoginInput, LoginPasswordInput } from '@/views/login';

export const ResetPasswordPart: React.FC<{ backToLogin: () => void }> = ({ backToLogin }) => {
    const [form] = Form.useForm();

    const timeChange = useRef<number>();
    // 等待服务器响应中间状态
    const [awaitCodeRes, setAwaitCodeRes] = useState<boolean>(false);
    const [time, setTime] = useState(60);
    const [isGetCode, setIsGetCode] = useState(true);
    useEffect(() => {
        clearInterval(timeChange.current);
        return () => clearInterval(timeChange.current);
    }, []);
    useEffect(() => {
        if (time > 0 && time < 60) {
            setIsGetCode(false);
        } else {
            clearInterval(timeChange.current);
            setTime(60);
            setIsGetCode(true);
        }
    }, [time]);

    /**
     * 获取验证码
     */
    const getMsgCode = async () => {
        if (!isGetCode) {
            // 倒计时未结束,不能重复点击
            return;
        }
        if (!form.getFieldValue('mobile')) {
            message.error('请先输入手机号');
            console.log(form.getFieldValue('mobile'));
            return;
        }
        setAwaitCodeRes(true);
        await userStore
            .getMobileCodeFunc({ purpose: 'reset', mobile: form.getFieldValue('mobile') })
            .then(res => {
                if (!res) {
                    message.error('手机号未注册');
                    setAwaitCodeRes(false);
                } else {
                    message.success('发送成功,请填写收到的验证码');
                    setAwaitCodeRes(false);
                    // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
                    timeChange.current = setInterval(
                        () =>
                            setTime(val => {
                                const cur = val - 1;
                                return cur;
                            }),
                        1000
                    ) as unknown as number;
                }
            })
            .catch(error => {
                message.error(error.response?.data?.info || '网络连接异常');
                setAwaitCodeRes(false);
            });
    };

    const onFinished = async (formData: Login.ForgetPasswordParam) => {
        if (form.getFieldValue('password') !== form.getFieldValue('repeatPsw'))
            message.error('重复密码与新密码不一致，请重试！');
        forgetPassword(formData)
            .then(() => {
                message.success('重置密码成功！');
                backToLogin();
            })
            .catch(error => {
                message.error(error.response?.data?.info || error.response?.data?.non_field_errors || '重置密码失败！');
            });
    };

    return (
        <div id="reset-password">
            <h1>忘记密码</h1>
            <Form className="login-page-form" form={form} onFinish={onFinished}>
                <Form.Item
                    name="mobile"
                    rules={[
                        { required: true, message: '请输入手机号！' },
                        {
                            pattern: /^1[3-9]\d{9}$/g,
                            message: '手机号格式不正确',
                        },
                    ]}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <LoginInput type="tel" placeholder="请输入手机号" prefix={<UserOutlined />} size="large" />
                </Form.Item>
                <Form.Item
                    name="code"
                    rules={[
                        { required: true, message: '请输入验证码！' },
                        {
                            pattern: /^[a-z0-9A-Z]+$/g,
                            message: '验证码格式不正确',
                        },
                    ]}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                    extra={
                        <Button
                            onClick={isGetCode ? () => getMsgCode() : undefined}
                            className="login-getcode-button"
                            style={{
                                color: isGetCode ? '#000' : '#464646',
                                ...GetMobileCodeButtonStyle,
                            }}
                            disabled={awaitCodeRes}
                        >
                            {isGetCode ? '获取验证码' : `重新发送(${time}s)`}
                        </Button>
                    }
                >
                    <LoginInput
                        placeholder="请输入验证码"
                        maxLength={4}
                        prefix={<SafetyCertificateOutlined />}
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="new_password"
                    rules={[{ required: true, message: '请输入新密码！' }]}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <LoginPasswordInput placeholder="请输入新密码" prefix={<LockOutlined />} size="large" />
                </Form.Item>
                <Form.Item
                    name="confirm_password"
                    rules={[{ required: true, message: '请再次输入新密码！' }]}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <LoginPasswordInput placeholder="请再次输入新密码" prefix={<LockOutlined />} size="large" />
                </Form.Item>
                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="default"
                            className="back-to-login-btn"
                            onClick={backToLogin}
                            style={{ ...LoginButtonStyle, color: '#9EA5AE' }}
                        >
                            返回登录
                        </Button>
                        <div style={{ width: '30%' }} />
                        <Button htmlType="submit" type="primary" {...LoginButtonProps}>
                            确认修改
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};
