import { request } from "../../utils/request";
import { ClientFieldGenerationStrategy, generateClientSideFieldForAreaObject } from "./clientField";
/* eslint-enable no-param-reassign */

/**
 * notice : you need convert the result to right type you need according to strategy you want to play with
 * @param strategy see {@link ClientFieldGenerationStrategy}
 * @param parentId
 */
export function fetchAllAreaTree(strategy, parentId) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/enter/region/',
      method: 'GET',
      params: parentId === undefined ? undefined : {
        pid: parentId
      }
    }).then(areas => {
      resolve(areas.map(value => generateClientSideFieldForAreaObject(strategy, value)));
    }).catch(reject);
  });
}
export function queryAreaById(areaId) {
  return request({
    url: `/v1/enter/region/${areaId}/`,
    method: 'GET'
  });
}
export function deleteAreaById(areaId) {
  return request({
    url: `/v1/enter/region/${areaId}/`,
    method: 'DELETE'
  });
}
export function createArea(name, parentAreaId) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/enter/region/',
      method: 'POST',
      data: {
        name,
        ...(parentAreaId === undefined ? {} : {
          pid: parentAreaId
        })
      }
    }).then(justAddedArea => resolve(generateClientSideFieldForAreaObject(ClientFieldGenerationStrategy.PURE_AREA_TREE, justAddedArea))).catch(reject);
  });
}
export function modifyArea(newestName, targetAreaId) {
  return new Promise((resolve, reject) => {
    if (newestName.trim().length > 15) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('区域名称最大15个字');
      return;
    }
    request({
      url: `/v1/enter/region/${targetAreaId}/`,
      method: 'PATCH',
      data: {
        name: newestName
      }
    }).then(justUpdatedArea => resolve(generateClientSideFieldForAreaObject(ClientFieldGenerationStrategy.PURE_AREA_TREE, justUpdatedArea))).catch(reject);
  });
}