/**
 * 轮询
 * @param request 需要轮询的请求
 * @param setData useState - 设定的值
 * @param setTimer timer延时器，用于清除timeout，一般使用useRef存储
 * @param waiting 正常轮询间隔时间 默认十分钟
 * @param retry 失败后重试时间 默认一分钟
 * @param param 请求参数
 */
export async function polling(request, setData, setTimer, param, waiting = 10 * 60 * 1000, retry = 1 * 60 * 1000) {
  try {
    setData(await request(param));
    setTimer(setTimeout(() => {
      polling(request, setData, setTimer, param, waiting, retry);
    }, waiting));
  } catch (error) {
    setTimer(setTimeout(() => {
      polling(request, setData, setTimer, param, waiting, retry);
    }, retry));
  }
}