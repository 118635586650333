import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Progress, ProgressProps } from 'antd';
import PartTitle from '../../components/PartTitle';
import ContentContainer from '../../components/ContentContainer';
import GlobalStyle from '@/styles';
import { BigScreen } from '@greendev/common';

const { color } = GlobalStyle;

const EnvProgress: React.FC<ProgressProps> = props => {
    const { percent } = props;
    let blockPer = 0;
    if (percent) {
        blockPer = percent > 1 ? percent - 1 : percent;
    }
    return (
        <div className="runtime-progress">
            <Progress
                className="runtime-progress-line"
                showInfo={false}
                type="line"
                strokeLinecap="square"
                trailColor={'#27453F'}
                {...props}
            />
            <div className="runtime-progress-block" style={{ left: `${blockPer}%` }} />
            <div className="runtime-progress-center-line" style={{ width: `${blockPer}%` }} />
        </div>
    );
};

/** 展示大屏-监测点开启组件 */
const BigScreenRuntimePart: React.FC<{ data: BigScreen.ProductRuntimeType }> = ({ data }) => {
    const currentData = useMemo(() => data, [data]);

    return (
        <div style={{ flex: 1, padding: '14px', overflow: 'hidden' }}>
            <PartTitle>监测点开启</PartTitle>
            <ContentContainer className="runtime-part">
                <img
                    style={{ margin: '0 12px', height: '50%' }}
                    src={require('@/assets/images/operation_statistics.png')}
                    alt="background"
                />
                <div style={{ flex: 1, padding: '0 14px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '16px' }}>生产</div>
                        <span className="runtime-part-font-type1">{currentData.open_production}条</span>
                    </div>
                    <EnvProgress
                        strokeColor={{ from: '#8C7B35', to: color.themeYellow }}
                        percent={Math.floor((currentData.open_production / currentData.production) * 100)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '16px' }}>治污</div>
                        <span className="runtime-part-font-type2">{currentData.open_pollution}条</span>
                    </div>
                    <EnvProgress
                        strokeColor={{ from: '##1A6E5D', to: color.themeColorLight }}
                        percent={Math.floor((currentData.open_pollution / currentData.pollution) * 100)}
                    />
                </div>
            </ContentContainer>
        </div>
    );
};

export default observer(BigScreenRuntimePart);
