import { request } from "../../utils/request";
import { CompanyStatusLevel } from "./..";

// 企业总览
export function EnterpriseOverview(enterpriseId) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/visual/workbench/${enterpriseId}/point/`,
      method: 'GET'
    }).then(enterpriseOverview => resolve(enterpriseOverview)).catch(reject);
  });
}

/* eslint-disable no-param-reassign */
//企业生产
export function EnterpriseProduction(enterpriseId) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/visual/workbench/${enterpriseId}/status/`,
      method: 'GET'
    }).then(enterpriseOverview => {
      if (enterpriseOverview.alarm === null) {
        enterpriseOverview.status = CompanyStatusLevel.NORMAL;
      } else {
        enterpriseOverview.status = enterpriseOverview.alarm.lift_time === null ? CompanyStatusLevel.WARING : CompanyStatusLevel.RECOVER;
      }
      resolve(enterpriseOverview);
    }).catch(reject);
  });
}

/* eslint-enable no-param-reassign */

//监测点统计
export function MonitoringPointStatistics(enterpriseId) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/enter/region/${enterpriseId}/`,
      method: 'GET'
    }).then(enterpriseOverview => resolve(enterpriseOverview)).catch(reject);
  });
}

//当前运行
export function CurrentOperation(enterpriseId, params) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/visual/workbench/${enterpriseId}/modern/`,
      method: 'GET',
      params
    }).then(enterpriseOverview => resolve(enterpriseOverview)).catch(reject);
  });
}

// 运行统计
export function OperationStatistics(enterpriseId, params) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/visual/workbench/${enterpriseId}/statistics/`,
      method: 'GET',
      params
    }).then(enterpriseOverview => resolve(enterpriseOverview)).catch(reject);
  });
}

// 最近告警
export function RecentAlarm(enterpriseId) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/visual/workbench/${enterpriseId}/alarm/info/`,
      method: 'GET'
    }).then(enterpriseOverview => resolve(enterpriseOverview)).catch(reject);
  });
}