import { Form, TreeSelect } from 'antd';
import areaTreeState from '@/stores/areaTreeState';
import { Observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { TreeSelectProps } from 'antd/lib/tree-select';
import { Area } from '@greendev/common';

export function useAreaInitialize() {
    useEffect(() => {
        areaTreeState.initializeAreas(true);
    }, []);
}

type AreaTreeSelectProps = Pick<TreeSelectProps<any, Area.FrontEndProfile>, 'value' | 'onChange' | 'style'>;

export const AreaTreeSelect = (props: AreaTreeSelectProps & Omit<AreaFormTreeSelectProps, 'required'>) => {
    const { label } = props;
    return (
        <Observer>
            {() => (
                <TreeSelect<any, Area.FrontEndProfile>
                    treeData={areaTreeState.areasWithLoadingState.areas}
                    fieldNames={{
                        value: 'id',
                        label: 'name',
                    }}
                    allowClear
                    style={{ width: '100%' }}
                    // @ts-ignore
                    loadData={record =>
                        areaTreeState.requestChildAreaUnderTargetArea(
                            record.id as number,
                            record.children as Area.FrontEndProfile[] | undefined
                        )
                    }
                    dropdownStyle={{ maxHeight: 350, overflow: 'auto' }}
                    placeholder={`请选择${label}`}
                    {...props}
                />
            )}
        </Observer>
    );
};

type AreaFormTreeSelectProps = {
    required: boolean;
    label: string;
} & AreaTreeSelectProps;

/**
 * use this component when you want to select area in a form
 * notice : you should always call {@link useAreaInitialize} in root of your screen, so the initialize areas will be fetch and this component can display them
 * @param props some configuration of Form.Item
 * @constructor
 */
const AreaFormTreeSelect = (props: AreaFormTreeSelectProps) => {
    const { required, label } = props;
    return (
        <Form.Item name="area" label={label} rules={[{ required, message: `请选择${label}` }]}>
            <AreaTreeSelect {...props} />
        </Form.Item>
    );
};

export default AreaFormTreeSelect;
