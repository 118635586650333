import React from 'react';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { observer } from 'mobx-react-lite';

export type EChartGroupBarChartProps = {
    data: { data: [] };
    unit: string;
};

const EchartBar3D = (props: EChartGroupBarChartProps) => {
    const { data, unit } = props;
    const xAxisData: any[] = [];
    const seriesData: any[] = [];
    const botData: number[] = [];
    const topData: number[] = [];
    let sum = 0;
    // eslint-disable-next-line array-callback-return
    data.data.map((item: { name: any; value: number }) => {
        xAxisData.push(item.name);
        seriesData.push(item.value);
        botData.push(1); // 底数小三角
        if (item.value > sum) {
            sum = item.value;
        }
    });
    // eslint-disable-next-line array-callback-return
    data.data.map(() => {
        topData.push(sum + 20); //背景柱子数据
    });
    const barChartOption: EChartsOption = {
        title: [
            {
                x: 'left',
                y: 'top',
                top: '2%',
                text: `单位:(${unit})`,
                left: '0%',
                textStyle: {
                    fontStyle: 'normal',
                    color: '#9EA5AE',
                    fontfamily: 'MicrosoftYaHei-Bold',
                    fontweight: 'normal',
                    fontstretch: 'normal',
                    fontSize: 12,
                },
            },
        ],
        grid: {
            // 图形大小，位置
            containLabel: true,
            top: '15%',
            left: '1%',
            right: '1%',
            bottom: '1%',
        },
        tooltip: {
            // 提示
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter(params: any[]) {
                const item = params[2];
                return `${item.name} : ${item.value}`;
            },
        },
        dataZoom: {
            type: 'inside', //inside slider
            show: true, //flase直接隐藏图形
            xAxisIndex: [0],
            start: 0, //滚动条的起始位置
            end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            orient: 'vertical',
        },
        calculable: true,
        xAxis: {
            // x轴相关
            axisLabel: {
                color: '#9DCFCF',
                fontSize: 14,
                interval: 0,
                margin: 15,
                // overflow: 'truncate',
                // width: 85,
                formatter: (val: string) => {
                    if (val.length > 6) return `${val.slice(0, 6)}...`;
                    return val;
                },
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    // color: 'rgba(76, 228, 230, 0.1)',
                    // width: 1,
                    // type: 'dashed',
                    color: '#197160',
                },
                show: true,
            },
            data: xAxisData,
            type: 'category',
            triggerEvent: true,
        },
        yAxis: {
            // y轴相关
            axisLabel: {
                color: '#9DCFCF',
                fontSize: 12,
                show: false,
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: 'rgba(76, 228, 230, 0.2)', //虚线颜色
                    type: 'dashed',
                },
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#197160',
                },
            },
            name: '',
        },
        series: [
            {
                data: seriesData,
                type: 'bar',
                barMaxWidth: 'auto',
                barWidth: 25,
                itemStyle: {
                    color: 'rgba(66, 195, 170, 0.7)',
                    // color: {
                    //     x: 0,
                    //     y: 0,
                    //     x2: 0,
                    //     y2: 1,
                    //     type: 'linear',
                    //     global: false,
                    //     colorStops: [
                    //         {
                    //             offset: 0,
                    //             color: 'rgba(66, 195, 170, 0.7)',
                    //         },
                    //         {
                    //             offset: 1,
                    //             color: 'rgba(66, 134, 144, 1)',
                    //         },
                    //     ],
                    // },
                },
                label: {
                    show: false,
                    position: 'top',
                    distance: 10,
                    color: '#fff',
                },
            },
            {
                data: botData, //底部三角
                type: 'pictorialBar',
                barMaxWidth: '20',
                // color: 'rgba(66, 134, 144, 1)',
                color: 'rgba(66, 195, 170, 0.7)',
                symbol: 'diamond',
                symbolOffset: [0, '50%'],
                symbolSize: [25, 15],
            },
            {
                data: seriesData, //数据上部分
                type: 'pictorialBar',
                barMaxWidth: '20',
                symbolPosition: 'end',
                symbol: 'diamond',
                symbolOffset: [0, '-50%'],
                symbolSize: [25, 15],
                zlevel: 2,
                color: 'rgba(66, 195, 170, 1)',
            },
            {
                data: topData, //背景阴影
                type: 'bar',
                barMaxWidth: 'auto',
                barWidth: 25,
                barGap: '-100%',
                zlevel: -1,
                // color: 'rgba(23, 77, 77, 0.8)',
                color: 'rgba(204, 243, 233, 0.39)',
            },
            {
                data: topData, //顶部三角
                type: 'pictorialBar',
                barMaxWidth: '20',
                symbolPosition: 'end',
                symbol: 'diamond',
                symbolOffset: [0, '-50%'],
                symbolSize: [25, 15],
                // color: 'rgba(23, 77, 77, 1)',
                color: 'rgba(204, 243, 233, 0.89)',
                zlevel: -1,
            },
        ],
    };
    return (
        <ReactEcharts
            option={barChartOption}
            onEvents={{
                mouseover: (el: any) => {
                    if (el.componentType === 'xAxis') {
                        let elementDiv = document.querySelector('#extension');
                        if (!elementDiv) {
                            const div = document.createElement('div');
                            div.setAttribute('id', 'extension');
                            div.style.display = 'block';
                            document.querySelector('html')!.appendChild(div);
                            elementDiv = document.querySelector('#extension');
                        }
                        const elementStyle =
                            'position: absolute; z-index: 999; color: #262626; background: #FFFFFF; font-size: 12px; padding: 4px; border: 1px soild #000000';
                        // @ts-ignore
                        elementDiv!.style.cssText = elementStyle;
                        elementDiv!.innerHTML = el.value;
                        document.querySelector('html')!.onmousemove = (event: any) => {
                            const xx = event.pageX + 10;
                            const yy = event.pageY - 25;
                            // @ts-ignore
                            elementDiv!.style.top = `${yy}px`;
                            // @ts-ignore
                            elementDiv!.style.left = `${xx}px`;
                        };
                    }
                    // console.log(el);
                },
                mouseout: () => {
                    // console.log(el);
                    const elementDiv = document.querySelector('#extension');
                    // console.log(elementDiv);
                    // @ts-ignore
                    elementDiv!.style.cssText = 'display: none';
                },
            }}
            style={{ width: '100%', height: '80%', backgroundColor: 'transparent' }}
        />
    );
};

export default observer(EchartBar3D);
