import { DatePicker, Form, FormInstance, Input, Select, Tooltip } from 'antd';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { dataFormatByHideYear, disableDaysBeforeToday, disableDaysOutSideThisYear } from '@/AntDesignReset';
import {
    EffluentPlan,
    EffluentPlanCategory,
    effluentPlanCategoryStaticData,
    EffluentPlanExecutionStrategy,
    effluentPlanExecutionStrategyStaticData,
    EffluentPlanLevel,
    effluentPlanLevelStaticData,
    generateIdentifiedByType,
    splitIdWithName,
    TreeItemType,
    TreeItemUniqueValue,
    fetchAllMonitoring,
    MonitoringPointKind,
    dealRequestError,
    Monitoring,
} from '@greendev/common';
import moment from 'moment';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

export type AddEffluentPlanContentProps = {
    originalEffluentPlan?: EffluentPlan.BackendProfile;
};

const AddEffluentPlanContent = forwardRef<FormInstance<any>, AddEffluentPlanContentProps>((props, ref) => {
    const { originalEffluentPlan } = props;
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

    const [currentCategory, setCurrentCategory] = useState<EffluentPlanCategory | undefined>(
        originalEffluentPlan?.category
    );

    const [currentEnterprise, setCurrentEnterprise] = useState<TreeItemUniqueValue>();
    const [currentEnterpriseLines, setCurrentEnterpriseLines] = useState<Monitoring.FrontEndProfile[]>([]);
    const retryGetListStatus = useRef<boolean>(false);
    const getMonitoringList = () => {
        if (currentEnterprise?.type === TreeItemType.COMPANY && !retryGetListStatus.current) {
            fetchAllMonitoring({
                get_all: true,
                kind: MonitoringPointKind.Produce,
                enterprise: currentEnterprise.originObjectId,
            })
                .then(res => {
                    setCurrentEnterpriseLines(res as unknown as Monitoring.FrontEndProfile[]);
                })
                .catch(err => {
                    retryGetListStatus.current = true;
                    dealRequestError(err);
                    setCurrentEnterpriseLines([]);
                    setTimeout(() => {
                        retryGetListStatus.current = false;
                        getMonitoringList();
                    }, 2000);
                });
        }
        // console.log(currentEnterprise);
    };

    useEffect(() => {
        if (originalEffluentPlan !== undefined) {
            const allBackfilledCompanyIdentifications = [];
            //store temp mapping
            for (const eachCompanyIdWithName of originalEffluentPlan.enterprise) {
                const idWithName = splitIdWithName(eachCompanyIdWithName);
                allBackfilledCompanyIdentifications.push(generateIdentifiedByType(TreeItemType.COMPANY, idWithName.id));
            }
            if (originalEffluentPlan.category === EffluentPlanCategory.FACTORY_POLICY) {
                setCurrentEnterprise({
                    type: TreeItemType.COMPANY,
                    originObjectId: splitIdWithName(originalEffluentPlan.enterprise[0]).id,
                });
            }
            const formBackfillData: any = {
                'name': originalEffluentPlan.name,
                'category': originalEffluentPlan.category.toString(10),
                'level': originalEffluentPlan.level.toString(10),
                'executeStrategy': originalEffluentPlan.period.toString(10),
                'monitor_point': originalEffluentPlan.monitor_point.map(val => val.split('-')[0]),
                'execute-time': [
                    moment(originalEffluentPlan.s_date).set('year', moment().year()),
                    moment(originalEffluentPlan.e_date).set('year', moment().year()),
                ],
                'enterprise':
                    originalEffluentPlan.category === EffluentPlanCategory.FACTORY_POLICY
                        ? allBackfilledCompanyIdentifications[0]
                        : allBackfilledCompanyIdentifications,
            };
            if (originalEffluentPlan.category === EffluentPlanCategory.LIMIT) {
                formBackfillData.accountLimitation = originalEffluentPlan.percent;
            }
            setInitialValues(formBackfillData);
        }
    }, [originalEffluentPlan]);

    useEffect(() => {
        getMonitoringList();
        form.setFieldsValue((val: any) => {
            console.log(val);
            return { ...val };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEnterprise]);

    useEffect(() => {
        // console.log(form.getFieldValue());
        form.setFieldsValue({ ...form.getFieldsValue(), monitor_point: [], enterprise: undefined });
        if (currentCategory === EffluentPlanCategory.FACTORY_POLICY) {
            form.setFieldsValue((val: any) => {
                const enterpriseLocal = Array.isArray(val.enterprise) ? val.enterprise[0] : val.enterprise;
                console.log(enterpriseLocal, 'enterpriseLocal');
                return { ...val, enterprise: enterpriseLocal };
            });
        } else {
            form.setFieldsValue((val: any) => {
                const enterpriseLocal = Array.isArray(val.enterprise) ? val.enterprise : [val.enterprise];
                console.log(enterpriseLocal, 'enterpriseLocal');
                return { ...val, enterprise: enterpriseLocal };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCategory]);

    const [currentExecuteStrategy, setCurrentExecuteStrategy] = useState<EffluentPlanExecutionStrategy | undefined>(
        originalEffluentPlan?.period
    );
    const changeCurrentExecuteStrategy = (targetStrategy: EffluentPlanExecutionStrategy) => {
        setCurrentExecuteStrategy(targetStrategy);
        //invalid selected execute strategy
        // @ts-ignore
        ref.current?.setFieldsValue({ 'execute-time': undefined });
    };

    return (
        <Form
            labelCol={{ span: 6 }}
            labelAlign={'right'}
            wrapperCol={{ span: 18 }}
            ref={ref}
            initialValues={initialValues}
            key={initialValues?.name}
            form={form}
        >
            <Form.Item
                name="name"
                label="方案名称"
                rules={[
                    { required: true, message: '请输入方案名称' },
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/,
                        message: '方案名称只能包含1-20位的汉字、字母、数字、下划线!',
                    },
                ]}
            >
                <Input placeholder="请输入方案名称" />
            </Form.Item>
            <Form.Item name="category" label="类型" rules={[{ required: true, message: '请选择类型' }]}>
                <Select<EffluentPlanCategory>
                    placeholder="请选择类型"
                    allowClear
                    onChange={selectedKey => {
                        setCurrentCategory(Number(selectedKey));
                    }}
                >
                    {effluentPlanCategoryStaticData.map(eachCategory => (
                        <Select.Option key={eachCategory.value}>{eachCategory.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            {currentCategory === EffluentPlanCategory.LIMIT && (
                <Form.Item
                    label={
                        <>
                            <Tooltip title="生产线开启限制量，如限制60%，则实际生产中只能开启40%">
                                <i className="iconfont icon_tishi" style={{ marginTop: '2px' }} />
                            </Tooltip>
                            <span>限产百分比</span>
                        </>
                    }
                    name="accountLimitation"
                    rules={[
                        {
                            required: true,
                            validator: (_, value) => {
                                const isnumber = /^\d+$/.test(value);
                                const invalidNumberError = new Error('请输入1-99之间的限产百分比整数');
                                if (!isnumber) {
                                    return Promise.reject(invalidNumberError);
                                }
                                if (Number(value) < 1 || Number(value) > 99) {
                                    return Promise.reject(invalidNumberError);
                                }
                                return Promise.resolve(Number(value));
                            },
                        },
                    ]}
                >
                    <Input placeholder="请输入限产百分比" />
                </Form.Item>
            )}
            <Form.Item name="level" label="预警等级" rules={[{ required: true, message: '请选择预警等级' }]}>
                <Select<EffluentPlanLevel> placeholder="请选择预警等级" allowClear>
                    {effluentPlanLevelStaticData.map(eachCategory => (
                        <Select.Option key={eachCategory.value}>{eachCategory.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            {currentCategory === EffluentPlanCategory.FACTORY_POLICY && (
                <PurgedAreaCompanyTreeSelectUsedInForm
                    required
                    label={'执行企业'}
                    onChange={val => {
                        form.setFieldsValue({ ...form.getFieldsValue(), monitor_point: [] });
                        setCurrentEnterprise(JSON.parse(val));
                    }}
                />
            )}
            {currentCategory === EffluentPlanCategory.FACTORY_POLICY && (
                <Form.Item
                    name="monitor_point"
                    label="执行产线"
                    rules={[{ required: true, message: '请选择执行产线' }]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder="请选择执行生产线"
                        disabled={!currentEnterprise?.originObjectId}
                    >
                        {currentEnterpriseLines.map(item => (
                            <Select.Option key={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            <Form.Item name="executeStrategy" label="执行周期" rules={[{ required: true, message: '请选择执行周期' }]}>
                <Select<EffluentPlanExecutionStrategy>
                    placeholder="请选择执行周期"
                    allowClear
                    onChange={selectedKey => {
                        changeCurrentExecuteStrategy(Number(selectedKey));
                    }}
                >
                    {effluentPlanExecutionStrategyStaticData.map(eachCategory => (
                        <Select.Option key={eachCategory.value}>{eachCategory.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="execute-time" label="执行时间" rules={[{ required: true, message: '请选择开始时间' }]}>
                <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    disabledDate={
                        currentExecuteStrategy === EffluentPlanExecutionStrategy.CONTINUE
                            ? disableDaysOutSideThisYear
                            : disableDaysBeforeToday
                    }
                    format={
                        currentExecuteStrategy === EffluentPlanExecutionStrategy.CONTINUE
                            ? dataFormatByHideYear
                            : undefined
                    }
                    dropdownClassName={
                        currentExecuteStrategy === EffluentPlanExecutionStrategy.CONTINUE ? 'hideYearStyle' : '' //持续执行时隐藏掉年份
                    }
                />
            </Form.Item>
            {currentCategory !== EffluentPlanCategory.FACTORY_POLICY && (
                <PurgedAreaCompanyTreeSelectUsedInForm required={false} label={'关联企业'} treeCheckable />
            )}
        </Form>
    );
});

export default AddEffluentPlanContent;
