import { Drawer } from 'antd';
import React, { forwardRef, PropsWithChildren, useImperativeHandle, useState } from 'react';
import { CommonModalTitle, CommonModalTitleProps } from '@/components/modal/CommonModalTitle';

export type CommonDrawerProps = {
    // eslint-disable-next-line react/no-unused-prop-types
    footer?: React.ReactNode;
    onCancel: () => void;
} & CommonModalTitleProps;

export type CommonDrawerApi = {
    startLoading: () => void;
    endLoading: () => void;
    showModal: () => void;
    hiddenModal: () => void;
};

const CommonDrawer = forwardRef<CommonDrawerApi, PropsWithChildren<CommonDrawerProps>>(
    (props: PropsWithChildren<CommonDrawerProps>, ref) => {
        const [visible, setVisible] = useState(false);
        const [loading, setLoading] = useState(false);
        console.log(loading);
        const startLoading = () => {
            setLoading(true);
        };
        const endLoading = () => {
            setLoading(false);
        };
        const showModal = () => {
            setVisible(true);
        };
        const hiddenModal = () => {
            endLoading();
            setVisible(false);
        };
        useImperativeHandle(ref, () => ({
            showModal,
            hiddenModal,
            startLoading,
            endLoading,
        }));
        return (
            <Drawer
                forceRender
                getContainer={false}
                placement="bottom"
                visible={visible}
                title={<CommonModalTitle title={props.title} />}
                footer={null}
                onClose={() => {
                    props.onCancel?.();
                    endLoading();
                    hiddenModal();
                }}
            >
                {props.children}
            </Drawer>
        );
    }
);

export default CommonDrawer;
