import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import GlobalStyle from '@/styles';
import { Col, Row, Space, Typography } from 'antd';
import { autorun } from 'mobx';
import AmapHelper from '@/utils/amap';
import MapDotNormal from '@/assets/images/map_dot_normal.png';
import MapDotWaring from '@/assets/images/map_dot_waring.png';
import MapDotRecover from '@/assets/images/map_dot_recover.png';
import { renderToString } from 'react-dom/server';
import ModalTitleBackground from '@/assets/images/modal_title.png';
import ModalContentBackground from '@/assets/images/modal_content.png';
import ModalCloseBackground from '@/assets/images/modal_close.png';
import TitleDotSimple from '@/assets/images/title_dot_simple.png';
import './index.scss';
import { Company, CompanyStatusLevel } from '@greendev/common';
import { userStore } from '@/stores/userStore';
import { UserDashboardViewRouteParams } from '@/views/users/dashboardView';
import { useNavigate } from 'react-router-dom'; 

export type MapComponentProps = {
    displayedCompany: Omit<Company.BackendProfile, 'isLeaf' | 'uniqueId'>[];
};

const CompanyInfoWindowTitle = (props: { info: string }) => {
    const { color } = GlobalStyle;
    const { info } = props;
    return (
        <Space align={'center'} style={{ height: '100%' }}>
            <i
                className="iconfont icon_env_monitoring_points"
                style={{
                    fontSize: '1rem',
                    verticalAlign: 'middle',
                    color: color.themeColor,
                }}
            />
            <Typography.Title
                level={4}
                style={{
                    color: color.themeColorLight,
                    margin: 0,
                }}
            >
                {info}
            </Typography.Title>
        </Space>
    );
};

const CompanyInfoWindowContent = (props: PropsWithChildren<{ info: string }>) => {
    const { color } = GlobalStyle;
    const { info, children } = props;
    return (
        <Space align={'center'} style={{ height: '100%' }}>
            <Typography.Text
                style={{
                    color: color.themeColorLight,
                }}
            >
                {info}
            </Typography.Text>
            {children}
        </Space>
    );
};

const mapContainerId = 'testContainer';
const mapCloseButtonId = `${mapContainerId}-close-button`;
const mapDetailButtonId = `${mapContainerId}-detail-button`;

const BigScreenMapPart = (props: MapComponentProps) => {
    const { color } = GlobalStyle;

    const { displayedCompany } = props;

    const navigate = useNavigate();

    const jumpToDashboardView = (routeParams: UserDashboardViewRouteParams) => {
        navigate(userStore.dashboardPath, {
            state: routeParams,
        });
    };

    const alreadyLoadMap = useRef<any>(null);
    useEffect(
        () =>
            autorun(() => {
                if (AmapHelper.mapApi === undefined) {
                    return;
                }
                const AMap = AmapHelper.mapApi;
                //only initialize map part when ref is null
                if (alreadyLoadMap.current === null) {
                    const map = new AMap.Map(mapContainerId, {
                        zoom: 11, //级别
                        center: [116.397428, 39.90923], //中心点坐标
                        // mapStyle: 'amap://styles/f7cdd25f7323969294bf7d1453c45107', //设置地图的显示样式
                        mapStyle: 'amap://styles/d5db3adb1aecc374c0975c8b23f68739', //设置地图的显示样式
                    });
                    alreadyLoadMap.current = map;
                    map.setFeatures(['road', 'point']); // 多个种类要素显示(maybe this is default setting...)
                    // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
                    // map.addControl(new AMap.ToolBar());
                    // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
                    // map.addControl(new AMap.Scale());
                    //创建一个实例
                    // @ts-ignore
                    const districtExplorer = new AMapUI.DistrictExplorer({
                        eventSupport: false, //打开事件支持
                        map,
                    });

                    districtExplorer.loadAreaNode(100000, (error: any, areaNode: any) => {
                        //清除已有的绘制内容
                        districtExplorer.clearFeaturePolygons();
                        //绘制父区域
                        districtExplorer.renderParentFeature(areaNode, {
                            cursor: 'default',
                            bubble: true,
                            strokeColor: '#01705A', //线颜色
                            strokeOpacity: 1, //线透明度
                            strokeWeight: 4, //线宽
                            fillColor: '#01705A', //填充色
                            fillOpacity: 0, //填充透明度
                        });
                    });
                }
                if (alreadyLoadMap.current !== null && displayedCompany.length > 0) {
                    //todo determine if there execute multiple times
                    //display companies as markers and adjust map to proper height
                    const allMarker: any[] = [];
                    for (const eachCompany of displayedCompany) {
                        let markerIcon;
                        let statusText: string;
                        let statusTextColor: string;
                        switch (eachCompany.pro_state) {
                            case CompanyStatusLevel.NORMAL:
                                markerIcon = MapDotNormal;
                                statusText = '正常';
                                statusTextColor = color.themeColor;
                                break;
                            case CompanyStatusLevel.WARING:
                                markerIcon = MapDotWaring;
                                statusText = '异常';
                                statusTextColor = color.themeRed;
                                break;
                            case CompanyStatusLevel.RECOVER:
                                markerIcon = MapDotRecover;
                                statusText = '异常解除';
                                statusTextColor = color.themeYellow;
                                break;
                            default:
                                break;
                        }
                        const marker = new AMap.Marker({
                            icon: markerIcon,
                            position: [eachCompany.lng, eachCompany.lat],
                            anchor: 'center', // 设置锚点方位
                        });
                        //鼠标点击marker弹出自定义的信息窗体
                        AMap.event.addListener(marker, 'click', () => {
                            // const container = map.getContainer();
                            // container.clientHeight
                            const contentWidth = 338;
                            const titleHeight = 45;
                            const eachPieceOfContentHeight = 36;
                            const contentHeight = eachPieceOfContentHeight * 5 + 10;
                            //can't bind the stupid handler in fom when use dom server!!!
                            //see : https://stackoverflow.com/a/36323856/11742589
                            const infoWindow = new AMap.InfoWindow({
                                isCustom: true, //使用自定义窗体
                                content: renderToString(
                                    <div>
                                        <div
                                            id={mapCloseButtonId}
                                            style={{
                                                position: 'absolute',
                                                zIndex: 99,
                                                left: contentWidth - 2,
                                                top: -20,
                                            }}
                                        >
                                            <img src={ModalCloseBackground} width={22} height={22} alt={''} />
                                        </div>

                                        <Space direction={'vertical'} size={5}>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${ModalTitleBackground})`,
                                                    backgroundSize: '100% 100%',
                                                    width: contentWidth,
                                                    height: titleHeight,
                                                    padding: '0px 20px 0px 20px',
                                                }}
                                                className={'title-shadow'}
                                            >
                                                <CompanyInfoWindowTitle info={eachCompany.name} />
                                            </div>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${ModalContentBackground})`,
                                                    backgroundSize: '100% 100%',
                                                    width: contentWidth,
                                                    height: contentHeight,
                                                    padding: '5px 20px 5px 20px',
                                                    position: 'relative',
                                                }}
                                                className={'content-shadow'}
                                            >
                                                <div
                                                    id={mapDetailButtonId}
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 99,
                                                        right: 5,
                                                        top: 0,
                                                        height: eachPieceOfContentHeight * 2,
                                                    }}
                                                >
                                                    <Space align={'center'} style={{ height: '100%' }}>
                                                        <Space direction={'vertical'} align={'center'} size={3}>
                                                            <i
                                                                className="iconfont iconchakanxiangqing2"
                                                                title={'查看'}
                                                                style={{
                                                                    fontSize: '1rem',
                                                                    verticalAlign: 'middle',
                                                                    color: color.themeColorLight,
                                                                }}
                                                            />
                                                            <Space align={'center'} size={4}>
                                                                <Typography.Text
                                                                    style={{
                                                                        color: color.themeColorLight,
                                                                    }}
                                                                >
                                                                    {'查看详情'}
                                                                </Typography.Text>
                                                                <img
                                                                    src={TitleDotSimple}
                                                                    width={8}
                                                                    height={8}
                                                                    alt={''}
                                                                />
                                                            </Space>
                                                        </Space>
                                                    </Space>
                                                </div>
                                                <Row>
                                                    <Col span={24} style={{ height: eachPieceOfContentHeight }}>
                                                        <CompanyInfoWindowContent
                                                            info={`行业 : ${
                                                                eachCompany.trade_name === null
                                                                    ? '-'
                                                                    : eachCompany.trade_name
                                                            }`}
                                                        />
                                                    </Col>
                                                    <Col span={24} style={{ height: eachPieceOfContentHeight }}>
                                                        <CompanyInfoWindowContent
                                                            info={`限停产 : ${
                                                                eachCompany.limit_level === null
                                                                    ? '-'
                                                                    : eachCompany.limit_level
                                                            }`}
                                                        />
                                                    </Col>
                                                    <Col span={24} style={{ height: eachPieceOfContentHeight }}>
                                                        <CompanyInfoWindowContent info={`生产状况 : `}>
                                                            <Typography.Text
                                                                style={{
                                                                    color: statusTextColor,
                                                                }}
                                                            >
                                                                {`${statusText}`}
                                                            </Typography.Text>
                                                        </CompanyInfoWindowContent>
                                                    </Col>
                                                    <Col span={24} style={{ height: eachPieceOfContentHeight }}>
                                                        <CompanyInfoWindowContent
                                                            info={`行政区域 : ${eachCompany.ad_region_name}`}
                                                        />
                                                    </Col>
                                                    <Col span={24} style={{ height: eachPieceOfContentHeight }}>
                                                        <CompanyInfoWindowContent
                                                            info={`公司地址 : ${eachCompany.address}`}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Space>
                                    </div>
                                ),
                                offset: new AMap.Pixel(0, -60),
                            });
                            const closeInfoWindow = () => {
                                infoWindow.close();
                            };
                            const jumpToDetailScreen = () => {
                                jumpToDashboardView({
                                    company: {
                                        id: eachCompany.id,
                                        name: eachCompany.name,
                                    },
                                });
                            };
                            infoWindow.on('open', () => {
                                setTimeout(() => {
                                    const closeButton = document.getElementById(mapCloseButtonId);
                                    const detailButton = document.getElementById(mapDetailButtonId);
                                    if (closeButton !== null && detailButton !== null) {
                                        closeButton.addEventListener('click', closeInfoWindow);
                                        detailButton.addEventListener('click', jumpToDetailScreen);
                                    }
                                }, 500);
                            });
                            infoWindow.on('close', () => {
                                const closeButton = document.getElementById(mapCloseButtonId);
                                const detailButton = document.getElementById(mapDetailButtonId);
                                if (closeButton !== null && detailButton !== null) {
                                    closeButton.removeEventListener('click', closeInfoWindow);
                                    detailButton.removeEventListener('click', jumpToDetailScreen);
                                }
                            });
                            infoWindow.open(alreadyLoadMap.current, marker.getPosition());
                        });
                        allMarker.push(marker);
                    }
                    alreadyLoadMap.current.add(allMarker);
                    if (displayedCompany.length > 0) {
                        // debugger
                        alreadyLoadMap.current.setFitView(allMarker);
                    }
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [color, displayedCompany]
    );
    return <div id={mapContainerId} style={{ width: '100%', height: '100%' }} />;
};

export default observer(BigScreenMapPart);
