/** 色彩规范 */
export default {
    color: {
        /** 主色 */
        themeColor: '#42c3aa',
        /** 辅助色-橙黄 */
        themeYellow: '#FDA82E',
        /** 辅助色-红 */
        themeRed: '#FB887F',
        /** 辅助色-蓝 */
        themeBlue: '#60ACF2',
        /** 辅助色-浅蓝 */
        themeCyan: '#96BEFB',

        /** 标题文字 */
        titleColor: '#000000',
        /** 正文文字 */
        contentColor: '#595959',
        /** 次要文字 （导航未选中文字，更多） */
        assistColor: '#899699',
        /** 描述文字 （图例及列表中的描述） */
        noteColor: '#9EA5AE',

        /** 其它颜色 */
        /** 主色-浅 */
        themeColorLight: '#DEF3EE',
        /** 主色-浅灰 */
        themeColorGrey: '#F2F9F9',
        /** 辅助色-浅色橙黄 */
        themeYellowLight: '#FFF2E2',
        /** 辅助色-浅红 */
        themeRedLight: '#F5DEDF',
        /** 辅助色-浅蓝 */
        themeBlueLight: '#E9F2FF',
        /** 辅助色-灰 (模块背景的描边) */
        otherGrey: '#E3E6E4',
        /** 辅助色-浅灰 */
        otherGreyLight: '#F0F2F5',
    },
};
