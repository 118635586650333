import AMapLoader from '@amap/amap-jsapi-loader';
import { action, computed, makeObservable, observable } from 'mobx';

class AMapHelper {
    _mapApi: any;

    private _DistrictExplorer: any;

    get mapApi(): any {
        return this._mapApi;
    }

    get DistrictExplorer(): any {
        return this._DistrictExplorer;
    }

    injectProxyAddress = (proxyAddress: string, onLoadError?: (message: string) => void) => {
        // @ts-ignore
        window._AMapSecurityConfig = {
            serviceHost: `${proxyAddress}_AMapService`,
        };
        this.init(onLoadError);
    };

    private init = (onLoadError?: (message: string) => void) => {
        AMapLoader.load({
            key: '0077fe3bc4655ec356062d7e3a0d46c9', // 申请好的Web端开发者Key，首次调用 load 时必填
            version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            AMapUI: {
                // 是否加载 AMapUI，缺省不加载
                version: '1.1', // AMapUI 缺省 1.1
                plugins: ['geo/DistrictExplorer'], // 需要加载的 AMapUI ui插件
            },
            plugins: ['AMap.ToolBar', 'AMap.Scale', 'AMap.Geocoder'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等,
        })
            .then(AMap => {
                this._mapApi = AMap;
            })
            .catch(e => {
                if (onLoadError !== undefined) {
                    onLoadError(e);
                } else {
                    console.log(e);
                }
            });
    };

    /**
     * 地址查询经纬度
     * @param address
     * @param querySuccessful
     * @param onFail
     */
    queryLngLatByAddress(address: string, querySuccessful: (lnglat: string) => void, onFail: () => void) {
        if (this._mapApi === undefined) {
            return;
        }
        const geocoder = new this.mapApi.Geocoder({
            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            city: '全国',
        });
        geocoder.getLocation(address, (status: string, result: { info: string; geocodes: { location: any }[] }) => {
            if (status === 'complete' && result.info === 'OK') {
                const lnglat = result.geocodes[0].location;
                querySuccessful(lnglat);
            } else {
                onFail();
            }
        });
    }

    constructor() {
        //fuck the mobx,read document and debug the observable!!!
        //don't use makeAutoObservable for the _mapApi, because the observable will applied to this field recursively!!!
        //see following link for different between observable and observable.ref:
        //https://mobx.js.org/api.html#observable
        //https://mobx.js.org/api.html#observableref
        makeObservable(this, {
            _mapApi: observable.ref,
            mapApi: computed,
            injectProxyAddress: action,
        });
    }
}

export default new AMapHelper();
