import React from 'react';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import GlobalStyle from '@/styles';
import { Workbench } from '@greendev/common';

const { color } = GlobalStyle;

export type CardViewProps = {
    data?: Workbench.EnterpriseOverviewFrontEndProfile;
};

const CardView = (props: CardViewProps) => {
    const { data } = props;
    return (
        <Row gutter={32} className="ItemViewStyle">
            <Col
                className="gutter-row"
                span={6}
                style={{ marginBottom: '16px', paddingLeft: '0px', paddingRight: '0px' }}
            >
                <div className="colBox">
                    <div className="pic" style={{ background: color.themeYellowLight }}>
                        <i className="iconfont iconshengchanxian" style={{ color: color.themeYellow }} />
                    </div>
                    <div className="title">生产线开启量</div>
                    <div className="col">
                        <span className="value" style={{ color: color.themeYellow }}>
                            {_.isUndefined(data?.production_up) ? '-' : data?.production_up}
                        </span>
                    </div>
                </div>
            </Col>
            <Col className="gutter-row" span={6} style={{ marginBottom: '16px', paddingRight: '0px' }}>
                <div className="colBox">
                    <div className="pic" style={{ background: color.themeColorLight }}>
                        <i className="iconfont icon_env_production" style={{ color: color.themeColor }} />
                    </div>
                    <div className="title">治污线开启量</div>
                    <div className="col">
                        <span className="value" style={{ color: color.themeColor }}>
                            {_.isUndefined(data?.facility_up) ? '-' : data?.facility_up}
                        </span>
                    </div>
                </div>
            </Col>
            <Col className="gutter-row" span={6} style={{ marginBottom: '16px', paddingRight: '0px' }}>
                <div className="colBox">
                    <div className="pic" style={{ background: '#FFE5E3' }}>
                        <i className="iconfont iconbaojing1" style={{ color: color.themeRed }} />
                    </div>
                    <div className="title">报警数量</div>
                    <div className="col">
                        <span className="value" style={{ color: color.themeRed }}>
                            {_.isUndefined(data?.alarm_count) ? '-' : data?.alarm_count}
                        </span>
                    </div>
                </div>
            </Col>
            <Col className="gutter-row" span={6} style={{ marginBottom: '16px', paddingRight: '0px' }}>
                <div className="colBox">
                    <div className="pic" style={{ background: color.themeBlueLight }}>
                        <i className="iconfont icon_env_devices" style={{ color: color.themeBlue }} />
                    </div>
                    <div className="title">设备安装数量</div>
                    <div className="col">
                        <span className="value" style={{ color: color.themeBlue }}>
                            {_.isUndefined(data?.device_count) ? '-' : data?.device_count}
                        </span>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default observer(CardView);
