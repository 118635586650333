import {
    fetchAllMonitoring,
    generateIdentifiedByType,
    Monitoring,
    PollutionControl,
    queryPollutionControlById,
    recoverInformationFromIdentify,
    TreeItemType,
} from '@greendev/common';
import { Form, FormInstance, Input, InputNumber, message, Select } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

export type PollutionControlEditContentProps = {
    editMode?: string;
    originPollutionControl?: PollutionControl.BackendProfile;
};

//新增、编辑弹框内容显示
const PollutionControlEditContent = forwardRef<FormInstance<any>, PollutionControlEditContentProps>(
    (props: PollutionControlEditContentProps, ref) => {
        const { editMode, originPollutionControl } = props;
        const onFinish = (values: any) => {
            console.log('Success:', values);
        };

        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };

        const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

        const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>(
            originPollutionControl?.enterprise
        );

        const [facility, setFacility] = useState<Monitoring.FrontEndProfile[]>([]);
        const [production, setProduction] = useState<Monitoring.FrontEndProfile[]>([]);

        useEffect(() => {
            if (originPollutionControl !== undefined) {
                queryPollutionControlById(originPollutionControl.id).then(() => {
                    setInitialValues({
                        name: originPollutionControl.name,
                        enterprise: generateIdentifiedByType(TreeItemType.COMPANY, originPollutionControl.enterprise),
                        facility: originPollutionControl.facility,
                        production: originPollutionControl.production,
                        appoint: originPollutionControl.appoint,
                    });
                });
                setSelectedCompanyId(originPollutionControl.enterprise);
            } else if (editMode === 'add') {
                setInitialValues(undefined);
                setSelectedCompanyId(undefined);
                setProduction([]);
                setFacility([]);
            } else {
                setInitialValues(undefined);
                setSelectedCompanyId(undefined);
                setProduction([]);
                setFacility([]);
            }
        }, [originPollutionControl, editMode]);

        useEffect(() => {
            if (selectedCompanyId !== undefined) {
                //refresh all monitoring points under this area
                fetchAllMonitoring({
                    enterprise: selectedCompanyId,
                    kind: 2,
                    get_all: true,
                }).then(allFacility => {
                    setFacility(allFacility as Monitoring.FrontEndProfile[]);
                });
                fetchAllMonitoring({
                    enterprise: selectedCompanyId,
                    kind: 1,
                    get_all: true,
                }).then(allProduction => {
                    setProduction(allProduction as Monitoring.FrontEndProfile[]);
                });
            }
        }, [selectedCompanyId]);

        const changeCurrentSelectedArea = (targetAreaId?: number) => {
            setSelectedCompanyId(targetAreaId);
            //invalid selected monitoring points
            // @ts-ignore
            ref.current?.setFieldsValue({ facility: undefined, production: undefined });
            setFacility([]);
            setProduction([]);
        };

        return (
            <Form
                layout="horizontal"
                ref={ref}
                labelAlign={'right'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues}
                key={initialValues?.name}
            >
                <Form.Item
                    label="关联名称"
                    name="name"
                    rules={[
                        { required: true, message: '请输入关联名称' },
                        {
                            pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,25}$/,
                            message: '关联名称只能包含1-25位的汉字、字母、数字、下划线!',
                        },
                        {
                            whitespace: true,
                            message: '不允许输入空格',
                        },
                    ]}
                >
                    <Input placeholder="请输入关联名称" />
                </Form.Item>
                <PurgedAreaCompanyTreeSelectUsedInForm
                    label="企业"
                    style={{ width: '100%' }}
                    treeCheckable={false}
                    required
                    onlyCanSelectCompany
                    onChange={(itemIdentification?: string) => {
                        if (itemIdentification === undefined) {
                            changeCurrentSelectedArea(undefined);
                            return;
                        }
                        changeCurrentSelectedArea(recoverInformationFromIdentify(itemIdentification).originObjectId);
                    }}
                />
                <Form.Item label="生产线" name="production" rules={[{ required: true, message: '请选择生产线' }]}>
                    <Select
                        placeholder="请选择生产线"
                        virtual={false}
                        showArrow
                        allowClear
                        mode="multiple"
                        onFocus={() => {
                            if (selectedCompanyId !== undefined) {
                                //refresh all monitoring points under this area
                                fetchAllMonitoring({
                                    enterprise: selectedCompanyId,
                                    kind: 1,
                                    get_all: true,
                                }).then(allProduction => {
                                    setProduction(allProduction as Monitoring.FrontEndProfile[]);
                                });
                            } else {
                                message.info('请先选择企业');
                            }
                        }}
                    >
                        {production.map(item => (
                            <Select.Option value={item.id} key={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="治污线" name="facility" rules={[{ required: true, message: '请选择治污线' }]}>
                    <Select
                        placeholder="请选择治污线"
                        virtual={false}
                        showArrow
                        allowClear
                        mode="multiple"
                        onFocus={() => {
                            if (selectedCompanyId !== undefined) {
                                //refresh all monitoring points under this area
                                fetchAllMonitoring({
                                    enterprise: selectedCompanyId,
                                    kind: 2,
                                    get_all: true,
                                }).then(allFacility => {
                                    setFacility(allFacility as Monitoring.FrontEndProfile[]);
                                });
                            } else {
                                message.info('请先选择企业');
                            }
                        }}
                    >
                        {facility.map(item => (
                            <Select.Option value={item.id} key={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="时效选择"
                    name="appoint"
                    rules={[
                        { required: true, message: '请输入时效选择' },
                        {
                            pattern: /(^[1-9]\d*$)/,
                            message: '时效选择需要是整数!',
                        },
                        {
                            pattern: /^.{0,4}$/,
                            message: '时效选择长度为3位!',
                        },
                        {
                            pattern: /^(?!\s)(?!.*\s$)/,
                            message: '总电流不允许有空格!',
                        },
                    ]}
                >
                    <InputNumber
                        placeholder="时效选择"
                        precision={0}
                        max={120}
                        min={1}
                        step="1"
                        addonAfter={'分钟'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Form>
        );
    }
);

export default PollutionControlEditContent;
