import React from 'react';
import { RouteProps } from 'react-router';

import PrivateRoute from './privateRoute';

export interface WrapperRouteProps extends RouteProps {
    /** document title locale id */
    titleId?: string;
    /** authorization？ */
    auth?: boolean;
}

// @ts-ignore
const WrapperRouteComponent: React.FC<WrapperRouteProps> = ({ auth, ...props }) => {
    const WitchRoute = auth ? <PrivateRoute {...props} /> : props.element;
    return WitchRoute || null;
};

export default WrapperRouteComponent;
