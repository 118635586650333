import * as XLSX from 'xlsx';
import _, { keysIn } from 'lodash';
import { useEffect, useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { WithLoadingState } from '@greendev/common';

/**
 *  defined the key readable mapping :
 * const keyMapping: ExcelKeyReadableMapping<Company.ExcelExposeProfile> = {
        companyName: '企业名称',
        areaNamePath: '行政区',
        industryName: '所属行业',
        address: '地址',
        lnglat: '经纬度',
        expiredTime: '到期时间',
        accounts: '关联帐号',
        remark: '备注',
    };
 */
export type ExcelKeyReadableMapping<TYPE> = {
    [key in keyof TYPE]: string;
};

export function exportExcel<ExposeProfile>(
    keymapping: ExcelKeyReadableMapping<ExposeProfile>,
    data: ExposeProfile[],
    fileName: string,
    sheetName?: string
) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(worksheet, [_.valuesIn(keymapping)], { origin: 'A1' });
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName || '所有');
    //better way to calculate column width, but too tedious...
    // const columnWidths = [];
    // for (const eachKey of keysIn(companiesForExcel[0])) {
    //     let columnWidth;
    //     switch (eachKey as keyof Company.ExcelExposeProfile) {
    //         case 'companyName':
    //         case 'areaNamePath':
    //         case 'industryName':
    //         case 'address':
    //         case 'lnglat':
    //         case 'expiredTime':
    //         case 'accounts':
    //         case 'remark':
    //             columnWidth = Math.max(
    //
    //                 ...companiesForExcel.map(eachCompany =>      // @ts-ignore
    //                     eachCompany[eachKey] === null ? 0 : eachCompany[eachKey].length
    //                 )
    //             );
    //             if (columnWidth > 40) {
    //                 columnWidth = 40;
    //             }
    //             columnWidths.push(columnWidth);
    //             break;
    //         default:
    //             columnWidths.push(20);
    //             break;
    //     }
    // }
    // worksheet['!cols'] = columnWidths.map(eachColumnWidth => ({ wch: eachColumnWidth }));
    worksheet['!cols'] = _.keysIn(data[0]).map(() => ({ wch: 30 }));
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

/**
 * 1: function used to put file in to parse file
 * 2: function used to to cancel file processing
 * 3: if file is processing
 */
export type UseExcelImporterResultTYpe = [(file: UploadFile) => void, () => void, boolean];

/**
 * invoke with causing!!!
 * @param onReadDataInSheet !!! should be memorized !!!
 */
export function useExcelImporter(
    onReadDataInSheet: (recordsInSheet: { [key: string]: any }[]) => void
): UseExcelImporterResultTYpe {
    const [processingExcel, setProcessingExcel] = useState<
        | WithLoadingState<{
              file: UploadFile;
          }>
        | undefined
    >(undefined);
    const afterSelectExcel = (file: UploadFile) => {
        setProcessingExcel({
            file,
            loading: true,
        });
    };
    const afterUploadExcel = () => {
        setProcessingExcel(undefined);
    };
    useEffect(() => {
        if (processingExcel === undefined) {
            return;
        }
        const reader = new FileReader();
        reader.onload = e => {
            // @ts-ignore
            const data = e.target.result;
            /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
            const workbook = XLSX.read(data);
            const firstWorkSheet = workbook.Sheets[workbook.SheetNames[0]];
            const companies: any[] = XLSX.utils.sheet_to_json(firstWorkSheet, {
                defval: null,
            });
            for (const eachRecord of companies) {
                for (const eachKey of keysIn(eachRecord)) {
                    if (typeof eachRecord[eachKey] === 'string' && eachRecord[eachKey].length === 0) {
                        eachRecord[eachKey] = null;
                    }
                }
            }
            onReadDataInSheet(companies);
        };
        const targetExcelFile = processingExcel.file.originFileObj;
        reader.readAsArrayBuffer(targetExcelFile as File);
    }, [onReadDataInSheet, processingExcel]);

    return [afterSelectExcel, afterUploadExcel, processingExcel?.loading || false];
}
