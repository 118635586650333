import React, { useState, useRef, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Table, Button, Popconfirm, Row, Col, Switch, Space, Typography, Divider, message } from 'antd';
import { MyIcon } from '@/utils/iconfont';
import { map } from 'lodash';
import GlobalModal, { GlobalModalApi } from '@/components/modal/GlobalModal';
import { getRoleList, editRole, deleteRole, copyRole, Role } from '@greendev/common';
import EditRole from './editRolePart';

const iconFontStyle: React.CSSProperties = { fontSize: '1rem', verticalAlign: 'middle', color: '#333333' };

const RoleManagementView = () => {
    const modalRef = useRef<GlobalModalApi>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalType, setModalType] = useState('add'); //add 新增  //edit 编辑  //ready 查看
    const ref = useRef<any>({ page: 1, pageSize: 20 });
    const [userId, setUserId] = useState(0);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataSource, setDataSource] = useState<Role.BackEndProfile[]>([]);
    const userList = (page = 1) => {
        setIsLoading(true);
        setCurrentPage(page);
        getRoleList({ page, page_size: ref.current ? ref.current.pageSize : 20 })
            .then(res => {
                let result = res;
                ref.current.current = page;
                setTotal(result.count!);
                result = map(result.results, (item: Role.BackEndProfile) => ({ ...item, key: item.id }));
                setDataSource(result as Role.BackEndProfile[]);
                setIsLoading(false);
            })
            .catch(() => {
                if (page > 1) {
                    ref.current.current = page - 1;
                    setCurrentPage(page - 1);
                    userList(ref.current.current);
                }
            });
    };

    useEffect(() => {
        userList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const title = useMemo(() => {
        if (modalType === 'add') {
            return '新增角色';
        }
        if (modalType === 'edit') {
            return '编辑角色';
        }
        return '查看角色';
    }, [modalType]);
    const columns = useMemo(
        () => [
            {
                title: '序号',
                dataIndex: 'idx',
                width: 80,
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: '角色名称',
                width: 100,
                dataIndex: 'name',
                render: (text: string) => (
                    <p
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '角色描述',
                width: 180,
                dataIndex: 'desc',
                render: (text: string) => (
                    <p
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '开启状态',
                width: 100,
                dataIndex: 'is_active',
                render: (_: any, record: Role.BackEndProfile) => (
                    <Switch
                        checkedChildren="开启"
                        unCheckedChildren="关闭"
                        // {...switchText}
                        checked={record.is_active}
                        size="small"
                        onClick={value => {
                            console.log(value);
                            editRole(record.id, { is_active: value })
                                .then(() => {
                                    message.success(`${value ? '开启' : '关闭'}成功`);
                                    userList(currentPage);
                                })
                                .catch();
                        }}
                    />
                ),
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 200,
                render: (time: number) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: 196,
                render: (_: any, item: any) => (
                    <Space split={<Divider type="vertical" />}>
                        <Typography.Link
                            className="opera"
                            onClick={() => {
                                copyRole(item.id)
                                    .then(() => {
                                        message.success('复制角色成功');
                                        userList(currentPage);
                                    })
                                    .catch(error => {
                                        message.error(error.response?.data?.name?.['0'] || '复制角色失败');
                                    });
                            }}
                        >
                            <MyIcon type="iconfuzhi1" style={iconFontStyle} title="复制" />
                        </Typography.Link>
                        <Typography.Link
                            className="opera"
                            onClick={() => {
                                setModalType('ready');
                                setUserId(item.id);
                                modalRef.current?.showModal();
                            }}
                        >
                            <MyIcon type={'iconchakan1'} style={iconFontStyle} title={'查看'} />
                        </Typography.Link>
                        <Typography.Link
                            className="opera"
                            onClick={() => {
                                setModalType('edit');
                                setUserId(item.id);
                                modalRef.current?.showModal();
                            }}
                        >
                            <MyIcon type={'icona-zu6914'} style={iconFontStyle} title={'编辑'} />
                        </Typography.Link>
                        <Typography.Link className="opera">
                            <Popconfirm
                                title="删除该角色？"
                                okText="删除"
                                cancelText="取消"
                                onConfirm={() => {
                                    deleteRole(item.id)
                                        .then(() => {
                                            message.success('删除成功');
                                            userList(ref.current.current);
                                        })
                                        .catch(() => {
                                            message.error('删除角色失败');
                                        });
                                }}
                            >
                                <MyIcon type="iconshanchu1" style={iconFontStyle} title={'删除'} />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentPage]
    );
    return (
        <div className="container">
            <Row>
                <Col span={24}>
                    <Button
                        onClick={() => {
                            setModalType('add');
                            modalRef.current?.showModal();
                        }}
                        type="primary"
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        添加角色
                    </Button>
                    <Table
                        bordered
                        loading={isLoading}
                        dataSource={dataSource}
                        rowKey={record => record.id + Math.random()}
                        onChange={page => {
                            ref.current = page;
                            userList(page.current);
                        }}
                        columns={columns}
                        pagination={{
                            defaultPageSize: 20, // commonPageSize
                            total,
                            current: currentPage,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showTotal: totalCount => `共 ${totalCount} 条`,
                        }}
                        scroll={{ y: 'calc(100vh - 305px)' }}
                    />
                </Col>
            </Row>
            <GlobalModal
                title={title}
                childRef={modalRef}
                onOk={() => {
                    if (modalType === 'edit') {
                        userList(ref.current.current);
                    }
                }}
                footer={null}
            >
                <EditRole
                    type={modalType}
                    userId={userId}
                    setDataSource={setDataSource}
                    showLoading={() => {}}
                    hiddenLoading={() => {}}
                    onOk={() => {}}
                    refreshList={() => {
                        userList(ref.current.current);
                    }}
                />
            </GlobalModal>
        </div>
    );
};

export default observer(RoleManagementView);
