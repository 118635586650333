import React from 'react';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { observer } from 'mobx-react-lite';

export type EChartGroupBarChartProps = {
    data: any;
    unit: string;
};

const EchartLineView = (props: EChartGroupBarChartProps) => {
    const { data, unit } = props;
    const barChartOption: EChartsOption = {
        title: [
            {
                x: 'left',
                y: 'top',
                top: '-2%',
                text: `单位:(${unit})`,
                left: '0%',
                textStyle: {
                    fontStyle: 'normal',
                    color: '#9EA5AE',
                    fontfamily: 'MicrosoftYaHei-Bold',
                    fontweight: 'normal',
                    fontstretch: 'normal',
                    fontSize: 12,
                },
            },
        ],
        legend: {
            type: 'scroll',
            // bottom: '-5',
            // textStyle: {
            //     color: '#9EA5AE',
            //     fontSize: 12,
            // },
            // itemGap: 20,
            // itemWidth: 18,
        },
        grid: {
            top: '20%',
            left: '5%',
            bottom: '5%',
            right: '5%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        calculable: true,
        dataZoom: {
            type: 'inside', //inside slider
            show: true, //flase直接隐藏图形
            xAxisIndex: [0],
            start: 0, //滚动条的起始位置
            end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            orient: 'vertical',
        },
        xAxis: [
            {
                type: 'category',
                data: data.xAxisData,
                gridIndex: 0,
                boundaryGap: false,
                axisLine: {
                    //坐标轴轴线相关设置。数学上的x轴
                    show: true,
                    lineStyle: {
                        color: '#e3e6e4',
                    },
                },
                axisLabel: {
                    //坐标轴刻度标签的相关设置
                    textStyle: {
                        color: '#9EA5AE',
                        fontSize: 12,
                    },
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    formatter(data: any) {
                        return data;
                    },
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#e3e6e4',
                    },
                },
                axisTick: {
                    show: false,
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                nameTextStyle: {
                    color: '#9EA5AE',
                    fontSize: 12,
                    padding: 10,
                },
                min: 0,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#e3e6e4',
                    },
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#e3e6e4',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#9EA5AE',
                    },
                },
                axisTick: {
                    show: false,
                },
            },
        ],
        series:
            data.dataSets?.length === 1
                ? [
                      {
                          type: 'line',
                          symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
                          showAllSymbol: true,
                          symbolSize: 0,
                          smooth: true,
                          lineStyle: {
                              normal: {
                                  width: 2,
                              },
                          },
                          tooltip: {
                              show: true,
                          },
                          markPoint: data.dataSets[0].markPoint,
                          name: data.dataSets[0].name,
                          data: data.dataSets[0].data,
                          itemStyle: data.dataSets[0].itemStyle,
                          showSymbol: false,
                      },
                  ]
                : data.dataSets?.map((eachDataSet: any) => {
                      const datas = eachDataSet;
                      //@ts-ignore
                      datas.type = 'line';
                      datas.smooth = true;
                      datas.showSymbol = false;
                      datas.lineStyle = {
                          normal: {
                              width: 2,
                          },
                      };
                      return eachDataSet;
                  }),
    };

    return (
        <ReactEcharts
            key={Date.now()}
            option={barChartOption}
            style={{ width: '100%', height: '95%', backgroundColor: 'transparent', overflow: 'hidden' }}
        />
    );
};

export default observer(EchartLineView);
