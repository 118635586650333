import React, { useState, useRef, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Carousel } from 'antd';
import alarm_bg from '@/assets/images/alarm_bg.png';
import { BigScreen, getIllegalEnter, polling } from '@greendev/common';
import PartTitle from '../../components/PartTitle';
import ContentContainer from '../../components/ContentContainer';
import { userStore } from '@/stores/userStore';

// 最近告警
// const alarmData = [
//     {
//         id: 1,
//         name: '网关离线',
//         alarm_time: '2022-03-10 09:31:51',
//         is_lift: false,
//         content: '限产40%，实际生产56%，超限生产',
//     },
//     {
//         id: 2,
//         name: '超限生产',
//         alarm_time: '2022-03-10 09:31:51',
//         is_lift: true,
//         content: '网关离线,限产40%，实际生产56%，超限生产,限产40%，实际生产56%，超限生产',
//     },
//     { id: 3, content: '限产40%，实际生产56%，超限生产', is_lift: false, alarm_time: '2022-01-05 12:21:15' },
//     { id: 4, content: '限产报警解除', is_lift: true, alarm_time: '2022-01-05 12:21:15' },
//     { id: 5, content: '限产报警解除', is_lift: true, alarm_time: '2022-01-05 12:21:15' },
//     { id: 6, content: '限产40%，实际生产56%，超限生产', is_lift: false, alarm_time: '2022-01-05 12:21:15' },
// ];

/** 展示大屏-违规企业组件 */
const BigScreenViolationPart = () => {
    const [data, setData] = useState<BigScreen.IllegalEnterType>([]);
    const timer = useRef<NodeJS.Timer>();
    const setTimer = (newtimer: NodeJS.Timer) => {
        timer.current = newtimer;
    };
    useEffect(() => {
        if (userStore.menuList.length > 0) {
            polling(getIllegalEnter, setData, setTimer);
        }
        return () => {
            clearTimeout(timer.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.menuList]);

    const currentData = useMemo(() => data, [data]);

    return (
        <div style={{ flex: 1, height: '100%', padding: '14px', overflow: 'hidden' }}>
            <PartTitle>违规企业</PartTitle>
            <ContentContainer>
                {currentData.length > 0 ? (
                    <Carousel
                        className="big-screen-timeline"
                        dotPosition="left"
                        dots={false}
                        infinite={currentData.length > 5}
                        autoplay={currentData.length > 5}
                        rows={5}
                        autoplaySpeed={5000}
                        style={{ width: '100%', height: '100%' }}
                    >
                        {currentData.map(item => (
                            <div key={item.id}>
                                {/* 这个虽然看起来没用，但别删了，删了的话下面的div会被组件写入内联样式 */}
                                <span> </span>
                                <div className="big-screen-timeline-item">
                                    <span className="big-screen-timeline-item-date">
                                        <span>{item.alarm_time.split(' ')[1]}</span>
                                        <span>{item.alarm_time.split(' ')[0]}</span>
                                    </span>
                                    <span className="big-screen-timeline-item-icon">
                                        <img src={alarm_bg} alt="alarm_bg" />
                                        <i className={'iconfont iconbaojing'} />
                                    </span>
                                    <span className="big-screen-timeline-item-company">
                                        <span>
                                            <span className="big-screen-timeline-item-company-badge">{item.trade}</span>
                                            {item.enterprise}
                                        </span>
                                        <span>{item.ad_region}</span>
                                    </span>
                                    {item.lift_time && <span className="big-screen-timeline-item-status">已解除</span>}
                                </div>
                            </div>
                        ))}
                    </Carousel>
                ) : (
                    <div className="nodata-rank">
                        <img src={require('@/assets/images/bigscreen_rank_nodata.png')} alt="nodata" />
                    </div>
                )}
            </ContentContainer>
        </div>
    );
};

export default observer(BigScreenViolationPart);
