/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Radio, Row, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import {
    Account,
    Company,
    CurrentOperation,
    dealRequestError,
    EnterpriseOverview,
    EnterpriseProduction,
    fetchAllCompany,
    getUserInfo,
    kindData,
    OperationStatistics,
    RecentAlarm,
    recoverInformationFromIdentify,
    usePurgedAreaCompanyState,
    Workbench,
} from '@greendev/common';
import { peakvalue } from '@/utils/format';
import CardView from '@/views/users/dashboardView/CardView';
import EchartPie from '@/components/echart/EchartPie';
import EchartBar from '@/components/echart/EchartBar';
import EchartLine from '@/components/echart/EchartLine';
import TimeLine from '@/views/users/dashboardView/TimeLine';
import { EmptyView } from '@/components/EmptyView'; //空数据占位组件
import { AxiosError } from 'axios';
import OccupationBitmap from '@/assets/images/Occupation_bitmap.png';
import iconNormal from '@/assets/images/icon_normal.png';
import iconRelieve from '@/assets/images/icon_relieve.png';
import iconAbnormal from '@/assets/images/icon_abnormal.png';
import production_status1 from '@/assets/images/production_status1.png';
import production_status2 from '@/assets/images/production_status2.png';
import production_status3 from '@/assets/images/production_status3.png';
import production_status4 from '@/assets/images/production_status4.png';
import production_status5 from '@/assets/images/production_status5.png';
import production_status6 from '@/assets/images/production_status6.png';
import notData from '@/assets/images/notData.png';
import IconNotData from '@/assets/images/icon_notData.png';
import notEnterprise from '@/assets/images/notEnterprise.png';
import './index.scss';
import GlobalStyle from '@/styles';
import _ from 'lodash';
import { colors1, colors2 } from './color';
import { userStore } from '@/stores/userStore';
import { PurgedAreaCompanyTreeSelect } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

const { RangePicker } = DatePicker;
const { color } = GlobalStyle;

type SelectedCompanyInfo = {
    companyId: number;
    identificationOrName: string;
};

export type DataProps = {
    day: string;
    // eslint-disable-next-line react/no-unused-prop-types
    schemeData?: any;
};

// 暂无减排计划
export const NotData = (props: DataProps) => (
    <Col span={24} style={{ backgroundImage: `url(${notData})`, justifyContent: 'space-between' }}>
        <span>{''}</span>
        <span className="notDataTile">
            <img src={IconNotData} alt={'暂无减排计划'} className="iconNotData" />
            暂无减排计划
        </span>
        <span className="day">{props.day}</span>
    </Col>
);

// 减排计划
export const Data = (props: DataProps) => (
    <Col
        span={24}
        style={{
            backgroundImage: `url(${
                props.schemeData?.level_name === '橙色预警'
                    ? production_status1
                    : props.schemeData?.level_name === '黄色预警'
                    ? production_status4
                    : props.schemeData?.level_name === '红色预警'
                    ? production_status2
                    : props.schemeData?.level_name === '冬季停产'
                    ? production_status3
                    : props.schemeData?.level_name === '夏季错峰'
                    ? production_status6
                    : production_status5
            })`,
        }}
    >
        <div className="type" style={{ color: props.schemeData?.cat_name === '限产' ? '#000000' : '#FB887F' }}>
            {props.schemeData?.cat_name}
        </div>
        <div className="value" style={{ color: props.schemeData?.cat_name === '限产' ? '#000000' : '#FB887F' }}>
            {props.schemeData?.percent}%
        </div>
        <div className="grade">{props.schemeData?.level_name}</div>
        <div className="day">{props.day}</div>
    </Col>
);

export type UserDashboardViewRouteParams = {
    company: {
        id: number;
        name: string;
    };
};

//用户工作台界面
const UserDashboardView = () => {
    usePurgedAreaCompanyState();
    const { state } = useLocation();

    /**
     * displayed company in current screen , sometimes we need display the passed company from other screens
     * 入口函数处不要做异步处理，这会导致用户点击后延迟跳转，以及直接打开url无法获取数据。selectedCompanyInfo的初始化放在页面打开后进行。
     */
    const [selectedCompanyInfo, setSelectedCompanyInfo] = useState<SelectedCompanyInfo | undefined | any>(undefined);
    // 是否处于加载状态
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initCompany = () => {
        if (isLoading || userStore.category === -1) return;
        if (state) {
            const passedCompanyInfo = state as UserDashboardViewRouteParams;
            setSelectedCompanyInfo({
                companyId: passedCompanyInfo.company.id,
                identificationOrName: passedCompanyInfo.company.name,
            });
        } else {
            setIsLoading(true);
            if (userStore.category === Account.UserType.company || userStore.category === Account.UserType.agent) {
                getUserInfo()
                    .then(accountProfile => {
                        //account is company account, try to lunch dashboard with id of company bind with this account.
                        //account is agent, fetch all company under bind with this agent and try to launch with first one
                        const { enterprises } = accountProfile.results[0];
                        if (enterprises.length !== 0) {
                            setSelectedCompanyInfo({
                                companyId: enterprises[0].id,
                                identificationOrName: enterprises[0].name,
                            });
                        }
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    }); //robustness
            } else {
                console.log(userStore.category, 'run data');
                //admin don't have user information interface , so just use query all company interface
                fetchAllCompany({ get_all: true })
                    // @ts-ignore
                    .then((allCompanies: Company.BackendProfile[]) => {
                        if (allCompanies.length !== 0) {
                            setSelectedCompanyInfo({
                                companyId: allCompanies[0].id,
                                identificationOrName: allCompanies[0].name,
                            });
                        }
                        setIsLoading(false);
                        // (allCompanies as Company.BackendProfile[])
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        initCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.category, state]);

    const [pointData, setPoinData] = useState<Workbench.EnterpriseOverviewFrontEndProfile>();
    const [alarmData, setAlarmData] = useState<Workbench.RecentAlarmFrontEndProfile[]>([]);
    const [enterpriseProduction, setEnterpriseProduction] = useState<Workbench.EnterpriseProductionFrontEndProfile>();
    const [currentOperation, setCurrentOperation] = useState<Workbench.CurrentOperationFrontEndProfile[] | undefined>(
        []
    );
    const [operationStatistics, setOperationStatistics] = useState<Workbench.OperationStatisticsFrontEndProfile[]>();
    const [currentLegend, setCurrentLegend] = useState<{ name: string }[]>();

    // 获取运行统计状态
    const [loadingRuntimeData, setLoadingRuntimeData] = useState<boolean>(false);

    // useEffect(() => {
    //     console.log('operationStatistics', operationStatistics);
    // }, [operationStatistics]);

    // 设备分布
    const deviceSpread = [
        { value: pointData?.production || 0, name: '生产' },
        { value: pointData?.facility || 0, name: '治污' },
    ];
    const [selected, setSelected] = useState(1);
    const [currentRuntimeColor, setCurrentRuntimeColor] = useState<number>(1);
    const [selectedStatistics, setSelectedStatistics] = useState(1);

    const [startDate, setStartDate] = useState(moment().subtract(29, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const onChangeDatePicker = (currentdate: any | undefined) => {
        setStartDate(value => (currentdate && currentdate[0] ? moment(currentdate[0]).format('YYYY-MM-DD') : value));
        setEndDate(value => (currentdate && currentdate[1] ? moment(currentdate[1]).format('YYYY-MM-DD') : value));
    };
    const setTimeWithButton = (days: number) => {
        setStartDate(moment().subtract(days, 'days').format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    };

    // const [time, setTime] = useState(moment().format('YYYY-MM-DD'));
    // const onChangeDatePicker = (value: any, dateString: any) => {
    //     setTime(dateString);
    // };

    const onChange = (e: any) => {
        setSelected(e.target?.value);
        console.log(e);
    };
    const statisticsOnChange = (e: any) => {
        setSelectedStatistics(e.target?.value);
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const companyId = selectedCompanyInfo?.companyId;
        if (companyId === undefined) {
            return;
        }
        //use company id to fetch data~
        console.log(`changed company id is :${companyId}`);
        EnterpriseOverview(companyId)
            .then(result => {
                setPoinData(result);
            })
            .catch(error => {
                dealRequestError(error);
            });
        RecentAlarm(companyId)
            .then(result => setAlarmData(result))
            .catch((error: string | AxiosError<any, any>) => {
                dealRequestError(error);
            });
        EnterpriseProduction(companyId)
            .then(result => setEnterpriseProduction(result))
            .catch((error: string | AxiosError<any, any>) => {
                dealRequestError(error);
            });
    }, [selectedCompanyInfo?.companyId]);

    const legend: any = [];

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getOperationStatistics = (companyId: number, params: any) => {
        if (companyId === undefined) {
            return;
        }
        // // 清除data数据
        // data.length = 0;
        legend.lenth = 0;
        setLoadingRuntimeData(true);
        OperationStatistics(companyId, params)
            .then(result => {
                if (result.length > 0) {
                    const day = moment(params.e_time).diff(moment(params.s_time), 'days');
                    const isBiggerThanOneDay = day > 1;
                    const data: React.SetStateAction<any[]> = [];
                    legend.push('product');
                    // eslint-disable-next-line guard-for-in
                    for (const index in result) {
                        const item = result[index];
                        legend.push(item.name);
                        // 当有数据时
                        if (item.current.length > 0) {
                            // eslint-disable-next-line guard-for-in
                            for (const currentDataIndex in item.current) {
                                const currentData = item.current[currentDataIndex];
                                // 如果当前data此序号没有数据，则添加时间
                                if (!data[currentDataIndex]) {
                                    data[currentDataIndex] = [];
                                    if (isBiggerThanOneDay) {
                                        //2022-03-28
                                        data[currentDataIndex][0] = `${currentData?.time?.slice(0, 10)}`;
                                    } else {
                                        //23:59
                                        data[currentDataIndex][0] = `${currentData?.time?.slice(11, 16)}`;
                                    }
                                }
                                data[currentDataIndex][Number.parseInt(index, 10) + 1] = peakvalue(currentData.mean);
                            }
                        }
                        // 当最后一个监测点没有数据，但之前有有数据的监测点存在时，最后一位补充undefined，防止最后一个监测点不显示
                        if (
                            Number.parseInt(index, 10) === result.length - 1 &&
                            item.current.length === 0 &&
                            data.length > 0
                        ) {
                            for (const Item of data) {
                                Item.push(undefined);
                            }
                        }
                    }
                    // 有数据才添加legend
                    if (data.length > 0) {
                        const currentLegendObj: { name: string }[] = [];
                        currentLegendObj[0] = { name: 'product' };
                        // eslint-disable-next-line guard-for-in
                        for (const index in legend) {
                            currentLegendObj[Number.parseInt(index, 10) + 1] = { name: '' };
                            currentLegendObj[Number.parseInt(index, 10) + 1].name = legend[index] as string;
                        }
                        setCurrentLegend(currentLegendObj);
                        console.log(currentLegend);
                        data.unshift(legend);
                    }
                    // debugger;
                    setOperationStatistics(data);
                } else {
                    setOperationStatistics(undefined);
                }
                setLoadingRuntimeData(false);
            })
            .catch((error: string | AxiosError<any, any>) => {
                setLoadingRuntimeData(false);
                dealRequestError(error);
            });
    };
    const xDataCurrentOperation: string[] | undefined | null | any = [];
    const yDataCurrentOperation: number[] | undefined | null | any = [];
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getCurrentOperation = (companyId: number, params: any) => {
        if (companyId === undefined) {
            return;
        }
        CurrentOperation(companyId, params)
            .then(result => {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                if (JSON.stringify(result) !== '[]') {
                    _.map(result, (item: { name: string; current: number }) => {
                        xDataCurrentOperation.push(item.name);
                        yDataCurrentOperation.push(peakvalue(item.current) || 0);
                    });
                    setCurrentOperation([xDataCurrentOperation, yDataCurrentOperation]);
                } else {
                    setCurrentOperation(undefined);
                }
                setCurrentRuntimeColor(params.kind);
            })
            .catch((error: string | AxiosError<any, any>) => {
                setCurrentRuntimeColor(params.kind);
                dealRequestError(error);
            });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        () => getCurrentOperation(selectedCompanyInfo?.companyId, { kind: selected }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCompanyInfo?.companyId, selected]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        () => {
            if (moment(endDate, 'YYYY-MM-DD') < moment(startDate, 'YYYY-MM-DD')) {
                setStartDate(endDate);
            }
            getOperationStatistics(selectedCompanyInfo?.companyId, {
                kind: selectedStatistics,
                s_time: moment(endDate, 'YYYY-MM-DD') < moment(startDate, 'YYYY-MM-DD') ? endDate : startDate,
                e_time: moment(endDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCompanyInfo?.companyId, selectedStatistics, startDate, endDate]
    );
    const liftStatus = {
        normal: { icon: iconNormal, name: '正常', color: color.themeColor },
        abnormal: { icon: iconAbnormal, name: '异常', color: color.themeRed },
        relieve: { icon: iconRelieve, name: '异常解除', color: color.themeYellow },
    };
    // eslint-disable-next-line no-nested-ternary
    //企业状态
    const status =
        // eslint-disable-next-line no-nested-ternary
        enterpriseProduction?.alarm === null
            ? liftStatus.normal
            : enterpriseProduction?.alarm?.lift_time === null
            ? liftStatus.abnormal
            : liftStatus.relieve;
    return isLoading ? (
        <div className="flex-center" style={{ height: '80vh' }}>
            <Spin />
        </div>
    ) : selectedCompanyInfo === undefined ? (
        <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <img src={notEnterprise} alt={'暂无企业'} />
            <p style={{ fontSize: '22px', color: '#9EA5AE', lineHeight: '40px' }}>当前暂无企业</p>
        </div>
    ) : (
        <div className="container dashboardViewStyle" style={{ height: '100%' }}>
            <i className="iconfont icon_env_company" style={{ marginRight: '10px', color: color.themeColor }} />
            {userStore.category === Account.UserType.company ? (
                <span
                    style={{
                        // display: 'inline-block',
                        // width: '320px',
                        color: color.themeColor,
                        fontSize: '16px',
                        // border: `1px solid ${color.themeColor}`,
                        // borderRadius: '5px',
                    }}
                >
                    {selectedCompanyInfo.identificationOrName}
                </span>
            ) : (
                <PurgedAreaCompanyTreeSelect
                    label="企业"
                    value={selectedCompanyInfo.identificationOrName}
                    style={{ width: '340px', fontSize: '16px' }}
                    treeCheckable={false}
                    onlyCanSelectCompany
                    allowClear={false}
                    onChange={(itemIdentification: string) => {
                        setSelectedCompanyInfo({
                            companyId: recoverInformationFromIdentify(itemIdentification).originObjectId,
                            identificationOrName: itemIdentification,
                        });
                    }}
                />
            )}
            <CardView data={pointData} />
            <div className="userDashooard enterpriseProductionStyle" style={{ margin: '0px' }}>
                <div className="gutter-row" style={{ width: '65.5%', float: 'left' }}>
                    {_.isUndefined(enterpriseProduction) ? (
                        <EmptyView />
                    ) : (
                        <Row gutter={32} style={{ height: '100%' }} className="productionListStyle">
                            <Col span={4}>
                                <img src={status.icon} alt={status.name} className="iconState" />
                                <p style={{ color: status.color }} className="state">
                                    {status.name}
                                </p>
                            </Col>
                            <Col span={14}>
                                <Row gutter={32} className="productionItemStyle">
                                    {enterpriseProduction?.scheme_today &&
                                    enterpriseProduction?.scheme_today !== null ? (
                                        <Data schemeData={enterpriseProduction?.scheme_today} day={'今日'} />
                                    ) : (
                                        <NotData day={'今日'} />
                                    )}
                                    {enterpriseProduction?.scheme_tomorrow &&
                                    enterpriseProduction?.scheme_tomorrow !== null ? (
                                        <Data schemeData={enterpriseProduction?.scheme_tomorrow} day={'明日'} />
                                    ) : (
                                        <NotData day={'明日'} />
                                    )}
                                </Row>
                            </Col>
                            <Col span={6}>
                                <img src={OccupationBitmap} alt={'占位'} className="occupationBitmap" />
                            </Col>
                        </Row>
                    )}
                </div>
                <div className="gutter-row" style={{ width: '33.5%', float: 'right' }}>
                    <span className="common_title">监测点统计</span>
                    {_.isEmpty(pointData) ? (
                        <EmptyView />
                    ) : (
                        <EchartPie
                            data={deviceSpread}
                            colors={[color.themeYellow, color.themeColor]}
                            style={{ marginTop: '-10px' }}
                        />
                    )}
                </div>
            </div>
            <Row gutter={32} style={{ marginRight: '0px', clear: 'both' }}>
                <Col
                    span={16}
                    style={{
                        width: '100%',
                        height: '48vh',
                    }}
                >
                    <Row className="userDashooard">
                        <Col
                            span={24}
                            style={{
                                height: '23.5vh',
                                marginBottom: '1vh',
                                padding: '14px',
                            }}
                        >
                            <Row gutter={32}>
                                <Col span={8}>
                                    <span className="common_title">当前运行</span>
                                </Col>
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    <Radio.Group value={selected} onChange={onChange} style={{ marginTop: '-5px' }}>
                                        {kindData.map(item => (
                                            <Radio.Button value={item.id} key={item.id}>
                                                {item.name}
                                            </Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </Col>
                            </Row>
                            {_.isEmpty(currentOperation) ? (
                                <EmptyView />
                            ) : (
                                <EchartBar
                                    xAxisData={currentOperation?.[0]}
                                    yAxisData={currentOperation?.[1]}
                                    unit={'A'}
                                    colors={
                                        currentRuntimeColor === 1
                                            ? {
                                                  color0: color.themeYellow,
                                                  color1: '#FEEACF',
                                                  fzColor: color.noteColor,
                                                  pictorialBarBottom: '#FEEACF',
                                                  pictorialBarTop: 'rgba(255,216,160,0.8)',
                                              }
                                            : {
                                                  color0: color.themeColor,
                                                  color1: '#CCF3E9',
                                                  fzColor: color.noteColor,
                                                  pictorialBarBottom: '#CCF3E9',
                                                  pictorialBarTop: 'rgba(174,238,226,0.8)',
                                              }
                                    }
                                />
                            )}
                        </Col>
                        <Col
                            span={24}
                            style={{
                                height: '23.5vh',
                                padding: '14px',
                            }}
                        >
                            <Row justify="space-between" gutter={5}>
                                <Col span={9}>
                                    <span className="common_title">运行统计</span>
                                </Col>
                                <Col span={6} style={{ textAlign: 'center' }}>
                                    <Radio.Group
                                        value={selectedStatistics}
                                        onChange={statisticsOnChange}
                                        style={{ marginTop: '-5px' }}
                                    >
                                        {kindData.map(item => (
                                            <Radio.Button value={item.id} key={item.id}>
                                                {item.name}
                                            </Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </Col>
                                <Col
                                    span={9}
                                    style={{
                                        textAlign: 'right',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-start',
                                        marginTop: '-4px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'end',
                                            alignItems: 'flex-start',
                                            textAlign: 'right',
                                            padding: 0,
                                        }}
                                    >
                                        <Button
                                            type={
                                                moment().format('YYYY-MM-DD') === endDate &&
                                                moment().format('YYYY-MM-DD') === startDate
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            style={{
                                                marginRight: '5px',
                                                fontSize: '12px',
                                                padding: '1px 5px',
                                                textAlign: 'center',
                                                height: '30px',
                                            }}
                                            onClick={() => {
                                                setTimeWithButton(0);
                                            }}
                                        >
                                            今日
                                        </Button>
                                        <Button
                                            type={
                                                moment().format('YYYY-MM-DD') === endDate &&
                                                moment().subtract(6, 'days').format('YYYY-MM-DD') === startDate
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            style={{
                                                marginRight: '5px',
                                                fontSize: '12px',
                                                padding: '1px 5px',
                                                textAlign: 'center',
                                                height: '30px',
                                            }}
                                            onClick={() => {
                                                setTimeWithButton(6);
                                            }}
                                        >
                                            近7天
                                        </Button>
                                        <Button
                                            type={
                                                moment().format('YYYY-MM-DD') === endDate &&
                                                moment().subtract(29, 'days').format('YYYY-MM-DD') === startDate
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            style={{
                                                marginRight: '5px',
                                                fontSize: '12px',
                                                padding: '1px 5px',
                                                textAlign: 'center',
                                                height: '30px',
                                            }}
                                            onClick={() => {
                                                setTimeWithButton(29);
                                            }}
                                        >
                                            近30天
                                        </Button>
                                        <RangePicker
                                            disabledDate={current =>
                                                // 可选今天以前时间跨度30天以内的日期。
                                                !(
                                                    current < moment().endOf('day') &&
                                                    current >= moment(endDate).subtract(29, 'day') &&
                                                    current <= moment(startDate).add(29, 'days')
                                                )
                                            }
                                            onCalendarChange={onChangeDatePicker}
                                            format="yyyy-MM-DD"
                                            style={{ height: '30px' }}
                                            value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                                            allowClear={false}
                                            size="small"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {_.isEmpty(operationStatistics) ? (
                                <EmptyView />
                            ) : loadingRuntimeData ? (
                                <div className="flex-center" style={{ height: '80%' }}>
                                    <Spin />
                                </div>
                            ) : (
                                <EchartLine
                                    data={operationStatistics}
                                    colors={selectedStatistics === 1 ? colors1 : colors2}
                                    unit={'A'}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col
                    span={8}
                    style={{
                        width: '100%',
                        height: '48vh',
                        padding: '14px',
                        background: 'rgba(255, 255, 255, 0.39)',
                        border: '1px solid #E3E6E4',
                        borderRadius: '12px',
                    }}
                    className="timeLineContainer"
                >
                    <span className="common_title">
                        {'报警列表'} <span style={{ color: '#899699', fontSize: '12px' }}>{'(最新8条)'}</span>
                        <span className="more">
                            {
                                <Link
                                    to="/alert"
                                    onClick={() => {
                                        // handle(e, '隐患列表');
                                    }}
                                >
                                    更多&gt;
                                </Link>
                            }
                        </span>
                    </span>
                    <div
                        style={{
                            height: '90%',
                            width: '100%',
                            overflowY: 'auto',
                            paddingRight: 20,
                            overflowX: 'hidden',
                        }}
                    >
                        {_.isEmpty(alarmData) ? (
                            <EmptyView
                                image={require('@/assets/images/dashboard_alarm_nodata.png')}
                                description={'报警次数 0次'}
                                style={{ color: '#A6D3CA' }}
                            />
                        ) : (
                            <TimeLine alarmData={alarmData} />
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default observer(UserDashboardView);
