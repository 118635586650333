import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

const NumberClock: React.FC = () => {
    const [currentTime, setCurrentTime] = useState<string>(moment().format('YYYY-MM-DD HH:mm:ss'));
    const ref = useRef<any>();
    useEffect(() => {
        ref.current = setInterval(() => {
            setCurrentTime(moment().format('YYYY-MM-DD HH:mm:ss'));
        }, 1000);
        return () => {
            clearInterval(ref.current);
        };
    }, []);
    return (
        <div
            style={{
                fontFamily: 'env-number',
                fontSize: '16px',
                color: '#ffffff',
                position: 'absolute',
                inset: 'auto 5% 15% auto',
                minWidth: '150px',
            }}
        >
            {currentTime}
        </div>
    );
};

export default NumberClock;
