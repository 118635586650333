import { Form, FormInstance, Input, Select, Typography } from 'antd';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
    Device,
    deviceIsGateway,
    fetchAllMonitoring,
    fetchDevices,
    generateIdentifiedByType,
    Monitoring,
    recoverInformationFromIdentify,
    TreeItemType,
} from '@greendev/common';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

export type AddDeviceContentConfiguration = {
    originDevice: Device.BackendProfile;
    isChangeDeviceMode: boolean;
};

export type AddDeviceContentProps = {
    configuration?: AddDeviceContentConfiguration;
};

const AddDeviceContent = forwardRef<FormInstance<any>, AddDeviceContentProps>((props, ref) => {
    const { configuration } = props;

    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (configuration !== undefined) {
            const { isChangeDeviceMode, originDevice } = configuration;
            if (isChangeDeviceMode === true) {
                setInitialValues({
                    deviceId: originDevice.qr_code,
                });
            } else {
                setInitialValues({
                    deviceId: originDevice.qr_code,
                    name: originDevice.name,
                    enterprise: generateIdentifiedByType(TreeItemType.COMPANY, originDevice.enterprise),
                    multiple: originDevice.multiple,
                    monitoringPoints: originDevice.monitoring_points_name,
                    remark: originDevice.remark === null ? undefined : originDevice.remark,
                    gateway: originDevice.gateway === null ? undefined : originDevice.gateway.toString(10),
                });
            }
        }
    }, [configuration]);

    const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>(
        configuration?.originDevice.enterprise
    );

    const [monitoringPoints, setMonitoringPoints] = useState<Monitoring.FrontEndProfile[]>([]);
    useEffect(() => {
        if (selectedCompanyId !== undefined) {
            //refresh all monitoring points under this area
            fetchAllMonitoring({
                enterprise: selectedCompanyId,
                get_all: true,
            }).then(allMonitoringPoints => {
                setMonitoringPoints(allMonitoringPoints as Monitoring.FrontEndProfile[]);
            });
        }
    }, [selectedCompanyId]);

    //all logic should not work when user want to add or modify the device need play with gateway!

    const [currentInputDeviceId, setCurrentInputDeviceId] = useState<string>('');

    const shouldEnableSelectGatewayFeature = useMemo(() => {
        const isBackfillWithNormalDevice =
            configuration?.originDevice !== undefined && !deviceIsGateway(configuration?.originDevice.category_id);
        const isUserWantAddNormalDevice =
            currentInputDeviceId.length >= 4 && !deviceIsGateway(currentInputDeviceId.slice(0, 4));
        //todo this version only have ct device and gateway device, but there may have more device type in feature
        return isBackfillWithNormalDevice || isUserWantAddNormalDevice;
    }, [configuration, currentInputDeviceId]);

    //store all gateway under this company
    const [gateways, setGateways] = useState<Device.BackendProfile[]>([]);
    //refresh gateway list if user try to add or modify the device need play with gateway!
    useEffect(() => {
        if (selectedCompanyId !== undefined) {
            if (shouldEnableSelectGatewayFeature) {
                //refresh all gateway under this company
                fetchDevices({
                    category_id: 'F001',
                    enterprise_id: selectedCompanyId,
                    get_all: true,
                }).then(allGateway => {
                    // @ts-ignore
                    setGateways(allGateway);
                });
            } else {
                setGateways([]);
                // @ts-ignore
                ref.current?.setFieldsValue({ gateway: undefined });
            }
        }
    }, [ref, selectedCompanyId, shouldEnableSelectGatewayFeature]);

    const tryToMatchGatewayWithCorrespondingMonitoringPoint = () => {
        if (gateways.length > 0) {
            // @ts-ignore
            const gatewayFieldValue = ref.current?.getFieldValue('gateway');
            if (gatewayFieldValue !== undefined) {
                //if user already selected the monitoring point, we will not select the right one for him.
                return;
            }
            // @ts-ignore
            const monitoringPointFieldValue = ref.current?.getFieldValue('monitoringPoints');
            if (monitoringPointFieldValue === undefined) {
                //if user not select the monitoring point, we can not select the corresponding one for him.
                return;
            }
            let selectedMonitoringPointId: number;
            if (configuration?.originDevice !== undefined) {
                if (monitoringPointFieldValue === configuration?.originDevice.monitoring_points_name) {
                    //user not re-select this field
                    selectedMonitoringPointId = configuration?.originDevice.monitoring_points;
                } else {
                    selectedMonitoringPointId = Number(monitoringPointFieldValue);
                }
            } else {
                selectedMonitoringPointId = Number(monitoringPointFieldValue);
            }
            //select the gateway which corresponding to selected monitoring point and set it to select component
            const targetGateway = gateways.find(value => value.monitoring_points === selectedMonitoringPointId);
            if (targetGateway !== undefined) {
                // @ts-ignore
                ref.current?.setFieldsValue({ gateway: targetGateway.id.toString(10) });
            }
        }
    };
    useEffect(() => {
        tryToMatchGatewayWithCorrespondingMonitoringPoint();
    }, [gateways]); //eslint-disable-line react-hooks/exhaustive-deps

    const changeSelectedMonitoringPointId = shouldEnableSelectGatewayFeature
        ? () => {
              tryToMatchGatewayWithCorrespondingMonitoringPoint();
          }
        : undefined;

    const changeCurrentSelectedArea = (targetAreaId?: number) => {
        setSelectedCompanyId(targetAreaId);
        //invalid selected monitoring points and gateway
        setMonitoringPoints([]);
        // @ts-ignore
        ref.current?.setFieldsValue({ gateway: undefined });
        setGateways([]);
        // @ts-ignore
        ref.current?.setFieldsValue({ monitoringPoints: undefined });
    };

    return (
        <Form
            labelCol={{ span: 6 }}
            labelAlign={'right'}
            wrapperCol={{ span: 18 }}
            ref={ref}
            initialValues={initialValues}
            key={initialValues?.name}
        >
            <Form.Item name="deviceId" label="设备ID" rules={[{ required: true, message: '请输入设备ID' }]}>
                <Input
                    disabled={configuration !== undefined && !configuration.isChangeDeviceMode}
                    placeholder="请输入设备ID"
                    onChange={event => {
                        setCurrentInputDeviceId(event.target.value);
                    }}
                />
            </Form.Item>
            {!configuration?.isChangeDeviceMode && (
                <>
                    <Form.Item
                        name={'name'}
                        label="名称"
                        rules={[
                            { required: true, message: '请输入名称' },
                            {
                                pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/,
                                message: '名称只能包含1-20位的汉字、字母、数字、下划线!',
                            },
                        ]}
                    >
                        <Input placeholder="请输入名称" />
                    </Form.Item>
                    <PurgedAreaCompanyTreeSelectUsedInForm
                        required
                        label={'所属企业'}
                        disabled={configuration !== undefined}
                        onlyCanSelectCompany
                        onChange={(itemIdentification?: string) => {
                            if (itemIdentification === undefined) {
                                changeCurrentSelectedArea(undefined);
                                return;
                            }
                            changeCurrentSelectedArea(
                                recoverInformationFromIdentify(itemIdentification).originObjectId
                            );
                        }}
                    />
                    <Form.Item
                        name="monitoringPoints"
                        label="监测点"
                        rules={[{ required: true, message: '请选择监测点' }]}
                    >
                        <Select placeholder="请选择监测点" allowClear onChange={changeSelectedMonitoringPointId}>
                            {monitoringPoints.map(eachCategory => (
                                <Select.Option key={eachCategory.id}>{eachCategory.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {shouldEnableSelectGatewayFeature && (
                        <Form.Item name="gateway" label="网关" rules={[{ required: true, message: '请选择网关' }]}>
                            <Select<number> placeholder="请选择网关" allowClear>
                                {gateways.map(eachGatewayDevice => (
                                    <Select.Option key={eachGatewayDevice.id}>{eachGatewayDevice.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {shouldEnableSelectGatewayFeature && (
                        <Form.Item
                            label={'外接互感器'}
                            name="multiple"
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) => {
                                        const isnumber = /^\d+(\.\d{1,2})?$/.test(value);
                                        const invalidNumberError = new Error('请输入0-99999.99之间的外接互感器倍数');
                                        if (!isnumber) {
                                            return Promise.reject(invalidNumberError);
                                        }
                                        if (Number(value) < 0 || Number(value) > 99999.99) {
                                            return Promise.reject(invalidNumberError);
                                        }
                                        return Promise.resolve(Number(value));
                                    },
                                },
                            ]}
                        >
                            <Input placeholder="请输入外接互感器倍数" suffix={<Typography.Text>倍</Typography.Text>} />
                        </Form.Item>
                    )}

                    <Form.Item
                        name={'remark'}
                        label="备注"
                        rules={[
                            {
                                pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/,
                                message: '备注只能包含1-20位的汉字、字母、数字、下划线!',
                            },
                        ]}
                    >
                        <Input.TextArea allowClear />
                    </Form.Item>
                </>
            )}
        </Form>
    );
});

export default AddDeviceContent;
