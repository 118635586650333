import { request } from "../../utils/request";
/**
 * @description 修改账号信息
 */
export function editUserInfo(id, data) {
  const options = {
    url: `v1/system/personal/${id}/`,
    method: 'patch',
    data: {
      name: data.name,
      mobile: data.mobile
    },
    params: {
      code: data.code
    }
  };
  return request(options);
}