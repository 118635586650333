import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Common } from '@greendev/common';
import { Image, Space, Typography } from 'antd';
import IconNormal from '@/assets/images/icon_normal.png';
import IconFail from '@/assets/images/icon_fail.png';
import GlobalStyle from '@/styles';

export type ImportResultInformation = {
    result: Common.ImportResult;
    onClickExportFailItems: (index: number[]) => void;
};

export type ImportResultModalAction = {
    displayResult: (result: ImportResultInformation) => void;
};

export const ImportResultModal = forwardRef<ImportResultModalAction, any>((props: any, ref) => {
    const { color } = GlobalStyle;
    const modalRef = useRef<CommonModalApi>(null);

    const [importResult, setImportResult] = useState<ImportResultInformation | undefined>(undefined);
    const failIndex = useMemo(() => {
        if (importResult === undefined) {
            return [];
        }
        return importResult.result.fail.map(eachFailItem => eachFailItem.index);
    }, [importResult]);

    useImperativeHandle(ref, () => ({
        displayResult: result => {
            setImportResult(result);
            modalRef.current?.showModal();
        },
    }));

    return (
        <CommonModal
            ref={modalRef}
            onCancel={() => {
                setImportResult(undefined);
                modalRef.current?.hiddenModal();
            }}
            title={'导入结果'}
            footer={<div />}
        >
            {importResult && (
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <div className={'flex-horizontal-center'}>
                        <Image
                            src={importResult.result.success.length === 0 ? IconFail : IconNormal}
                            width={64}
                            height={64}
                            style={{ objectFit: 'contain' }}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <Typography.Text>{`导入成功 : ${importResult.result.success.length} 条`}</Typography.Text>
                    </div>

                    <Space direction={'horizontal'} align={'center'}>
                        <Typography.Text>{`导入失败 : ${failIndex.length} 条`}</Typography.Text>
                        {failIndex.length > 0 && (
                            <>
                                <Typography.Text>{`, `}</Typography.Text>
                                <Typography.Text
                                    style={{ color: color.themeColor }}
                                    onClick={() => {
                                        importResult.onClickExportFailItems(failIndex);
                                    }}
                                >{`点击下载失败的数据`}</Typography.Text>
                            </>
                        )}
                    </Space>
                </Space>
            )}
        </CommonModal>
    );
});
