import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { message, Modal, Popconfirm, Popover, Table, Typography } from 'antd';
import { usePagination } from '@/utils/typeWrapper';
import {
    deleteFeedback,
    fetchAllFeedbacks,
    PaginationWrapper,
    dealRequestError,
    AppManagement,
} from '@greendev/common';
import { ColumnsType } from 'antd/es/table';
import FeedbackDetailContent from '@/views/admin/APPManage/feedbackManageView/FeedbackDetailContent';
import { CommonModalTitle } from '@/components/modal/CommonModalTitle';
import ImagePreviewModal from '@/components/modal/ImagePreviewModal';

const FeedbackManagementView = () => {
    const [feedbackDisplayModal, setFeedbackDisplayModal] = useState<{
        display: boolean;
        displayFeedback?: AppManagement.Feedback;
    }>({
        display: false,
    });

    const [tableDataWithLoadingState, changeLoadingState, refreshTableData, pagination] =
        usePagination<AppManagement.Feedback>(
            (page, pageSize) =>
                fetchAllFeedbacks({
                    page,
                    page_size: pageSize,
                }) as Promise<PaginationWrapper<AppManagement.Feedback>>
        );

    const columns: ColumnsType<AppManagement.Feedback> = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'id',
            align: 'left',
            width: 120,
        },
        {
            title: '内容',
            dataIndex: 'content',
            key: 'id',
            render: item => (
                <Popover
                    content={
                        <div style={{ width: 300 }}>
                            <Typography.Text>{item}</Typography.Text>
                        </div>
                    }
                >
                    <Typography.Text ellipsis>{item}</Typography.Text>
                </Popover>
            ),
        },
        {
            title: '联系方式',
            dataIndex: 'mobile',
            key: 'id',
            render: item => <Typography.Text>{item === null ? '暂无' : item}</Typography.Text>,
        },
        {
            title: '帐号',
            dataIndex: 'username',
            key: 'id',
        },
        {
            title: '反馈时间',
            dataIndex: 'add_time',
            key: 'id',
        },
        {
            title: '操作',
            dataIndex: 'id',
            render: (item, record) => (
                <div className="opera">
                    <i
                        className="iconfont iconchakan1"
                        title={'查看'}
                        onClick={() => {
                            setFeedbackDisplayModal({
                                display: true,
                                displayFeedback: record,
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={'确认要删除吗？'}
                        onConfirm={() => {
                            changeLoadingState(true);
                            deleteFeedback(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    refreshTableData();
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div className="container">
            <ImagePreviewModal />
            <Table<AppManagement.Feedback>
                tableLayout="fixed"
                dataSource={tableDataWithLoadingState.paginationData.results}
                loading={tableDataWithLoadingState.loading}
                pagination={pagination}
                columns={columns}
                rowKey={record => record.id}
                scroll={{ x: '100%', y: 'calc(100vh - 305px)' }}
            />
            {feedbackDisplayModal && (
                <Modal
                    visible={feedbackDisplayModal.display}
                    footer={null}
                    title={<CommonModalTitle title={'反馈详情'} />}
                    onCancel={() => {
                        setFeedbackDisplayModal({
                            display: false,
                        });
                    }}
                >
                    <FeedbackDetailContent displayedFeedback={feedbackDisplayModal.displayFeedback} />
                </Modal>
            )}
        </div>
    );
};

export default observer(FeedbackManagementView);
