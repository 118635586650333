import { TablePaginationConfig } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { dealRequestError, PaginationWrapper, WithLoadingState } from '@greendev/common';
import StatusCode from 'status-code-enum';

export function usePagination<RECORD>(
    dataFetcher: (page: number, pageSize: number) => Promise<PaginationWrapper<RECORD>>
): [
    WithLoadingState<{
        paginationData: PaginationWrapper<RECORD>;
    }>,
    (loadingState: boolean) => void,
    () => void,
    TablePaginationConfig,
    () => void
] {
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [tableDataWithLoadingState, setTableDataWithLoadingState] = useState<
        WithLoadingState<{
            paginationData: PaginationWrapper<RECORD>;
        }>
    >({
        paginationData: {
            count: 0,
            next: null,
            previous: null,
            results: [],
        },
        loading: true,
    });
    const changeLoadingState = (loading: boolean) => {
        setTableDataWithLoadingState({
            ...tableDataWithLoadingState,
            loading,
        });
    };

    const refreshAgentAccountsByCurrentParameters = (page: number, pageSize: number) => {
        changeLoadingState(true);
        dataFetcher(page, pageSize)
            .then(result => {
                setTableDataWithLoadingState({
                    paginationData: result as PaginationWrapper<RECORD>,
                    loading: false,
                });
            })
            .catch(error => {
                if (page > 1 && error.response.status === StatusCode.ClientErrorNotFound) {
                    const lastPage = page - 1;
                    setCurrentPage(lastPage);
                    refreshAgentAccountsByCurrentParameters(lastPage, pageSize);
                } else {
                    // error.res;
                    dealRequestError(error);
                    changeLoadingState(false);
                }
            });
    };
    const refreshTableData = () => {
        refreshAgentAccountsByCurrentParameters(currentPage, currentPageSize);
    };
    useEffect(() => {
        refreshAgentAccountsByCurrentParameters(currentPage, currentPageSize);
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const resetToFirstPage = () => {
        setCurrentPage(1);
        refreshAgentAccountsByCurrentParameters(1, currentPageSize);
    };

    const pagination: TablePaginationConfig = {
        pageSize: currentPageSize, //每页的条数
        current: currentPage, //当前页
        total: tableDataWithLoadingState.paginationData.count, //总数据
        showSizeChanger: true,
        onChange: (page: number, onChangedPageSize: number) => {
            setCurrentPage(page);
            refreshAgentAccountsByCurrentParameters(page, onChangedPageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current: number, onShowSizeChangedPageSize: number) => {
            setCurrentPageSize(onShowSizeChangedPageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: false, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: (total: any) => `共 ${total} 条`,
    };
    return [tableDataWithLoadingState, changeLoadingState, refreshTableData, pagination, resetToFirstPage];
}
