import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input, Tabs, ButtonProps, InputProps, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { userStore } from '@/stores/userStore';
import { isDev } from '@/config';
import LoginContainer from '@/views/login/loginContainer/loginContainer';
import { MobileLoginPart } from '@/views/login/mobileLoginPart/mobileLoginPart';
import { ResetPasswordPart } from '@/views/login/resetPasswordPart/resetPasswordPart';
import { MyIcon } from '@/utils/iconfont';
import './index.scss';
import { Login as LoginWorkSpace } from '@greendev/common';

const { TabPane } = Tabs;

/** 登录按键样式 */
export const LoginButtonStyle: React.CSSProperties = {
    // borderRadius: '27px',
    borderColor: '#E3E6E4',
    borderWidth: '1px',
    width: '100%',
    height: '54px',
    color: '#FFFFFF',
    // backgroundColor: '#42C3AA',
    fontSize: '24px',
};

/** 登录输入框样式 */
export const LoginInputStyle: React.CSSProperties = {
    borderColor: '#E3E6E4',
    borderWidth: '1px',
    borderRadius: '30px',
    fontSize: '16px',
};

/** 登录按钮样式 */
export const LoginButtonProps: ButtonProps = {
    className: 'login-page-form-button',
    style: LoginButtonStyle,
    size: 'large',
};

/** 登录输入框组件 */
export const LoginInput: React.FC<InputProps> = props => (
    <Input maxLength={20} style={{ ...LoginInputStyle }} {...props} />
);
export const LoginPasswordInput: React.FC<InputProps> = props => (
    <Input.Password style={{ ...LoginInputStyle }} maxLength={16} minLength={6} {...props} />
);

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isResetPsw, setIsResetPsw] = useState<boolean>(false);
    const ForgetButton = useMemo(
        () => (
            <div style={{ textAlign: 'right', margin: '-16px 0 20px 0', position: 'relative', zIndex: 99 }}>
                <span
                    onClick={() => {
                        setIsResetPsw(true);
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    忘记密码?
                </span>
            </div>
        ),
        []
    );

    const initialValues: LoginWorkSpace.LoginParams = {
        username: 'admin',
        password: 'finsiot',
    };

    /**
     * 表单验证成功回调
     * @param form
     */
    const onFinished = async (form: LoginWorkSpace.LoginParams) => {
        try {
            await userStore.login(form);
        } catch (error: any) {
            message.error(error.response?.data?.info || '登录失败');
            return;
        }
        const search = new URLSearchParams(location.search);
        // console.log('location.search', location.search);
        // @ts-ignore
        const from = location.state.from || search.get('from') || { pathname: '/dashboard' };
        navigate(from, { replace: true });
    };

    // 动态计算登录框宽度比例
    const [loginBoxScale, setLoginBoxScale] = useState<number>(1);
    const [loginBoxPadding, setLoginBoxPadding] = useState<number>(1);
    const changeLoginBoxScale = () => {
        setLoginBoxScale(
            window.innerHeight / 1080 >= window.innerWidth / 1920 ? window.innerHeight / 1080 : window.innerWidth / 1920
        );
        setLoginBoxPadding(window.innerWidth / 1920);
    };
    useEffect(() => {
        changeLoginBoxScale();
        window.addEventListener('resize', changeLoginBoxScale);
        return () => {
            window.removeEventListener('resize', changeLoginBoxScale);
        };
    }, []);

    return (
        <LoginContainer>
            <div className="login-box">
                <div className="login-image-box">
                    <img src={require('@/assets/images/login_image.png')} alt="login_image" draggable={false} />
                </div>
                <div style={{ display: 'flex', paddingRight: loginBoxPadding > 0.82 ? 193 * loginBoxPadding : 0 }}>
                    <div className="login-content-box" style={{ transform: `scale(${loginBoxScale})` }}>
                        {isResetPsw ? (
                            <ResetPasswordPart
                                backToLogin={() => {
                                    setIsResetPsw(false);
                                }}
                            />
                        ) : (
                            <div id="login">
                                <img
                                    className="login-title"
                                    src={require('@/assets/images/login_title.png')}
                                    alt="login_title"
                                    draggable={false}
                                />
                                <Tabs defaultActiveKey="1" centered tabBarGutter={120}>
                                    <TabPane tab="账号登录" key="1" style={{ color: '#42C3AA' }}>
                                        <Form<LoginWorkSpace.LoginParams>
                                            onFinish={onFinished}
                                            className="login-page-form"
                                            initialValues={isDev ? initialValues : undefined}
                                        >
                                            <Form.Item
                                                name="username"
                                                rules={[{ required: true, message: '请输入用户名！' }]}
                                                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                                            >
                                                <LoginInput
                                                    placeholder="用户名"
                                                    prefix={
                                                        <MyIcon
                                                            type="icon_env_login_accout"
                                                            style={{ margin: '0 8px 0 6px' }}
                                                        />
                                                    }
                                                    size="large"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="password"
                                                rules={[{ required: true, message: '请输入密码！' }]}
                                                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                                            >
                                                <LoginPasswordInput
                                                    placeholder="密码"
                                                    prefix={
                                                        <MyIcon
                                                            type="icon_env_login_psw"
                                                            style={{ margin: '0 8px 0 6px' }}
                                                        />
                                                    }
                                                    size="large"
                                                />
                                            </Form.Item>
                                            {ForgetButton}
                                            <Form.Item>
                                                <Button htmlType="submit" type="primary" {...LoginButtonProps}>
                                                    登录
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </TabPane>
                                    <TabPane tab="验证码登录" key="2">
                                        <MobileLoginPart>{ForgetButton}</MobileLoginPart>
                                    </TabPane>
                                </Tabs>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </LoginContainer>
    );
};

export default observer(Login);
