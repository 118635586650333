import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC, lazy, Suspense, useEffect, useMemo } from 'react';
import type { RouteObject } from 'react-router';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

import { SuspendFallbackLoading } from '@/layout/suspendFallbackLoading';
import WrapperRouteComponent from '@/router/config';
import { userStore } from '@/stores/userStore';

// import RoutesList from '@/router/routesComponents';

export const NotFound = lazy(() => import('@/views/error/404'));

// pages
const LoginPage = lazy(() => import('@/views/login'));
const HomePage = lazy(() => import('@/views/dashboard'));
const LayoutPage = lazy(() => import('@/layout'));
const BigScreen = lazy(() => import('@/views/bigScreen'));

const defaultRouteList: RouteObject[] = [
    {
        path: 'login',
        element: <WrapperRouteComponent element={<LoginPage />} titleId="title.login" />,
    },
    {
        path: '/',
        element: <WrapperRouteComponent element={<LayoutPage />} titleId="" />,
        children: [],
    },
    {
        path: '/monitoring-center',
        element: <WrapperRouteComponent element={<BigScreen />} titleId="monitoring-center" auth />,
    },
];

const errorPages = [
    {
        path: '*',
        element: <WrapperRouteComponent element={<NotFound />} titleId="title.notFount" />,
    },
];

export const defaultMenuRoutes = [
    {
        path: '/dashboard',
        key: '/dashboard',
        meta: {
            title: '首页',
        },
        element: <WrapperRouteComponent element={<HomePage />} titleId="title.dashboard" />,
    },
];

const RenderRouter: FC<RenderRouterProps> = ({ routerList }) => {
    // console.log('routerList', routerList);
    const element = useRoutes(routerList);
    return <Suspense fallback={<SuspendFallbackLoading message="页面加载失败" />}>{element}</Suspense>;
};

const DynamicRouter: FC = () => {
    const { token, menuList = [] } = userStore;
    const navigate = useNavigate();
    const { pathname, state } = useLocation();
    // console.log(RoutesList);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        // console.log('logged', !!token, state);
        if ((!token || token === '') && pathname !== '/login') {
            return navigate({ pathname: 'login' }, { replace: true, state: { from: pathname } });
        }

        /** 大屏页面不进行权限验证 */
        // if(token && token !== '' && pathname === '/monitoring-center') {
        //     // eslint-disable-next-line consistent-return
        //     return;
        // }

        if (token && token !== '' && !userStore.isEmptyPerms) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            !menuList.length && !userStore.isSetMenuList && userStore.afterLogin();
            if (pathname === '/login') {
                navigate({ pathname: '/' }, { replace: true });
            }
        }
        // console.log('pathname: ', pathname);
    }, [menuList, token, navigate, pathname, state]);

    const newRoutes = useMemo(() => {
        const routes = cloneDeep(defaultRouteList);
        const layoutRoute = routes.find(item => item.path === '/')?.children;
        layoutRoute?.push(...cloneDeep([...defaultMenuRoutes, ...menuList]), ...errorPages);
        return routes;
    }, [menuList]);

    return <RenderRouter routerList={newRoutes} />;
};

interface RenderRouterProps {
    routerList: RouteObject[];
}

export default observer(DynamicRouter);
