import { Button } from 'antd';
import React from 'react';

export type CommonModalFooterProps = {
    loading: boolean;
    onCancel?: () => void;
    onOk?: () => void;
};
export const CommonModalFooter = (props: CommonModalFooterProps) => {
    const { loading, onCancel, onOk } = props;
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ padding: '0 25px' }}>
                <Button disabled={loading} onClick={onCancel}>
                    取消
                </Button>
            </div>
            <div style={{ padding: '0 25px' }}>
                <Button type="primary" onClick={onOk} loading={loading} htmlType="submit">
                    确认
                </Button>
            </div>
        </div>
    );
};
