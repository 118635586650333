import React from 'react';
import { Table, TableProps } from 'antd';

export type PaginationProps = {
    pageSize?: number; //分页
    currentPage?: number; //首页
    count?: number; //总条数
    setPageSize?: any; //分页方法
    getData?: any; //触发数据请求方法
};

export type CommonTableProps = PaginationProps &
    Pick<TableProps<any>, 'className' | 'columns' | 'expandable' | 'rowKey' | 'dataSource' | 'loading' | 'pagination'>;

/**
 * 表格组件
 */
export const CommonTable = (props: CommonTableProps) => {
    const paginations = {
        // eslint-disable-next-line react/destructuring-assignment
        pageSize: props.pageSize, //每页的条数
        // eslint-disable-next-line react/destructuring-assignment
        current: props.currentPage, //当前页
        // eslint-disable-next-line react/destructuring-assignment
        total: props.count, //总数据
        showSizeChanger: true,
        onChange: (page?: number, pagesize?: number) => {
            // eslint-disable-next-line react/destructuring-assignment
            props.getData(page, pagesize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current?: any, pagesize?: number) => {
            // eslint-disable-next-line react/destructuring-assignment
            props.setPageSize(pagesize);
        }, //pageSize 变化的回调
        hideOnSinglePage: false, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: (total?: number) => `共 ${total} 条`,
    };
    return (
        <Table
            bordered
            scroll={{ y: 'calc(100vh - 305px)' }}
            locale={{
                emptyText: '暂无数据',
            }}
            // eslint-disable-next-line react/destructuring-assignment
            pagination={props.pagination && paginations}
            {...props}
        />
    );
};
