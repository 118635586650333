import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { getProductRuntime, getEnterStatistics, fetchAllCompany, polling, BigScreen, Company } from '@greendev/common';
import './index.scss';
import NumberClock from './components/NumberClock';
import AlertPart from './parts/alertPart/alertPart';
import CompanyPart from './parts/companyPart/companyPart';
import MapPart from './parts/mapPart/mapPart';
import ProductPart from './parts/productPart/productPart';
import ProductLinePart from './parts/productLinePart/productLinePart';
import RuntimePart from './parts/runtimePart/runtimePart';
import ViolationPart from './parts/violationPart/violationPart';
import { userStore } from '@/stores/userStore';

/** 企业统计组件-格式转化 */
export const formatCompanyData = (dataArr: { [key: string]: any }[]) => {
    for (const item of dataArr) {
        item.value = item.count;
    }
    return dataArr as { name: string; value: number }[];
};

const BigScreenView = () => {
    // 运行统计+监测点统计数据
    const [data, setData] = useState<BigScreen.ProductRuntimeType>({
        total: 0,
        production: 0,
        pollution: 0,
        open_pollution: 0,
        open_production: 0,
    });
    const timer = useRef<NodeJS.Timer>();
    const setTimer = (newtimer: NodeJS.Timer) => {
        timer.current = newtimer;
    };

    // 地图数据
    const [mapData, setMapData] = useState<Company.BackendProfile[]>([]);
    const mapTimer = useRef<NodeJS.Timer>();
    const setMapTimer = (newtimer: NodeJS.Timer) => {
        mapTimer.current = newtimer;
    };

    // 企业统计数据
    const [companyData, setCompanyData] = useState<BigScreen.EnterStatisticsType>();
    const companyTimer = useRef<NodeJS.Timer>();
    const setCompanyTimer = (newtimer: NodeJS.Timer) => {
        companyTimer.current = newtimer;
    };

    useEffect(() => {
        if (userStore.menuList.length > 0) {
            polling(getProductRuntime, setData, setTimer);
            polling(fetchAllCompany, setMapData, setMapTimer, { get_all: true });
            polling(getEnterStatistics, setCompanyData, setCompanyTimer);
        }
        return () => {
            clearTimeout(timer.current!);
            clearTimeout(mapTimer.current!);
            clearTimeout(companyTimer.current!);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.menuList]);

    // useEffect(() => {
    //     console.log(mapData);
    // }, [mapData]);

    // 动态计算标题字体比例
    const [titleScale, setLoginBoxScale] = useState<number>(1);
    const changeLoginBoxScale = () => {
        setLoginBoxScale(window.innerWidth / 1920);
    };
    useEffect(() => {
        changeLoginBoxScale();
        window.addEventListener('resize', changeLoginBoxScale);
        return () => {
            window.removeEventListener('resize', changeLoginBoxScale);
        };
    }, []);

    return (
        <div className="big-screen-container">
            <div className="big-screen-container-title">
                <div className="big-screen-container-title-title" style={{ fontSize: Math.floor(titleScale * 38) }}>
                    智慧环保监测中心
                </div>
                <NumberClock />
            </div>
            <div className="big-screen-container-top">
                <div className="big-screen-container-top-left">
                    <CompanyPart
                        areaAnalysisData={companyData ? formatCompanyData(companyData.ad_region) : []}
                        industryAnalysisData={companyData ? formatCompanyData(companyData.trade) : []}
                        scale={titleScale}
                    />
                    <ProductLinePart data={data} />
                </div>
                <div className="big-screen-container-top-center">
                    <MapPart displayedCompany={mapData} />
                </div>
                <div className="big-screen-container-top-right">
                    <ProductPart />
                    <RuntimePart data={data} />
                </div>
            </div>
            <div className="big-screen-container-bottom">
                <div className="big-screen-container-bottom-left">
                    <AlertPart />
                </div>
                <div className="big-screen-container-bottom-right">
                    <ViolationPart />
                </div>
            </div>
        </div>
    );
};

export default observer(BigScreenView);
