import { dealRequestError, request } from "../../utils/request";
import _ from 'lodash';
export function fetchAllCurrentTree(params = {}) {
  return new Promise(resolve => {
    request({
      url: '/v1/scheme/current/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(result => {
      if (params.get_all === true) {
        resolve(result);
      } else {
        resolve(result);
      }
    }).catch(dealRequestError);
  });
}

// 删除电流限制
export function deleteCurrentById(id) {
  return request({
    url: `/v1/scheme/current/${id}/`,
    method: 'DELETE'
  });
}

//电流限制详情
export function queryCurrentById(id) {
  return request({
    url: `/v1/scheme/current/${id}/`,
    method: 'GET'
  });
}

// 添加电流限制
export function createCurrent(params) {
  return request({
    url: '/v1/scheme/current/',
    method: 'POST',
    data: _.omitBy(_.omit(params, 'enter'), _.isUndefined)
  });
}

// 修改电流限制
export function modifyCurrent(id, params) {
  return request({
    url: `/v1/scheme/current/${id}/`,
    method: 'PATCH',
    data: _.omitBy(_.omit(params, 'enter'), _.isUndefined)
  });
}