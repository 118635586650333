import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import EchartBar3D from '@/components/echart/EchartBar3d';
import { polling, getAlarmTrend, BigScreen } from '@greendev/common';
import PartTitle from '../../components/PartTitle';
import ContentContainer from '../../components/ContentContainer';
import NoData from '../../components/NoData';
import { userStore } from '@/stores/userStore';

const formatCompanyData = (dataArr: { [key: string]: any }[]) => {
    let index = 0;
    while (index < dataArr.length) {
        if (dataArr[index].count === 0) {
            dataArr.splice(index, 1);
        } else {
            // eslint-disable-next-line no-param-reassign
            dataArr[index].value = dataArr[index].count;
            index++;
        }
    }
    return dataArr as { name: string; value: number }[];
};

/** 展示大屏-报警排行组件 */
const BigScreenAlertPart = () => {
    const [data, setData] = useState<BigScreen.AlarmTrendType>([]);
    const timer = useRef<NodeJS.Timer>();
    const setTimer = (newtimer: NodeJS.Timer) => {
        timer.current = newtimer;
    };
    useEffect(() => {
        if (userStore.menuList.length > 0) {
            polling(getAlarmTrend, setData, setTimer);
        }
        return () => {
            clearTimeout(timer.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.menuList]);

    return (
        <div style={{ flex: 1, height: '100%', padding: '14px', overflow: 'hidden' }}>
            <PartTitle>本月告警排行</PartTitle>
            <ContentContainer>
                {data.length > 0 ? (
                    <EchartBar3D data={{ data: formatCompanyData(data) as [] }} unit={'次'} />
                ) : (
                    <NoData isCharts />
                )}
            </ContentContainer>
        </div>
    );
};

export default observer(BigScreenAlertPart);
