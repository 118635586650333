/**
 * 自动加载views目录下的index.tsx文件
 */
const context = require.context('@/views', true, /index.tsx/);

const routesComponents: { [key: string]: any } = {};

for (const key of context.keys()) {
    routesComponents[key.replace('./', 'views/').replace('/index.tsx', '')] = context(key);
}

// console.log(routesComponents);

export default routesComponents;
