import React, { forwardRef, useEffect, useState } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';
import { generateIdentifiedByType, kindData, Monitoring, queryMonitoringById, TreeItemType } from '@greendev/common';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

export type MonitoringEditContentPorps = {
    editMode?: string;
    targetMonitoring?: Monitoring.FrontEndProfile;
};

const MonitoringEditContent = forwardRef<FormInstance<any>, MonitoringEditContentPorps>((props, ref) => {
    const { editMode, targetMonitoring } = props;
    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (targetMonitoring !== undefined && editMode !== 'del') {
            queryMonitoringById(targetMonitoring.id).then(() => {
                //notice : if you don't want to send a request to query name , you can get the area name from ad_region_name field
                setInitialValues({
                    name: targetMonitoring.name,
                    enterprise: generateIdentifiedByType(TreeItemType.COMPANY, targetMonitoring.enterprise),
                    kind: targetMonitoring.kind,
                    desc: targetMonitoring.desc,
                });
            });
        } else {
            setInitialValues({});
        }
    }, [editMode, targetMonitoring]);
    return (
        <Form
            labelAlign={'right'}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            ref={ref}
            initialValues={initialValues}
            key={initialValues?.name}
        >
            <Form.Item
                label="监测点名称"
                name="name"
                extra={
                    editMode === 'del' && (
                        <p style={{ fontSize: '12px', color: '#9EA5AE', marginTop: '6px' }}>
                            此操作无法撤消。监测点删除后，该监测点下所有设备一并删除，数据不可恢复。
                        </p>
                    )
                }
                rules={[
                    { required: true, message: '请输入监测点名称' },
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/,
                        message: '监测点名称只能包含1-20位的汉字、字母、数字、下划线!',
                    },
                    {
                        whitespace: true,
                        message: '不允许输入空格',
                    },
                ]}
            >
                <Input placeholder="请输入监测点名称" />
            </Form.Item>
            {editMode !== 'del' && (
                <>
                    <PurgedAreaCompanyTreeSelectUsedInForm
                        label="所属企业"
                        style={{ width: '100%' }}
                        treeCheckable={false}
                        required
                        onlyCanSelectCompany
                        disabled={editMode === 'edit'}
                    />
                    <Form.Item label="监测类型" name="kind" rules={[{ required: true, message: '请选择监测类型' }]}>
                        <Select placeholder="请选择监测类型" virtual={false} showArrow allowClear>
                            {kindData.map(item => (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="desc"
                        rules={[
                            { required: false, message: '请输入备注' },
                            {
                                pattern: /^.{0,20}$/,
                                message: '备注长度为20位!',
                            },
                            {
                                whitespace: true,
                                message: '不允许输入空格',
                            },
                        ]}
                    >
                        <Input placeholder="请输入备注" />
                    </Form.Item>
                </>
            )}
        </Form>
    );
});

export default MonitoringEditContent;
