import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input, DatePicker, Select, Collapse, List } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CommonTable } from '@/components/table/CommonTable';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import { disabledDaysBeforeToday } from '@/AntDesignReset';
import _ from 'lodash';
import {
    fetchAllOperationLog,
    terminal,
    action,
    OperationLog,
    dealRequestError,
    WithLoadingState,
} from '@greendev/common';
import { AxiosError } from 'axios';
import moment from 'moment';
import GlobalStyle from '@/styles';
import './style.scss';

const { color } = GlobalStyle;

const SearchForm = (props: {
    setSearchValue: (page?: number, value?: any) => void;
    setSearchParams: (value?: any) => void;
}) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { setSearchValue, setSearchParams } = props;
    const onFinish = (values?: any) => {
        setSearchParams(values);
        setSearchValue(1, values);
    };

    return (
        <Form
            layout="horizontal"
            labelAlign={'right'}
            style={{ display: 'flex', justifyContent: 'end' }}
            onFinish={onFinish}
        >
            <Form.Item label="操作账号" name="username" style={{ marginLeft: '16px' }}>
                <Input placeholder="请输入操作账号" style={{ width: '180px' }} />
            </Form.Item>
            <Form.Item label="操作类型" name="action" style={{ marginLeft: '16px' }}>
                <Select<number>
                    placeholder="请选择操作类型"
                    allowClear
                    showArrow
                    virtual={false}
                    style={{ width: '150px' }}
                >
                    {action.map(
                        (item: {
                            id: React.Key | null | undefined;
                            name:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                        }) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        )
                    )}
                </Select>
            </Form.Item>
            <Form.Item label="操作对象" name="action_model" style={{ marginLeft: '16px' }}>
                <Input placeholder="请输入操作对象" style={{ width: '180px' }} />
            </Form.Item>
            <Form.Item label="终端" name="client" style={{ marginLeft: '16px' }}>
                <Select<number>
                    placeholder="请选择终端"
                    allowClear
                    showArrow
                    virtual={false}
                    style={{ width: '150px' }}
                >
                    {terminal.map(
                        (item: {
                            id: React.Key | null | undefined;
                            name:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                        }) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        )
                    )}
                </Select>
            </Form.Item>
            <Form.Item label="操作日期" name="date" style={{ marginLeft: '16px' }}>
                <DatePicker
                    disabledDate={disabledDaysBeforeToday}
                    style={{ width: '180px' }}
                    placeholder="请选择日期"
                    format={'YYYY-MM-DD'}
                />
            </Form.Item>
            <Form.Item style={{ marginLeft: '16px' }}>
                <Button type="primary" htmlType="submit">
                    搜索
                </Button>
            </Form.Item>
        </Form>
    );
};

// 操作日志页面
const OperationLogView = () => {
    const modalRef = useRef<CommonModalApi>(null);
    const [displayAddPollutionFormModal, setDisplayAddPollutionFormModal] = useState<{
        data: any[] | undefined;
    }>();
    const [logWithLoadingState, setLogWithLoadingState] = useState<
        WithLoadingState<{
            log: OperationLog.BackendLogProfile[];
        }>
    >({
        log: [],
        loading: true,
    });

    const changeLoadingState = (loading: boolean) => {
        setLogWithLoadingState({
            ...logWithLoadingState,
            loading,
        });
    };

    const [total, setTotal] = useState<number>(0);
    const [logPage, setLogPage] = useState<number | undefined>(1);
    const [pageSize, setPageSize] = useState<number | undefined>(10);
    const [searchParams, setSearchParams] = useState({});

    const refreshCurrentByLogParameters = (page = 1, searchValue?: any, page_size?: number) => {
        changeLoadingState(true);
        setLogPage(page);
        const params = _.omitBy(
            searchValue,
            (value?: any) => _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
        );
        params.page = page;
        params.page_size = page_size || pageSize;
        params.date = searchValue?.date ? moment(searchValue.date).format('YYYY-MM-DD') : null;
        fetchAllOperationLog(params)
            .then((result: any) => {
                setLogWithLoadingState({
                    log: result.results as OperationLog.BackendLogProfile[],
                    loading: false,
                });
                setTotal(result.count);
            })
            .catch((error: string | AxiosError<any, any>) => {
                dealRequestError(error);
                changeLoadingState(false);
            });
    };

    useEffect(() => {
        refreshCurrentByLogParameters();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const columns: ColumnsType<OperationLog.BackendLogProfile> = [
        {
            title: '操作账号',
            dataIndex: 'username',
            key: 'username',
            width: 240,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '操作类型',
            dataIndex: 'action',
            key: 'action',
            width: 110,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '操作对象',
            dataIndex: 'action_model',
            key: 'action_model',
            width: 160,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '对象ID',
            dataIndex: 'obj_id',
            key: 'obj_id',
            width: 220,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '操作详情',
            dataIndex: 'detail',
            key: 'detail',
            width: 440,
            align: 'left',
            render: (text?: any, recoad?: any) => {
                if (text === null) {
                    return '-';
                }
                if (recoad.action === '批量导入') {
                    return (
                        <div>
                            {recoad.detail[0].field}:
                            <span
                                style={{
                                    borderBottom: `1px solid ${color.themeColor}`,
                                    color: color.themeColor,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    modalRef.current?.showModal();
                                    setDisplayAddPollutionFormModal({ data: recoad.detail[0].new });
                                }}
                            >
                                {recoad.detail[0].new?.length}条
                            </span>
                        </div>
                    );
                }
                return text.length > 1 ? (
                    <Collapse ghost>
                        <Collapse.Panel
                            header={
                                <p
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        marginBottom: '0px',
                                        textAlign: 'left',
                                    }}
                                    title={`
                                    ${text[0].field} 
                                    ${
                                        text[0].old !== undefined
                                            ? `：  旧值为 ${
                                                  Object.prototype.toString.call(text?.[0].old?.[0]) ===
                                                  '[object Object]'
                                                      ? _.map(text?.[0].old, oldItem => JSON.stringify(oldItem))
                                                      : text[0].old
                                              }`
                                            : ''
                                    }
                                    ${
                                        text[0].new !== undefined
                                            ? `， 新值为 ${
                                                  Object.prototype.toString.call(text?.[0].new?.[0]) ===
                                                  '[object Object]'
                                                      ? _.map(text?.[0].new, newItem => JSON.stringify(newItem))
                                                      : text[0].new
                                              }`
                                            : ''
                                    }
                                    `}
                                >
                                    {`
                                        ${text[0].field}
                                        ${
                                            text[0].old !== undefined
                                                ? `：  旧值为 ${
                                                      Object.prototype.toString.call(text?.[0].old?.[0]) ===
                                                      '[object Object]'
                                                          ? _.map(text?.[0].old, oldItem => JSON.stringify(oldItem))
                                                          : text[0].old
                                                  }`
                                                : ''
                                        }
                                        ${
                                            text[0].new !== undefined
                                                ? `， 新值为 ${
                                                      Object.prototype.toString.call(text?.[0].new?.[0]) ===
                                                      '[object Object]'
                                                          ? _.map(text?.[0].new, newItem => JSON.stringify(newItem))
                                                          : text[0].new
                                                  }`
                                                : ''
                                        }
                                    `}
                                </p>
                            }
                            key="1"
                        >
                            {_.map(text.slice(1), item => (
                                <p
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        marginBottom: '0px',
                                        textAlign: 'left',
                                    }}
                                    key={Math.random()}
                                    title={`
                                        ${item.field}
                                        ${
                                            item.old !== undefined
                                                ? `：  旧值为 ${
                                                      Object.prototype.toString.call(item.old?.[0]) ===
                                                      '[object Object]'
                                                          ? _.map(item.old, oldItem => JSON.stringify(oldItem))
                                                          : item.old
                                                  }`
                                                : ''
                                        } 
                                        ${
                                            item.new !== undefined
                                                ? `， 新值为 ${
                                                      Object.prototype.toString.call(item.new?.[0]) ===
                                                      '[object Object]'
                                                          ? _.map(item.new, newItem => JSON.stringify(newItem))
                                                          : item.new
                                                  }`
                                                : ''
                                        }
                                    `}
                                >
                                    {`
                                        ${item.field}
                                        ${
                                            item.old !== undefined
                                                ? `：  旧值为 ${
                                                      Object.prototype.toString.call(item.old?.[0]) ===
                                                      '[object Object]'
                                                          ? _.map(item.old, oldItem => JSON.stringify(oldItem))
                                                          : item.old
                                                  }`
                                                : ''
                                        }
                                        ${
                                            item.new !== undefined
                                                ? `， 新值为 ${
                                                      Object.prototype.toString.call(item.new?.[0]) ===
                                                      '[object Object]'
                                                          ? _.map(item.new, newItem => JSON.stringify(newItem))
                                                          : item.new
                                                  }`
                                                : ''
                                        }
                                    `}
                                </p>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                ) : (
                    <p
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            marginBottom: '0px',
                            textAlign: 'left',
                        }}
                        title={`
                            ${text[0].field} 
                            ${
                                text[0].old !== undefined
                                    ? `：  旧值为 ${
                                          Object.prototype.toString.call(text?.[0].old?.[0]) === '[object Object]'
                                              ? _.map(text?.[0].old, oldItem => JSON.stringify(oldItem))
                                              : text[0].old
                                      }`
                                    : ''
                            } 
                            ${
                                text[0].new !== undefined
                                    ? `， 新值为 ${
                                          Object.prototype.toString.call(text?.[0].new?.[0]) === '[object Object]'
                                              ? _.map(text?.[0].new, newItem => JSON.stringify(newItem))
                                              : text[0].new
                                      }`
                                    : ''
                            }
                        `}
                    >
                        {`
                            ${text[0].field} 
                            ${
                                text[0].old !== undefined
                                    ? `：  旧值为 ${
                                          Object.prototype.toString.call(text?.[0].old?.[0]) === '[object Object]'
                                              ? _.map(text?.[0].old, oldItem => JSON.stringify(oldItem))
                                              : text[0].old
                                      }`
                                    : ''
                            } 
                            ${
                                text[0].new !== undefined
                                    ? `， 新值为 ${
                                          Object.prototype.toString.call(text?.[0].new?.[0]) === '[object Object]'
                                              ? _.map(text?.[0].new, newItem => JSON.stringify(newItem))
                                              : text[0].new
                                      }`
                                    : ''
                            }
                        `}
                    </p>
                );
            },
        },
        {
            title: '终端',
            dataIndex: 'client',
            key: 'client',
            width: 80,
        },
        {
            title: 'IP地址',
            dataIndex: 'ip',
            key: 'ip',
            width: 140,
        },
        {
            title: '操作时间',
            dataIndex: 'create_time',
            key: 'create_time',
            width: 180,
        },
    ];

    const paginations = {
        // eslint-disable-next-line react/destructuring-assignment
        pageSize, //每页的条数
        // eslint-disable-next-line react/destructuring-assignment
        current: logPage, //当前页
        // eslint-disable-next-line react/destructuring-assignment
        total, //总数据
        showSizeChanger: true,
        onChange: (page?: number, pagesize?: number) => {
            // eslint-disable-next-line react/destructuring-assignment
            setLogPage(page);
            setPageSize(pagesize);
            refreshCurrentByLogParameters(page, searchParams, pagesize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current?: any, pagesize?: number) => {
            console.log(current);
            // eslint-disable-next-line react/destructuring-assignment
            setPageSize(pagesize);
        }, //pageSize 变化的回调
        hideOnSinglePage: false, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        // eslint-disable-next-line @typescript-eslint/no-shadow
        showTotal: (total: any) => `共 ${total} 条`,
    };
    return (
        <div className="container opertionLogStyle">
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <SearchForm setSearchValue={refreshCurrentByLogParameters} setSearchParams={setSearchParams} />
            </div>
            <CommonTable
                dataSource={logWithLoadingState.log}
                loading={logWithLoadingState.loading}
                columns={columns}
                rowKey={record => record.id}
                pagination={paginations}
            />
            <CommonModal
                ref={modalRef}
                onOk={() => {
                    modalRef.current?.hiddenModal();
                }}
                onCancel={() => {
                    modalRef.current?.hiddenModal();
                }}
                title={'批量导入ID'}
            >
                <List
                    bordered
                    dataSource={displayAddPollutionFormModal?.data}
                    renderItem={item => <List.Item>{item}</List.Item>}
                    style={{
                        maxHeight: '50vh',
                        overflow: 'auto',
                    }}
                />
            </CommonModal>
        </div>
    );
};

export default observer(OperationLogView);
