import React from 'react';
import { Empty } from 'antd';

export type EmptyViewProps = {
    description?: any;
    image?: any;
    style?: React.CSSProperties;
};

export const EmptyView = (props: EmptyViewProps) => {
    const { description, image, style } = props;
    return (
        <div style={{ height: '94%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Empty
                image={image || Empty.PRESENTED_IMAGE_SIMPLE}
                description={description || '暂无数据'}
                style={style}
            />
        </div>
    );
};
