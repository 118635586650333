import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import { Button, Col, FormInstance, Image, message, Popconfirm, Row, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { usePagination } from '@/utils/typeWrapper';
import CreateOrUpdateBannerContent from '@/views/admin/APPManage/bannerManageView/CreateOrUpdateBannerContent';
import {
    UploadSituation,
    dealRequestError,
    PaginationWrapper,
    reduceSamePropertiesFromObject,
    createBanner,
    deleteBanner,
    fetchAllBanners,
    modifyBanner,
    AppManagement,
} from '@greendev/common';
import {
    fileUploaderPurposeKeyMapping,
    useFileUploaderDisposer,
    useFileUploaderState,
} from '@/components/fileUploader/manage';
import _ from 'lodash';

const BannerManagementView = () => {
    const modalRef = useRef<CommonModalApi>(null);
    const bannerCreateOrUpdateModalRef = useRef<FormInstance<any>>(null);
    const [bannerCreateOrUpdateModalState, setBannerCreateOrUpdateModalState] = useState<{
        display: boolean;
        targetBanner?: AppManagement.Banner;
    }>({
        display: false,
    });
    useEffect(() => {
        if (bannerCreateOrUpdateModalState !== undefined && bannerCreateOrUpdateModalState.display) {
            modalRef.current?.showModal();
        }
    }, [bannerCreateOrUpdateModalState]);

    const [tableDataWithLoadingState, changeLoadingState, refreshTableData, pagination] =
        usePagination<AppManagement.Banner>(
            (page, pageSize) =>
                fetchAllBanners({
                    page,
                    page_size: pageSize,
                }) as Promise<PaginationWrapper<AppManagement.Banner>>
        );

    const uploadBannerState = useFileUploaderState(fileUploaderPurposeKeyMapping.uploadBanner);
    useFileUploaderDisposer(fileUploaderPurposeKeyMapping.uploadBanner);

    const columns: ColumnsType<AppManagement.Banner> = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'id',
            align: 'left',
            width: 120,
        },
        {
            title: '广告位置',
            dataIndex: 'seat_name',
            key: 'id',
        },
        {
            title: '广告图片',
            dataIndex: 'image_url',
            key: 'id',
            render: item => <Image width={50} src={item} />,
        },
        {
            title: '跳转连接',
            dataIndex: 'site',
            key: 'id',
        },
        {
            title: '启用状态',
            dataIndex: 'state',
            key: 'id',
            render: (item, record) => (
                <Switch
                    checked={item}
                    size="small"
                    onChange={(value: boolean) => {
                        changeLoadingState(true);
                        modifyBanner(record.id, { state: value })
                            .then(() => {
                                message.success('状态修改成功');
                                refreshTableData();
                            })
                            .catch(dealRequestError)
                            .finally(() => {
                                changeLoadingState(false);
                            });
                    }}
                />
            ),
        },
        {
            title: '添加时间',
            dataIndex: 'add_time',
            key: 'id',
        },
        {
            title: '操作',
            dataIndex: 'id',
            render: (item, record) => (
                <div className="opera">
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setBannerCreateOrUpdateModalState({
                                display: true,
                                targetBanner: record,
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={'确认要删除吗？'}
                        onConfirm={() => {
                            changeLoadingState(true);
                            deleteBanner(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    refreshTableData();
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div className="container">
            <Row align={'middle'} className={'commonButton'} gutter={16}>
                <Col span={1}>
                    <Button
                        style={{ height: '100%' }}
                        type="primary"
                        onClick={() => {
                            setBannerCreateOrUpdateModalState({
                                display: true,
                            });
                        }}
                    >
                        新增
                    </Button>
                </Col>
            </Row>

            <Table<AppManagement.Banner>
                tableLayout="fixed"
                dataSource={tableDataWithLoadingState.paginationData.results}
                loading={tableDataWithLoadingState.loading}
                pagination={pagination}
                columns={columns}
                rowKey={record => record.id}
                scroll={{ x: '100%', y: 'calc(100vh - 305px)' }}
            />
            {bannerCreateOrUpdateModalState && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        bannerCreateOrUpdateModalRef.current?.validateFields().then(() => {
                            const { targetBanner } = bannerCreateOrUpdateModalState;
                            const isEditMode = targetBanner !== undefined;
                            const fieldValues: {
                                'title': string;
                                'location': string;
                                'jump-link': string | undefined;
                                'picture': any;
                            } = bannerCreateOrUpdateModalRef.current?.getFieldsValue();
                            modalRef.current?.startLoading();

                            uploadBannerState!!
                                .uploadSelectedFiles(UploadSituation.BANNER)
                                .then(allImages => {
                                    const addOrModifyAgentAccountParameter: AppManagement.CreateOrUpdateBannerParameter =
                                        {
                                            title: fieldValues.title,
                                            seat: Number(fieldValues.location),
                                            image: allImages.length === 0 ? undefined : allImages[0].relativeUrl,
                                            site: fieldValues['jump-link'],
                                        };

                                    let addOrModifyPromise;
                                    if (isEditMode) {
                                        const modifyParams = reduceSamePropertiesFromObject(
                                            addOrModifyAgentAccountParameter,
                                            targetBanner,
                                            [
                                                {
                                                    keyInOriginalObject: 'image',
                                                    keyInBaseMarkObject: 'image_url',
                                                    compareFunction: (
                                                        originalObjectValue: string,
                                                        baseMarkObjectValue: string
                                                    ) => baseMarkObjectValue.endsWith(originalObjectValue),
                                                },
                                            ]
                                        );
                                        if (_.keysIn(modifyParams).length === 0) {
                                            addOrModifyPromise = new Promise<void>(resolve => {
                                                resolve();
                                            });
                                        } else {
                                            addOrModifyPromise = modifyBanner(targetBanner.id, modifyParams);
                                        }
                                    } else {
                                        addOrModifyPromise = createBanner(addOrModifyAgentAccountParameter);
                                    }
                                    addOrModifyPromise
                                        .then(() => {
                                            message.success(isEditMode ? '编辑成功' : '添加成功');
                                            refreshTableData();
                                            modalRef.current?.hiddenModal();
                                            bannerCreateOrUpdateModalRef.current?.resetFields();
                                            setBannerCreateOrUpdateModalState({
                                                display: false,
                                            });
                                        })
                                        .catch(dealRequestError)
                                        .finally(() => {
                                            modalRef.current?.endLoading();
                                        });
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    modalRef.current?.endLoading();
                                });
                        });
                    }}
                    onCancel={() => {
                        bannerCreateOrUpdateModalRef.current?.resetFields();
                        setBannerCreateOrUpdateModalState({
                            display: false,
                        });
                    }}
                    title={`${bannerCreateOrUpdateModalState.targetBanner ? '编辑' : '新增'}`}
                >
                    <CreateOrUpdateBannerContent
                        ref={bannerCreateOrUpdateModalRef}
                        originalBanner={bannerCreateOrUpdateModalState.targetBanner}
                    />
                </CommonModal>
            )}
        </div>
    );
};

export default observer(BannerManagementView);
