import { request } from "../../utils/request";
/**
 * @description 获取企业统计(双端使用)
 */
export function getEnterStatistics() {
  const options = {
    url: `/v1/visual/monitor_center/enter_statistics/`,
    method: 'get'
  };
  return request(options);
}

/**
 * @description 获取企业统计APP
 */
export function getEnterStatisticsApp(start_time) {
  const options = {
    url: `/v1/visual/index/enter/static/`,
    method: 'get',
    params: {
      start_time
    }
  };
  return request(options);
}

/**
 * @description 获取企业生产
 */
export function getEnterProduct() {
  const options = {
    url: `/v1/visual/monitor_center/enter_pro/`,
    method: 'get'
  };
  return request(options);
}

/**
 * @description 获取产线运行统计
 */
export function getProductRuntime() {
  const options = {
    url: `/v1/visual/monitor_center/pro_line_statistics/`,
    method: 'get'
  };
  return request(options);
}

/**
 * @description 获取违规企业
 */
export function getIllegalEnter() {
  const options = {
    url: `/v1/visual/monitor_center/illegal_enter/`,
    method: 'get'
  };
  return request(options);
}

/**
 * @description 获取告警排名
 */
export function getAlarmTrend() {
  const options = {
    url: `/v1/visual/monitor_center/alarm_trend/`,
    method: 'get'
  };
  return request(options);
}