import React from 'react';

const NoData: React.FC<{ isCharts?: boolean }> = ({ isCharts }) => (
    <div className="nodata">
        {isCharts ? (
            <img src={require('@/assets/images/bigscreen_nodata_charts.png')} alt="nodata" />
        ) : (
            <img src={require('@/assets/images/bigscreen_nodata.png')} alt="nodata" />
        )}
        <div>暂无数据</div>
    </div>
);

export default NoData;
