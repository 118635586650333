import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { addCompany, ClientFieldGenerationStrategy, deleteCompanyById, editCompanyReal, fetchAllAreaTree, fetchAllCompany } from "../../api";
import { appendChildNodeToParentNode, ChildNodeOperation, dealRequestError, dropNodeFromTree, recoverInformationFromIdentify, TreeItemType, wrapTopArea } from "../../utils";
import { useEffect } from 'react';

/**
 * @deprecated use EachAreaCompanyTreeNode !!!
 */

/* eslint-disable no-param-reassign */
/**
 * generate "selectable" and "checkable" properties for each stem node
 * this function is used for let the areaTree can play with components need these properties to disable touch event when user click stem node
 * @param areaTree wrapped area-company tree
 */
export function generateDisableAttributeForTree(areaTree) {
  if (areaTree.uniqueId !== undefined) {
    //this object maybe is wrapper object
    const currentNodeIsCompany = recoverInformationFromIdentify(areaTree.uniqueId).type === TreeItemType.COMPANY;
    //only company can be select or check
    areaTree.selectable = currentNodeIsCompany;
    areaTree.checkable = currentNodeIsCompany;
  }
  if (areaTree.children !== undefined && areaTree.children.length > 0) {
    for (const child of areaTree.children) {
      generateDisableAttributeForTree(child);
    }
  }
}
const calculateOnlineDeviceCount = areaOrCompany => {
  if (areaOrCompany.children === undefined) {
    return 0;
  }
  let companyCount = 0;
  for (const eachChildNode of areaOrCompany.children) {
    if (recoverInformationFromIdentify(eachChildNode.uniqueId).type === TreeItemType.COMPANY) {
      //this node is company, determine if this device online
      companyCount += 1;
    } else {
      //this node is area, so calculate online device count under this area
      const number = calculateOnlineDeviceCount(eachChildNode);
      companyCount += number;
    }
  }
  return companyCount;
};
export function sortAreaByCompanyCount(areaTree) {
  if (areaTree.uniqueId !== undefined) {
    areaTree.children = areaTree.children?.map(eachCompany => {
      const onlineDeviceCountOfCompany = calculateOnlineDeviceCount(eachCompany);
      // console.log(`当前的公司名称为 : ${eachCompany.name} , 在线设备数量 : ${onlineDeviceCountOfCompany}`)
      // @ts-ignore
      eachCompany.companyCount = onlineDeviceCountOfCompany;
      return eachCompany;
    });
    areaTree.children = _.orderBy(areaTree.children, ['companyCount'], ['desc']);
  }
  if (areaTree.children !== undefined && areaTree.children.length > 0) {
    for (const child of areaTree.children) {
      sortAreaByCompanyCount(child);
    }
  }
}

/* eslint-enable no-param-reassign */

/**
 * usage :
 * 1. call initializeAreas function in component you want to operate area-company tree
 * 2. if the component need play with data which have "selectable" and "checkable" properties on stem node ,
 *    use areasWithCombinedChildrenLoadingStateWithStemDisable. otherwise you can use areasWithCombinedChildrenLoadingState for best performance.
 *    see {@link generateDisableAttributeForTree}
 * 3. call {@link requestChildAreaOrCompaniesUnderTargetArea} when you click stem node,for more detail, see {@link requestChildAreaOrCompaniesUnderTargetArea}
 * 4. use {@link deleteCompany} {@link updateCompany} {@link createCompany} to operate company in your application
 * 5. call {@link clearResource} when user logout application
 * operations about company should establish relationship with this global state !!!
 * see bug : http://zentao.finsiot.com/bug-view-1215.html  http://zentao.finsiot.com/bug-view-1216.html
 */
class AreaCompanyTreeState {
  _areasWithCombinedChildrenLoadingState = {
    areas: [],
    loading: true
  };
  get areasWithCombinedChildrenLoadingState() {
    return this._areasWithCombinedChildrenLoadingState;
  }
  get areasWithCombinedChildrenLoadingStateWithStemDisable() {
    const copyAreas = _.cloneDeep(this._areasWithCombinedChildrenLoadingState.areas);
    const treeWrapper = wrapTopArea(copyAreas);
    generateDisableAttributeForTree(treeWrapper);
    return {
      areas: treeWrapper.children,
      loading: false
    };
  }
  clearResource() {
    this._areasWithCombinedChildrenLoadingState = {
      areas: [],
      loading: true
    };
  }

  /**
   * you can call this function in different locations,
   * function internal will ensure the areas not will be init twice
   */
  initializeAreas() {
    //represent areas not initialized yet
    if (this._areasWithCombinedChildrenLoadingState.areas.length === 0) {
      fetchAllAreaTree(ClientFieldGenerationStrategy.AREA_TREE_WITH_COMBINED_CHILDREN).then(resultedAreas => {
        this._areasWithCombinedChildrenLoadingState = {
          areas: resultedAreas,
          loading: false
        };
      });
    }
  }
  changeLoadingState = loading => {
    this._areasWithCombinedChildrenLoadingState = {
      ...this._areasWithCombinedChildrenLoadingState,
      loading
    };
  };

  /**
   * you should call this function to fetch children node(area or companies) under area when you click it.
   * notice : you should call this function when click company or area which not have children area or companies(this area is leaf node)
   *          because there not have any data should be fetched from server.
   * @param targetArea clicked area
   */
  requestChildAreaOrCompaniesUnderTargetArea = targetArea => {
    if (recoverInformationFromIdentify(targetArea.uniqueId).type === TreeItemType.COMPANY) {
      throw new Error('You should not call requestChildAreaOrCompaniesUnderTargetArea with company node, you should deal the logic outside.');
    }
    if (!targetArea.have_enter && !targetArea.have_child) {
      throw new Error('You can not call requestChildAreaOrCompaniesUnderTargetArea with a leaf area node(this area without companies under it), you should deal the logic outside.');
    }
    const {
      id,
      have_child: haveChildren
    } = targetArea;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      this.changeLoadingState(true);
      //deep clone object ，modify child areas of target area
      const copyAreas = _.clone(this._areasWithCombinedChildrenLoadingState.areas);
      const treeWrapper = wrapTopArea(copyAreas);
      try {
        const areasUnderThisArea = haveChildren ? await fetchAllAreaTree(ClientFieldGenerationStrategy.AREA_TREE_WITH_COMBINED_CHILDREN, id) : [];
        const companiesUnderThisArea = await fetchAllCompany({
          ad_region: id,
          get_all: true,
          direct: true
        });
        const resultedAreas = areasUnderThisArea.concat(companiesUnderThisArea);
        appendChildNodeToParentNode(treeWrapper, id, resultedAreas, ChildNodeOperation.REPLACE);
        this._areasWithCombinedChildrenLoadingState = {
          areas: treeWrapper.children,
          loading: false
        };
        resolve(companiesUnderThisArea);
      } catch (error) {
        reject(error);
      }
    });
  };
  deleteCompany = targetCompany => new Promise((resolve, reject) => {
    this.changeLoadingState(true);
    deleteCompanyById(targetCompany.id).then(() => {
      //deep clone object ，modify child areas of target area
      const copyAreas = _.clone(this._areasWithCombinedChildrenLoadingState.areas);
      const treeWrapper = wrapTopArea(copyAreas);
      dropNodeFromTree(treeWrapper, targetCompany);
      this._areasWithCombinedChildrenLoadingState = {
        areas: treeWrapper.children,
        loading: false
      };
      resolve();
    }).catch(error => {
      dealRequestError(error);
      this.changeLoadingState(false);
      reject();
    });
  });
  createCompany = params => new Promise((resolve, reject) => {
    this.changeLoadingState(true);
    addCompany(params).then(result => {
      const copyAreas = _.clone(this._areasWithCombinedChildrenLoadingState.areas);
      const treeWrapper = wrapTopArea(copyAreas);
      appendChildNodeToParentNode(treeWrapper, result.ad_region, [result], ChildNodeOperation.ADD);
      this._areasWithCombinedChildrenLoadingState = {
        areas: treeWrapper.children,
        loading: false
      };
      resolve();
    }).catch(error => {
      dealRequestError(error);
      this.changeLoadingState(false);
      reject();
    });
  });
  updateCompany = (targetCompanyId, params) => new Promise((resolve, reject) => {
    this.changeLoadingState(true);
    editCompanyReal(targetCompanyId, params).then(result => {
      const copyAreas = _.clone(this._areasWithCombinedChildrenLoadingState.areas);
      const treeWrapper = wrapTopArea(copyAreas);
      appendChildNodeToParentNode(treeWrapper, result.ad_region, [result], ChildNodeOperation.UPDATE);
      this._areasWithCombinedChildrenLoadingState = {
        areas: treeWrapper.children,
        loading: false
      };
      resolve();
    }).catch(error => {
      dealRequestError(error);
      this.changeLoadingState(false);
      reject();
    });
  });
  constructor() {
    makeAutoObservable(this);
    // autorun(() => {
    //     console.log('area company tree', this.areasWithCombinedChildrenLoadingState);
    // });
  }
}
export const areaCompanyTreeState = new AreaCompanyTreeState();
export function useAreaCompanyState() {
  useEffect(() => {
    areaCompanyTreeState.initializeAreas();
  }, []);
}