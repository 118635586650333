import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { usePagination } from '@/utils/typeWrapper';
import {
    activeDevices,
    createDevice,
    dealRequestError,
    deleteDeviceById,
    Device,
    fetchDeviceCategories,
    fetchDevices,
    fetchDeviceStatus,
    generateIdentifiedByType,
    importDevices,
    modifyDevice,
    PaginationWrapper,
    recoverInformationFromIdentify,
    reduceSamePropertiesFromObject,
    TreeItemType,
    usePurgedAreaCompanyState,
} from '@greendev/common';
import { ColumnsType } from 'antd/es/table';
import { Button, Col, FormInstance, Input, message, Popconfirm, Row, Select, Table, Typography, Upload } from 'antd';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import AddDeviceContent, {
    AddDeviceContentConfiguration,
} from '@/views/common/basicDataManage/devicesManageView/AddDeviceContent';
import _, { keysIn } from 'lodash';
import { MyIcon } from '@/utils/iconfont';
import GlobalModal, { GlobalModalApi } from '@/components/modal/GlobalModal';
import DeviceDetailPart from './DeviceDetailPart';
import './index.scss';
import GlobalStyle from '@/styles';
import { ExcelKeyReadableMapping, exportExcel, useExcelImporter } from '@/utils/excel';
import { UploadFile } from 'antd/es/upload/interface';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ImportResultModal, ImportResultModalAction } from '@/components/importResult/ImportResultModal';
import { PurgedAreaCompanyTreeSelect } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

const searchButtonId = 'search-button-in-device-management';

const DevicesManagementView = () => {
    const { color } = GlobalStyle;
    usePurgedAreaCompanyState();
    const pagePreFetchData = useLocalObservable(() => ({
        categories: [] as Device.Category[],
        status: [] as Device.Status[],
        fetchCategoryAndStatus() {
            fetchDeviceCategories()
                .then((categories: Device.Category[]) => {
                    this.categories = categories;
                })
                .catch(dealRequestError);
            fetchDeviceStatus()
                .then((status: Device.Status[]) => {
                    this.status = status;
                })
                .catch(dealRequestError);
        },
        clearCategoryAndStatus() {
            this.categories = [];
            this.status = [];
        },
    }));
    useEffect(() => {
        pagePreFetchData.fetchCategoryAndStatus();
        return () => {
            pagePreFetchData.clearCategoryAndStatus();
        };
    }, [pagePreFetchData]);
    const modalRef = useRef<CommonModalApi>(null);
    const globalModalRef = useRef<GlobalModalApi>(null);
    const addDeviceFormRef = useRef<FormInstance<any>>(null);
    const [displayAddDeviceFormModal, setDisplayAddDeviceFormModal] = useState<{
        display: boolean;
        configuration?: AddDeviceContentConfiguration;
    }>({
        display: false,
    });
    useEffect(() => {
        if (displayAddDeviceFormModal.display) {
            modalRef.current?.showModal();
        }
    }, [displayAddDeviceFormModal]);

    const [searchDeviceName, setSearchDeviceName] = useState<string>('');
    const whenInputSearchDeviceName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchDeviceName(event.target.value);
    };

    const [searchDeviceId, setSearchDeviceId] = useState<string>('');
    const whenInputSearchDeviceId = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchDeviceId(event.target.value);
    };

    const [searchCompanyId, setSearchCompanyId] = useState<number | undefined>(undefined);
    const whenInputSearchCompany = (clickedCompany?: number) => {
        setSearchCompanyId(clickedCompany);
    };

    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
    const whenInputSelectedCategory = (targetCategory?: string) => {
        setSelectedCategory(targetCategory);
    };

    const [selectedStatus, setSelectedStatus] = useState<number | undefined>(undefined);
    const whenInputSelectedStatus = (targetStatusId?: number) => {
        setSelectedStatus(targetStatusId);
    };

    const [currentDeviceID, setCurrentDeviceID] = useState<number>(0);

    const [tableDataWithLoadingState, changeLoadingState, refreshTableData, pagination, resetToFirstPage] =
        usePagination<Device.BackendProfile>(
            (page, pageSize) =>
                fetchDevices({
                    name: searchDeviceName.trim().length === 0 ? undefined : searchDeviceName,
                    qr_code: searchDeviceId.trim().length === 0 ? undefined : searchDeviceId,
                    enterprise_id: searchCompanyId,
                    category_id: selectedCategory,
                    status: selectedStatus,
                    page,
                    page_size: pageSize,
                }) as Promise<PaginationWrapper<Device.BackendProfile>>
        );

    const columns: ColumnsType<Device.BackendProfile> = [
        {
            title: '设备名称',
            width: 100,
            dataIndex: 'name',
            key: 'id',
            align: 'left',
        },
        {
            title: '设备ID',
            width: 200,
            dataIndex: 'qr_code',
            key: 'id',
        },
        {
            title: '型号',
            width: 100,
            dataIndex: 'category_id',
            key: 'id',
        },
        {
            title: '网关',
            width: 160,
            dataIndex: 'gateway_name',
            key: 'id',
        },
        {
            title: '上次采集时间',
            width: 180,
            dataIndex: 'last_time',
            key: 'id',
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status_name',
            key: 'id',
            render: item => {
                let className;
                switch (item) {
                    case '在线':
                        className = 'device-online';
                        break;
                    case '离线':
                        className = 'device-offline';
                        break;
                    default:
                        className = 'device-not-active';
                        break;
                }
                return (
                    <div className="opera">
                        <Typography.Text className={className}>{item}</Typography.Text>
                    </div>
                );
            },
        },
        {
            title: '所属检测点',
            width: 150,
            dataIndex: 'monitoring_points_name',
            key: 'id',
        },
        {
            title: '所属企业',
            width: 180,
            dataIndex: 'enterprise_name',
            key: 'id',
        },
        {
            title: '添加时间',
            width: 180,
            dataIndex: 'create_time',
            key: 'id',
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 180,
            render: (item, record) => (
                <div className="opera">
                    <i
                        className="iconfont icona-zu6928"
                        title={'激活'}
                        onClick={() => {
                            if (!record.canActive) {
                                return;
                            }
                            changeLoadingState(true);
                            activeDevices([record.id])
                                .then(result => {
                                    if (result.success.includes(record.id)) {
                                        message.success('激活成功');
                                    } else {
                                        message.error('激活失败');
                                    }
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                        style={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            color: !record.canActive ? '#e0e0e0' : color.contentColor,
                        }}
                    />
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setDisplayAddDeviceFormModal({
                                display: true,
                                configuration: {
                                    originDevice: record,
                                    isChangeDeviceMode: false,
                                },
                            });
                        }}
                        style={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            margin: '0 0 0 0.6rem',
                        }}
                    />
                    <MyIcon
                        type="iconchakan1"
                        title="查看"
                        style={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            margin: '0 0 0 0.6rem',
                        }}
                        onClick={() => {
                            setCurrentDeviceID(record.id);
                            globalModalRef.current?.showModal();
                        }}
                    />
                    <i
                        className="iconfont icongenghuan"
                        title={'更换'}
                        onClick={() => {
                            setDisplayAddDeviceFormModal({
                                display: true,
                                configuration: {
                                    originDevice: record,
                                    isChangeDeviceMode: true,
                                },
                            });
                        }}
                        style={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            margin: '0 0 0 0.6rem',
                        }}
                    />
                    <Popconfirm
                        placement="leftTop"
                        title={'设备删除后，数据无法恢复'}
                        onConfirm={() => {
                            changeLoadingState(true);
                            deleteDeviceById(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    refreshTableData();
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const importResultModalRef = useRef<ImportResultModalAction>(null);
    const keyMapping: ExcelKeyReadableMapping<Device.ExcelExposeProfile> = {
        qr_code: '设备ID',
        name: '名称',
        enterpriseName: '所属企业',
        monitoringPointName: '监测点',
        gatewayName: '网关',
        remark: '备注',
    };
    const exportDevices = () => {
        fetchDevices({
            get_all: true,
        }).then(devices => {
            if ((devices as Device.BackendProfile[]).length === 0) {
                message.warn('暂无设备');
                return;
            }
            const devicesForExcel: Device.ExcelExposeProfile[] = (devices as Device.BackendProfile[]).map(
                eachDevice => ({
                    qr_code: eachDevice.qr_code,
                    name: eachDevice.name,
                    enterpriseName: eachDevice.enterprise_name,
                    monitoringPointName: eachDevice.monitoring_points_name,
                    gatewayName: eachDevice.gateway === null ? null : eachDevice.gateway_name,
                    remark: eachDevice.remark,
                })
            );
            exportExcel(keyMapping, devicesForExcel, '设备管理列表');
        });
    };
    const excelUploader = useCallback(recordsInSheet => {
        try {
            /* eslint-disable */
            const backendProfiles: Device.ImportDeviceProfile[] = recordsInSheet.map(
                (eachRecord: { [x: string]: any }) =>
                    _.omitBy(
                        {
                            qr_code: eachRecord[keyMapping['qr_code']],
                            name: eachRecord[keyMapping['name']],
                            enterprise: eachRecord[keyMapping['enterpriseName']],
                            monitoring_points: eachRecord[keyMapping['monitoringPointName']],
                            gateway: eachRecord[keyMapping['gatewayName']],
                            remark: eachRecord[keyMapping['remark']],
                        },
                        _.isNull
                    ) as Device.ImportDeviceProfile
            );
            /* eslint-enable */
            //request backend to import records
            importDevices(backendProfiles)
                .then(result => {
                    document.getElementById(searchButtonId)?.click();
                    importResultModalRef.current?.displayResult({
                        result,
                        onClickExportFailItems: index => {
                            const devicesForExcel: Device.ExcelExposeProfile[] = backendProfiles
                                .filter((value, eachIndex) => index.includes(eachIndex + 1))
                                .map(eachDevice => ({
                                    qr_code: eachDevice.qr_code,
                                    name: eachDevice.name,
                                    enterpriseName: eachDevice.enterprise,
                                    monitoringPointName: eachDevice.monitoring_points,
                                    gatewayName: eachDevice.gateway === null ? null : eachDevice.gateway,
                                    remark: eachDevice.remark,
                                }));
                            exportExcel(keyMapping, devicesForExcel, '设备管理列表(导入失败)');
                        },
                    });
                })
                .catch(dealRequestError)
                .finally(() => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    afterUploadExcel();
                });
        } catch (e) {
            message.error('格式错误');
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            afterUploadExcel();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [afterSelectExcel, afterUploadExcel, loading] = useExcelImporter(excelUploader);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // 表格多选事件
    const onSelectChange = (newestSelectedKeys: React.Key[]) => {
        setSelectedRowKeys(newestSelectedKeys);
    };
    const rowSelection: TableRowSelection<Device.BackendProfile> = {
        selectedRowKeys,
        getCheckboxProps: (record: Device.BackendProfile) => ({
            disabled: !record.canActive, // Column configuration not to be checked
            name: record.name,
        }),
        onChange: onSelectChange,
    };

    return (
        <div className="container">
            <Row align={'middle'} className={'commonButton'} gutter={16}>
                <Col span={3} xxl={2}>
                    <div className={'flex-horizontal-space-between'}>
                        <Button
                            style={{ height: '100%', width: '45%' }}
                            className={'flex-horizontal-center'}
                            type="primary"
                            onClick={() => {
                                setDisplayAddDeviceFormModal({
                                    display: true,
                                });
                            }}
                        >
                            新增
                        </Button>
                        <div style={{ height: '100%', width: '45%' }}>
                            <Upload
                                customRequest={options => {
                                    options.onError?.(new Error('fake error'));
                                }}
                                onChange={info => {
                                    const uploadFile = info.file as UploadFile;
                                    if (uploadFile.status !== 'error') {
                                        return;
                                    }
                                    afterSelectExcel(uploadFile);
                                }}
                                multiple={false}
                                showUploadList={false}
                            >
                                <Button
                                    style={{ height: '100%', width: '100%', color: color.themeColor }}
                                    className={'flex-horizontal-center'}
                                    type="default"
                                    loading={loading}
                                >
                                    导入
                                </Button>
                            </Upload>
                        </div>
                    </div>
                </Col>
                <Col span={0} xxl={2} />
                <Col span={3} xxl={3}>
                    <div className={'flex-horizontal-center'}>
                        <Typography.Text style={{ marginRight: 8 }}>名称 : </Typography.Text>
                        <Input
                            onChange={whenInputSearchDeviceName}
                            value={searchDeviceName}
                            allowClear
                            style={{ flex: 1 }}
                        />
                    </div>
                </Col>
                <Col span={3} xxl={3}>
                    <div className={'flex-horizontal-center'}>
                        <Typography.Text style={{ marginRight: 8 }}>设备ID : </Typography.Text>
                        <Input
                            onChange={whenInputSearchDeviceId}
                            value={searchDeviceId}
                            allowClear
                            style={{ flex: 1 }}
                        />
                    </div>
                </Col>
                <Col span={3} xxl={3}>
                    <div className={'flex-horizontal-center'}>
                        <Typography.Text style={{ marginRight: 8 }}>型号 : </Typography.Text>
                        <Select
                            allowClear
                            style={{ flex: 1 }}
                            options={pagePreFetchData.categories}
                            fieldNames={{
                                label: 'id',
                                value: 'id',
                            }}
                            onChange={(clickedCategoryId: string | undefined) => {
                                whenInputSelectedCategory(clickedCategoryId);
                            }}
                        />
                    </div>
                </Col>
                <Col span={6} xxl={6}>
                    <div className={'flex-horizontal-center'}>
                        <Typography.Text style={{ marginRight: 8 }}>所属企业 : </Typography.Text>
                        <PurgedAreaCompanyTreeSelect
                            label="所属企业"
                            value={
                                searchCompanyId === undefined
                                    ? undefined
                                    : generateIdentifiedByType(TreeItemType.COMPANY, searchCompanyId)
                            }
                            onChange={(itemIdentification?: string) => {
                                if (itemIdentification === undefined) {
                                    whenInputSearchCompany(undefined);
                                    return;
                                }
                                whenInputSearchCompany(
                                    recoverInformationFromIdentify(itemIdentification).originObjectId
                                );
                            }}
                            treeCheckable={false}
                            onlyCanSelectCompany
                            style={{ flex: 1 }}
                        />
                    </div>
                </Col>
                <Col span={3} xxl={3}>
                    <div className={'flex-horizontal-center'}>
                        <Typography.Text style={{ marginRight: 8 }}>状态 : </Typography.Text>
                        <Select
                            allowClear
                            style={{ flex: 1 }}
                            onChange={(clickStatusId: string | undefined) => {
                                whenInputSelectedStatus(
                                    clickStatusId === undefined ? undefined : Number(clickStatusId)
                                );
                            }}
                        >
                            {pagePreFetchData.status.map(eachStatus => (
                                <Select.Option key={eachStatus[0]}>{eachStatus[1]}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </Col>
                <Col span={3} xxl={2}>
                    <div className={'flex-horizontal-space-between'}>
                        <Button
                            id={searchButtonId}
                            style={{ height: '100%', width: '45%' }}
                            className={'flex-horizontal-center'}
                            type="primary"
                            onClick={() => {
                                resetToFirstPage();
                            }}
                        >
                            搜索
                        </Button>
                        <Button
                            style={{ height: '100%', width: '45%', color: color.themeColor }}
                            className={'flex-horizontal-center'}
                            type="default"
                            onClick={() => {
                                exportDevices();
                            }}
                        >
                            导出
                        </Button>
                    </div>
                </Col>
            </Row>
            <div style={{ position: 'relative' }}>
                <Table<Device.BackendProfile>
                    pagination={pagination}
                    dataSource={tableDataWithLoadingState.paginationData.results}
                    loading={tableDataWithLoadingState.loading}
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey={record => record.id}
                    scroll={{ y: 'calc(100vh - 305px)' }}
                />
                <div
                    style={
                        tableDataWithLoadingState.paginationData.results.length > 0
                            ? { display: 'block', position: 'absolute', left: 0, bottom: 0, margin: '16px 0' }
                            : { display: 'none' }
                    }
                >
                    <Popconfirm
                        disabled={selectedRowKeys.length === 0}
                        title={`确定要将${selectedRowKeys.length}项,激活吗?`}
                        onConfirm={() => {
                            message.loading('设备激活中，请耐心等待...', 0);
                            activeDevices(selectedRowKeys.map(eachKey => Number(eachKey)))
                                .then(result => {
                                    const { success, fail } = result;
                                    message.destroy();
                                    if (fail.length === 0) {
                                        message.success('批量激活成功');
                                        setSelectedRowKeys([]);
                                    } else if (success.length === 0) {
                                        message.error('批量激活失败');
                                    } else if (selectedRowKeys.length !== success.length && fail.length !== 0) {
                                        message.error('部分设备激活失败，请稍后重试...');
                                        setSelectedRowKeys([]);
                                    }
                                    refreshTableData();
                                })
                                .catch(dealRequestError);
                        }}
                    >
                        <Button
                            disabled={selectedRowKeys.length === 0}
                            className={'flex-horizontal-center'}
                            type="primary"
                        >
                            激活选中设备
                        </Button>
                    </Popconfirm>
                </div>
            </div>

            {displayAddDeviceFormModal.display && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        //todo use framework to process form
                        addDeviceFormRef.current?.validateFields().then(() => {
                            const { configuration } = displayAddDeviceFormModal;
                            const isEditMode = configuration !== undefined;
                            const noticeSuccess = () => {
                                message.success(isEditMode ? '编辑成功' : '添加成功');
                                modalRef.current?.hiddenModal();
                                addDeviceFormRef.current?.resetFields();
                                setDisplayAddDeviceFormModal({
                                    display: false,
                                });
                            };
                            const tryToInterceptModifyRequest = (
                                modifyParameter: Device.CreateOrUpdateDeviceParameter | Device.ChangeDeviceParameter
                            ) => {
                                let resultModifyParameters:
                                    | Device.CreateOrUpdateDeviceParameter
                                    | Device.ChangeDeviceParameter;
                                if (isEditMode) {
                                    resultModifyParameters = reduceSamePropertiesFromObject(
                                        modifyParameter,
                                        configuration.originDevice!!
                                    );
                                    if (keysIn(resultModifyParameters).length === 0) {
                                        noticeSuccess();
                                        return undefined;
                                    }
                                    return resultModifyParameters;
                                }
                                return undefined;
                            };
                            const isChangeDeviceMode = isEditMode && configuration.isChangeDeviceMode;
                            if (isChangeDeviceMode) {
                                const deviceId = addDeviceFormRef.current?.getFieldValue('deviceId');
                                const changeDeviceParameter: Device.ChangeDeviceParameter = {
                                    qr_code: deviceId,
                                };
                                const modifyParameter = tryToInterceptModifyRequest(
                                    changeDeviceParameter
                                ) as Device.ChangeDeviceParameter;
                                if (modifyParameter === undefined) {
                                    return;
                                }
                                modifyDevice(configuration.originDevice!!.id, modifyParameter)
                                    .then(() => {
                                        noticeSuccess();
                                        refreshTableData();
                                    })
                                    .catch((error: any) => {
                                        dealRequestError(error);
                                        modalRef.current?.endLoading();
                                    });
                            } else {
                                const fieldsValue: {
                                    deviceId: string;
                                    name: string;
                                    enterprise: string;
                                    monitoringPoints: string;
                                    remark?: string;
                                    gateway?: string;
                                    multiple?: string;
                                } = addDeviceFormRef.current?.getFieldsValue();
                                modalRef.current?.startLoading();
                                const selectedEnterpriseId = recoverInformationFromIdentify(
                                    fieldsValue.enterprise
                                ).originObjectId;
                                let selectedMonitoringPointId;
                                if (isEditMode) {
                                    if (
                                        fieldsValue.monitoringPoints ===
                                        configuration.originDevice!!.monitoring_points_name
                                    ) {
                                        //user not re-select this field
                                        selectedMonitoringPointId = configuration.originDevice!!.monitoring_points;
                                    } else {
                                        selectedMonitoringPointId = Number(fieldsValue.monitoringPoints);
                                    }
                                } else {
                                    selectedMonitoringPointId = Number(fieldsValue.monitoringPoints);
                                }
                                const addOrUpdateParams: Device.CreateOrUpdateDeviceParameter = {
                                    qr_code: fieldsValue.deviceId,
                                    name: fieldsValue.name,
                                    enterprise: selectedEnterpriseId,
                                    monitoring_points: selectedMonitoringPointId,
                                    remark:
                                        fieldsValue.remark !== undefined && fieldsValue.remark.length > 0
                                            ? fieldsValue.remark
                                            : null,
                                    gateway:
                                        fieldsValue.gateway !== undefined ? Number(fieldsValue.gateway) : undefined,
                                    //backend said that gateway device's multiple always is 1
                                    multiple: fieldsValue.multiple !== undefined ? Number(fieldsValue.multiple) : 1,
                                };
                                let resultPromise: Promise<any>;
                                if (isEditMode) {
                                    const modifyParameter = tryToInterceptModifyRequest(
                                        addOrUpdateParams
                                    ) as Device.CreateOrUpdateDeviceParameter;
                                    if (modifyParameter === undefined) {
                                        return;
                                    }
                                    resultPromise = modifyDevice(configuration.originDevice!!.id, modifyParameter);
                                } else {
                                    resultPromise = createDevice(addOrUpdateParams);
                                }
                                resultPromise
                                    .then(() => {
                                        noticeSuccess();
                                        refreshTableData();
                                    })
                                    .catch(error => {
                                        dealRequestError(error);
                                        modalRef.current?.endLoading();
                                    });
                            }
                        });
                    }}
                    onCancel={() => {
                        addDeviceFormRef.current?.resetFields();
                        setDisplayAddDeviceFormModal({
                            display: false,
                        });
                    }}
                    title={
                        displayAddDeviceFormModal.configuration?.originDevice === undefined ? '新增设备' : '编辑设备'
                    }
                >
                    <AddDeviceContent ref={addDeviceFormRef} configuration={displayAddDeviceFormModal.configuration} />
                </CommonModal>
            )}
            <ImportResultModal ref={importResultModalRef} />
            {/* 设备详情Modal */}
            <GlobalModal
                title={'设备详情'}
                childRef={globalModalRef}
                onOk={() => {
                    // if (modalType === 'edit') {
                    //     userList();
                    // }
                }}
                footer={null}
            >
                <DeviceDetailPart id={currentDeviceID} showLoading={() => {}} hiddenLoading={() => {}} />
            </GlobalModal>
        </div>
    );
};

export default observer(DevicesManagementView);
