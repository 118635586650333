//格式化日期：yyyy-MM-dd
export const formatDate = date => {
  const myyear = date.getFullYear();
  let mymonth = date.getMonth() + 1;
  let myweekday = date.getDate();
  if (mymonth < 10) {
    mymonth = `0${mymonth}`;
  }
  if (myweekday < 10) {
    myweekday = `0${myweekday}`;
  }
  return `${myyear}-${mymonth}-${myweekday}`;
};
export const now = new Date(); //今日
const nowMonth = () => now.getMonth(); //当前月
const nowYear = () => now.getFullYear(); //当前年
const nowDay = () => now.getDate(); // 当前日
const currentYearFirstDate = () => new Date(nowYear(), 0, 1); //本年第一天

//获得本季度的开始月份
const getQuarterStartMonth = () => {
  let quarterStartMonth = 0;
  if (nowMonth() < 3) {
    quarterStartMonth = 0;
  }
  if (nowMonth() > 2 && nowMonth() < 6) {
    quarterStartMonth = 3;
  }
  if (nowMonth() > 5 && nowMonth() < 9) {
    quarterStartMonth = 6;
  }
  if (nowMonth() > 8) {
    quarterStartMonth = 9;
  }
  return quarterStartMonth;
};

//获得本月的开始日期       本月
export const getMonthStartDate = () => {
  const monthStartDate = new Date(nowYear(), nowMonth(), 1);
  return formatDate(monthStartDate);
};

//获得本季度的开始日期     本季度
export const getQuarterStartDate = () => {
  const quarterStartDate = new Date(nowYear(), getQuarterStartMonth(), 1);
  return formatDate(quarterStartDate);
};

//格式化本年第一天日期     本年
export const getYear = () => `${currentYearFirstDate().getFullYear()}-0${currentYearFirstDate().getMonth() + 1}-0${currentYearFirstDate().getDate()}`;

// 获取一月前
export const getPrevMonthDate = () => {
  if (nowMonth() === 1) {
    return `${nowYear() - 1}-12-${nowDay()}`;
  }
  return `${nowYear()}-${nowMonth() > 10 ? nowMonth : `0${nowMonth()}`}-${nowDay() > 10 ? nowDay : `0${nowDay()}`}`;
};

// 获取一年前
export const getPrevYearDate = () => `${nowYear() - 1}-${nowMonth() > 9 ? nowMonth() + 1 : `0${nowMonth() + 1}`}-${nowDay() > 10 ? nowDay : `0${nowDay()}`}`;

// 将时间戳转换成日期格式：
export const timestampToTime = timestamp => {
  const date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = date.getDate() + ' ';
  return Y + M + D;
};

//保留两位小数
// eslint-disable-next-line consistent-return
export const peakvalue = power => {
  if (typeof power !== 'undefined' && power !== null) {
    // let max_power = parseInt(power * 100) / 100;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const max_power = Math.round(power * 100) / 100;
    return max_power;
  }
};