import React, { forwardRef, PropsWithChildren, useImperativeHandle, useState } from 'react';
import { Modal } from 'antd';
import { CommonModalFooter, CommonModalFooterProps } from '@/components/modal/CommonModalFooter';
import { CommonModalTitle, CommonModalTitleProps } from '@/components/modal/CommonModalTitle';

export type CommonModalProps = {
    footer?: React.ReactNode;
} & Omit<CommonModalFooterProps, 'loading'> &
    CommonModalTitleProps;

export type CommonModalApi = {
    startLoading: () => void;
    endLoading: () => void;
    showModal: () => void;
    hiddenModal: () => void;
};

const CommonModal = forwardRef<CommonModalApi, PropsWithChildren<CommonModalProps>>(
    (props: PropsWithChildren<CommonModalProps>, ref) => {
        const [visible, setVisible] = useState(false);
        const [loading, setLoading] = useState(false);
        const startLoading = () => {
            setLoading(true);
        };
        const endLoading = () => {
            setLoading(false);
        };
        const showModal = () => {
            setVisible(true);
        };
        const hiddenModal = () => {
            endLoading();
            setVisible(false);
        };
        useImperativeHandle(ref, () => ({
            showModal,
            hiddenModal,
            startLoading,
            endLoading,
        }));
        return (
            <Modal
                forceRender
                getContainer={false}
                title={<CommonModalTitle title={props.title} />}
                onCancel={() => {
                    props.onCancel?.();
                    endLoading();
                    hiddenModal();
                }}
                visible={visible}
                centered
                footer={
                    props.footer ? (
                        props.footer
                    ) : (
                        <CommonModalFooter
                            onCancel={() => {
                                props.onCancel?.();
                                endLoading();
                                hiddenModal();
                            }}
                            onOk={() => {
                                props.onOk?.();
                            }}
                            loading={loading}
                        />
                    )
                }
            >
                {props.children}
            </Modal>
        );
    }
);

export default CommonModal;
