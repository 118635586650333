import { DatePicker, Form, FormInstance, Input, Select, Typography } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import AMapHelper from '@/utils/amap';
import AreaFormTreeSelect from '@/views/common/basicDataManage/areaManageView/AreaFormTreeSelect';
import { Company, Industry, queryAreaById } from '@greendev/common';
import moment from 'moment';
import { disableDaysBeforeToday } from '@/AntDesignReset';

export type ModifyOrDeleteCompanyContentInfo = {
    action: 'delete' | 'modify';
    originCompany: Company.BackendProfile;
};

export type AddCompanyContentProps = {
    industries: Industry.FrontEndProfile[];
    modifyOrDeleteInfo?: ModifyOrDeleteCompanyContentInfo;
};

//新增、编辑弹框内容显示
const AddCompanyContent = forwardRef<FormInstance<any>, AddCompanyContentProps>((props, ref) => {
    const { industries, modifyOrDeleteInfo } = props;
    const updateLngLatByCurrentAddress = (address: string) => {
        if (address.trim().length === 0) {
            return;
        }
        AMapHelper.queryLngLatByAddress(
            address,
            lnglat => {
                //lnglat : {Q: 30.575199, R: 103.92386299999998, lng: 103.923863, lat: 30.575199}
                // @ts-ignore
                ref.current?.setFieldsValue({ lnglat });
            },
            () => {
                // message.error('根据地址查询位置失败');
                // @ts-ignore
                ref.current?.setFieldsValue({ lnglat: undefined });
            }
        );
    };

    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (modifyOrDeleteInfo !== undefined) {
            const { originCompany, action } = modifyOrDeleteInfo;
            if (action === 'delete') {
                return;
            }
            queryAreaById(originCompany.ad_region).then(result => {
                //notice : if you don't want to send a request to query name , you can get the area name from ad_region_name field
                setInitialValues({
                    'name': originCompany.name,
                    'area': result.name,
                    'industry': originCompany.trade === null ? undefined : originCompany.trade.toString(10),
                    'address': originCompany.address,
                    'lnglat': `${originCompany.lng},${originCompany.lat}`,
                    'expired-time': moment(originCompany.expire_date),
                    'accounts': originCompany.usernames,
                    'remark': originCompany.desc === null ? undefined : originCompany.desc,
                });
            });
        }
    }, [modifyOrDeleteInfo]);

    return (
        <Form
            labelAlign={'right'}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            ref={ref}
            initialValues={initialValues}
            key={initialValues?.name}
        >
            {modifyOrDeleteInfo?.action === 'delete' ? (
                <Form.Item
                    label="企业名称"
                    name="name"
                    extra={
                        <p style={{ fontSize: '12px', color: '#9EA5AE', marginTop: '6px' }}>
                            此操作无法撤消。企业删除后，该企业下所有设备一并删除，数据不可恢复。
                        </p>
                    }
                    rules={[
                        {
                            required: true,
                            validator: (_, value) => {
                                const invalidCompanyNameError = new Error('请输入与删除企业相同的企业名称');
                                if (value.trim() === modifyOrDeleteInfo.originCompany.name) {
                                    return Promise.resolve(value);
                                }
                                return Promise.reject(invalidCompanyNameError);
                            },
                        },
                    ]}
                >
                    <Input placeholder="请输入企业名称" />
                </Form.Item>
            ) : (
                <>
                    <Form.Item
                        name="name"
                        label="企业名称"
                        rules={[
                            { required: true, message: '请输入企业名称' },
                            {
                                pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,30}$/,
                                message: '企业名称只能包含1-30位的汉字、字母、数字、下划线!',
                            },
                        ]}
                    >
                        <Input placeholder="请输入企业名称" />
                    </Form.Item>
                    <AreaFormTreeSelect required label={'行政区'} style={{ width: '100%' }} />
                    <Form.Item name="industry" label="所属行业" rules={[{ required: true, message: '请选择所属行业' }]}>
                        <Select placeholder="请选择所属行业" allowClear>
                            {industries.map(eachIndustry => (
                                <Select.Option key={eachIndustry.id}>{eachIndustry.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="地址"
                        name="address"
                        rules={[
                            { required: true, message: '请选择地址' },
                            {
                                pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,50}$/,
                                message: '地址只能包含1-50位的汉字、字母、数字、下划线!',
                            },
                        ]}
                    >
                        <Input
                            id={'addCompanyContentAddressInput'}
                            placeholder="请填写地址"
                            onMouseLeave={e => {
                                //onMouseLeave not working in fucking Chrome!!!
                                //see the workaround :https://github.com/facebook/react/issues/4492
                                //@ts-ignore
                                updateLngLatByCurrentAddress(e.target.value);
                            }}
                            onBlur={e => {
                                updateLngLatByCurrentAddress(e.target.value);
                            }}
                            onPressEnter={e => {
                                // @ts-ignore
                                updateLngLatByCurrentAddress(e.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="经纬度" name="lnglat">
                        <Input disabled placeholder="根据企业地址解析经纬度" />
                    </Form.Item>
                    <Form.Item
                        name="expired-time"
                        label="到期时间"
                        rules={[{ type: 'object' as const, required: true, message: '请选择到期时间' }]}
                    >
                        <DatePicker disabledDate={disableDaysBeforeToday} />
                    </Form.Item>
                    {modifyOrDeleteInfo !== undefined && (
                        <Form.Item name="accountsDisplay" label="关联账号">
                            {/*only display accounts, can't modify it*/}
                            <Typography.Text>
                                {modifyOrDeleteInfo.originCompany.usernames.length === 0
                                    ? '无'
                                    : modifyOrDeleteInfo.originCompany.usernames.join(' , ')}
                            </Typography.Text>
                        </Form.Item>
                    )}
                    <Form.Item
                        name={'remark'}
                        label="备注"
                        rules={[
                            {
                                pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,50}$/,
                                message: '备注只能包含1-50位的汉字、字母、数字、下划线!',
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </>
            )}
        </Form>
    );
});

export default AddCompanyContent;
