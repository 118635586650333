import { makeAutoObservable, runInAction } from 'mobx';
import { useStorage } from '@/utils/storage';
import { MenuChild } from '@/layout/menu';
import { filterAsyncRoute } from '@/router/generator';
import { defaultMenuRoutes } from '@/router';
import {
    login,
    mobileLogin,
    getMobileCode,
    getUserInfo,
    getPermission,
    Login,
    areaCompanyTreeState,
    Account,
} from '@greendev/common';
import Crypto from 'crypto-js';
import areaTreeState from '@/stores/areaTreeState';
import { message } from 'antd';

export const flatArrayObject = <T = any>(list: any[], keys: string[], addentry = false, arr: T[] = []): T[] => {
    list.forEach(item => {
        keys.forEach(key => {
            if (Array.isArray(item[key])) {
                if (addentry) arr.push({ ...item });
                flatArrayObject(item[key], keys, addentry, arr);
            } else {
                arr.push({ ...item });
            }
        });
    });
    return arr;
};

export const userStore = makeAutoObservable({
    userID: -1,
    category: -1,
    token: useStorage.get('ACCESS_TOKEN_KEY', null),
    name: '',
    mobile: '',
    noticeCount: 0,
    collapsed: false,
    dashboardPath: '',
    logoPath: null as string | null,
    // 避免正在获取权限时重复请求
    isSetMenuList: false,
    // 账号权限为空
    isEmptyPerms: false,
    systemName: '',
    perms: [] as string[],
    menus: [] as MenuChild[],
    menuList: [] as MenuChild[],
    routeList: [] as MenuChild[],
    // 账号下的企业
    enterprises: [] as { name: string; id: number }[],

    // 清空token及用户信息
    resetToken() {
        this.token = '';
        this.name = '';
        this.perms = [];
        this.userID = -1;
        this.menus = [];
        this.menuList = [];
        this.routeList = [];
        this.systemName = '';
        useStorage.clear();
        this.logoPath = null;
    },
    // 保存token
    setToken(token: string) {
        this.token = token ?? '';
        const ex = 365 * 24 * 60 * 60 * 1000;
        useStorage.set('ACCESS_TOKEN_KEY', this.token, ex);
    },
    /**
     * 密码登录
     */
    async login(params: Login.LoginParams) {
        // const timeStamp = Math.floor(Date.now() / 1000).toString();
        // const AES_KEY = Crypto.enc.Utf8.parse('finsiot');
        // AES CBC加密
        // const cryptoPsw = Crypto.AES.encrypt(params.password, params.username + timeStamp, {
        //     vi: 123123,
        //     mode: Crypto.mode.CBC,
        //     padding: Crypto.pad.Pkcs7,
        // }).toString();
        const cryptoPsw = Crypto.AES.encrypt('123456', 'finsiot', {
            vi: '123',
            mode: Crypto.mode.CBC,
            padding: Crypto.pad.Pkcs7,
        }).ciphertext;
        // params.password = cryptoPsw;
        console.log('cryptoPsw', cryptoPsw.toString());
        try {
            const res = await login(params);
            if (!res.data?.token) return res.data;
            console.log('res:', res);
            this.setToken(res.data.token); // for mock
            return await this.afterLogin();
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * 获取验证码
     */
    async getMobileCodeFunc(params: Login.MobileCode) {
        // 验证码请求成功时没有返回值，但失败有。
        try {
            await getMobileCode(params);
            return true;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * 验证码登录
     */
    async mobileLogin(params: Login.MobileLoginParam) {
        try {
            const res = await mobileLogin(params);
            this.setToken(res.data.token);
            return await this.afterLogin();
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /** 登录后处理 */
    async afterLogin() {
        this.isEmptyPerms = false;
        this.isSetMenuList = true;
        getPermission()
            .then(res => {
                this.isSetMenuList = false;
                this.generateRoutes(res.permission);
                if (res.permission.length === 0) this.isEmptyPerms = true;
            })
            .catch(() => {
                message.error('获取用户权限错误，请尝试刷新页面！');
                this.isSetMenuList = false;
            });
        // 设置路由
        // 获取userinfo
        this.getUserInfoWithRetry();
    },
    async getUserInfoWithRetry() {
        try {
            const userInfoRes = (await getUserInfo()).results[0];
            this.setUserInfo(userInfoRes);
        } catch (error: any) {
            if (error.response?.data?.detail.includes('没有访问权限')) {
                message.error('无权获取用户信息，请联系上级代理/管理员！');
                return;
            }
            // 有token的话尝试重新获取用户信息
            if (this.token && this.token !== '') {
                setTimeout(() => {
                    this.getUserInfoWithRetry();
                }, 15 * 1000);
            }
        }
    },
    setUserInfo(userInfo: Login.UserInfoType) {
        this.setUserID(userInfo.id);
        this.name = userInfo.name || userInfo.username || 'DEFAULT NAME';
        this.category = userInfo.category || -1;
        this.mobile = userInfo.mobile?.toString() || '未获取到手机号';
        this.enterprises = userInfo.enterprises || [];
        this.logoPath = userInfo.logo_path ? userInfo.logo_path : null;
        this.systemName = userInfo.system_name || '';
        console.log(this.category === Account.UserType.admin);
    },
    setUserID(ID: number) {
        this.userID = ID;
    },
    logout() {
        if (this.token) {
            this.resetToken();
            areaTreeState.clearResource();
            areaCompanyTreeState.clearResource();
            return true;
        }
        return false;
    },
    generateRoutes(menus: API.Menu[]) {
        // 后端路由json进行转换成真正的router map
        console.log('MENUS:', menus);
        const routeList = filterAsyncRoute(flatArrayObject(menus, ['permission'], true), null);
        console.log('MyrouteList: ', routeList);
        // 404 route must be end
        // routeList.push(NotFoundRouter)
        const defaultRoutes = flatArrayObject<MenuChild>(defaultMenuRoutes, ['children']);
        const asyncRoutes = flatArrayObject<MenuChild>(routeList, ['children']);
        // 获取数据后，将赋值操作放到 runInAction 中
        runInAction(() => {
            this.routeList = [...defaultRoutes, ...asyncRoutes];
            // 菜单不显示首页项
            this.menuList = [...routeList];
            // this.menuList = [...defaultMenuRoutes, ...routeList];
        });
        console.log('this.menuList==========>', this.menuList);
    },
    setDashboardPath(path: string) {
        this.dashboardPath = path;
    },
});
