import { request } from "../../utils/request";
export function fetchAllIndustryTree() {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/enter/trade/',
      method: 'GET'
    }).then(result => {
      resolve(result.map(value => value));
    }).catch(reject);
  });
}

// 删除行业
export function deleteIndustryById(id) {
  return request({
    url: `/v1/enter/trade/${id}/`,
    method: 'DELETE'
  });
}

//行业详情
export function queryIndustryById(id) {
  return request({
    url: `/v1/enter/trade/${id}/`,
    method: 'GET'
  });
}

// 添加行业
export function addIndustry(params) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/enter/trade/',
      method: 'POST',
      data: params
    }).then(addedIndustry => resolve(addedIndustry)).catch(reject);
  });
}

// 修改行业
export function editIndustry(id, params) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/enter/trade/${id}/`,
      method: 'PATCH',
      data: params
    }).then(updatedIndustry => resolve(updatedIndustry)).catch(reject);
  });
}