import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, Col, FormInstance, Input, message, Popconfirm, Row, Switch, Table, Typography } from 'antd';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import { usePagination } from '@/utils/typeWrapper';
import {
    createAgentAccount,
    deleteAgentAccountById,
    fetchAgentAccounts,
    modifyAgentAccount,
    UploadSituation,
    fetchAllRoles,
    arrayCompareFunction,
    PaginationWrapper,
    dealRequestError,
    Role,
    Account,
    usePurgedAreaCompanyState,
} from '@greendev/common';
import AddAgentAccountContent, {
    OriginalAgentAccountInfoWithCompaniesInfo,
} from '@/views/common/accountManage/agentAccountView/AddAgentAccountContent';
import { ColumnsType } from 'antd/es/table';
import ImagePreviewModal from '@/components/modal/ImagePreviewModal';
import {
    fileUploaderPurposeKeyMapping,
    useFileUploaderDisposer,
    useFileUploaderState,
} from '@/components/fileUploader/manage';
import handleFormValueWithDuplicationCheck, {
    momentFieldScheme,
    RequestValueType,
    stringArrayFieldScheme,
    stringFieldScheme,
} from '@/utils/formUploadHandler';
import { fetchAllCompanyIdByTreeNodeIdentifies } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

const AgentAccountView = () => {
    usePurgedAreaCompanyState();
    const pagePreFetchData = useLocalObservable(() => ({
        roles: [] as Role.BackEndProfile[],
        fetchAllRoles() {
            fetchAllRoles({
                get_all: true,
            })
                .then(result => {
                    this.roles = result as Role.BackEndProfile[];
                })
                .catch(dealRequestError);
        },
        clearRoles() {
            this.roles = [];
        },
    }));
    useEffect(() => {
        pagePreFetchData.fetchAllRoles();
        return () => {
            pagePreFetchData.clearRoles();
        };
    }, [pagePreFetchData]);

    const modalRef = useRef<CommonModalApi>(null);
    const addAgentAccountViewContentRef = useRef<FormInstance<any>>(null);
    const [displayAddAgentAccountFormModal, setDisplayAddAgentAccountFormModal] = useState<{
        display: boolean;
        originAgentAccount?: OriginalAgentAccountInfoWithCompaniesInfo;
    }>({
        display: false,
    });
    useEffect(() => {
        if (displayAddAgentAccountFormModal.display) {
            modalRef.current?.showModal();
        }
    }, [displayAddAgentAccountFormModal]);

    const [searchAccount, setSearchAccount] = useState<string>('');
    const whenInputSearchAccount = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchAccount(event.target.value);
    };
    const [searchName, setSearchName] = useState<string>('');
    const whenInputSearchName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchName(event.target.value);
    };
    const [searchPhone, setSearchPhone] = useState<string>('');
    const whenInputSearchPhone = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchPhone(event.target.value);
    };

    const [tableDataWithLoadingState, changeLoadingState, refreshTableData, pagination, resetToFirstPage] =
        usePagination<Account.BackendAgentAccountProfile>(
            (page, pageSize) =>
                fetchAgentAccounts({
                    username: searchAccount.trim().length === 0 ? undefined : searchAccount,
                    name: searchName.trim().length === 0 ? undefined : searchName,
                    mobile: searchPhone.trim().length === 0 ? undefined : searchPhone,
                    page,
                    page_size: pageSize,
                }) as Promise<PaginationWrapper<Account.BackendAgentAccountProfile>>
        );

    const columns: ColumnsType<Account.BackendAgentAccountProfile> = [
        {
            title: '帐号',
            dataIndex: 'username',
            key: 'id',
            align: 'left',
            width: 100,
        },
        {
            title: '联系人姓名',
            dataIndex: 'name',
            key: 'id',
            width: 120,
        },
        {
            title: '联系人电话',
            dataIndex: 'mobile',
            key: 'id',
            width: 120,
        },
        {
            title: '公司名称',
            dataIndex: 'enterprise_name',
            key: 'id',
            width: 150,
        },
        {
            title: '帐号限制',
            dataIndex: 'son_count',
            key: 'id',
            width: 100,
        },
        {
            title: '服务到期时间',
            dataIndex: 'expire_time',
            key: 'id',
            width: 160,
        },
        {
            title: '角色',
            dataIndex: 'role_name',
            key: 'id',
            width: 120,
        },
        {
            title: '状态',
            dataIndex: 'is_active',
            key: 'id',
            width: 80,
            render: (item, record) => (
                <Switch
                    disabled={record.is_expired}
                    onClick={() => {
                        //todo determine how to notice user
                        if (record.is_expired) {
                            message.warn('当前账户已过期，无法激活');
                        }
                    }}
                    checked={item}
                    size="small"
                    onChange={(value: boolean) => {
                        changeLoadingState(true);
                        modifyAgentAccount(record.id, { is_active: value })
                            .then(() => {
                                message.success('状态修改成功');
                                refreshTableData();
                            })
                            .catch(dealRequestError)
                            .finally(() => {
                                changeLoadingState(false);
                            });
                    }}
                />
            ),
        },
        {
            title: '添加时间',
            dataIndex: 'date_joined',
            key: 'id',
            width: 160,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 180,
            render: (item, record) => (
                <div className="opera">
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setDisplayAddAgentAccountFormModal({
                                display: true,
                                originAgentAccount: {
                                    originalInfo: record,
                                },
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={'确认要删除吗？'}
                        onConfirm={() => {
                            changeLoadingState(true);
                            deleteAgentAccountById(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    refreshTableData();
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const uploadCompanyLogoState = useFileUploaderState(fileUploaderPurposeKeyMapping.uploadCompanyLogo);
    useFileUploaderDisposer(fileUploaderPurposeKeyMapping.uploadCompanyLogo);

    return (
        <div className="container">
            <Row align={'middle'} className={'commonButton'} gutter={16}>
                <Col span={4}>
                    <Button
                        style={{ height: '100%' }}
                        type="primary"
                        onClick={() => {
                            setDisplayAddAgentAccountFormModal({
                                display: true,
                            });
                        }}
                    >
                        新增
                    </Button>
                </Col>
                <Col span={4} offset={6}>
                    <Row>
                        <Col span={6} className={'center'}>
                            <Typography.Text>帐号 : </Typography.Text>
                        </Col>
                        <Col span={18}>
                            <Input onChange={whenInputSearchAccount} value={searchAccount} allowClear />
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Row>
                        <Col span={6} className={'center'}>
                            <Typography.Text>姓名 : </Typography.Text>
                        </Col>
                        <Col span={18}>
                            <Input onChange={whenInputSearchName} value={searchName} allowClear />
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Row>
                        <Col span={6} className={'center'}>
                            <Typography.Text>电话 : </Typography.Text>
                        </Col>
                        <Col span={18}>
                            <Input onChange={whenInputSearchPhone} value={searchPhone} allowClear />
                        </Col>
                    </Row>
                </Col>
                <Col span={2} style={{textAlign: 'right'}}>
                    <Button
                        style={{ height: '100%' }}
                        type="primary"
                        onClick={() => {
                            resetToFirstPage();
                        }}
                    >
                        搜索
                    </Button>
                </Col>
            </Row>

            <Table<Account.BackendAgentAccountProfile>
                pagination={pagination}
                dataSource={tableDataWithLoadingState.paginationData.results}
                loading={tableDataWithLoadingState.loading}
                columns={columns}
                rowKey={record => record.id}
                scroll={{ x: '100%', y: 'calc(100vh - 305px)' }}
            />
            <ImagePreviewModal />
            {displayAddAgentAccountFormModal.display && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        const formHandler = addAgentAccountViewContentRef.current;
                        if (formHandler === null) {
                            return;
                        }
                        const clearContentAndHideModal = (isModifyMode: boolean) => {
                            formHandler.resetFields();
                            message.success(isModifyMode ? '编辑成功' : '添加成功');
                            modalRef.current?.hiddenModal();
                            setDisplayAddAgentAccountFormModal({
                                display: false,
                            });
                        };

                        formHandler.validateFields().then(() => {
                            handleFormValueWithDuplicationCheck<
                                Account.AddOrModifyAgentAccountParams,
                                Account.BackendAgentAccountProfile
                            >(
                                formHandler,
                                [
                                    stringFieldScheme('username', 'username'),
                                    stringFieldScheme('name', 'name'),
                                    stringFieldScheme('phone', 'mobile'),
                                    stringFieldScheme('companyName', 'enterprise_name'),
                                    stringFieldScheme(
                                        'companyAddress',
                                        'enterprise_location',
                                        RequestValueType.LITERAL,
                                        true
                                    ),
                                    stringArrayFieldScheme(
                                        'enterprise',
                                        'enterprise',
                                        RequestValueType.LITERAL_ARRAY,
                                        true,
                                        (selectedAreaOrCompanyIdentifies: string[]) =>
                                            fetchAllCompanyIdByTreeNodeIdentifies(selectedAreaOrCompanyIdentifies),
                                        {
                                            keyInBaseMarkObject: 'enterprise',
                                            keyInOriginalObject: 'enterprise',
                                            compareFunction: arrayCompareFunction,
                                        }
                                    ),
                                    momentFieldScheme('expired-time', 'expire_time'),
                                    stringFieldScheme('accountLimitation', 'son_count', RequestValueType.NUMERICAL),
                                    stringFieldScheme('role', 'roles', RequestValueType.NUMERICAL),
                                    stringFieldScheme('systemName', 'system_name', RequestValueType.LITERAL, true),
                                    stringFieldScheme('remark', 'remark', RequestValueType.LITERAL, true),
                                    stringFieldScheme(
                                        'systemLogo',
                                        'system_logo',
                                        RequestValueType.LITERAL,
                                        true,
                                        undefined,
                                        {
                                            keyInOriginalObject: 'system_logo',
                                            keyInBaseMarkObject: 'logo_path',
                                            compareFunction: (
                                                originalObjectValue: string | null,
                                                baseMarkObjectValue: string | null
                                            ) => {
                                                if (originalObjectValue !== null) {
                                                    if (baseMarkObjectValue === null) {
                                                        //if user select new image,but there not have logo store in backend yet.
                                                        //return false to inform reduce function there have new logo
                                                        return false;
                                                    }
                                                    //backend already store the logo, and user select new one, we need compare them if same
                                                    return baseMarkObjectValue.endsWith(originalObjectValue);
                                                }
                                                //there not have logo, if record in backend not have logo yet, represent user do nothing,if backend have, represent user aim to empty the logo
                                                return baseMarkObjectValue === null;
                                            },
                                        },
                                        () =>
                                            new Promise<string | undefined>((resolve, reject) => {
                                                uploadCompanyLogoState!!
                                                    .uploadSelectedFiles(UploadSituation.SYSTEM_LOGO)
                                                    .then(allImages => {
                                                        resolve(
                                                            allImages.length === 0
                                                                ? undefined
                                                                : allImages[0].relativeUrl
                                                        );
                                                    })
                                                    .catch(reject);
                                            })
                                    ),
                                ],
                                (purgedModifyParamsOrAddParams, isModifyMode, requestObject, originalRecord) => {
                                    (isModifyMode
                                        ? modifyAgentAccount(originalRecord!!.id, purgedModifyParamsOrAddParams)
                                        : createAgentAccount(purgedModifyParamsOrAddParams)
                                    )
                                        .then(() => {
                                            clearContentAndHideModal(isModifyMode);
                                            refreshTableData();
                                        })
                                        .catch(dealRequestError)
                                        .finally(() => {
                                            modalRef.current?.endLoading();
                                        });
                                },
                                () => {
                                    clearContentAndHideModal(true);
                                },
                                displayAddAgentAccountFormModal.originAgentAccount?.originalInfo
                            );
                        });
                    }}
                    onCancel={() => {
                        addAgentAccountViewContentRef.current?.resetFields();
                        setDisplayAddAgentAccountFormModal({
                            display: false,
                        });
                    }}
                    title={displayAddAgentAccountFormModal.originAgentAccount === undefined ? '新增帐号' : '编辑帐号'}
                >
                    <AddAgentAccountContent
                        ref={addAgentAccountViewContentRef}
                        roles={pagePreFetchData.roles}
                        originAgentAccount={displayAddAgentAccountFormModal.originAgentAccount}
                    />
                </CommonModal>
            )}
        </div>
    );
};

export default observer(AgentAccountView);
