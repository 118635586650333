import { request } from "../../utils/request";
import { AppBannerLocation } from "./enum";
export const appBannerLocationStaticData = [{
  name: '个人中心',
  value: AppBannerLocation.PERSONAL_CENTER
}];
export function fetchAllBanners(parameter = {}) {
  return request({
    url: '/v1/system/banner/',
    method: 'GET',
    params: parameter
  });
}
export function createBanner(parameter) {
  return request({
    url: '/v1/system/banner/',
    method: 'POST',
    data: parameter
  });
}
export function modifyBanner(bannerId, parameter) {
  return request({
    url: `/v1/system/banner/${bannerId}/`,
    method: 'PATCH',
    data: parameter
  });
}
export function deleteBanner(bannerId) {
  return request({
    url: `/v1/system/banner/${bannerId}/`,
    method: 'DELETE'
  });
}
export function fetchAllFeedbacks(parameter = {}) {
  return request({
    url: '/v1/system/feedback/',
    method: 'GET',
    params: parameter
  });
}
export function deleteFeedback(feedbackId) {
  return request({
    url: `/v1/system/feedback/${feedbackId}/`,
    method: 'DELETE'
  });
}
export function addFeedback(parameter) {
  return request({
    url: '/v1/system/feedback/',
    method: 'POST',
    data: parameter
  });
}