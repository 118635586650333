import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, FormInstance, Input, message, Popconfirm, Row, Select, Switch, Table, Typography } from 'antd';
import ImagePreviewModal from '@/components/modal/ImagePreviewModal';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import {
    arrayCompareFunction,
    createEffluentPlan,
    dealRequestError,
    deleteEffluentPlanById,
    EffluentPlan,
    EffluentPlanCategory,
    effluentPlanCategoryStaticData,
    EffluentPlanExecutionStrategy,
    effluentPlanExecutionStrategyStaticData,
    EffluentPlanLevel,
    effluentPlanLevelStaticData,
    fetchEffluentPlans,
    modifyEffluentPlan,
    PaginationWrapper,
    usePurgedAreaCompanyState,
} from '@greendev/common';
import { usePagination } from '@/utils/typeWrapper';
import AddEffluentPlanContent from '@/views/users/emissionPlan/emergencyPlanView/AddEffluentPlanContent';
import { ColumnsType } from 'antd/es/table';
import moment, { Moment } from 'moment';
import { dataFormatByHideYear } from '@/AntDesignReset';
import _ from 'lodash';
import handleFormValueWithDuplicationCheck, {
    momentArrayFieldScheme,
    RequestValueType,
    stringArrayFieldScheme,
    stringFieldScheme,
} from '@/utils/formUploadHandler';
import { fetchAllCompanyIdByTreeNodeIdentifies } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

const EmergencyPlanView = () => {
    usePurgedAreaCompanyState();
    const modalRef = useRef<CommonModalApi>(null);
    const addEffluentPlanContentRef = useRef<FormInstance<any>>(null);
    const [displayAddEffluentPlanModal, setDisplayAddEffluentPlanModal] = useState<{
        display: boolean;
        originEffluentPlan?: EffluentPlan.BackendProfile;
    }>({
        display: false,
    });
    useEffect(() => {
        if (displayAddEffluentPlanModal.display) {
            modalRef.current?.showModal();
        }
    }, [displayAddEffluentPlanModal]);

    const [searchName, setSearchName] = useState<string>('');
    const whenInputSearchName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchName(event.target.value);
    };

    const [selectedCategory, setSelectedCategory] = useState<EffluentPlanCategory | undefined>(undefined);
    const whenSelectCategory = (value: EffluentPlanCategory) => {
        setSelectedCategory(value);
    };

    const [selectedLevel, setSelectedLevel] = useState<EffluentPlanLevel | undefined>(undefined);
    const whenSelectedLevel = (value: EffluentPlanLevel) => {
        setSelectedLevel(value);
    };

    const [selectedExecuteStrategy, setSelectedExecuteStrategy] = useState<EffluentPlanExecutionStrategy | undefined>(
        undefined
    );
    const whenSelectedExecuteStrategy = (value: EffluentPlanExecutionStrategy) => {
        setSelectedExecuteStrategy(value);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tableDataWithLoadingState, changeLoadingState, refreshTableData, pagination, resetToFirstPage] =
        usePagination<EffluentPlan.BackendProfile>(
            (page, pageSize) =>
                fetchEffluentPlans({
                    name: searchName.trim().length === 0 ? undefined : searchName,
                    category: selectedCategory,
                    level: selectedLevel,
                    period: selectedExecuteStrategy,
                    page,
                    page_size: pageSize,
                }) as Promise<PaginationWrapper<EffluentPlan.BackendProfile>>
        );

    const columns: ColumnsType<EffluentPlan.BackendProfile> = [
        {
            title: '方案名称',
            dataIndex: 'name',
            key: 'id',
            align: 'left',
            width: 180,
        },
        {
            title: '类型',
            dataIndex: 'cat_name',
            key: 'id',
            width: 100,
        },
        {
            title: '限产百分比(%)',
            dataIndex: 'percent',
            key: 'id',
            width: 160,
            render: (_item, record) => {
                if(record.cat_name === '一厂一策') return '-';
                return record.percent;
            }
        },
        {
            title: '预警等级',
            dataIndex: 'level_name',
            key: 'id',
            width: 100,
        },
        {
            title: '开始时间',
            dataIndex: 's_date',
            key: 'id',
            width: 150,
            render: (item, record) => {
                let displayDate = item;
                if (record.period === EffluentPlanExecutionStrategy.CONTINUE) {
                    displayDate = dataFormatByHideYear(moment(item));
                }
                return (
                    <div className="opera">
                        <Typography.Text>{displayDate}</Typography.Text>
                    </div>
                );
            },
        },
        {
            title: '结束时间',
            dataIndex: 'e_date',
            key: 'id',
            width: 150,
            render: (item, record) => {
                let displayDate = item;
                if (record.period === EffluentPlanExecutionStrategy.CONTINUE) {
                    displayDate = dataFormatByHideYear(moment(item));
                }
                return (
                    <div className="opera">
                        <Typography.Text>{displayDate}</Typography.Text>
                    </div>
                );
            },
        },
        {
            title: '执行周期',
            dataIndex: 'period_name',
            key: 'id',
            width: 120,
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'id',
            width: 100,
            render: (item, record) => (
                <Switch
                    checked={item}
                    size="small"
                    onChange={(value: boolean) => {
                        changeLoadingState(true);
                        modifyEffluentPlan(record.id, { state: value })
                            .then(() => {
                                message.success('状态修改成功');
                                refreshTableData();
                            })
                            .catch(dealRequestError)
                            .finally(() => {
                                changeLoadingState(false);
                            });
                    }}
                />
            ),
        },
        {
            title: '添加时间',
            dataIndex: 'add_time',
            key: 'id',
            width: 180,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 180,
            render: (item, record) => (
                <div className="opera">
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setDisplayAddEffluentPlanModal({
                                display: true,
                                originEffluentPlan: record,
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={'确认要删除吗？'}
                        onConfirm={() => {
                            changeLoadingState(true);
                            deleteEffluentPlanById(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    refreshTableData();
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div className="container">
            <Row align={'middle'} className={'commonButton'} gutter={16}>
                <Col span={1}>
                    <Button
                        style={{ height: '100%' }}
                        type="primary"
                        onClick={() => {
                            setDisplayAddEffluentPlanModal({
                                display: true,
                            });
                        }}
                    >
                        新增
                    </Button>
                </Col>
                <Col span={4} offset={2}>
                    <Row>
                        <Col span={6} className={'center'}>
                            <Typography.Text>名称 : </Typography.Text>
                        </Col>
                        <Col span={18}>
                            <Input onChange={whenInputSearchName} value={searchName} allowClear />
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Row>
                        <Col span={6} className={'center'}>
                            <Typography.Text>类型 : </Typography.Text>
                        </Col>
                        <Col span={18}>
                            <Select<EffluentPlanCategory>
                                onChange={whenSelectCategory}
                                value={selectedCategory}
                                allowClear
                                style={{ width: '100%' }}
                                // @ts-ignore
                                options={effluentPlanCategoryStaticData}
                                fieldNames={{
                                    label: 'name',
                                    value: 'value',
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <Row>
                        <Col span={8} className={'center'}>
                            <Typography.Text>预警等级 : </Typography.Text>
                        </Col>
                        <Col span={16}>
                            <Select<EffluentPlanLevel>
                                onChange={whenSelectedLevel}
                                value={selectedLevel}
                                allowClear
                                style={{ width: '100%' }}
                                // @ts-ignore
                                options={effluentPlanLevelStaticData}
                                fieldNames={{
                                    label: 'name',
                                    value: 'value',
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={5}>
                    <Row>
                        <Col span={8} className={'center'}>
                            <Typography.Text>执行周期 : </Typography.Text>
                        </Col>
                        <Col span={16}>
                            <Select<EffluentPlanExecutionStrategy>
                                onChange={whenSelectedExecuteStrategy}
                                value={selectedExecuteStrategy}
                                allowClear
                                style={{ width: '100%' }}
                                // @ts-ignore
                                options={effluentPlanExecutionStrategyStaticData}
                                fieldNames={{
                                    label: 'name',
                                    value: 'value',
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                    <Button
                        style={{ height: '100%' }}
                        type="primary"
                        onClick={() => {
                            resetToFirstPage();
                        }}
                    >
                        搜索
                    </Button>
                </Col>
            </Row>

            <Table<EffluentPlan.BackendProfile>
                pagination={pagination}
                dataSource={tableDataWithLoadingState.paginationData.results}
                loading={tableDataWithLoadingState.loading}
                columns={columns}
                rowKey={record => record.id}
                scroll={{ x: '100%', y: 'calc(100vh - 305px)' }}
            />
            <ImagePreviewModal />
            {displayAddEffluentPlanModal.display && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        const formHandler = addEffluentPlanContentRef.current;
                        if (formHandler === null) {
                            return;
                        }
                        const clearContentAndHideModal = (isModifyMode: boolean) => {
                            modalRef.current?.endLoading();
                            formHandler.resetFields();
                            message.success(isModifyMode ? '编辑成功' : '添加成功');
                            modalRef.current?.hiddenModal();
                            setDisplayAddEffluentPlanModal({
                                display: false,
                            });
                        };

                        formHandler.validateFields().then(() => {
                            handleFormValueWithDuplicationCheck<
                                EffluentPlan.CreateOrUpdateEffluentPlanParameter,
                                EffluentPlan.BackendProfile
                            >(
                                formHandler,
                                [
                                    stringFieldScheme('name', 'name'),
                                    stringFieldScheme('category', 'category', RequestValueType.NUMERICAL),
                                    stringFieldScheme('level', 'level', RequestValueType.NUMERICAL),
                                    stringFieldScheme('executeStrategy', 'period', RequestValueType.NUMERICAL),
                                    momentArrayFieldScheme(
                                        'execute-time',
                                        's_date',
                                        RequestValueType.LITERAL,
                                        false,
                                        (momentRange: Moment[]) => Promise.resolve(momentRange[0].format('YYYY-MM-DD')),
                                        {
                                            keyInBaseMarkObject: 's_date',
                                            keyInOriginalObject: 's_date',
                                            compareFunction: (
                                                originalObjectValue: string,
                                                baseMarkObjectValue: string
                                            ) =>
                                                baseMarkObjectValue.includes('1970')
                                                    ? moment(originalObjectValue)
                                                          .set('year', 1970)
                                                          .isSame(moment(baseMarkObjectValue))
                                                    : originalObjectValue === baseMarkObjectValue,
                                        }
                                    ),
                                    momentArrayFieldScheme(
                                        'execute-time',
                                        'e_date',
                                        RequestValueType.LITERAL,
                                        false,
                                        (momentRange: Moment[]) => Promise.resolve(momentRange[1].format('YYYY-MM-DD')),
                                        {
                                            keyInBaseMarkObject: 'e_date',
                                            keyInOriginalObject: 'e_date',
                                            compareFunction: (
                                                originalObjectValue: string,
                                                baseMarkObjectValue: string
                                            ) =>
                                                baseMarkObjectValue.includes('1970')
                                                    ? moment(originalObjectValue)
                                                          .set('year', 1970)
                                                          .isSame(moment(baseMarkObjectValue))
                                                    : originalObjectValue === baseMarkObjectValue,
                                        }
                                    ),
                                    stringFieldScheme(
                                        'accountLimitation',
                                        'percent',
                                        RequestValueType.NUMERICAL,
                                        true,
                                        undefined,
                                        {
                                            keyInBaseMarkObject: 'percent',
                                            keyInOriginalObject: 'percent',
                                            compareFunction: (originalObjectValue: any, baseMarkObjectValue: number) =>
                                                originalObjectValue === null
                                                    ? true
                                                    : originalObjectValue === baseMarkObjectValue,
                                        }
                                    ),
                                    stringArrayFieldScheme(
                                        'enterprise',
                                        'enterprise',
                                        RequestValueType.LITERAL_ARRAY,
                                        true,
                                        (selectedAreaOrCompanyIdentifies: string[] | string) => {
                                            if (typeof selectedAreaOrCompanyIdentifies === 'string') {
                                                // eslint-disable-next-line no-param-reassign
                                                selectedAreaOrCompanyIdentifies = [selectedAreaOrCompanyIdentifies];
                                            }
                                            return fetchAllCompanyIdByTreeNodeIdentifies(
                                                selectedAreaOrCompanyIdentifies
                                            );
                                        },
                                        {
                                            keyInBaseMarkObject: 'enterprise',
                                            keyInOriginalObject: 'enterprise',
                                            compareFunction: arrayCompareFunction,
                                        }
                                    ),
                                    stringArrayFieldScheme(
                                        'monitor_point',
                                        'monitor_point',
                                        RequestValueType.LITERAL_ARRAY,
                                        true,
                                        (selectedMonitoringIdentifies: string[]) => {
                                            console.log(selectedMonitoringIdentifies);
                                            return Promise.resolve(
                                                selectedMonitoringIdentifies.map(val => Number.parseInt(val, 10))
                                            );
                                        },
                                        {
                                            keyInBaseMarkObject: 'monitor_point',
                                            keyInOriginalObject: 'monitor_point',
                                            compareFunction: arrayCompareFunction,
                                        }
                                    ),
                                ],
                                (purgedModifyParamsOrAddParams, isModifyMode, requestObject, originalRecord) => {
                                    let addOrModifyPromise;
                                    const tempPurgedParams = _.cloneDeep(purgedModifyParamsOrAddParams);
                                    const isStopCategory =
                                        tempPurgedParams.category === EffluentPlanCategory.STOP.valueOf();
                                    if (isStopCategory) {
                                        tempPurgedParams.percent = 100;
                                    }
                                    if (isModifyMode) {
                                        if (
                                            tempPurgedParams.s_date !== undefined ||
                                            tempPurgedParams.e_date !== undefined ||
                                            tempPurgedParams.period !== undefined
                                        ) {
                                            tempPurgedParams.s_date = requestObject.s_date;
                                            tempPurgedParams.e_date = requestObject.e_date;
                                            tempPurgedParams.period = requestObject.period;
                                        }
                                        if (
                                            tempPurgedParams.percent !== undefined ||
                                            tempPurgedParams.category !== undefined
                                        ) {
                                            tempPurgedParams.category = requestObject.category;
                                            if (requestObject.category === EffluentPlanCategory.STOP.valueOf()) {
                                                tempPurgedParams.percent = 100;
                                            }
                                        }
                                        addOrModifyPromise = modifyEffluentPlan(originalRecord!!.id, tempPurgedParams);
                                    } else {
                                        addOrModifyPromise = createEffluentPlan(tempPurgedParams);
                                    }
                                    addOrModifyPromise
                                        .then(() => {
                                            clearContentAndHideModal(isModifyMode);
                                            refreshTableData();
                                        })
                                        .catch(error => {
                                            dealRequestError(error);
                                            modalRef.current?.endLoading();
                                        });
                                },
                                () => {
                                    clearContentAndHideModal(true);
                                },
                                displayAddEffluentPlanModal.originEffluentPlan
                            );
                        });
                    }}
                    onCancel={() => {
                        addEffluentPlanContentRef.current?.resetFields();
                        setDisplayAddEffluentPlanModal({
                            display: false,
                        });
                    }}
                    title={
                        displayAddEffluentPlanModal.originEffluentPlan === undefined ? '新增减排方案' : '编辑减排方案'
                    }
                >
                    <AddEffluentPlanContent
                        ref={addEffluentPlanContentRef}
                        originalEffluentPlan={displayAddEffluentPlanModal.originEffluentPlan}
                    />
                </CommonModal>
            )}
        </div>
    );
};

export default observer(EmergencyPlanView);
