import { Modal } from 'antd';
import React from 'react';
import { Observer } from 'mobx-react-lite';
import imagePreviewState from '@/stores/imagePreviewState';

const ImagePreviewModal = () => (
    <Observer>
        {() => (
            <Modal
                visible={imagePreviewState.previewImage !== undefined}
                title={imagePreviewState.previewImage?.name}
                footer={null}
                onCancel={() => {
                    imagePreviewState.dismissImagePreview();
                }}
            >
                <img
                    alt={imagePreviewState.previewImage?.preview}
                    style={{ width: '100%' }}
                    src={imagePreviewState.previewImage?.preview}
                />
            </Modal>
        )}
    </Observer>
);

export default ImagePreviewModal;
