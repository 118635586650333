import React, { useMemo } from 'react';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { observer } from 'mobx-react-lite';
import * as echarts from 'echarts';
import _ from 'lodash';

export type EChartGroupBarChartProps = {
    data: any;
    colors: any;
    unit: string;
};

const EchartLine = (props: EChartGroupBarChartProps) => {
    const { data, colors, unit } = props;
    const seriesData: any = [];
    const servers = useMemo(() => new Array(data[0].length - 1), [data]);
    // eslint-disable-next-line react/destructuring-assignment
    // eslint-disable-next-line @typescript-eslint/no-shadow
    _.map(servers, (_, index) => {
        // 超出部分沿用之前的颜色设定
        const useingIndex = index % colors.length;
        seriesData.push({
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
                normal: {
                    width: 1,
                    color: colors[useingIndex]?.color, // 线条颜色
                },
            },
            itemStyle: {
                color: colors[useingIndex]?.color,
            },
            tooltip: {
                show: true,
            },
            areaStyle: {
                //区域填充样式
                normal: {
                    //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                    color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: colors[useingIndex]?.colorrgba1,
                            },
                            {
                                offset: 1,
                                color: colors[useingIndex]?.colorrgba2,
                            },
                        ],
                        false
                    ),
                    shadowColor: colors[useingIndex]?.showdowColor, //阴影颜色
                    shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                },
            },
        });
    });
    const barChartOption: EChartsOption = {
        legend: {
            icon: 'roundRect',
            type: 'scroll',
            bottom: '-5',
            textStyle: {
                color: '#9EA5AE',
                fontSize: 12,
            },
            itemGap: 20,
            itemWidth: 8,
            itemHeight: 8,
        },
        grid: {
            top: '25%',
            left: '5%',
            bottom: '12%',
            right: '2%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
        },
        calculable: true,
        dataZoom: {
            type: 'inside', //inside slider
            show: true, //flase直接隐藏图形
            xAxisIndex: [0],
            start: 0, //滚动条的起始位置
            end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            orient: 'vertical',
        },
        xAxis: [
            {
                type: 'category',
                gridIndex: 0,
                boundaryGap: false,
                axisLine: {
                    //坐标轴轴线相关设置。数学上的x轴
                    show: true,
                    lineStyle: {
                        color: '#e3e6e4',
                    },
                },
                axisLabel: {
                    //坐标轴刻度标签的相关设置
                    textStyle: {
                        color: '#9EA5AE',
                        fontSize: 12,
                    },
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    formatter(data: any) {
                        return data;
                    },
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#e3e6e4',
                    },
                },
                axisTick: {
                    show: false,
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                name: `单位:(${unit})`,
                textStyle: {
                    fontStyle: 'normal',
                    color: '#9EA5AE',
                    fontweight: 'normal',
                    fontstretch: 'normal',
                    fontSize: 12,
                },
                nameTextStyle: {
                    color: '#9EA5AE',
                    fontSize: 12,
                    padding: 5,
                },
                min: 0,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#f0f2f5',
                    },
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#e3e6e4',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#9EA5AE',
                    },
                },
                axisTick: {
                    show: false,
                },
            },
        ],
        dataset: {
            dimensions: data[0],
            source: data,
        },
        series: seriesData,
    };
    return (
        <ReactEcharts
            notMerge
            option={barChartOption}
            style={{ width: '100%', height: '80%', backgroundColor: 'transparent' }}
        />
    );
};

export default observer(EchartLine);
