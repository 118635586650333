import { Form, FormInstance, Input } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import AreaFormTreeSelect from '@/views/common/basicDataManage/areaManageView/AreaFormTreeSelect';
import { Area } from '@greendev/common';

export type AreaEditContentProps = {
    /**
     * true means this content should be used as change name of area , and will display a treeSelect component when this field is false(add mode)
     */
    editMode: boolean;
    /**
     * only exist when {@link editMode} is true , represent the area you want to change
     */
    targetArea?: Area.FrontEndProfile;
};

//新增、编辑弹框内容显示
const AreaEditContent = forwardRef<FormInstance<any>, AreaEditContentProps>((props: AreaEditContentProps, ref) => {
    const { editMode, targetArea } = props;

    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
    useEffect(() => {
        if (targetArea !== undefined) {
            const { name } = targetArea;
            setInitialValues({
                name,
            });
        }
    }, [targetArea]);

    return (
        <Form
            labelCol={{ span: 6 }}
            labelAlign={'right'}
            wrapperCol={{ span: 18 }}
            ref={ref}
            initialValues={initialValues}
            key={initialValues?.name}
        >
            {!editMode && <AreaFormTreeSelect required={false} label={'上级行政区'} />}
            <Form.Item
                name="name"
                label="行政区名称"
                rules={[
                    { required: true, message: '请输入行政区名称' },
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,15}$/,
                        message: '行政区只能包含1-15位的汉字、字母、数字、下划线!',
                    },
                ]}
            >
                <Input placeholder="请输入行政区名称" />
            </Form.Item>
        </Form>
    );
});

export default AreaEditContent;
