import { DatePicker, Form, FormInstance, Input, Select } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { disableDaysBeforeToday } from '@/AntDesignReset';
import moment from 'moment';
import FileUploader from '@/components/fileUploader/FileUploader';
import { generateUploadFileObjectByUrl } from '@/stores/fileUploaderState';
import { fileUploaderPurposeKeyMapping, useFileUploaderState } from '@/components/fileUploader/manage';
import { Account, generateIdentifiedByType, Role, TreeItemType } from '@greendev/common';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

export type OriginalAgentAccountInfoWithCompaniesInfo = {
    originalInfo: Account.BackendAgentAccountProfile;
};

export type AddAgentAccountContentProps = {
    originAgentAccount?: OriginalAgentAccountInfoWithCompaniesInfo;
    roles: Role.BackEndProfile[];
};

const AddAgentAccountContent = forwardRef<FormInstance<any>, AddAgentAccountContentProps>((props, ref) => {
    const { originAgentAccount, roles } = props;

    const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

    const uploadCompanyLogoState = useFileUploaderState(fileUploaderPurposeKeyMapping.uploadCompanyLogo);

    useEffect(() => {
        if (originAgentAccount !== undefined) {
            const { originalInfo } = originAgentAccount;

            if (originalInfo.logo_path !== null && uploadCompanyLogoState !== undefined) {
                uploadCompanyLogoState.backfillImages([generateUploadFileObjectByUrl(originalInfo.logo_path)]);
            }
            setInitialValues({
                'username': originalInfo.username,
                'name': originalInfo.name,
                'phone': originalInfo.mobile,
                'companyName': originalInfo.enterprise_name,
                'companyAddress': originalInfo.enterprise_location,
                'enterprise': originalInfo.enterprise.map(eachCompanyId =>
                    generateIdentifiedByType(TreeItemType.COMPANY, eachCompanyId)
                ),
                'expired-time': moment(originalInfo.expire_time),
                'accountLimitation': originalInfo.son_count,
                'role': originalInfo.roles.toString(10),
                'systemName': originalInfo.system_name,
                'remark': originalInfo.remark,
            });
        }
        return () => {
            if (uploadCompanyLogoState !== undefined) {
                uploadCompanyLogoState.clearSelectedFiles();
            }
        };
    }, [uploadCompanyLogoState, originAgentAccount]);

    return (
        <Form
            labelCol={{ span: 6 }}
            labelAlign={'right'}
            wrapperCol={{ span: 18 }}
            ref={ref}
            initialValues={initialValues}
            key={initialValues?.name}
        >
            <Form.Item
                name="username"
                label="代理帐号"
                rules={[
                    { required: true, message: '请输入代理帐号' },
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/,
                        message: '帐号只能包含1-20位的汉字、字母、数字、下划线!',
                    },
                ]}
            >
                <Input placeholder="请输入代理帐号" />
            </Form.Item>
            <Form.Item
                name="name"
                label="联系人姓名"
                rules={[
                    { required: true, message: '请输入联系人姓名' },
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,10}$/,
                        message: '联系人姓名只能包含1-10位的汉字、字母、数字、下划线!',
                    },
                ]}
            >
                <Input placeholder="请输入联系人姓名" />
            </Form.Item>
            <Form.Item
                name="phone"
                label="联系人电话"
                rules={[
                    { required: true, message: '请输入联系人电话' },
                    {
                        pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                        message: '请输入合法的联系人电话',
                    },
                ]}
            >
                <Input placeholder="请输入联系人电话" />
            </Form.Item>
            <Form.Item
                name="companyName"
                label="公司名称"
                rules={[
                    { required: true, message: '请输入公司名称' },
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,30}$/,
                        message: '公司名称只能包含1-30位的汉字、字母、数字、下划线!',
                    },
                ]}
            >
                <Input placeholder="请输入公司名称" />
            </Form.Item>
            <Form.Item
                name="companyAddress"
                label="公司地址"
                rules={[
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_-]{1,50}$/,
                        message: '公司地址只能包含1-50位的汉字、字母、数字、下/中划线!',
                    },
                ]}
            >
                <Input placeholder="请输入公司地址" />
            </Form.Item>
            <PurgedAreaCompanyTreeSelectUsedInForm required={false} label={'访问权限'} treeCheckable />
            <Form.Item
                name="expired-time"
                label="到期时间"
                rules={[{ type: 'object' as const, required: true, message: '请选择到期时间' }]}
            >
                <DatePicker disabledDate={disableDaysBeforeToday} />
            </Form.Item>
            <Form.Item
                name="accountLimitation"
                label="帐号限制"
                rules={[
                    { required: true, message: '请输入帐号限制' },
                    {
                        pattern: /^[0-9]{1,3}$/,
                        message: '最多999个帐号',
                    },
                ]}
            >
                <Input placeholder="请输入帐号限制" />
            </Form.Item>
            <Form.Item name="role" label="角色" rules={[{ required: true, message: '请选择角色' }]}>
                <Select placeholder="请选择角色" allowClear>
                    {roles.map(eachRole => (
                        <Select.Option key={eachRole.id}>{eachRole.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="systemName"
                label="系统名称"
                rules={[
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_.]{1,40}$/,
                        message: '系统名称只能包含1-40位的汉字、字母、数字、下划线、小圆点!',
                    },
                ]}
            >
                <Input placeholder="请输入系统名称" />
            </Form.Item>
            <Form.Item name="systemLogo" label="系统Logo">
                {uploadCompanyLogoState !== undefined && (
                    <FileUploader maxImageCount={1} fileUploaderState={uploadCompanyLogoState} />
                )}
            </Form.Item>
            <Form.Item
                name={'remark'}
                label="备注"
                rules={[
                    {
                        pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,50}$/,
                        message: '备注只能包含1-50位的汉字、字母、数字、下划线!',
                    },
                ]}
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    );
});

export default AddAgentAccountContent;
