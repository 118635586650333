import React, { lazy } from 'react';
import { Result } from 'antd';

import { NotFound } from '@/router';
import WrapperRouteComponent from '@/router/config';
import RouteView from '@/router/routeView';

import { MenuItem } from '@/layout/menu';

import routesComponents from '@/router/routesComponents';

import { userStore } from '@/stores/userStore';

export function isExternal(path: string) {
    return /^(https?:|mailto:|tel:)/.test(path);
}

export function filterAsyncRoute(routes: API.Menu[], parentRoute: API.Menu | null, lastKeyPath: string[] = []) {
    return (
        routes
            // eslint-disable-next-line
            .filter(item => item.type !== 2 && item.is_show && item.p_id == parentRoute?.id)
            .map(item => {
                const { router, view_path: viewPath, name, icon, keep_alive: keepalive } = item;
                // 提取工作台路径
                if (viewPath === 'views/users/dashboardView') {
                    userStore.setDashboardPath(router!);
                }
                let fullPath = '';
                const pathPrefix = lastKeyPath.slice(-1)[0] || '';
                if (router && /http(s)?:/.test(router)) {
                    fullPath = router;
                } else if (router) {
                    fullPath = router.startsWith('/') ? router : `/${router}`;
                    fullPath = router.startsWith(pathPrefix) ? fullPath : pathPrefix + fullPath;
                    fullPath = [...new Set(fullPath.split('/'))].join('/');
                }
                let realRoutePath = router;
                if (router && parentRoute) {
                    if (fullPath.startsWith(parentRoute?.router!)) {
                        realRoutePath = fullPath.split(parentRoute.router!)[1];
                    } else if (!isExternal(parentRoute.router!) && !isExternal(router)) {
                        realRoutePath = router;
                    }
                }
                // eslint-disable-next-line no-nested-ternary
                realRoutePath = realRoutePath!.startsWith('/')
                    ? realRoutePath!.slice(1)
                    : realRoutePath?.startsWith('#')
                    ? `${window.location.protocol}//${window.location.host}/${router?.substring(1)}`
                    : realRoutePath;
                const route: MenuItem = {
                    path: realRoutePath!,
                    key: fullPath,
                    keyPath: lastKeyPath.concat(fullPath),
                    // name: toHump(viewPath),
                    meta: {
                        title: name,
                        icon: icon as string,
                        noCache: !keepalive,
                    },
                };

                if (item.type === 0) {
                    // 如果是目录
                    const children = filterAsyncRoute(routes, item, route.keyPath);
                    if (children?.length) {
                        route.element = (
                            <WrapperRouteComponent element={<RouteView />} auth titleId="title.dashboard" />
                        );
                        route.children = children;
                    } else {
                        route.element = (
                            <Result
                                status="500"
                                title={name}
                                subTitle="目录类型菜单不是真实页面，请为当前目录添加页面级子菜单或更改当前菜单类型."
                            />
                        );
                    }
                    return route;
                }
                if (item.type === 1 && viewPath) {
                    // 如果是页面
                    // console.log('viewPath', viewPath);
                    const Component = routesComponents[viewPath] ? lazy(() => import(`../${viewPath}`)) : NotFound;
                    // console.log('ViewPath', Component);
                    route.element = <WrapperRouteComponent element={<Component />} auth titleId="title.dashboard" />;
                    return route;
                }
                return undefined;
            })
            .filter((item): item is MenuItem => !!item)
    );
}
