import React from 'react';
import title_dot from '@/assets/images/title_dot.png';

const PartTitle: React.FC = ({ children }) => (
    <span className="bigScreen_common_title">
        {children}
        <img src={title_dot} alt={''} style={{ height: '17px' }} />
    </span>
);

export default PartTitle;
