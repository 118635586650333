import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Popover, Form, Select, Button, TableColumnsType } from 'antd';
import { AreaTreeSelect, useAreaInitialize } from '@/views/common/basicDataManage/areaManageView/AreaFormTreeSelect';
import {
    getAlertList,
    getAlertCategory,
    dealRequestError,
    fetchAllIndustryTree,
    Alert,
    Industry,
} from '@greendev/common';
import { map } from 'lodash';
import GlobalStyle from '@/styles';

const { color } = GlobalStyle;

type AlertParamsType = {
    category?: string;
    ad_region?: string;
    grade?: string;
    is_lift?: string;
};

/** 报警管理界面 */
const AlertManageView = () => {
    const [alertType, setAlertType] = useState<Alert.AlertCategoryType>([]);
    const [alertTableData, setAlertTableData] = useState<Alert.AlertManageItem[]>([]);
    const [industries, setIndustries] = useState<Industry.FrontEndProfile[]>([]);
    const [alertParams, setAlertParams] = useState<AlertParamsType>({});
    // 分页
    const ref = useRef<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const AlertList = (
        page = 1,
        params = { category: undefined, ad_region: undefined, grade: undefined, is_lift: undefined }
    ) => {
        setIsLoading(true);
        setCurrentPage(page);
        getAlertList({ page, page_size: ref.current ? ref.current.pageSize : 20, ...params })
            .then(res => {
                setTotal(res.count!);
                const result = map(res.results, item => ({ ...item, key: item.id }));
                setAlertTableData(result);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(true);
            });
    };

    const [selectedArea, setSelectedArea] = useState<number | undefined>(undefined);
    const whenSelectArea = (value: number) => {
        setSelectedArea(value);
    };

    const getCategory = () => {
        getAlertCategory().then(res => {
            setAlertType(res);
            console.log(res);
        });
    };

    const fetchAllIndustry = () => {
        fetchAllIndustryTree()
            .then(result => {
                setIndustries(result);
            })
            .catch(dealRequestError);
    };

    useAreaInitialize();
    useEffect(() => {
        getCategory();
        AlertList();
        fetchAllIndustry();
    }, []);

    const columns: TableColumnsType<Alert.AlertManageItem> = [
        {
            title: '企业名称',
            dataIndex: 'enterprise',
            key: 'enterprise',
        },
        {
            title: '报警类型',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: '行政区',
            dataIndex: 'ad_region',
            key: 'ad_region',
            render: val => val || '-',
        },
        {
            title: '所属行业',
            dataIndex: 'trade',
            key: 'trade',
            render: val => val || '-',
        },
        {
            title: '内容',
            dataIndex: 'content',
            key: 'content',
            ellipsis: {
                showTitle: false,
            },
            render: val => <Popover content={val}>{val}</Popover> || '-',
        },
        {
            title: '报警时间',
            dataIndex: 'alarm_time',
            key: 'alarm_time',
        },
        {
            title: '是否解除',
            dataIndex: 'lift_time',
            key: 'lift_time',
            render: val => {
                if (val) {
                    return (
                        <Popover content={val}>
                            <div style={{ color: color.titleColor }}>已解除</div>
                        </Popover>
                    );
                }
                return <div style={{ color: color.themeRed }}>未解除</div>;
            },
        },
    ];

    const onFinish = (data: any) => {
        AlertList(1, data);
        setAlertParams(data);
    };

    return (
        <div className="container">
            <Form
                layout="horizontal"
                labelAlign={'right'}
                onFinish={onFinish}
                style={{ display: 'flex', justifyContent: 'end' }}
            >
                <Form.Item label="类型" name="category" style={{ margin: '0 8px' }}>
                    <Select
                        placeholder="请选择报警类型"
                        virtual={false}
                        showArrow
                        allowClear
                        style={{ width: '180px' }}
                    >
                        {alertType.map(item => (
                            <Select.Option value={item[0]} key={item[0]}>
                                {item[1]}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="行政区" name="ad_region" style={{ margin: '0 8px' }}>
                    <AreaTreeSelect
                        label={'行政区'}
                        onChange={whenSelectArea}
                        value={selectedArea}
                        style={{ width: '180px' }}
                    />
                </Form.Item>
                <Form.Item name="trade" label="所属行业" style={{ margin: '0 8px' }}>
                    <Select placeholder="请选择所属行业" allowClear style={{ width: '180px' }}>
                        {industries.map(eachIndustry => (
                            <Select.Option key={eachIndustry.id}>{eachIndustry.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="状态" name="is_lift" style={{ margin: '0 16px 0 8px' }}>
                    <Select
                        placeholder="请选择报警状态"
                        virtual={false}
                        showArrow
                        allowClear
                        style={{ width: '180px' }}
                    >
                        <Select.Option value={'True'}>已解除</Select.Option>
                        <Select.Option value={'False'}>未解除</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        搜索
                    </Button>
                </Form.Item>
            </Form>
            <Table
                loading={isLoading}
                rowKey={record => record.id}
                columns={columns as any}
                dataSource={alertTableData}
                onChange={page => {
                    ref.current = page;
                    AlertList(page.current, alertParams as any);
                }}
                pagination={{
                    defaultPageSize: 20, // commonPageSize
                    total,
                    current: currentPage,
                    pageSizeOptions: [10, 20, 30, 50, 100],
                    showTotal: totalCount => `共 ${totalCount} 条`,
                }}
                scroll={{ y: 'calc(100vh - 305px)' }}
            />
        </div>
    );
};

export default observer(AlertManageView);
