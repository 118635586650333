import React from 'react';
import { Timeline } from 'antd';
import { map } from 'lodash';
import { observer } from 'mobx-react-lite';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import GlobalStyle from '@/styles';
import { Workbench } from '@greendev/common';

const { color } = GlobalStyle;

export type AlarmDataProps = {
    alarmData?: any | Workbench.RecentAlarmFrontEndProfile;
};

const TimeLine = (props: AlarmDataProps) => {
    const { alarmData } = props;
    return (
        <Timeline mode={'left'} style={{ width: '100%', marginLeft: '20px', marginTop: '20px' }}>
            {map(alarmData.slice(0, 6), item => (
                <Timeline.Item
                    key={item.id + Math.random() * 1000}
                    dot={
                        <p
                            style={{
                                textAlign: 'center',
                                background:
                                    item.lift_time === 'None' || item.lift_time !== null
                                        ? color.themeColor
                                        : color.themeRed,
                                width: '22px',
                                height: '22px',
                                lineHeight: '22px',
                                borderRadius: '50%',
                            }}
                        >
                            {item.lift_time === 'None' || item.lift_time !== null ? (
                                <CheckOutlined style={{ fontSize: '12px', color: '#fff' }} />
                            ) : (
                                <i className={'iconfont iconbaojing'} style={{ fontSize: '12px', color: '#fff' }} />
                            )}
                        </p>
                    }
                >
                    <p className="ant-timeline-item_time">{`${item.alarm_time?.slice(0, 10)} ${item.alarm_time?.slice(
                        11,
                        19
                    )}`}</p>
                    <p className="ant-timeline-item_name">
                        {item.lift_time === 'None' || item.lift_time !== null
                            ? item.content
                            : `${item.category}： ${item.content}`}
                    </p>
                </Timeline.Item>
            ))}
        </Timeline>
    );
};

export default observer(TimeLine);
