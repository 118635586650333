import { request } from "../../utils/request";
/**
 * @description 获取报警设置列表
 */
export function getAlertSettingList() {
  const options = {
    url: '/v1/system/notify/',
    method: 'get'
  };
  return request(options);
}

/**
 * @description 修改报警设置列表
 */
export function editAlertSettingList(id, data) {
  const options = {
    url: `/v1/system/notify/${id}/`,
    method: 'patch',
    data
  };
  return request(options);
}

/**
 * @description 获取报警通知人列表
 */
export function getAlertReceiver() {
  const options = {
    url: `/v1/system/recipient/`,
    method: 'get'
  };
  return request(options);
}

/**
 * @description 修改报警通知人
 */
export function editAlertReceiver(id, data) {
  const options = {
    url: `/v1/system/recipient/${id}/`,
    method: 'patch',
    data
  };
  return request(options);
}

/**
 * @description 获取报警管理列表
 */
export function getAlertList(params) {
  const options = {
    url: `/v1/alarm/`,
    method: 'get',
    params
  };
  return request(options);
}

/**
 * backend will result wrong-counted records if current account is company account
 * @description 获取app端报警管理列表
 */
export function getAlertListForApp(params) {
  const options = {
    url: `/v1/alarm/list/`,
    method: 'get',
    params
  };
  return request(options);
}

/**
 * @description 获取报警类型
 */
export function getAlertCategory() {
  const options = {
    url: `/v1/alarm/category/`,
    method: 'get'
  };
  return request(options);
}

/**
 * @description APP - 获取报警排行
 */
export function getAlertRank(params) {
  const options = {
    url: `/v1/visual/index/alarm/static/`,
    method: 'get',
    params
  };
  return request(options);
}

/**
 * @description APP - 获取报警详情列表
 */
export function getAlertDetailList(params) {
  const options = {
    url: `/v1/visual/index/alarm/detail/`,
    method: 'get',
    params
  };
  return request(options);
}

/**
 * @description APP - 正常/异常/异常解除企业列表
 */
export function getAlertCompanyStatusList(params) {
  const options = {
    url: `/v1/visual/index/enter/detail/`,
    method: 'get',
    params
  };
  return request(options);
}