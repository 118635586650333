import { dealRequestError, generateIdentifiedByType, TreeItemType, wrapTopArea } from "../../utils";
import { addCompany, ClientFieldGenerationStrategy, deleteCompanyById, editCompanyReal, fetchAllAreaTree, fetchAllCompany } from "../../api";
import { makeAutoObservable } from 'mobx';
import { useEffect } from 'react';
import { generateDisableAttributeForTree, sortAreaByCompanyCount } from "./..";
import _ from 'lodash';
class PurgedAreaCompanyTreeState {
  _areasWithCombinedChildrenLoadingState = {
    areaCompanyTree: [],
    loading: true
  };
  get areasWithCombinedChildrenLoadingState() {
    return this._areasWithCombinedChildrenLoadingState;
  }
  get areasWithCombinedChildrenLoadingStateWithStemDisable() {
    const copyAreas = _.cloneDeep(this._areasWithCombinedChildrenLoadingState.areaCompanyTree);
    const treeWrapper = wrapTopArea(copyAreas);
    // @ts-ignore
    generateDisableAttributeForTree(treeWrapper);
    // @ts-ignore
    sortAreaByCompanyCount(treeWrapper);
    return {
      areaCompanyTree: treeWrapper.children,
      loading: false
    };
  }
  clearResource() {
    this._areasWithCombinedChildrenLoadingState = {
      areaCompanyTree: [],
      loading: true
    };
  }

  /**
   * you can call this function in different locations,
   * function internal will ensure the areas not will be init twice
   */
  initializeAreas() {
    //represent areas not initialized yet
    if (this._areasWithCombinedChildrenLoadingState.areaCompanyTree.length === 0) {
      fetchAllAreaTree(ClientFieldGenerationStrategy.AREA_TREE_WITH_COMBINED_CHILDREN).then(resultedAreas => {
        this._areasWithCombinedChildrenLoadingState = {
          areaCompanyTree: resultedAreas,
          loading: false
        };
      });
    }
  }
  changeLoadingState = loading => {
    this._areasWithCombinedChildrenLoadingState = {
      ...this._areasWithCombinedChildrenLoadingState,
      loading
    };
  };
  formatDataFunc = companies => {
    const res = [];
    for (const eachCompany of companies) {
      let currentChild = res;
      for (let ArrIndex = 0; ArrIndex < eachCompany.ad_region_list.length; ArrIndex++) {
        const region = eachCompany.ad_region_list[ArrIndex];
        const hasIndex = currentChild.findIndex(val => val.name === region.name);
        // 如果已存在当前区域，则进入它的子元素中
        if (hasIndex !== -1) {
          currentChild = currentChild[hasIndex].children || [];
        } else {
          // 未存在时则新建区域，并进入子元素中
          currentChild.push({
            id: region.id,
            uniqueId: generateIdentifiedByType(TreeItemType.AREA, region.id),
            name: region.name,
            children: [],
            isLeaf: false
          });
          currentChild = currentChild[currentChild.length - 1].children || [];
        }
      }
      // 区域遍历结束后写入企业
      currentChild.push({
        id: eachCompany.id,
        uniqueId: generateIdentifiedByType(TreeItemType.COMPANY, eachCompany.id),
        name: eachCompany.name,
        isLeaf: true
      });
    }
    return res;
  };
  refreshPurgedAreaCompanyTree = () => {
    this.changeLoadingState(true);
    fetchAllCompany({
      get_all: true
    }).then(companies => {
      const result = this.formatDataFunc(companies);
      this._areasWithCombinedChildrenLoadingState = {
        areaCompanyTree: result,
        loading: false
      };
    }).catch(dealRequestError);
  };
  deleteCompany = targetCompany => new Promise((resolve, reject) => {
    this.changeLoadingState(true);
    deleteCompanyById(targetCompany.id).then(() => {
      this.refreshPurgedAreaCompanyTree();
      resolve();
    }).catch(error => {
      dealRequestError(error);
      this.changeLoadingState(false);
      reject();
    });
  });
  createCompany = params => new Promise((resolve, reject) => {
    this.changeLoadingState(true);
    addCompany(params).then(result => {
      this.refreshPurgedAreaCompanyTree();
      resolve();
    }).catch(error => {
      dealRequestError(error);
      this.changeLoadingState(false);
      reject();
    });
  });
  updateCompany = (targetCompanyId, params) => new Promise((resolve, reject) => {
    this.changeLoadingState(true);
    editCompanyReal(targetCompanyId, params).then(() => {
      this.refreshPurgedAreaCompanyTree();
      resolve();
    }).catch(error => {
      dealRequestError(error);
      this.changeLoadingState(false);
      reject();
    });
  });
  constructor() {
    makeAutoObservable(this);
    // autorun(() => {
    //     console.log('area company tree', this.areasWithCombinedChildrenLoadingState);
    // });
  }
}
export const purgedAreaCompanyTreeState = new PurgedAreaCompanyTreeState();
export function usePurgedAreaCompanyState() {
  useEffect(() => {
    purgedAreaCompanyTreeState.refreshPurgedAreaCompanyTree();
  }, []);
}