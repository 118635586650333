import { request } from "../../utils/request";
import _ from 'lodash';
/* eslint-disable no-param-reassign */

export const deviceIsGateway = categoryId => categoryId === 'F001' || categoryId === 'F000';
export const deviceIsGatewayByObject = deviceObject => deviceIsGateway(deviceObject.category_id);
export function generateClientSideFieldForDeviceObject(backendObject) {
  const notCanActive = backendObject.status_name !== '未激活' || deviceIsGateway(backendObject.category_id);
  backendObject.canActive = !notCanActive;
  // backendObject.multiple = Math.floor(backendObject.multiple);
  return backendObject;
}
export function fetchDevices(params = {}) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/device/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(result => {
      if (params.get_all === true) {
        resolve(result.map(eachDevice => generateClientSideFieldForDeviceObject(eachDevice)));
      } else {
        result.results = result.results.map(eachDevice => generateClientSideFieldForDeviceObject(eachDevice));
        resolve(result);
      }
    }).catch(reject);
  });
}

/* eslint-enable no-param-reassign */

export function deleteDeviceById(id) {
  return request({
    url: `/v1/device/${id}/`,
    method: 'DELETE'
  });
}
export function modifyDevice(deviceId, params) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/device/${deviceId}/`,
      method: 'PATCH',
      data: _.omitBy(params, _.isUndefined)
    }).then(modifiedDevice => {
      resolve(generateClientSideFieldForDeviceObject(modifiedDevice));
    }).catch(reject);
  });
}
export function activeDevices(activationList) {
  return request({
    url: '/v1/device/active/',
    method: 'PATCH',
    data: {
      activation_list: activationList
    },
    timeout: activationList.length + 5000 + 4000
  });
}
export function createDevice(params) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/device/',
      method: 'POST',
      data: _.omitBy(params, _.isUndefined)
    }).then(createdDevice => {
      resolve(generateClientSideFieldForDeviceObject(createdDevice));
    }).catch(reject);
  });
}
export function fetchDeviceCategories() {
  return request({
    url: '/v1/device/category/',
    method: 'GET'
  });
}
export function fetchDeviceStatus() {
  return request({
    url: '/v1/device/status/',
    method: 'GET'
  });
}

/**
 * @description 获取设备详情
 * @param id 设备id
 */
export function getDeviceDetail(id) {
  const options = {
    url: `/v1/device/${id}/`,
    method: 'get'
  };
  return new Promise((resolve, reject) => {
    request(options).then(createdDevice => {
      resolve(generateClientSideFieldForDeviceObject(createdDevice));
    }).catch(reject);
  });
}

/**
 * @description 互感器时序详情
 * @param id 设备id
 */
export function getTransformerData(id, params) {
  const options = {
    url: `/v1/device/${id}/transformer/`,
    method: 'get',
    params
  };
  return request(options);
}

/**
 * 批量导入设备
 */
export function importDevices(devices) {
  return request({
    url: '/v1/device/batch_import/',
    method: 'POST',
    data: devices
  });
}

/** 验证设备ID是否可用 */
export function verifyDeviceId(qr_code) {
  const options = {
    url: `/v1/device/query/`,
    method: 'get',
    params: {
      qr_code
    }
  };
  return request(options);
}

/** 编辑设备 - APP */
export function editDevice(id, data) {
  const options = {
    url: `/v1/device/${id}/`,
    method: 'patch',
    data
  };
  return request(options);
}