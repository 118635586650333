import { dealRequestError, request } from "../../utils/request";
import _ from 'lodash';
import { EffluentPlanCategory, EffluentPlanExecutionStrategy, EffluentPlanLevel } from "./enum";
export const effluentPlanCategoryStaticData = [{
  name: '停产',
  value: EffluentPlanCategory.STOP
}, {
  name: '限产',
  value: EffluentPlanCategory.LIMIT
}, {
  name: '一厂一策',
  value: EffluentPlanCategory.FACTORY_POLICY
}];
export const effluentPlanLevelStaticData = [{
  name: '黄色预警',
  value: EffluentPlanLevel.YELLOW
}, {
  name: '橙色预警',
  value: EffluentPlanLevel.ORANGE
}, {
  name: '红色预警',
  value: EffluentPlanLevel.RED
}, {
  name: '冬防期停产',
  value: EffluentPlanLevel.WINTER_PROTECTION_STOP
}, {
  name: '夏季错峰生产',
  value: EffluentPlanLevel.SUMMER_STAGGER
}, {
  name: '秋冬防期限产',
  value: EffluentPlanLevel.AUTUMN_WINTER_PROTECTION_LIMIT
}];
export const effluentPlanExecutionStrategyStaticData = [{
  name: '单次执行',
  value: EffluentPlanExecutionStrategy.ONCE
}, {
  name: '持续执行',
  value: EffluentPlanExecutionStrategy.CONTINUE
}];
export function fetchEffluentPlans(params = {}) {
  return new Promise(resolve => {
    request({
      url: '/v1/scheme/economy/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(result => {
      if (params.get_all === true) {
        resolve(result);
      } else {
        resolve(result);
      }
    }).catch(dealRequestError);
  });
}
export function deleteEffluentPlanById(id) {
  return request({
    url: `/v1/scheme/economy/${id}/`,
    method: 'DELETE'
  });
}
export function modifyEffluentPlan(effluentPlanId, params) {
  // eslint-disable-next-line no-param-reassign
  if (params.category === EffluentPlanCategory.FACTORY_POLICY) params.percent = 100;
  return request({
    url: `/v1/scheme/economy/${effluentPlanId}/`,
    method: 'PATCH',
    data: params
  });
}
export function createEffluentPlan(params) {
  // eslint-disable-next-line no-param-reassign
  if (params.category === EffluentPlanCategory.FACTORY_POLICY) params.percent = 100;
  return request({
    url: '/v1/scheme/economy/',
    method: 'POST',
    data: _.omitBy(params, _.isUndefined)
  });
}