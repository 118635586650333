import { request } from "../../utils/request";
import _ from 'lodash';
export function fetchPLC(params) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/enter/standing/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(result => {
      resolve(result);
    }).catch(reject);
  });
}

/**
 * 下载单个设备的台账Excel
 * 下载企业下所有设备的台账Excel
 */
export function downPLC(data) {
  return request({
    url: '/v1/enter/standing/download/',
    method: 'GET',
    params: data,
    responseType: 'blob'
  });
}