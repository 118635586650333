export type PieChartDataItem = {
    name: string;
    /**
     * value used generate each series in chart, but for display problem, this value must be reduced to proper value sometimes
     */
    value: number;

    itemStyle?: {
        color?: string;
        opacity?: number;
    };
};

export type PieChartDataItemWithReducedValue = PieChartDataItem & {
    /**
     * original value of {@link PieChartDataItem.value}
     */
    originalValue: number;
    /**
     * sometimes displayed name need reduce length from original {@link PieChartDataItem.name}, but sometimes it remained length
     */
    reducedName: string;
    reducedCount: string;
};

const fixedFontSize = 14;

// 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
function getParametricEquation(
    startRatio: number,
    endRatio: number,
    isSelected: boolean,
    isHovered: boolean,
    k: number,
    h: number
) {
    // 计算
    const midRatio = (startRatio + endRatio) / 2;

    const startRadian = startRatio * Math.PI * 2;
    const endRadian = endRatio * Math.PI * 2;
    const midRadian = midRatio * Math.PI * 2;

    // 如果只有一个扇形，则不实现选中效果。
    if (startRatio === 0 && endRatio === 1) {
        // eslint-disable-next-line no-param-reassign
        isSelected = false;
    }

    // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
    // eslint-disable-next-line no-param-reassign
    k = typeof k !== 'undefined' ? k : 1 / 3;

    // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
    const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
    const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

    // 计算高亮效果的放大比例（未高亮，则比例为 1）
    const hoverRate = isHovered ? 1.05 : 1;

    // 返回曲面参数方程
    return {
        u: {
            min: -Math.PI,
            max: Math.PI * 3,
            step: Math.PI / 32,
        },

        v: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20,
        },

        x(u: number, v: number) {
            if (u < startRadian) {
                return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            if (u > endRadian) {
                return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        y(u: number, v: number) {
            if (u < startRadian) {
                return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            if (u > endRadian) {
                return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
            }
            return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        z(u: number, v: number) {
            if (u < -Math.PI * 0.5) {
                return Math.sin(u);
            }
            if (u > Math.PI * 2.5) {
                return Math.sin(u) * h * 0.1;
            }
            return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
        },
    };
}

/**
 * 生成模拟 3D 饼图的配置项
 * @param pieData
 * @param internalDiameterRatio
 */
export function getPie3D(pieData: PieChartDataItem[], internalDiameterRatio: number, canvasWidth: number) {
    const maxValueOfThisBunchOfData = Math.max(...pieData.map(value => value.value));
    /* eslint-disable no-param-reassign */
    let innerPieData: PieChartDataItemWithReducedValue[];
    if (maxValueOfThisBunchOfData > 99) {
        innerPieData = pieData.map(eachPieChartData => ({
            ...eachPieChartData,
            //percentage related to max value of this bunch of data
            value: Math.floor((eachPieChartData.value / maxValueOfThisBunchOfData) * 100),
            originalValue: eachPieChartData.value,
            reducedName:
                eachPieChartData.name.length > 4 ? `${eachPieChartData.name.slice(0, 4)}...` : eachPieChartData.name,
            reducedCount: eachPieChartData.value > 99 ? '99+家' : `${eachPieChartData.value}家`,
        }));
    } else {
        innerPieData = pieData.map(eachPieChartData => ({
            ...eachPieChartData,
            originalValue: eachPieChartData.value,
            reducedName:
                eachPieChartData.name.length > 4 ? `${eachPieChartData.name.slice(0, 4)}...` : eachPieChartData.name,
            reducedCount: eachPieChartData.value > 99 ? '99+家' : `${eachPieChartData.value}家`,
        }));
    }
    /* eslint-enable no-param-reassign */
    const series: any[] = [];
    let sumValue = 0;
    let startValue = 0;
    let endValue = 0;
    const legendData = [];
    const k =
        typeof internalDiameterRatio !== 'undefined'
            ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
            : 1 / 3;

    // 为每一个饼图数据，生成一个 series-surface 配置
    for (let i = 0; i < innerPieData.length; i++) {
        sumValue += innerPieData[i].value;

        const seriesItem: any = {
            name:
                typeof innerPieData[i].name === 'undefined'
                    ? `series${i}`
                    : `${innerPieData[i].reducedName}(${innerPieData[i].reducedCount})`,
            // @ts-ignore
            type: 'surface',
            label: innerPieData[i].name,
            parametric: true,
            wireframe: {
                show: false,
            },
            innerPieData: innerPieData[i],
            pieStatus: {
                selected: false,
                hovered: false,
                k,
            },
        };
        const targetItemStyle = innerPieData[i].itemStyle;
        if (targetItemStyle !== undefined) {
            const itemStyle: any = {};
            if (targetItemStyle.color !== undefined) {
                itemStyle.color = targetItemStyle.color;
            }
            if (targetItemStyle.opacity !== undefined) {
                itemStyle.opacity = targetItemStyle.opacity;
            }

            seriesItem.itemStyle = itemStyle;
        }

        series.push(seriesItem);
    }

    const labelSeries: any[] = [];

    // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
    // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
    for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].innerPieData.value;

        series[i].innerPieData.startRatio = startValue / sumValue;
        series[i].innerPieData.endRatio = endValue / sumValue;
        //todo add label series
        // const eachLabelSeries: any = {
        //     id: series[i].innerPieData.name,
        //     type: 'bar3D',
        //     // zlevel:-9,
        //     barSize: [0.1, 0.1],
        //     data: [],
        //     label: {
        //         show: true,
        //         distance: 3,
        //         formatter: function (params:any) {
        //             return `${params.value[3]}家\n\n`;
        //         },
        //         textStyle:{
        //             color:'#ffffff'
        //         },
        //         backgroundColor: 'transparent',
        //     },
        // };
        ////you can control if display label in here
        // const labelRadian = (series[i].innerPieData.startRatio + series[i].innerPieData.endRatio) * Math.PI;
        // eachLabelSeries.data.push({
        //     name: series[i].name,
        //     value: [Math.cos(labelRadian), Math.sin(labelRadian), 1.2, series[i].innerPieData.value],
        //     itemStyle: {
        //         opacity: 1,
        //     },
        // });
        // labelSeries.push(eachLabelSeries);

        series[i].parametricEquation = getParametricEquation(
            series[i].innerPieData.startRatio,
            series[i].innerPieData.endRatio,
            false,
            false,
            k,
            series[i].innerPieData.value
        );

        startValue = endValue;
        legendData.push(series[i].name);
    }

    // 将 labelSeries 添加进去 @20210613
    series.push(...labelSeries);
    //calculate each text with : inspired by https://www.codetd.com/article/11843913
    // console.log(`canvas width : ${canvasWidth}`);
    // 准备待返回的配置项，把准备好的 legendData、series 传入。
    const option: any = {
        color: ['#16F280', '#FCD32D', '#30D2FF', '#16F2C6', '#2D85FC'],
        // animation: true,
        legend: {
            bottom: '4%',
            width: '100%', //80 percent horizontal center in legend container, because of we not setup the left prop
            // backgroundColor: 'pink',
            // padding: 0,
            itemWidth: 20,
            itemHeight: 4,
            itemGap: 10,
            formatter: '{a|{name}}',
            textStyle: {
                color: '#FFFFFF',
                fontSize: fixedFontSize,
                backgroundColor: 'transparent',
                width: (canvasWidth - 120) / 2,
                rich: {
                    a: {
                        align: 'center',
                    },
                },
            },
        },
        tooltip: {
            backgroundColor: 'RGBA(0, 49, 85, 1)',
            borderColor: 'rgba(0, 151, 251, 1)',
            borderWidth: 1,
            borderRadius: 0,
            textStyle: {
                color: '#BCE9FC',
                fontSize: fixedFontSize,
                align: 'left',
            },
            formatter: (params: any) => {
                if (params.seriesName !== 'mouseoutSeries') {
                    return `${
                        option.series[params.seriesIndex].label
                    }<br/><span style='display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                        params.color
                    };'></span>${`${option.series[params.seriesIndex].innerPieData.reducedCount}`}`;
                }
                return '';
            },
        },
        xAxis3D: {
            min: -1,
            max: 1,
        },
        yAxis3D: {
            min: -1,
            max: 1,
        },
        zAxis3D: {
            min: -1,
            max: 1,
        },
        grid3D: {
            show: false,
            boxHeight: 5,
            top: '-15%',
            viewControl: {
                //3d效果可以放大、旋转等，请自己去查看官方配置
                alpha: 30,
                // beta: 40,
                rotateSensitivity: 0,
                zoomSensitivity: 0,
                panSensitivity: 0,
                autoRotate: false,
                projection: 'perspective', // 先设置为这个perspective
                distance: 180,
            },
            //后处理特效可以为画面添加高光、景深、环境光遮蔽（SSAO）、调色等效果。可以让整个画面更富有质感。
            postEffect: {
                //配置这项会出现锯齿，请自己去查看官方配置有办法解决
                enable: true,
                bloom: {
                    enable: true,
                    bloomIntensity: 0.1,
                },
                SSAO: {
                    enable: true,
                    quality: 'medium',
                    radius: 2,
                },
            },
        },
        series,
    };
    return option;
}
