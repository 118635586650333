import React from 'react';

const ContentContainer: React.FC<{ style?: React.CSSProperties; className?: string }> = ({
    style,
    className,
    children,
}) => (
    <div className={`part-content-container ${className || ''}`} style={{ ...style }}>
        <span className="part-content-container-angle left-top" />
        <span className="part-content-container-angle right-top" />
        <span className="part-content-container-angle left-bottom" />
        <span className="part-content-container-angle right-bottom" />
        {children}
    </div>
);

export default ContentContainer;
