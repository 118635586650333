import React, { forwardRef, useEffect, useState } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';
import { Account, generateIdentifiedByType, queryAccountById, Role, TreeItemType } from '@greendev/common';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

export type AccountEditContentProps = {
    targetAccount?: Account.BackendAccountProfile;
    roles?: Role.BackEndProfile[];
};

//新增、编辑弹框内容显示
const CompanyEditContent = forwardRef<FormInstance<any>, AccountEditContentProps>(
    (props: AccountEditContentProps, ref) => {
        const { targetAccount, roles } = props;
        const onFinish = (values: any) => {
            console.log('Success:', values);
        };

        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };

        const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
        useEffect(() => {
            if (targetAccount !== undefined) {
                queryAccountById(targetAccount.id).then(result => {
                    setInitialValues({
                        username: result.username,
                        name: result.name,
                        mobile: targetAccount.mobile,
                        enterprise: generateIdentifiedByType(TreeItemType.COMPANY, targetAccount.enterprise[0]),
                        roles: targetAccount.roles,
                    });
                });
            } else {
                setInitialValues({});
            }
        }, [targetAccount]);

        return (
            <Form
                layout="horizontal"
                ref={ref}
                labelAlign={'right'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues}
                key={initialValues?.mobile}
            >
                <Form.Item
                    label="账号"
                    name="username"
                    rules={[
                        { required: true, message: '请输入账号' },
                        {
                            pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/,
                            message: '账号只能包含1-20位的汉字、字母、数字、下划线!',
                        },
                        {
                            whitespace: true,
                            message: '不允许输入空格',
                        },
                    ]}
                >
                    <Input placeholder="请输入账号" />
                </Form.Item>
                <Form.Item
                    label="联系人姓名"
                    name="name"
                    rules={[
                        { required: true, message: '请输入联系人姓名' },
                        {
                            pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,10}$/,
                            message: '联系人姓名只能包含1-10位的汉字、字母、数字、下划线!',
                        },
                        {
                            whitespace: true,
                            message: '不允许输入空格',
                        },
                    ]}
                >
                    <Input placeholder="请输入联系人姓名" />
                </Form.Item>
                <Form.Item
                    label="联系人电话"
                    name="mobile"
                    rules={[
                        { required: true, message: '请输入联系人电话' },
                        {
                            // eslint-disable-next-line prefer-regex-literals
                            pattern: new RegExp(/^1[3-9]\d{9}$/, 'g'),
                            message: '联系人电话格式不正确',
                        },
                        {
                            whitespace: true,
                            message: '不允许输入空格',
                        },
                    ]}
                >
                    <Input placeholder="请输入联系人电话" />
                </Form.Item>
                <PurgedAreaCompanyTreeSelectUsedInForm
                    label={'关联企业'}
                    required
                    style={{ width: '100%' }}
                    treeCheckable={false}
                    onlyCanSelectCompany
                />
                <Form.Item label="角色" name="roles" rules={[{ required: true, message: '请选择角色' }]}>
                    <Select placeholder="请选择角色" allowClear virtual={false} showArrow>
                        {roles?.map(eachRole => (
                            <Select.Option key={eachRole.id} value={eachRole.id}>
                                {eachRole.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        );
    }
);

export default CompanyEditContent;
