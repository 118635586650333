import { DatePicker, Form, FormInstance, Input, InputNumber, message, Select, Tooltip } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { disableDaysAfterToday, disableDaysBeforeToday } from '@/AntDesignReset';
import moment from 'moment';
import {
    Current,
    fetchAllMonitoring,
    generateIdentifiedByType,
    Monitoring,
    queryCurrentById,
    recoverInformationFromIdentify,
    splitIdWithName,
    TreeItemType,
} from '@greendev/common';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

export type CurrentEditContentProps = {
    editMode?: string;
    targetCurrent?: Current.FrontEndProfile;
};

//新增、编辑弹框内容显示
const CurrentEditContent = forwardRef<FormInstance<any>, CurrentEditContentProps>(
    (props: CurrentEditContentProps, ref) => {
        const { editMode, targetCurrent } = props;
        const onFinish = (values: any) => {
            console.log('Success:', values);
        };

        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };

        const [initialValues, setInitialValues] = useState<any | undefined>(undefined);
        const [monitoringPoints, setMonitoringPoints] = useState<Monitoring.FrontEndProfile[]>([]);
        const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>(
            targetCurrent && splitIdWithName(targetCurrent?.enter).id
        );
        useEffect(() => {
            if (targetCurrent !== undefined) {
                setSelectedCompanyId(splitIdWithName(targetCurrent?.enter).id);
                queryCurrentById(targetCurrent.id).then(() => {
                    //notice : if you don't want to send a request to query name , you can get the area name from ad_region_name field
                    setInitialValues({
                        name: targetCurrent.name,
                        enterprise:
                            targetCurrent &&
                            generateIdentifiedByType(TreeItemType.COMPANY, splitIdWithName(targetCurrent.enter).id),
                        monitor_point: targetCurrent.monitor_point,
                        percent: targetCurrent.percent,
                        total: targetCurrent.total,
                        s_date: moment(targetCurrent.s_date),
                        e_date: moment(targetCurrent.e_date),
                    });
                });
            } else if (editMode === 'add') {
                setInitialValues({});
                setSelectedCompanyId(undefined);
                setMonitoringPoints([]);
            }
        }, [targetCurrent, editMode]);

        useEffect(() => {
            if (selectedCompanyId !== undefined && targetCurrent !== undefined) {
                fetchAllMonitoring({
                    enterprise: selectedCompanyId,
                    get_all: true,
                }).then(allMonitoringPoints => {
                    setMonitoringPoints(allMonitoringPoints as Monitoring.FrontEndProfile[]);
                });
            } else if (selectedCompanyId !== undefined && editMode === 'add') {
                fetchAllMonitoring({
                    enterprise: selectedCompanyId,
                    get_all: true,
                }).then(allMonitoringPoints => {
                    setMonitoringPoints(allMonitoringPoints as Monitoring.FrontEndProfile[]);
                });
            }
        }, [editMode, targetCurrent, selectedCompanyId]);

        const changeCurrentSelectedArea = (targetAreaId?: number) => {
            setSelectedCompanyId(targetAreaId);
            //invalid selected monitoring points and gateway
            setMonitoringPoints([]);
            // @ts-ignore
            ref.current?.setFieldsValue({ monitor_point: undefined });
        };
        return (
            <Form
                layout="horizontal"
                ref={ref}
                labelAlign={'right'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues}
                key={initialValues?.name}
            >
                <Form.Item
                    label="规则名称"
                    name="name"
                    rules={[
                        { required: true, message: '请输入规则名称' },
                        {
                            pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,25}$/,
                            message: '规则名称只能包含1-25位的汉字、字母、数字、下划线!',
                        },
                        {
                            whitespace: true,
                            message: '不允许输入空格',
                        },
                    ]}
                >
                    <Input placeholder="请输入规则名称" />
                </Form.Item>
                <PurgedAreaCompanyTreeSelectUsedInForm
                    label="企业"
                    style={{ width: '100%' }}
                    treeCheckable={false}
                    required
                    onlyCanSelectCompany
                    onChange={(itemIdentification?: string) => {
                        if (itemIdentification === undefined) {
                            changeCurrentSelectedArea(undefined);
                            return;
                        }
                        changeCurrentSelectedArea(recoverInformationFromIdentify(itemIdentification).originObjectId);
                    }}
                />
                <Form.Item
                    label={
                        <>
                            <Tooltip title="如果监测点的电流超过限制范围，将触发报警">
                                <i className="iconfont icon_tishi" style={{ marginTop: '2px' }} />
                            </Tooltip>
                            <span>监测点</span>
                        </>
                    }
                    name="monitor_point"
                    rules={[{ required: true, message: '请选择监测点' }]}
                >
                    <Select<number>
                        placeholder="请选择监测点"
                        allowClear
                        showArrow
                        virtual={false}
                        onFocus={() => {
                            if (selectedCompanyId === undefined) {
                                message.info('请先选择企业');
                            }
                        }}
                    >
                        {monitoringPoints.map(eachCategory => (
                            <Select.Option key={eachCategory.id} value={eachCategory.id}>
                                {eachCategory.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <>
                            <Tooltip title="超过最大电流的限制百分比，将触发报警">
                                <i className="iconfont icon_tishi" style={{ marginTop: '2px' }} />
                            </Tooltip>
                            <span>总电流</span>
                        </>
                    }
                    name="total"
                    rules={[
                        { required: true, message: '请输入总电流' },
                        {
                            pattern: /^.{0,4}$/,
                            message: '电流限制长度为4位!',
                        },
                        {
                            pattern: /^(?!\s)(?!.*\s$)/,
                            message: '总电流不允许有空格!',
                        },
                    ]}
                >
                    <InputNumber
                        placeholder="请输入总电流"
                        precision={0}
                        max={9999}
                        min={0}
                        maxLength={4}
                        addonAfter={'A'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <>
                            <Tooltip title="输入范围：0~99">
                                <i className="iconfont icon_tishi" style={{ marginTop: '2px' }} />
                            </Tooltip>
                            <span>电流限制</span>
                        </>
                    }
                    name="percent"
                    rules={[
                        { required: true, message: '请输入电流限制' },
                        {
                            pattern: /^[0-9]{1,2}$/,
                            message: '电流限制只允许0~99的整数',
                        },
                        {
                            pattern: /^(?!\s)(?!.*\s$)/,
                            message: '电流限制不允许有空格!',
                        },
                    ]}
                >
                    <InputNumber
                        placeholder="请输入电流限制"
                        max={99}
                        min={0}
                        precision={0}
                        addonAfter={'%'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    name="s_date"
                    label="开始时间"
                    rules={[{ type: 'object' as const, required: true, message: '请选择开始时间' }]}
                >
                    <DatePicker disabledDate={disableDaysAfterToday} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name="e_date"
                    label="结束时间"
                    rules={[{ type: 'object' as const, required: true, message: '请选择结束时间' }]}
                >
                    <DatePicker disabledDate={disableDaysBeforeToday} style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        );
    }
);

export default CurrentEditContent;
