import React from 'react';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { observer } from 'mobx-react-lite';
import ImgZW from '@/assets/images/zhiwu.png';
import ImgSC from '@/assets/images/shengchan.png';

export type EChartGroupPieChartProps = {
    data: any | undefined;
    colors: string[] | undefined;
    style: object | undefined;
};

const EchartPie = (props: EChartGroupPieChartProps) => {
    const { data, colors, style } = props;
    const generateData = (totalNum: number, bigvalue: number, smallvalue: number, color: string) => {
        const dataArr = [];
        for (let i = 0; i < totalNum; i++) {
            if (i % 2 === 0) {
                dataArr.push({
                    name: (i + 1).toString(),
                    value: bigvalue,
                    itemStyle: {
                        normal: {
                            color,
                            borderWidth: 0,
                        },
                    },
                });
            } else {
                dataArr.push({
                    name: (i + 1).toString(),
                    value: smallvalue,
                    itemStyle: {
                        normal: {
                            color: 'rgba(0,0,0,0)',
                            borderWidth: 0,
                        },
                    },
                });
            }
        }
        return dataArr;
    };
    const barChartOption: EChartsOption = {
        grid: {
            show: false,
            left: '1%',
            right: '1%',
            top: '0',
            bottom: '5',
            containLabel: false,
        },
        tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)',
        },
        color: colors,
        calculable: true,
        legend: {
            orient: 'vertical',
            top: 'center',
            right: '1%',
            itemGap: 15,
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
                color: '#9EA5AE',
                fontSize: 12,
            },
            data: ['生产', '治污'],
        },
        series: [
            {
                type: 'pie',
                radius: ['55%', '75%'],
                center: ['50%', '50%'],
                itemStyle: {
                    borderRadius: 10,
                    borderWidth: 1,
                },
                roseType: 'radius', //通过半径区分数据大小;'radius':扇区圆心角展现数据的百分比，半径展现数据的大小。'area':所有扇区圆心角相同，仅通过半径展现数据大小。
                label: {
                    show: true,
                    color: 'inherit',
                    position: 'outside',
                    fontSize: 12,
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    formatter: (k: { value: any; dataIndex: number }) => [
                        `{imagessck${k?.dataIndex}|}`,
                        `{a|${k.value}}`,
                    ],
                    rich: {
                        a: {
                            color: '#252631',
                            lineHeight: 14,
                            fontSize: 14,
                        },
                        imagessck0: {
                            backgroundColor: {
                                image: ImgSC,
                            },
                            height: 16,
                            align: 'center',
                        },
                        imagessck1: {
                            backgroundColor: {
                                image: ImgZW,
                            },
                            height: 16,
                            align: 'center',
                        },
                    },
                },
                labelLine: {
                    length: 5,
                    length2: 10,
                    smooth: true,
                },
                data,
            },
            {
                name: '不动外圆',
                type: 'pie',
                zlevel: 4,
                silent: true,
                radius: ['85%', '84%'],
                label: {
                    show: false,
                },
                data: generateData(100, 25, 20, '#E6E6E6'),
            },
        ],
    };
    return (
        <ReactEcharts
            option={barChartOption}
            style={{ width: '100%', height: '90%', backgroundColor: 'transparent', ...style }}
        />
    );
};

export default observer(EchartPie);
