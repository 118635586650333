import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Popconfirm, message, FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import { CommonTable } from '@/components/table/CommonTable';
import IndustryEditContent, {
    IndustryEditContentProps,
} from '@/views/common/basicDataManage/industryManageView/IndustryEditContent';
import _ from 'lodash';
import {
    Industry,
    fetchAllIndustryTree,
    deleteIndustryById,
    addIndustry,
    editIndustry,
    reduceSamePropertiesFromObject,
    dealRequestError,
} from '@greendev/common';

type IndustryWithLoadingState = {
    industry: Industry.FrontEndProfile[];
    loading: boolean;
};

// 行业管理页面
const IndustryManagementView = () => {
    const [update, setUpdate] = useState<any>();
    const [industryWithLoadingState, setIndustryWithLoadingState] = useState<IndustryWithLoadingState>({
        industry: [],
        loading: true,
    });
    const modalRef = useRef<CommonModalApi>(null);
    const industryEditModalRef = useRef<FormInstance<any>>(null);
    const [industryEditModalState, setIndustryEditModalState] = useState<IndustryEditContentProps | undefined | any>();

    const changeLoadingState = (loading: boolean) => {
        setIndustryWithLoadingState({
            ...industryWithLoadingState,
            loading,
        });
    };

    useEffect(() => {
        fetchAllIndustryTree().then(result => {
            setIndustryWithLoadingState({
                industry: result,
                loading: false,
            });
        });
    }, [update]);

    const delChildIndutry = (targeIndustry: Industry.FrontEndProfile) => {
        changeLoadingState(true);
        deleteIndustryById(targeIndustry.id)
            .then(() => {
                message.success('删除成功');
                const copyIndustry = _.clone(industryWithLoadingState.industry);
                setIndustryWithLoadingState({
                    industry: copyIndustry,
                    loading: false,
                });
                setUpdate(copyIndustry);
            })
            .catch(error => {
                dealRequestError(error);
                changeLoadingState(false);
            });
    };

    const addChildIndustry = (targeIndustry: Industry.FrontEndProfile) => {
        changeLoadingState(true);
        const params = _.omitBy(
            targeIndustry,
            (value?: any) => _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
        );
        addIndustry(params)
            .then(result => {
                message.success('新增成功');
                setUpdate(result);
                modalRef.current?.hiddenModal();
                setIndustryEditModalState(undefined);
                industryEditModalRef.current?.resetFields();
            })
            .catch(error => {
                dealRequestError(error);
                changeLoadingState(false);
            });
    };

    const editChildIndustry = (targeIndustry: Industry.FrontEndProfile, id?: number) => {
        changeLoadingState(true);
        const params: any = _.omitBy(
            targeIndustry,
            (value?: any) => _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
        );
        params.desc = targeIndustry.desc === '' ? null : targeIndustry.desc;
        const patchObject = reduceSamePropertiesFromObject(params, industryEditModalState.targetIndustry);
        if (_.keysIn(patchObject).length === 0) {
            message.success('编辑成功');
            modalRef.current?.hiddenModal();
            setIndustryEditModalState(undefined);
            industryEditModalRef.current?.resetFields();
            changeLoadingState(false);
        } else {
            editIndustry(id, patchObject)
                .then(result => {
                    message.success('编辑成功');
                    setIndustryWithLoadingState({
                        industry: industryWithLoadingState.industry,
                        loading: false,
                    });
                    setUpdate(result);
                    modalRef.current?.hiddenModal();
                    setIndustryEditModalState(undefined);
                    industryEditModalRef.current?.resetFields();
                })
                .catch(error => {
                    dealRequestError(error);
                    changeLoadingState(false);
                });
        }
    };

    const columns: ColumnsType<Industry.FrontEndProfile> = [
        {
            title: '行业名称',
            dataIndex: 'name',
            key: 'name',
            width: 260,
            render: (text?: any) => (
                <p
                    style={{
                        minWidth: '200px',
                        maxWidth: '260px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '备注',
            dataIndex: 'desc',
            key: 'desc',
            render: (text?: any) => (
                <p
                    style={{
                        minWidth: '300px',
                        maxWidth: '600px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'add_time',
            key: 'add_time',
            width: 200,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 180,
            render: (item?: any, record?: any) => (
                <div className="opera">
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setIndustryEditModalState({
                                editMode: 'edit',
                                targetIndustry: record,
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={'确认要删除吗？'}
                        onConfirm={() => {
                            delChildIndutry(record);
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (industryEditModalState !== undefined) {
            modalRef.current?.showModal();
        }
    }, [industryEditModalState]);
    return (
        <div className="container">
            <Button
                type="primary"
                className="commonButton"
                onClick={() => {
                    setIndustryEditModalState({
                        editMode: 'add',
                    });
                }}
            >
                新增
            </Button>
            <CommonTable
                dataSource={industryWithLoadingState.industry}
                loading={industryWithLoadingState.loading}
                columns={columns}
                rowKey={record => record.id}
            />
            {industryEditModalState && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        industryEditModalRef.current?.validateFields().then(values => {
                            if (industryEditModalState?.editMode === 'add') {
                                addChildIndustry(values);
                            } else {
                                editChildIndustry(values, industryEditModalState?.targetIndustry?.id);
                            }
                        });
                    }}
                    onCancel={() => {
                        industryEditModalRef.current?.resetFields();
                        setIndustryEditModalState(undefined);
                    }}
                    title={`${industryEditModalState?.editMode === 'edit' ? '编辑' : '新增'}行业`}
                >
                    <IndustryEditContent ref={industryEditModalRef} {...industryEditModalState} />
                </CommonModal>
            )}
        </div>
    );
};
export default observer(IndustryManagementView);
