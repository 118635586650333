import { FormInstance } from 'antd';
import { Moment } from 'moment';
import { ReduceCompareFunction, reduceSamePropertiesFromObject } from '@greendev/common';
import { keysIn } from 'lodash';

export enum FormValueType {
    STRING,
    STRING_ARRAY,
    MOMENT,
    MOMENT_ARRAY,
}

export enum RequestValueType {
    NUMERICAL,
    NUMERICAL_ARRAY,
    LITERAL,
    LITERAL_ARRAY,
    OBJECT,
}

export type FormFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile> = {
    formKey: string;
    /**
     * if you have some value not exist in form, use this function to get put the value to request object
     */
    externalFormValue?: () => Promise<any>;
    requestObjectKey: keyof AddOrModifyParamsProfile;
    formValueType: FormValueType;
    requestValueType: RequestValueType;

    /**
     * you can custom the process used to transfer form value to request value
     * notice : you don't need consider the situation when from value is undefined
     *          the value is always exist when this function invoke!!!
     * @param formValue
     */
    formValueToRequestValue?: (formValue: any) => Promise<any>;
    /**
     * represent this field is optional to backend
     * and will be undefined in add mode and null in modify mode
     */
    optional?: boolean;
    /**
     * see {@link ReduceCompareFunction}
     */
    reduceCompareFunc?: ReduceCompareFunction<AddOrModifyParamsProfile, OriginalRecordProfile>;
};

export function stringFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile>(
    formKey: string,
    requestObjectKey: keyof AddOrModifyParamsProfile,
    requestValueType: RequestValueType = RequestValueType.LITERAL,
    optional?: boolean,
    formValueToRequestValue?: (formValue: any) => Promise<any>,
    reduceCompareFunc?: ReduceCompareFunction<AddOrModifyParamsProfile, OriginalRecordProfile>,
    externalFormValue?: () => Promise<any>
): FormFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile> {
    return {
        formKey,
        requestObjectKey,
        optional,
        formValueType: FormValueType.STRING,
        requestValueType,
        formValueToRequestValue,
        reduceCompareFunc,
        externalFormValue,
    };
}

export function stringArrayFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile>(
    formKey: string,
    requestObjectKey: keyof AddOrModifyParamsProfile,
    requestValueType: RequestValueType = RequestValueType.LITERAL_ARRAY,
    optional?: boolean,
    formValueToRequestValue?: (formValue: any) => Promise<any>,
    reduceCompareFunc?: ReduceCompareFunction<AddOrModifyParamsProfile, OriginalRecordProfile>,
    externalFormValue?: () => Promise<any>
): FormFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile> {
    return {
        formKey,
        requestObjectKey,
        optional,
        formValueType: FormValueType.STRING_ARRAY,
        requestValueType,
        formValueToRequestValue,
        reduceCompareFunc,
        externalFormValue,
    };
}

export function momentFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile>(
    formKey: string,
    requestObjectKey: keyof AddOrModifyParamsProfile,
    requestValueType: RequestValueType = RequestValueType.LITERAL,
    optional?: boolean,
    formValueToRequestValue?: (formValue: any) => Promise<any>,
    reduceCompareFunc?: ReduceCompareFunction<AddOrModifyParamsProfile, OriginalRecordProfile>,
    externalFormValue?: () => Promise<any>
): FormFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile> {
    return {
        formKey,
        requestObjectKey,
        optional,
        formValueType: FormValueType.MOMENT,
        requestValueType,
        formValueToRequestValue,
        reduceCompareFunc,
        externalFormValue,
    };
}

export function momentArrayFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile>(
    formKey: string,
    requestObjectKey: keyof AddOrModifyParamsProfile,
    requestValueType: RequestValueType = RequestValueType.LITERAL,
    optional?: boolean,
    formValueToRequestValue?: (formValue: any) => Promise<any>,
    reduceCompareFunc?: ReduceCompareFunction<AddOrModifyParamsProfile, OriginalRecordProfile>,
    externalFormValue?: () => Promise<any>
): FormFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile> {
    return {
        formKey,
        requestObjectKey,
        optional,
        formValueType: FormValueType.MOMENT_ARRAY,
        requestValueType,
        formValueToRequestValue,
        reduceCompareFunc,
        externalFormValue,
    };
}

type RequestFieldMapping<AddOrModifyParamsProfile> = {
    keyToRequestObjectMapping: keyof AddOrModifyParamsProfile;
    // value: string | number | object;
    value: any;
};

function validateTransformerFunctionResultValue(resultValue: any) {
    const targetRequestValueType = typeof resultValue;
    // typeof null : 'object'
    return !(
        targetRequestValueType !== 'string' &&
        targetRequestValueType !== 'number' &&
        targetRequestValueType !== 'object'
    );
}

/**
 * you
 * @param formHandler
 * @param valueSchemes you should notice the order of passed array!!!
 * @param whenProcessFinish
 * @param whenUserNotDoAnyOperation
 * @param originalRecord
 */
function handleFormValueWithDuplicationCheck<
    AddOrModifyParamsProfile extends object,
    OriginalRecordProfile extends object
>(
    formHandler: FormInstance,
    valueSchemes: FormFieldScheme<AddOrModifyParamsProfile, OriginalRecordProfile>[],
    whenProcessFinish: (
        purgedModifyParamsOrAddParams: AddOrModifyParamsProfile,
        isModifyMode: boolean,
        requestObject: AddOrModifyParamsProfile,
        originalRecord?: OriginalRecordProfile
    ) => void,
    whenUserNotDoAnyOperation: () => void,
    originalRecord?: OriginalRecordProfile
) {
    const isModifyMode = originalRecord !== undefined;

    //fill request object with this object
    const allFormValue: Promise<RequestFieldMapping<AddOrModifyParamsProfile>>[] = valueSchemes.map(
        eachFieldScheme =>
            new Promise<RequestFieldMapping<AddOrModifyParamsProfile>>((resolve, reject) => {
                //we can gain the value from outside rather than from form handler
                let formOriginalValuePromise: Promise<any>;
                if (eachFieldScheme.externalFormValue !== undefined) {
                    formOriginalValuePromise = eachFieldScheme.externalFormValue();
                } else {
                    formOriginalValuePromise = Promise.resolve(formHandler.getFieldValue(eachFieldScheme.formKey));
                }
                formOriginalValuePromise
                    .then(formOriginalValue => {
                        //there some optional field in request body of add request or modify request
                        //you should pass undefined to backend if this field is optional in add request, or default value( [] / null ) in modify request
                        const requestFieldIsArray =
                            eachFieldScheme.requestValueType === RequestValueType.NUMERICAL_ARRAY ||
                            eachFieldScheme.requestValueType === RequestValueType.LITERAL_ARRAY;
                        const backendFieldEmptyCommand = requestFieldIsArray ? [] : null;
                        const backendFieldNullValue = isModifyMode ? backendFieldEmptyCommand : undefined;
                        const optional = eachFieldScheme.optional || false;
                        if (
                            optional &&
                            (formOriginalValue === undefined ||
                                (typeof formOriginalValue === 'string' && formOriginalValue.length === 0))
                        ) {
                            resolve({
                                keyToRequestObjectMapping: eachFieldScheme.requestObjectKey,
                                value: backendFieldNullValue,
                            });
                            return;
                        }
                        //robustness
                        if (formOriginalValue === undefined) {
                            reject(
                                new Error(
                                    `Form value is undefined, but passed optional metadata is true ! ( key : ${eachFieldScheme.requestObjectKey} ) `
                                )
                            );
                            return;
                        }

                        if (eachFieldScheme.formValueToRequestValue !== undefined) {
                            eachFieldScheme.formValueToRequestValue!!(formOriginalValue)
                                .then(targetRequestValue => {
                                    if (!validateTransformerFunctionResultValue(targetRequestValue)) {
                                        reject(
                                            new Error(
                                                `The type of result of transformer function should only be number/string/object ! ( key : ${eachFieldScheme.requestObjectKey} ) `
                                            )
                                        );
                                    } else {
                                        resolve({
                                            keyToRequestObjectMapping: eachFieldScheme.requestObjectKey,
                                            value: targetRequestValue,
                                        });
                                    }
                                })
                                .catch(reject);
                            return;
                        }

                        switch (eachFieldScheme.formValueType) {
                            case FormValueType.STRING:
                                switch (eachFieldScheme.requestValueType) {
                                    case RequestValueType.NUMERICAL:
                                        resolve({
                                            keyToRequestObjectMapping: eachFieldScheme.requestObjectKey,
                                            value: Number(formOriginalValue),
                                        });
                                        break;
                                    case RequestValueType.LITERAL:
                                        resolve({
                                            keyToRequestObjectMapping: eachFieldScheme.requestObjectKey,
                                            value: formOriginalValue,
                                        });
                                        break;
                                    default:
                                        reject(
                                            new Error(
                                                `Form value with string type can not transfer to other type except number or string ! ( key : ${eachFieldScheme.requestObjectKey} ) `
                                            )
                                        );
                                        break;
                                }
                                break;
                            case FormValueType.STRING_ARRAY:
                                switch (eachFieldScheme.requestValueType) {
                                    case RequestValueType.NUMERICAL_ARRAY:
                                        resolve({
                                            keyToRequestObjectMapping: eachFieldScheme.requestObjectKey,
                                            value: (formOriginalValue as string[]).map(value => Number(value)),
                                        });
                                        break;
                                    case RequestValueType.LITERAL_ARRAY:
                                        resolve({
                                            keyToRequestObjectMapping: eachFieldScheme.requestObjectKey,
                                            value: formOriginalValue,
                                        });
                                        break;

                                    default:
                                        reject(
                                            new Error(
                                                `Form value with string type can not transfer to other type except numerical/string array ! ( key : ${eachFieldScheme.requestObjectKey} ) `
                                            )
                                        );
                                        break;
                                }
                                break;
                            case FormValueType.MOMENT:
                                switch (eachFieldScheme.requestValueType) {
                                    case RequestValueType.NUMERICAL:
                                        resolve({
                                            keyToRequestObjectMapping: eachFieldScheme.requestObjectKey,
                                            value: (formOriginalValue as Moment).valueOf(),
                                        });
                                        break;
                                    case RequestValueType.LITERAL:
                                        resolve({
                                            keyToRequestObjectMapping: eachFieldScheme.requestObjectKey,
                                            value: (formOriginalValue as Moment).format('YYYY-MM-DD'),
                                        });
                                        break;
                                    default:
                                        reject(
                                            new Error(
                                                `Form value with Moment type only can transfer to number or string ! ( key : ${eachFieldScheme.requestObjectKey} ) `
                                            )
                                        );
                                        break;
                                }
                                break;
                            default:
                                reject(
                                    new Error(
                                        `Unrecognized fromValueType ! ( key : ${eachFieldScheme.requestObjectKey} ) `
                                    )
                                );
                                break;
                        }
                    })
                    .catch(reject);
            })
    );
    Promise.all(allFormValue)
        .then(allFormValueProcessResult => {
            const requestObject: any = {};
            for (const allFormValueProcessResultElement of allFormValueProcessResult) {
                requestObject[allFormValueProcessResultElement.keyToRequestObjectMapping] =
                    allFormValueProcessResultElement.value;
            }
            if (isModifyMode) {
                const purgedModifyParams = reduceSamePropertiesFromObject<
                    AddOrModifyParamsProfile,
                    OriginalRecordProfile
                >(
                    requestObject as AddOrModifyParamsProfile,
                    originalRecord,
                    valueSchemes
                        .filter(value => value.reduceCompareFunc !== undefined)
                        .map(value => value.reduceCompareFunc!!)
                );
                if (keysIn(purgedModifyParams).length === 0) {
                    whenUserNotDoAnyOperation();
                } else {
                    whenProcessFinish(
                        purgedModifyParams,
                        true,
                        requestObject as AddOrModifyParamsProfile,
                        originalRecord
                    );
                }
            } else {
                whenProcessFinish(
                    requestObject,
                    isModifyMode,
                    requestObject as AddOrModifyParamsProfile,
                    originalRecord
                );
            }
        })
        .catch(error => {
            //if there have some error open during process form field, throw exception top outside
            throw error;
        });
}

export default handleFormValueWithDuplicationCheck;
