import React from 'react';

export type CommonModalTitleProps = {
    title: string;
};

export const CommonModalTitle = (props: CommonModalTitleProps) => {
    const { title } = props;
    return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{title}</div>;
};
