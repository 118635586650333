import React from 'react';
import { usePagination } from '@/utils/typeWrapper';
import {
  dealRequestError,
  PLC,
  fetchPLC,
  PaginationWrapper,
  downPLC
} from '@greendev/common';
import { observer } from 'mobx-react-lite';
import { ColumnsType } from 'antd/es/table';
import { Table, message } from 'antd';
import { MyIcon } from '@/utils/iconfont';
import moment from 'moment';

const PLCLedger = ({ data }: any) => {
  console.log(data, 'data');
  const [tableDataWithLoadingState, , , pagination, resetToFirstPage] =
    usePagination<PLC.BackendProfile>(
      (page, pageSize) =>
        fetchPLC({
          monitor_point_id: data.id,
          page,
          page_size: pageSize,
        }) as Promise<PaginationWrapper<PLC.BackendProfile>>
    );
  const columns: ColumnsType<PLC.BackendProfile> = [
    {
      title: '日期',
      width: 180,
      dataIndex: 'date',
      key: 'id',
      align: 'left',
      render: (text?: any) => (
        <p
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            margin: '0 auto',
          }}
          title={text || '-'}
        >
          {text || '-'}
        </p>
      ),
    },
    {
      title: '设备名称',
      width: 180,
      dataIndex: 'monitor_point_name',
      key: 'id',
      render: (text?: any) => (
        <p
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            margin: '0 auto',
          }}
          title={text || '-'}
        >
          {text || '-'}
        </p>
      ),
    },
    {
      title: '开启时间',
      width: 180,
      dataIndex: 'up_time',
      key: 'id',
      render: (text?: any) => (
        <p
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            margin: '0 auto',
          }}
          title={text || '-'}
        >
          {text || '-'}
        </p>
      ),
    },
    {
      title: '关闭时间',
      width: 180,
      dataIndex: 'close_time',
      key: 'id',
      render: (text?: any) => (
        <p
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            margin: '0 auto',
          }}
          title={text || '-'}
        >
          {text || '-'}
        </p>
      ),
    },
    {
      title: '今日开启时长',
      width: 180,
      dataIndex: 'today_up_time',
      key: 'id',
      render: (text?: any) => (
        <p
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            margin: '0 auto',
          }}
          title={text || '-'}
        >
          {text || '-'}
        </p>
      ),
    },
    {
      title: '累计开启时长',
      width: 180,
      dataIndex: 'accumulative_up_time',
      key: 'id',
      render: (text?: any) => (
        <p
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            margin: '0 auto',
          }}
          title={text || '-'}
        >
          {text || '-'}
        </p>
      ),
    },
    {
      title: '功率',
      width: 160,
      dataIndex: 'power',
      key: 'id',
    }
  ];

  const exportPLC = (monitor_point_id?: number) => {
    downPLC({ monitor_point_id })
      .then((res: any) => {
        message.success('下载成功');
        const blob = new Blob([res as BlobPart]);
        const elink = document.createElement('a');  //创建a标签节点
        elink.download = `PLC台账${moment().format('YYYYMMDDHHmmss')}.xlsx`;  //下载出来的名字
        elink.style.display = 'none';
        elink.href = window.URL.createObjectURL(blob); //创建好的url   放入a 标签 href中
        document.body.appendChild(elink); //追加到body中
        elink.click(); //执行a标签的点击
        window.URL.revokeObjectURL(elink.href);   //浏览器打开excel文件
      })
      .catch(dealRequestError)
  };

  return (
    <>
      <div className="down-button" onClick={() => {
        exportPLC(data.id);
      }}>
        <MyIcon type="icon_down" style={{ fontSize: 24, color: '#666666' }} />
      </div>
      <div className="down-all-button" onClick={() => {
        exportPLC();
      }}>
        <MyIcon type="icon_db_down" style={{ fontSize: 24, color: '#666666' }} />
      </div>
      <div className="refresh-button" onClick={() => resetToFirstPage()}>
        <MyIcon type="iconrefresh-1-copy" style={{ fontSize: 24, color: '#666666' }} />
      </div>
      <Table<PLC.BackendProfile>
        pagination={pagination}
        dataSource={tableDataWithLoadingState.paginationData.results}
        loading={tableDataWithLoadingState.loading}
        columns={columns}
        rowKey={record => record.id}
        scroll={{ y: 'calc(100vh - 305px)' }}
      />
    </>
  )
};
export default observer(PLCLedger);