import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, FormInstance, Input, message, Popconfirm, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import { usePagination } from '@/utils/typeWrapper';
import {
    createPollutionControl,
    dealRequestError,
    deletePollutionControlById,
    fetchAllPollutionControl,
    generateIdentifiedByType,
    modifyPollutionControl,
    PaginationWrapper,
    PollutionControl,
    recoverInformationFromIdentify,
    reduceSamePropertiesFromObject,
    TreeItemType,
    usePurgedAreaCompanyState,
} from '@greendev/common';
import _ from 'lodash';
import { AreaTreeSelect, useAreaInitialize } from '@/views/common/basicDataManage/areaManageView/AreaFormTreeSelect';
import PollutionControlEditContent from './PollutionControlEditContent';
import { getTextByJs } from '@/utils/format';
import { PurgedAreaCompanyTreeSelect } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

// 生产治污关联界面
const PollutionControlView = () => {
    useAreaInitialize();
    usePurgedAreaCompanyState();
    const [displayAddPollutionFormModal, setDisplayAddPollutionFormModal] = useState<{
        editMode?: string;
        originPollutionControl?: PollutionControl.BackendProfile | undefined;
    }>();
    const modalRef = useRef<CommonModalApi>(null);
    const searchRef = useRef<FormInstance<any>>(null);
    const pollutionControlEditModalRef = useRef<FormInstance<any>>(null);

    useEffect(() => {
        if (displayAddPollutionFormModal !== undefined) {
            modalRef.current?.showModal();
        }
    }, [displayAddPollutionFormModal]);

    const [searchName, setSearchName] = useState<string>('');
    const whenInputSearchName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchName(event.target.value);
    };

    const [selectedArea, setSelectedArea] = useState<number | undefined>(undefined);
    const whenSelectArea = (value: number) => {
        setSelectedArea(value);
    };

    const [searchArea, setSearchArea] = useState<number | undefined>(undefined);
    const whenInputSearchArea = (clickedArea?: number) => {
        setSearchArea(clickedArea);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tableDataWithLoadingState, changeLoadingState, refreshTableData, pagination, resetToFirstPage] =
        usePagination<PollutionControl.BackendProfile>(
            (page, pageSize) =>
                fetchAllPollutionControl({
                    name: searchName.trim().length === 0 ? undefined : searchName,
                    ad_region: selectedArea,
                    enterprise_id: searchArea,
                    page,
                    page_size: pageSize,
                }) as Promise<PaginationWrapper<PollutionControl.BackendProfile>>
        );

    const columns: ColumnsType<PollutionControl.BackendProfile> = [
        {
            title: '关联名称',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '行政区',
            dataIndex: 'ad_region',
            key: 'ad_region',
            width: 260,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '企业',
            dataIndex: 'enterprise_name',
            key: 'enterprise_name',
            width: 140,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '生产线',
            dataIndex: 'production_name',
            key: 'production_name',
            width: 260,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text?.length > 0 ? getTextByJs(text) : '-'}
                >
                    {text?.length > 0 ? getTextByJs(text) : '-'}
                </p>
            ),
        },
        {
            title: '治污线',
            dataIndex: 'facility_name',
            key: 'facility_name',
            width: 260,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text?.length > 0 ? getTextByJs(text) : '-'}
                >
                    {text?.length > 0 ? getTextByJs(text) : '-'}
                </p>
            ),
        },
        {
            title: '时效性',
            dataIndex: 'appoint',
            key: 'appoint',
            width: 140,
            render: (text?: any) => `${text}分钟` || '-',
        },
        {
            title: '状态',
            dataIndex: 'is_active',
            key: 'is_active',
            width: 60,
            render: (text, record) => (
                <Switch
                    checked={text}
                    size="small"
                    onChange={(value: boolean) => {
                        changeLoadingState(true);
                        console.log(record, value);
                        modifyPollutionControl(record.id, { is_active: value })
                            .then(() => {
                                message.success('状态修改成功');
                                refreshTableData();
                            })
                            .catch(dealRequestError)
                            .finally(() => {
                                changeLoadingState(false);
                            });
                    }}
                />
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            width: 160,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 100,
            render: (text, record) => (
                <div className="opera" key={text}>
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setDisplayAddPollutionFormModal({
                                editMode: 'edit',
                                originPollutionControl: record,
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={'确认要删除吗？'}
                        onConfirm={() => {
                            changeLoadingState(true);
                            deletePollutionControlById(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    refreshTableData();
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div className="container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        type="primary"
                        className="commonButton"
                        onClick={() => {
                            setDisplayAddPollutionFormModal({
                                editMode: 'add',
                            });
                        }}
                    >
                        新增
                    </Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Form
                        layout="horizontal"
                        labelAlign={'right'}
                        style={{ display: 'flex', justifyContent: 'end' }}
                        ref={searchRef}
                        onFinish={refreshTableData}
                    >
                        <Form.Item label="名称" name="name" style={{ marginLeft: '16px' }}>
                            <Input
                                placeholder="请输入名称"
                                style={{ width: '180px' }}
                                onChange={whenInputSearchName}
                                value={searchName}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item label="行政区" name="ad_region" style={{ margin: '0 16px' }}>
                            <AreaTreeSelect
                                style={{ width: '180px' }}
                                label={'行政区'}
                                onChange={whenSelectArea}
                                value={selectedArea}
                            />
                        </Form.Item>
                        <Form.Item label="所属企业" name="enterprise_id" style={{ margin: '0 16px' }}>
                            <PurgedAreaCompanyTreeSelect
                                label="所属企业"
                                style={{ width: '180px' }}
                                value={
                                    searchArea === undefined
                                        ? undefined
                                        : generateIdentifiedByType(TreeItemType.COMPANY, searchArea)
                                }
                                onChange={(itemIdentification?: string) => {
                                    if (itemIdentification === undefined) {
                                        whenInputSearchArea(undefined);
                                        return;
                                    }
                                    whenInputSearchArea(
                                        recoverInformationFromIdentify(itemIdentification).originObjectId
                                    );
                                }}
                                treeCheckable={false}
                                onlyCanSelectCompany
                            />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: '16px' }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    resetToFirstPage();
                                }}
                            >
                                搜索
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Table<PollutionControl.BackendProfile>
                pagination={pagination}
                dataSource={tableDataWithLoadingState.paginationData.results}
                loading={tableDataWithLoadingState.loading}
                columns={columns}
                rowKey={record => record.id}
                scroll={{ y: 'calc(100vh - 305px)' }}
            />
            {displayAddPollutionFormModal !== undefined && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        pollutionControlEditModalRef.current?.validateFields().then(() => {
                            const { originPollutionControl } = displayAddPollutionFormModal!;
                            const isEditMode = originPollutionControl !== undefined;
                            const fieldsValue: {
                                name: string;
                                facility: number[];
                                production: number[];
                                enterprise: string;
                                appoint: number;
                            } = pollutionControlEditModalRef.current?.getFieldsValue();
                            modalRef.current?.startLoading();

                            const addOrModifyEffluentPlanParameter: PollutionControl.CreateOrUpdatePollutionControlParameter =
                                {
                                    name: fieldsValue.name,
                                    appoint: fieldsValue.appoint,
                                    facility: fieldsValue.facility,
                                    production: fieldsValue.production,
                                    enterprise: recoverInformationFromIdentify(fieldsValue.enterprise).originObjectId,
                                };
                            let addOrModifyPromise: Promise<any> | undefined;
                            if (isEditMode) {
                                const patchObject = reduceSamePropertiesFromObject(
                                    addOrModifyEffluentPlanParameter,
                                    originPollutionControl
                                );
                                if (_.keysIn(patchObject).length === 0) {
                                    addOrModifyPromise = undefined;
                                } else {
                                    addOrModifyPromise = modifyPollutionControl(originPollutionControl.id, patchObject);
                                }
                            } else {
                                addOrModifyPromise = createPollutionControl(addOrModifyEffluentPlanParameter);
                            }
                            if (addOrModifyPromise === undefined) {
                                //user not change anything, so just do a fake notice!
                                message.success('编辑成功');
                                modalRef.current?.hiddenModal();
                                modalRef.current?.endLoading();
                                pollutionControlEditModalRef.current?.resetFields();
                                setDisplayAddPollutionFormModal(undefined);
                            } else {
                                addOrModifyPromise
                                    .then(() => {
                                        message.success(isEditMode ? '编辑成功' : '添加成功');
                                        refreshTableData();
                                        modalRef.current?.hiddenModal();
                                        modalRef.current?.endLoading();
                                        pollutionControlEditModalRef.current?.resetFields();
                                        setDisplayAddPollutionFormModal(undefined);
                                    })
                                    .catch(dealRequestError)
                                    .finally(() => {
                                        modalRef.current?.endLoading();
                                    });
                            }
                        });
                    }}
                    onCancel={() => {
                        pollutionControlEditModalRef.current?.resetFields();
                        setDisplayAddPollutionFormModal(undefined);
                    }}
                    title={`${displayAddPollutionFormModal?.editMode === 'edit' ? '编辑' : '新增'}`}
                >
                    <PollutionControlEditContent
                        ref={pollutionControlEditModalRef}
                        originPollutionControl={displayAddPollutionFormModal?.originPollutionControl}
                    />
                </CommonModal>
            )}
        </div>
    );
};

export default observer(PollutionControlView);
