import { UploadFile } from 'antd/lib/upload/interface';
import _ from 'lodash';
import { makeAutoObservable } from 'mobx';

function getBase64(file: Blob) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
}

class ImagePreviewState {
    private _previewImage: UploadFile | undefined = undefined;

    get previewImage(): UploadFile | undefined {
        return this._previewImage;
    }

    /**
     * display image preview by current file(selected from local machine or backfilled from remote url)
     * @param targetFile
     */
    displayImagePreview(targetFile: UploadFile) {
        const copiedFile = _.cloneDeep(targetFile);
        if (copiedFile.url === undefined && copiedFile.originFileObj !== undefined) {
            //this file is selected from local machine
            getBase64(copiedFile.originFileObj).then(base64OfImage => {
                copiedFile.preview = base64OfImage;
                this._previewImage = copiedFile;
            });
        } else {
            //this file is uploaded from remote server
            copiedFile.preview = copiedFile.url;
            this._previewImage = copiedFile;
        }
    }

    dismissImagePreview() {
        this._previewImage = undefined;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default new ImagePreviewState();
