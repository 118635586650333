import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, FormInstance, Input, message, Popconfirm, Switch } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CommonModal, { CommonModalApi } from '../../../../components/modal/CommoModal';
import { CommonTable } from '@/components/table/CommonTable';
import _ from 'lodash';
import {
    createCurrent,
    Current,
    dealRequestError,
    deleteCurrentById,
    extractAllIdsFromIdentifications,
    fetchAllCurrentTree,
    modifyCurrent,
    reduceSamePropertiesFromObject,
    splitIdWithName,
    usePurgedAreaCompanyState,
    WithLoadingState,
} from '@greendev/common';
import { AreaTreeSelect, useAreaInitialize } from '@/views/common/basicDataManage/areaManageView/AreaFormTreeSelect';
import CurrentEditContent from './CurrentEditContent';
import { Moment } from 'moment';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

const SearchForm = (props: {
    setSearchValue: (page?: number, value?: any) => void;
    setSearchParams: (value?: any) => void;
}) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { setSearchValue, setSearchParams } = props;
    const onFinish = (values?: any) => {
        setSearchParams(values);
        const companyIds = values.enterprise && extractAllIdsFromIdentifications([values.enterprise]);
        // eslint-disable-next-line no-param-reassign
        values.enterprise = companyIds instanceof Array ? companyIds[0] : companyIds;
        setSearchValue(1, values);
    };

    const [selectedArea, setSelectedArea] = useState<number | undefined>(undefined);
    const whenSelectArea = (value: number) => {
        setSelectedArea(value);
    };

    return (
        <Form
            layout="horizontal"
            labelAlign={'right'}
            style={{ display: 'flex', justifyContent: 'end' }}
            onFinish={onFinish}
        >
            <Form.Item label="名称" name="name" style={{ marginLeft: '16px' }}>
                <Input placeholder="请输入名称" style={{ width: '180px' }} />
            </Form.Item>
            <Form.Item label="行政区" name="ad_region" style={{ margin: '0 16px' }}>
                <AreaTreeSelect
                    style={{ width: '180px' }}
                    label={'行政区'}
                    onChange={whenSelectArea}
                    value={selectedArea}
                />
            </Form.Item>
            <PurgedAreaCompanyTreeSelectUsedInForm
                label={'企业'}
                required={false}
                treeCheckable={false}
                onlyCanSelectCompany
                style={{ width: '180px' }}
            />
            <Form.Item style={{ marginLeft: '16px' }}>
                <Button type="primary" htmlType="submit">
                    搜索
                </Button>
            </Form.Item>
        </Form>
    );
};

// 电流限制界面
const CurrentLimitView = () => {
    useAreaInitialize();
    usePurgedAreaCompanyState();
    const [currentWithLoadingState, setCurrentWithLoadingState] = useState<
        WithLoadingState<{
            current: Current.BackendCurrentProfile[];
        }>
    >({
        current: [],
        loading: true,
    });

    const [displayAddCurrentFormModal, setDisplayAddCurrentFormModal] = useState<
        | {
              editMode: string;
              targetCurrent?: Current.BackendCurrentProfile | undefined;
          }
        | undefined
    >(undefined);

    const modalRef = useRef<CommonModalApi>(null);
    const currentEditModalRef = useRef<FormInstance<any>>(null);

    useEffect(() => {
        if (displayAddCurrentFormModal !== undefined) {
            modalRef.current?.showModal();
        }
    }, [displayAddCurrentFormModal]);

    const changeLoadingState = (loading: boolean) => {
        setCurrentWithLoadingState({
            ...currentWithLoadingState,
            loading,
        });
    };

    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number | undefined>(1);
    const [pageSize, setPageSize] = useState<number | undefined>(10);
    const [searchParams, setSearchParams] = useState({});

    const refreshCurrentByCurrentParameters = (page = 1, searchValue?: any, page_size?: number) => {
        changeLoadingState(true);
        setCurrentPage(page);
        const params = _.omitBy(
            searchValue,
            (value?: any) => _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
        );
        params.page = page;
        params.page_size = page_size || pageSize;
        fetchAllCurrentTree(params)
            .then(result => {
                setCurrentWithLoadingState({
                    current: result.results as Current.BackendCurrentProfile[],
                    loading: false,
                });
                setTotal(result.count);
            })
            .catch(() => {
                if (page > 1) {
                    setCurrentPage(page - 1);
                    refreshCurrentByCurrentParameters(page - 1, searchValue, page_size || pageSize);
                }
                // dealRequestError(error);
                // changeLoadingState(false);
            });
    };

    useEffect(() => {
        refreshCurrentByCurrentParameters();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const columns: ColumnsType<Current.BackendCurrentProfile> = [
        {
            title: '规则名称',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '行政区',
            dataIndex: 'ad_region',
            key: 'ad_region',
            width: 260,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '企业',
            dataIndex: 'enter',
            key: 'enter',
            width: 140,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={splitIdWithName(text)?.name || '-'}
                >
                    {splitIdWithName(text)?.name || '-'}
                </p>
            ),
        },
        {
            title: '监测点',
            dataIndex: 'monitor_name',
            key: 'monitor_name',
            width: 160,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '总电流(A)',
            dataIndex: 'total',
            key: 'total',
            width: 120,
        },
        {
            title: '电流限制',
            dataIndex: 'percent',
            key: 'percent',
            width: 120,
        },
        {
            title: '开始时间',
            dataIndex: 's_date',
            key: 's_date',
            width: 150,
        },
        {
            title: '结束时间',
            dataIndex: 'e_date',
            key: 'e_date',
            width: 150,
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: 60,
            render: (text, record) => (
                <Switch
                    checked={text}
                    size="small"
                    onChange={(value: boolean) => {
                        changeLoadingState(true);
                        console.log(record, value);
                        modifyCurrent(record?.id, { state: value })
                            .then(() => {
                                message.success('状态修改成功');
                                refreshCurrentByCurrentParameters(currentPage, searchParams);
                            })
                            .catch(dealRequestError)
                            .finally(() => {
                                changeLoadingState(false);
                            });
                    }}
                />
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'add_time',
            key: 'add_time',
            width: 160,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 100,
            render: (text, record) => (
                <div className="opera" key={text}>
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setDisplayAddCurrentFormModal({
                                editMode: 'edit',
                                targetCurrent: record,
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={'确认要删除吗？'}
                        onConfirm={() => {
                            changeLoadingState(true);
                            deleteCurrentById(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    refreshCurrentByCurrentParameters(currentPage, searchParams);
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const paginations = {
        // eslint-disable-next-line react/destructuring-assignment
        pageSize, //每页的条数
        // eslint-disable-next-line react/destructuring-assignment
        current: currentPage, //当前页
        // eslint-disable-next-line react/destructuring-assignment
        total, //总数据
        showSizeChanger: true,
        onChange: (page?: number, page_size?: number) => {
            // eslint-disable-next-line react/destructuring-assignment
            setCurrentPage(page);
            setPageSize(page_size);
            refreshCurrentByCurrentParameters(page, searchParams, page_size);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current?: any, pagesize?: number) => {
            console.log(current);
            // eslint-disable-next-line react/destructuring-assignment
            setPageSize(pagesize);
        }, //pageSize 变化的回调
        hideOnSinglePage: false, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        // eslint-disable-next-line @typescript-eslint/no-shadow
        showTotal: (total: any) => `共 ${total} 条`,
    };

    return (
        <div className="container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        type="primary"
                        className="commonButton"
                        onClick={() => {
                            setDisplayAddCurrentFormModal({
                                editMode: 'add',
                                targetCurrent: undefined,
                            });
                        }}
                    >
                        新增
                    </Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <SearchForm setSearchValue={refreshCurrentByCurrentParameters} setSearchParams={setSearchParams} />
                </div>
            </div>
            <CommonTable
                dataSource={currentWithLoadingState.current}
                loading={currentWithLoadingState.loading}
                columns={columns}
                rowKey={record => record.id}
                pagination={paginations}
            />
            {displayAddCurrentFormModal !== undefined && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        currentEditModalRef.current?.validateFields().then(() => {
                            const fieldsValue: {
                                name: string;
                                total: number;
                                percent: number;
                                s_date: Moment;
                                e_date: Moment;
                                monitor_point: number;
                            } = currentEditModalRef.current?.getFieldsValue();
                            console.log(fieldsValue, 'fieldsValue');
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            const start_date: string = fieldsValue.s_date.format('YYYY-MM-DD');
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            const end_date: string = fieldsValue.e_date.format('YYYY-MM-DD');
                            const addOrModifyEffluentPlanParameter: Current.CreateOrUpdateCurrentParameter = {
                                name: fieldsValue.name,
                                percent: fieldsValue.percent,
                                total: fieldsValue.total,
                                monitor_point: fieldsValue.monitor_point,
                                s_date: start_date,
                                e_date: end_date,
                            };
                            let addOrModifyPromise: Promise<Current.FrontEndProfile> | undefined;
                            if (displayAddCurrentFormModal?.editMode === 'edit') {
                                const patchObject = reduceSamePropertiesFromObject(
                                    addOrModifyEffluentPlanParameter,
                                    displayAddCurrentFormModal?.targetCurrent!
                                );
                                addOrModifyPromise =
                                    _.keysIn(patchObject).length === 0
                                        ? undefined
                                        : modifyCurrent(displayAddCurrentFormModal.targetCurrent?.id, patchObject);
                            } else {
                                addOrModifyPromise = createCurrent(addOrModifyEffluentPlanParameter);
                            }
                            if (addOrModifyPromise === undefined) {
                                //user not change anything, so just do a fake notice!
                                message.success('编辑成功');
                                modalRef.current?.hiddenModal();
                                currentEditModalRef.current?.resetFields();
                                setDisplayAddCurrentFormModal(undefined);
                                modalRef.current?.endLoading();
                            } else {
                                addOrModifyPromise
                                    .then(() => {
                                        message.success(
                                            displayAddCurrentFormModal?.editMode === 'edit' ? '编辑成功' : '添加成功'
                                        );
                                        refreshCurrentByCurrentParameters(currentPage, searchParams);
                                        modalRef.current?.hiddenModal();
                                        modalRef.current?.endLoading();
                                        currentEditModalRef.current?.resetFields();
                                        setDisplayAddCurrentFormModal(undefined);
                                    })
                                    .catch(dealRequestError)
                                    .finally(() => {
                                        modalRef.current?.endLoading();
                                    });
                            }
                        });
                    }}
                    onCancel={() => {
                        currentEditModalRef.current?.resetFields();
                        setDisplayAddCurrentFormModal(undefined);
                    }}
                    title={`${displayAddCurrentFormModal?.editMode === 'edit' ? '编辑' : '新增'}`}
                >
                    <CurrentEditContent ref={currentEditModalRef} {...displayAddCurrentFormModal} />
                </CommonModal>
            )}
        </div>
    );
};

export default observer(CurrentLimitView);
