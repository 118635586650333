import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, DatePicker, Form, FormInstance, Input, message, Select, Space, Upload } from 'antd';
import { CommonTable } from '@/components/table/CommonTable';
import { ColumnsType } from 'antd/es/table';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import CommonDrawer, { CommonDrawerApi } from '@/components/modal/CommonDrawer';
import EchartLineView from './EchartLineView';
import { EmptyView } from '@/components/EmptyView'; //空数据占位组件
import {
    addMonitoring,
    dealRequestError,
    deleteMonitoringById,
    editMonitoring,
    extractAllIdsFromIdentifications,
    fetchAllMonitoring,
    getCurrentData,
    importMonitoringPoints,
    kindData,
    Monitoring,
    peakvalue,
    reduceSamePropertiesFromObject,
    usePurgedAreaCompanyState,
} from '@greendev/common';
import _ from 'lodash';
import { AreaTreeSelect, useAreaInitialize } from '@/views/common/basicDataManage/areaManageView/AreaFormTreeSelect';
import MonitoringEditContent, { MonitoringEditContentPorps } from './MonitoringEditContent';
import moment from 'moment';
import './style.scss';
import { PurgedAreaCompanyTreeSelect } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';
import { ImportResultModal, ImportResultModalAction } from '@/components/importResult/ImportResultModal';
import { ExcelKeyReadableMapping, exportExcel, useExcelImporter } from '@/utils/excel';
import { UploadFile } from 'antd/es/upload/interface';
import GlobalStyle from '@/styles';
import GlobalModal, { GlobalModalApi } from '@/components/modal/GlobalModal';
import PLCLedger from './PLCLedger';

type MonitoringWithLoadingState = {
    monitoring: Monitoring.FrontEndProfile[];
    loading: boolean;
};

const { RangePicker } = DatePicker;
const searchButtonId = 'search-button-in-monitoring-point-management';

// eslint-disable-next-line react/no-unused-prop-types
const SearchForm = (props: {
    setSearchValue: (page?: number, value?: any) => void;
    setSearchParams: (value: any) => void;
    exportMonitoringPoints: () => void;
}) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { setSearchValue, setSearchParams, exportMonitoringPoints } = props;
    const { color } = GlobalStyle;
    const onFinish = (values?: any) => {
        setSearchParams(values);
        const companyIds = values.enterprise && extractAllIdsFromIdentifications([values.enterprise]);
        // eslint-disable-next-line no-param-reassign
        values.enterprise = companyIds instanceof Array ? companyIds[0] : companyIds;
        setSearchValue(1, values);
    };

    const [selectedArea, setSelectedArea] = useState<number | undefined>(undefined);
    const whenSelectArea = (value: number) => {
        setSelectedArea(value);
    };

    return (
        <Form
            layout="horizontal"
            labelAlign={'right'}
            onFinish={onFinish}
            style={{ display: 'flex', justifyContent: 'end' }}
        >
            <Form.Item label="名称" name="name" style={{ marginLeft: '16px' }}>
                <Input placeholder="请输入名称" style={{ width: '180px' }} />
            </Form.Item>
            <Form.Item label="类型" name="kind" style={{ marginLeft: '16px' }}>
                <Select placeholder="请选择监测类型" virtual={false} showArrow allowClear style={{ width: '180px' }}>
                    {kindData.map(item => (
                        <Select.Option value={item.id} key={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="所属企业" name="enterprise" style={{ marginLeft: '16px' }}>
                <Space>
                    <Form.Item name="enterprise" noStyle>
                        <PurgedAreaCompanyTreeSelect
                            label="所属企业"
                            style={{ width: '180px' }}
                            treeCheckable={false}
                            onlyCanSelectCompany
                        />
                    </Form.Item>
                </Space>
            </Form.Item>
            <Form.Item label="行政区" name="ad_region" style={{ margin: '0 16px' }}>
                <AreaTreeSelect
                    style={{ width: '180px' }}
                    label={'行政区'}
                    onChange={whenSelectArea}
                    value={selectedArea}
                />
            </Form.Item>
            <Form.Item>
                <div className={'flex-horizontal-space-between'}>
                    <Button
                        id={searchButtonId}
                        style={{ height: '100%', width: '45%' }}
                        className={'flex-horizontal-center'}
                        type="primary"
                        htmlType="submit"
                    >
                        搜索
                    </Button>
                    <Button
                        style={{ height: '100%', width: '45%', color: color.themeColor }}
                        className={'flex-horizontal-center'}
                        type="default"
                        onClick={() => {
                            exportMonitoringPoints();
                        }}
                    >
                        导出
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

//监测点管理页面
const MonitoringPointsManagementView = () => {
    useAreaInitialize();
    usePurgedAreaCompanyState();
    const { color } = GlobalStyle;
    const modalRef = useRef<CommonModalApi>(null);
    const globalModalRef = useRef<GlobalModalApi>(null);
    const drawerRef = useRef<CommonDrawerApi>(null);
    const monitoringEditModalRef = useRef<FormInstance<any>>(null);
    const [monitoringEditModalState, setMonitoringEditModalState] = useState<MonitoringEditContentPorps | undefined>(
        undefined
    );
    useEffect(() => {
        if (monitoringEditModalState !== undefined) {
            modalRef.current?.showModal();
        }
    }, [monitoringEditModalState]);

    const [monitoringWithLoadingState, setMonitoringWithLoadingState] = useState<MonitoringWithLoadingState>({
        monitoring: [],
        loading: true,
    });

    const changeLoadingState = (loading: boolean) => {
        setMonitoringWithLoadingState({
            ...monitoringWithLoadingState,
            loading,
        });
    };

    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});
    const [currentData, setCurrentData] = useState<any>([]);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>(undefined);

    const [startDate, setStartDate] = useState(moment().subtract(29, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const onChangeDatePicker = (currentdate: any | undefined) => {
        setStartDate(value => (currentdate && currentdate[0] ? moment(currentdate[0]).format('YYYY-MM-DD') : value));
        setEndDate(value => (currentdate && currentdate[1] ? moment(currentdate[1]).format('YYYY-MM-DD') : value));
    };
    const setTimeWithButton = (days: number) => {
        setStartDate(moment().subtract(days, 'days').format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    };

    const onValueChange = (page = 1, input?: any, page_size?: number) => {
        changeLoadingState(true);
        setCurrentPage(page);
        const params = _.omitBy(
            input,
            (value?: any) => _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
        );
        params.page = page;
        params.page_size = page_size || pageSize;
        fetchAllMonitoring(params)
            .then(resultOrigin => {
                const result = resultOrigin as unknown as Monitoring.FrontEndProfile;
                setMonitoringWithLoadingState({
                    monitoring: result.results as Monitoring.FrontEndProfile[],
                    loading: false,
                });
                setTotal(result.count);
            })
            .catch(() => {
                if (page > 1) {
                    setCurrentPage(page - 1);
                    onValueChange(page - 1, input, page_size || pageSize);
                }
                // dealRequestError(error);
                // changeLoadingState(false);
            });
    };

    useEffect(() => {
        onValueChange();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line @typescript-eslint/default-param-last
    const delChildMonitoring = (id: number, name: string) => {
        changeLoadingState(true);
        deleteMonitoringById(id, name)
            .then(() => {
                message.success('删除成功');
                modalRef.current?.hiddenModal();
                monitoringEditModalRef.current?.resetFields();
                onValueChange(currentPage, searchParams);
                setMonitoringEditModalState(undefined);
                changeLoadingState(false);
            })
            .catch(error => {
                dealRequestError(error);
                changeLoadingState(false);
            });
    };

    const addChildMonitoring = (targeMonitoring: Monitoring.FrontEndProfile) => {
        changeLoadingState(true);
        // eslint-disable-next-line no-param-reassign
        targeMonitoring.enterprise = extractAllIdsFromIdentifications([targeMonitoring?.enterprise.toString()])[0];
        const params = _.omitBy(
            targeMonitoring,
            (value?: any) => _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
        );
        addMonitoring(params)
            .then(() => {
                message.success('新增成功');
                modalRef.current?.hiddenModal();
                monitoringEditModalRef.current?.resetFields();
                onValueChange(currentPage, searchParams);
            })
            .catch(error => {
                dealRequestError(error);
                changeLoadingState(false);
            });
    };

    const editChildMonitoring = (id?: number, targeMonitoringOrigin?: Monitoring.FrontEndProfile) => {
        changeLoadingState(true); //enter_name enterprise
        // eslint-disable-next-line no-param-reassign
        if (targeMonitoringOrigin) {
            const targeMonitoring = targeMonitoringOrigin;
            targeMonitoring.enterprise = extractAllIdsFromIdentifications([targeMonitoring?.enterprise.toString()])[0];
            const params = _.omitBy(
                targeMonitoring,
                (value?: any) => _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
            ) as any;
            params.desc = targeMonitoring?.desc === '' ? null : targeMonitoring?.desc;
            const patchObject = reduceSamePropertiesFromObject(params, monitoringEditModalState!.targetMonitoring!);
            if (_.keysIn(patchObject).length === 0) {
                message.success('编辑成功');
                modalRef.current?.hiddenModal();
                setMonitoringEditModalState(undefined);
                monitoringEditModalRef.current?.resetFields();
                changeLoadingState(false);
            } else {
                editMonitoring(id, patchObject)
                    .then(() => {
                        message.success('编辑成功');
                        modalRef.current?.hiddenModal();
                        monitoringEditModalRef.current?.resetFields();
                        setMonitoringEditModalState(undefined);
                        onValueChange(currentPage, searchParams);
                    })
                    .catch(error => {
                        dealRequestError(error);
                        changeLoadingState(false);
                    });
            }
        }
    };
    // 去重
    const unique = (arr: any) => Array.from(new Set(arr));
    //查看数据
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getCurrent = (id: number) => {
        if (id === undefined) {
            return;
        }
        setRefreshData(true);
        if (moment(endDate, 'YYYY-MM-DD') < moment(startDate, 'YYYY-MM-DD')) {
            setStartDate(endDate);
        }
        getCurrentData(id, {
            s_time: moment(endDate, 'YYYY-MM-DD') < moment(startDate, 'YYYY-MM-DD') ? endDate : startDate,
            e_time: moment(endDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
        })
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .then(result => {
                if (result.length > 0) {
                    const colors = [
                        [
                            '#FC756B',
                            '#FFaF33',
                            '#FC9E32',
                            '#52E0D7',
                            '#1890FF',
                            '#F18484',
                            '#a592a3',
                            '#91CC75',
                            '#83C7E1',
                            '#FAC858',
                        ],
                        [
                            '#2AC9AA',
                            '#96BEFB',
                            '#60ACF2',
                            '#F18484',
                            '#91CC75',
                            '#F59A23',
                            '#1890FF',
                            '#83C7E1',
                            '#FAC858',
                            '#52E0D7',
                        ],
                    ];
                    const dataList: any = [];
                    const xAxisData: any = [];
                    result.map((item, index: number) => {
                        const list = item?.data?.map(itemList => peakvalue(itemList?.current));
                        item?.data?.map((itemList: { time: string }) =>
                            xAxisData.push(
                                endDate === startDate ? itemList?.time?.split(' ')[1] : itemList?.time?.split(' ')[0]
                            )
                        );
                        return dataList.push({
                            name: item.name,
                            data: list,
                            itemStyle: {
                                color: colors[detail!.kind - 1][index % colors[detail!.kind - 1].length],
                            },
                        });
                    });
                    setCurrentData({ xAxisData: unique(xAxisData), dataSets: dataList });
                } else {
                    setCurrentData(undefined);
                }
                setRefreshData(false);
            })
            .catch(error => {
                setRefreshData(false);
                dealRequestError(error);
            });
    };

    useEffect(() => {
        getCurrent(detail?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail, startDate, endDate]);

    const columns: ColumnsType<Monitoring.FrontEndProfile> = [
        {
            title: '监测点名称',
            dataIndex: 'name',
            key: 'name',
            width: 260,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '监测类型',
            width: 100,
            dataIndex: 'kind_name',
        },
        {
            title: '所属企业',
            dataIndex: 'enter_name',
            key: 'enter_name',
            width: 260,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '行政区',
            dataIndex: 'ad_region',
            key: 'ad_region',
            width: 320,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '备注',
            dataIndex: 'desc',
            key: 'desc',
            width: 200,
            render: (text?: any) => (
                <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        { title: '关联设备数', dataIndex: 'dev_num', width: 150 },
        {
            title: '添加时间',
            dataIndex: 'add_time',
            key: 'add_time',
            width: 160,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 160,
            render: (item?: any, record?: any) => (
                <div className="opera">
                    <i
                        className="iconfont icon_plc"
                        title={'PLC台账'}
                        onClick={() => {
                            globalModalRef.current?.showModal();
                            console.log(record, 'record');
                            setDetail(record);
                        }}
                        style={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            cursor: 'pointer',
                            marginRight: '.5rem',
                        }}
                    />
                    <i
                        className="iconfont iconchakan1"
                        title={'查看数据'}
                        onClick={() => {
                            drawerRef.current?.showModal();
                            setDetail(record);
                            setMonitoringEditModalState(undefined);
                            setEndDate(moment().format('YYYY-MM-DD'));
                        }}
                        style={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            cursor: 'pointer',
                        }}
                    />
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setMonitoringEditModalState({
                                editMode: 'edit',
                                targetMonitoring: record,
                            });
                            modalRef.current?.showModal();
                        }}
                        style={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            margin: '0 0.6rem',
                            cursor: 'pointer',
                        }}
                    />
                    <i
                        className="iconfont iconshanchu1"
                        title={'删除'}
                        onClick={() => {
                            setMonitoringEditModalState({
                                editMode: 'del',
                                targetMonitoring: record,
                            });
                            modalRef.current?.showModal();
                        }}
                        style={{
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            cursor: 'pointer',
                        }}
                    />
                </div>
            ),
        },
    ];

    const paginations = {
        // eslint-disable-next-line react/destructuring-assignment
        pageSize, //每页的条数
        // eslint-disable-next-line react/destructuring-assignment
        current: currentPage, //当前页
        // eslint-disable-next-line react/destructuring-assignment
        total, //总数据
        showSizeChanger: true,
        onChange: (page?: number, page_size?: number) => {
            // eslint-disable-next-line react/destructuring-assignment
            setCurrentPage(page || 1);
            setPageSize(page_size || 20);
            onValueChange(page, searchParams, page_size);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current?: any, pagesize?: number) => {
            // eslint-disable-next-line react/destructuring-assignment
            setPageSize(pagesize || 20);
        }, //pageSize 变化的回调
        hideOnSinglePage: false, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        // eslint-disable-next-line @typescript-eslint/no-shadow
        showTotal: (total: any) => `共 ${total} 条`,
    };

    const importResultModalRef = useRef<ImportResultModalAction>(null);
    const keyMapping: ExcelKeyReadableMapping<Monitoring.ExcelExposeProfile> = {
        name: '监测点名称',
        companyName: '所属企业',
        kindName: '监测类型',
        remark: '备注',
    };
    const exportMonitoringPoints = () => {
        fetchAllMonitoring({
            get_all: true,
        }).then(monitoringPoints => {
            if ((monitoringPoints as Monitoring.FrontEndProfile[]).length === 0) {
                message.warn('暂无监测点');
                return;
            }
            const monitoringPointForExcel: Monitoring.ExcelExposeProfile[] = (
                monitoringPoints as Monitoring.FrontEndProfile[]
            ).map(
                eachMonitoringPoint =>
                    ({
                        name: eachMonitoringPoint.name,
                        companyName: eachMonitoringPoint.enter_name,
                        kindName: eachMonitoringPoint.kind_name,
                        remark: eachMonitoringPoint.desc,
                    } as Monitoring.ExcelExposeProfile)
            );
            exportExcel(keyMapping, monitoringPointForExcel, '监测点管理列表');
        });
    };
    const excelUploader = useCallback(recordsInSheet => {
        try {
            /* eslint-disable */
            const backendProfiles: Monitoring.ExcelImportProfile[] = recordsInSheet.map(
                (eachRecord: { [x: string]: any }) =>
                    _.omitBy(
                        {
                            name: eachRecord[keyMapping['name']],
                            enterprise: eachRecord[keyMapping['companyName']],
                            kind: eachRecord[keyMapping['kindName']],
                            desc: eachRecord[keyMapping['remark']],
                        } as Monitoring.ExcelImportProfile,
                        _.isNull
                    ) as Monitoring.ExcelImportProfile
            );
            /* eslint-enable */
            //request backend to import records
            importMonitoringPoints(backendProfiles)
                .then(result => {
                    document.getElementById(searchButtonId)?.click();
                    importResultModalRef.current?.displayResult({
                        result,
                        onClickExportFailItems: index => {
                            const companiesForExcel: Monitoring.ExcelExposeProfile[] = backendProfiles
                                .filter((value, eachIndex) => index.includes(eachIndex + 1))
                                .map(
                                    eachMonitoringPoint =>
                                        ({
                                            name: eachMonitoringPoint.name,
                                            companyName: eachMonitoringPoint.enterprise,
                                            kindName: eachMonitoringPoint.kind,
                                            remark: eachMonitoringPoint.desc,
                                        } as Monitoring.ExcelExposeProfile)
                                );
                            exportExcel(keyMapping, companiesForExcel, '监测点管理列表(导入失败)');
                        },
                    });
                })
                .catch(dealRequestError)
                .finally(() => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    afterUploadExcel();
                });
        } catch (e) {
            message.error('格式错误');
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            afterUploadExcel();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [afterSelectExcel, afterUploadExcel, loading] = useExcelImporter(excelUploader);

    return (
        <div className="container monitoringPointsPlc">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                <div className={'flex-horizontal-space-between commonButton'}>
                    <Button
                        style={{ width: '45%' }}
                        className={'flex-horizontal-center'}
                        type="primary"
                        onClick={() => {
                            setMonitoringEditModalState({
                                editMode: 'add',
                                targetMonitoring: undefined,
                            });
                        }}
                    >
                        新增
                    </Button>
                    <div style={{ width: '45%' }}>
                        <Upload
                            customRequest={options => {
                                options.onError?.(new Error('fake error'));
                            }}
                            onChange={info => {
                                const uploadFile = info.file as UploadFile;
                                if (uploadFile.status !== 'error') {
                                    return;
                                }
                                afterSelectExcel(uploadFile);
                            }}
                            multiple={false}
                            showUploadList={false}
                        >
                            <Button
                                style={{ width: '100%', color: color.themeColor }}
                                className={'flex-horizontal-center'}
                                type="default"
                                loading={loading}
                            >
                                导入
                            </Button>
                        </Upload>
                    </div>
                </div>
                <SearchForm
                    setSearchValue={onValueChange}
                    setSearchParams={setSearchParams}
                    exportMonitoringPoints={exportMonitoringPoints}
                />
            </div>
            <CommonTable
                columns={columns}
                dataSource={monitoringWithLoadingState.monitoring}
                loading={monitoringWithLoadingState.loading}
                rowKey={record => record.id}
                pagination={paginations}
            />
            {monitoringEditModalState !== undefined && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        monitoringEditModalRef.current?.validateFields().then(values => {
                            if (monitoringEditModalState?.editMode === 'add') {
                                addChildMonitoring(values);
                            } else if (monitoringEditModalState?.editMode === 'edit') {
                                editChildMonitoring(monitoringEditModalState?.targetMonitoring?.id, values);
                            } else {
                                delChildMonitoring(monitoringEditModalState?.targetMonitoring?.id!!, values.name);
                            }
                            // modalRef.current?.startLoading();
                        });
                    }}
                    onCancel={() => {
                        monitoringEditModalRef.current?.resetFields();
                    }}
                    // eslint-disable-next-line no-nested-ternary
                    title={`${
                        // eslint-disable-next-line no-nested-ternary
                        monitoringEditModalState?.editMode === 'edit'
                        ? '编辑监测点'
                        : monitoringEditModalState?.editMode === 'add'
                        ? '新增监测点'
                        : '你确定删除监测点吗？'
                    }`}
                >
                    <MonitoringEditContent ref={monitoringEditModalRef} {...monitoringEditModalState} />
                </CommonModal>
            )}
            <CommonDrawer
                ref={drawerRef}
                // onOk={() => {
                //     drawerRef.current?.hiddenModal();
                // }}
                onCancel={() => {
                    drawerRef.current?.hiddenModal();
                }}
                title={detail?.name}
            >
                <>
                    <div className="device-detail-datepicker">
                        <Button
                            type={
                                moment().format('YYYY-MM-DD') === endDate && moment().format('YYYY-MM-DD') === startDate
                                    ? 'primary'
                                    : 'default'
                            }
                            style={{ marginRight: '5px' }}
                            onClick={() => {
                                setTimeWithButton(0);
                            }}
                        >
                            今日
                        </Button>
                        <Button
                            type={
                                moment().format('YYYY-MM-DD') === endDate &&
                                moment().subtract(6, 'days').format('YYYY-MM-DD') === startDate
                                    ? 'primary'
                                    : 'default'
                            }
                            style={{ marginRight: '5px' }}
                            onClick={() => {
                                setTimeWithButton(6);
                            }}
                        >
                            近7天
                        </Button>
                        <Button
                            type={
                                moment().format('YYYY-MM-DD') === endDate &&
                                moment().subtract(29, 'days').format('YYYY-MM-DD') === startDate
                                    ? 'primary'
                                    : 'default'
                            }
                            style={{ marginRight: '5px' }}
                            onClick={() => {
                                setTimeWithButton(29);
                            }}
                        >
                            近30天
                        </Button>
                        <RangePicker
                            disabledDate={current =>
                                // 可选今天以前时间跨度30天以内的日期。
                                !(
                                    current < moment().endOf('day') &&
                                    current >= moment(endDate).subtract(29, 'day') &&
                                    current <= moment(startDate).add(29, 'days')
                                )
                            }
                            onCalendarChange={onChangeDatePicker}
                            format="yyyy-MM-DD"
                            value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                            allowClear={false}
                        />
                    </div>
                    {_.isEmpty(currentData) || refreshData ? (
                        <EmptyView
                            image={require('@/assets/images/monitoring_current_nodata.png')}
                            description={'设备未开启,无数据'}
                            style={{ color: '#A6D3CA' }}
                        />
                    ) : (
                        <EchartLineView data={currentData} unit={'A'} />
                    )}
                </>
            </CommonDrawer>
            <ImportResultModal ref={importResultModalRef} />
            <GlobalModal
                title={'设备台账'}
                childRef={globalModalRef}
                onOk={() => {
                    // if (modalType === 'edit') {
                    //     userList();
                    // }
                }}
                footer={null}
            >
              <PLCLedger data={detail}/>
            </GlobalModal>
        </div>
    );
};

export default observer(MonitoringPointsManagementView);
