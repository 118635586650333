import React from 'react';
import ReactDOM from 'react-dom';
import App from '@/App';
import reportWebVitals from '@/reportWebVitals';
// 使用npx create-react-app 创建的项目引入antd.css 之后会有警告，这里直接使用antd.variable.min.css
import 'antd/dist/antd.variable.min.css';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import './assets/iconfont/iconfont.css'; //引入iconfont样式
import './AntDesignReset';
import './styles/global.scss';

setUseWhatChange(process.env.NODE_ENV === 'development');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
