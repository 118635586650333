import FileUploaderState from '@/stores/fileUploaderState';
import { useEffect, useMemo } from 'react';

const fileUploaderManager = new Map<string, FileUploaderState>();

export const createFileUploaderUsage = (purpose: string) => {
    if (fileUploaderManager.get(purpose) === undefined) {
        fileUploaderManager.set(purpose, new FileUploaderState());
    }
};

export const destroyFileUploaderState = (purpose: string) => {
    fileUploaderManager.delete(purpose);
};

export const getFileUploaderState = (purpose: string) => fileUploaderManager.get(purpose);

export function useFileUploaderState(purpose: string) {
    return useMemo(() => {
        createFileUploaderUsage(purpose);
        return getFileUploaderState(purpose);
    }, [purpose]);
}

export function useFileUploaderDisposer(purpose: string) {
    useEffect(
        () =>
            //do nothing , only for dispose
            () => {
                destroyFileUploaderState(purpose);
            },
        [purpose]
    );
}

export const fileUploaderPurposeKeyMapping = {
    uploadCompanyLogo: 'uploadCompanyLogoPurpose',
    uploadBanner: 'uploadBannerPurpose',
};
