import { Button, Result } from 'antd';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { RouteProps, useNavigate } from 'react-router-dom';

import { userStore } from '@/stores/userStore';

// @ts-ignore
const PrivateRoute: FC<RouteProps> = props => {
    const { token } = userStore;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { element } = props;

    return token ? (
        element || null
    ) : (
        <Result
            status="403"
            title="403"
            subTitle={'用户尚未登录'}
            extra={
                <Button
                    type="primary"
                    onClick={() => navigate({ pathname: 'login' }, { replace: true, state: { from: pathname } })}
                >
                    {'前往登录'}
                </Button>
            }
        />
    );
};

export default PrivateRoute;
