// Hex正则表达式
const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;

// Hex转RGBA
const HexToRGBA = (hex: string, opacity: number = 1) => {
    if (!reg.test(hex)) return 'rgba(0,0,0,1)';
    let newHex = hex;
    if (newHex.length === 4) {
        newHex = '#';
        for (let i = 1; i < 4; i++) {
            newHex += hex.slice(i, i + 1).concat(hex.slice(i, i + 1));
        }
    }
    const rgba = [];
    for (let i = 1; i < 7; i += 2) {
        rgba.push(parseInt(`0x${newHex.slice(i, i + 2)}`, 16));
    }
    return `rgba(${rgba.join(',')},${opacity})`;
};

// 生成颜色方案
const generateColorList = (colorArr: string[]) => {
    const res: ColorDetail[] = [];
    for (const item of colorArr) {
        res.push({
            color: item,
            colorrgba1: HexToRGBA(item, 0.8),
            colorrgba2: HexToRGBA(item, 0.2),
            showdowColor: HexToRGBA(item, 0.3),
        });
    }
    return res;
};

// 生产颜色
const productColor = ['#FF9572', '#FCDB97', '#F46565', '#FFC833', '#FCA990', '#F77757', '#FB887F', '#FC9E32'];
// 治污颜色
const pollutionColor = ['#5BC9F2', '#3CDB7F', '#5180EA', '#61DFF2', '#29AAEF', '#3CD9BB', '#96BEFB', '#60ACF2'];

type ColorDetail = {
    color: string;
    colorrgba1: string;
    colorrgba2: string;
    showdowColor: string;
};

export const colors1 = generateColorList(productColor);
export const colors2 = generateColorList(pollutionColor);

export const currentDataColor = ['#52E0D7', '#F59A23', '#1890FF', '#F18484', '#91CC75', '#83C7E1', '#FAC858'];
