import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, Form, FormInstance, Input, message, Popconfirm, Switch, Upload } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CommonModal, { CommonModalApi } from '../../../../components/modal/CommoModal';
import { CommonTable } from '@/components/table/CommonTable';
import CompanyEditContent from '@/views/common/accountManage/companyAccountView/companyEditContent';
import {
    Account,
    createAccount,
    dealRequestError,
    deleteAccountById,
    extractAllIdsFromIdentifications,
    fetchAccounts,
    fetchAllRoles,
    importCompanyAccounts,
    modifyAccount,
    PaginationWrapper,
    reduceSamePropertiesFromObject,
    Role,
    usePurgedAreaCompanyState,
    WithLoadingState,
} from '@greendev/common';
import _ from 'lodash';
import { ExcelKeyReadableMapping, exportExcel, useExcelImporter } from '@/utils/excel';
import { UploadFile } from 'antd/es/upload/interface';
import GlobalStyle from '@/styles';
import { ImportResultModal, ImportResultModalAction } from '@/components/importResult/ImportResultModal';
import { PurgedAreaCompanyTreeSelectUsedInForm } from '@/views/common/basicDataManage/areaManageView/PurgedAreaCompanyTreeSelect';

const searchButtonId = 'search-button-in-account-view';

const SearchForm = (props: {
    setSearchValue: (page?: number, values?: any) => void;
    // eslint-disable-next-line react/no-unused-prop-types
    setSearchParams: (value: any) => void;
}) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { setSearchValue, setSearchParams } = props;
    const onFinish = (values?: any) => {
        setSearchParams(values);
        const companyIds = values.enterprise && extractAllIdsFromIdentifications([values.enterprise]);
        // eslint-disable-next-line no-param-reassign
        values.enterprise = companyIds instanceof Array ? companyIds[0] : companyIds;
        setSearchValue(1, values);
    };
    return (
        <Form
            layout="horizontal"
            labelAlign={'right'}
            style={{ display: 'flex', justifyContent: 'end' }}
            onFinish={onFinish}
        >
            <Form.Item label="账号" name="username" style={{ marginLeft: '16px' }}>
                <Input placeholder="请输入账号" style={{ width: '120px' }} />
            </Form.Item>
            <Form.Item label="姓名" name="name" style={{ marginLeft: '16px' }}>
                <Input placeholder="请输入联系人姓名" style={{ width: '150px' }} />
            </Form.Item>
            <Form.Item label="电话" name="mobile" style={{ margin: ' 0 16px' }}>
                <Input placeholder="请输入联系人电话" style={{ width: '150px' }} />
            </Form.Item>
            <PurgedAreaCompanyTreeSelectUsedInForm
                label={'企业'}
                required={false}
                treeCheckable={false}
                onlyCanSelectCompany
                style={{ width: '300px' }}
            />
            <Form.Item style={{ marginLeft: '16px' }}>
                <Button id={searchButtonId} type="primary" htmlType="submit">
                    搜索
                </Button>
            </Form.Item>
        </Form>
    );
};

// 企业账号管理界面
const CompanyAccountView = () => {
    const { color } = GlobalStyle;
    usePurgedAreaCompanyState();
    const pagePreFetchData = useLocalObservable(() => ({
        roles: [] as Role.BackEndProfile[],
        fetchAllRoles() {
            fetchAllRoles({
                get_all: true,
            })
                .then(result => {
                    this.roles = result as Role.BackEndProfile[];
                })
                .catch(dealRequestError);
        },
        clearRoles() {
            this.roles = [];
        },
    }));
    useEffect(() => {
        pagePreFetchData.fetchAllRoles();
        return () => {
            pagePreFetchData.clearRoles();
        };
    }, [pagePreFetchData]);

    const [accountsWithLoadingState, setAccountsWithLoadingState] = useState<
        WithLoadingState<{
            accountsWithPagination: PaginationWrapper<Account.BackendAccountProfile>;
        }>
    >({
        accountsWithPagination: {
            count: 0,
            next: null,
            previous: null,
            results: [],
        },
        loading: true,
    });

    const [displayAddAccountFormModal, setDisplayAddAccountFormModal] = useState<
        | {
              editMode: string;
              targetAccount?: Account.BackendAccountProfile | undefined;
          }
        | undefined
    >(undefined);

    const modalRef = useRef<CommonModalApi>(null);
    const accountEditModalRef = useRef<FormInstance<any>>(null);

    useEffect(() => {
        if (displayAddAccountFormModal !== undefined) {
            modalRef.current?.showModal();
        }
    }, [displayAddAccountFormModal]);

    const changeLoadingState = (loading: boolean) => {
        setAccountsWithLoadingState({
            ...accountsWithLoadingState,
            loading,
        });
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useState({});

    const refreshAccountsByCurrentParameters = (page = 1, values?: any, page_size?: number) => {
        changeLoadingState(true);
        setCurrentPage(page);
        const params = _.omitBy(
            values,
            (value?: any) => _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
        );
        params.page = page;
        params.page_size = page_size || pageSize;
        fetchAccounts(params)
            .then(result => {
                setAccountsWithLoadingState({
                    accountsWithPagination: result as PaginationWrapper<Account.BackendAccountProfile>,
                    loading: false,
                });
            })
            .catch(() => {
                if (page > 1) {
                    setCurrentPage(page - 1);
                    refreshAccountsByCurrentParameters(page - 1, values, page_size || pageSize);
                }
                // dealRequestError(error);
                // changeLoadingState(false);
            });
    };
    useEffect(() => {
        refreshAccountsByCurrentParameters();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const importResultModalRef = useRef<ImportResultModalAction>(null);
    const keyMapping: ExcelKeyReadableMapping<Account.ExcelExposeProfileForAccount> = {
        username: '账号',
        name: '联系人姓名',
        mobile: '联系人电话',
        enterpriseName: '关联企业',
        roleName: '角色',
    };
    const exportAccounts = () => {
        fetchAccounts({
            get_all: true,
        }).then(accounts => {
            if ((accounts as Account.BackendAccountProfile[]).length === 0) {
                message.warn('暂无企业帐号');
                return;
            }
            const accountsForExcel: Account.ExcelExposeProfileForAccount[] = (
                accounts as Account.BackendAccountProfile[]
            ).map(eachAccount => ({
                username: eachAccount.username,
                name: eachAccount.name,
                mobile: eachAccount.mobile,
                enterpriseName: eachAccount.enterprises_name[0].name,
                roleName: eachAccount.role_name,
            }));
            exportExcel(keyMapping, accountsForExcel, '企业账号列表');
        });
    };
    const excelUploader = useCallback(recordsInSheet => {
        try {
            const backendProfiles: Account.ExcelImportProfileForAccount[] = recordsInSheet.map(
                (eachRecord: { [x: string]: any }) =>
                    /* eslint-disable */
                    _.omitBy(
                        {
                            username: eachRecord[keyMapping['username']],
                            name: eachRecord[keyMapping['name']],
                            mobile: eachRecord[keyMapping['mobile']],
                            enterprise: eachRecord[keyMapping['enterpriseName']],
                            roles: eachRecord[keyMapping['roleName']],
                        },
                        _.isNull
                    ) as Account.ExcelImportProfileForAccount
            );
            /* eslint-enable */
            //request backend to import records
            importCompanyAccounts(backendProfiles)
                .then(result => {
                    document.getElementById(searchButtonId)?.click();
                    importResultModalRef.current?.displayResult({
                        result,
                        onClickExportFailItems: index => {
                            const accountsForExcel: Account.ExcelExposeProfileForAccount[] = backendProfiles
                                .filter((value, eachIndex) => index.includes(eachIndex + 1))
                                .map(eachAccount => ({
                                    username: eachAccount.username,
                                    name: eachAccount.name,
                                    mobile: eachAccount.mobile,
                                    enterpriseName: eachAccount.enterprise,
                                    roleName: eachAccount.roles,
                                }));
                            exportExcel(keyMapping, accountsForExcel, '企业账号列表(导入失败)');
                        },
                    });
                })
                .catch(dealRequestError)
                .finally(() => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    afterUploadExcel();
                });
        } catch (e) {
            message.error('格式错误');
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            afterUploadExcel();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [afterSelectExcel, afterUploadExcel, loading] = useExcelImporter(excelUploader);

    const columns: ColumnsType<Account.BackendAccountProfile> = [
        {
            title: '账号',
            dataIndex: 'username',
            key: 'username',
            width: 240,
            render: (text?: any) => (
                <p
                    style={{
                        minWidth: '200px',
                        maxWidth: '260px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '联系人姓名',
            dataIndex: 'name',
            key: 'name',
            width: 160,
            render: (text?: any) => (
                <p
                    style={{
                        minWidth: '100px',
                        maxWidth: '160px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text || '-'}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '联系人电话',
            dataIndex: 'mobile',
            key: 'mobile',
            width: 140,
        },
        {
            title: '关联企业',
            dataIndex: 'enterprises_name',
            key: 'enterprises_name',
            width: 260,
            render: (text?: any) => (
                <p
                    style={{
                        minWidth: '200px',
                        maxWidth: '260px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text?.length > 0 ? text[0].name : '-'}
                >
                    {text?.length > 0 ? text[0].name : '-'}
                </p>
            ),
        },
        {
            title: '角色',
            dataIndex: 'role_name',
            key: 'role_name',
            width: 240,
            render: (text?: any) => (
                <p
                    style={{
                        minWidth: '200px',
                        maxWidth: '240px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text || '-'}
                </p>
            ),
        },
        {
            title: '服务到期时间',
            dataIndex: 'expire_time',
            key: 'expire_time',
            width: 160,
        },
        {
            title: '状态',
            dataIndex: 'is_active',
            key: 'is_active',
            width: 80,
            render: (text, record) => (
                <Switch
                    disabled={record.is_expired}
                    onClick={() => {
                        //todo determine how to notice user
                        if (record.is_expired) {
                            message.warn('当前账户已过期，无法激活');
                        }
                    }}
                    checked={text}
                    size="small"
                    onChange={(value: boolean) => {
                        changeLoadingState(true);
                        modifyAccount(record.id, { is_active: value })
                            .then(() => {
                                message.success('状态修改成功');
                                refreshAccountsByCurrentParameters(currentPage, searchParams);
                            })
                            .catch(dealRequestError)
                            .finally(() => {
                                changeLoadingState(false);
                            });
                    }}
                />
            ),
        },
        {
            title: '添加时间',
            dataIndex: 'date_joined',
            key: 'date_joined',
            width: 160,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 120,
            render: (text, record) => (
                <div className="opera" key={text}>
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setDisplayAddAccountFormModal({
                                editMode: 'edit',
                                targetAccount: record,
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={'确认要删除吗？'}
                        onConfirm={() => {
                            changeLoadingState(true);
                            deleteAccountById(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    refreshAccountsByCurrentParameters(currentPage, searchParams);
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    changeLoadingState(false);
                                });
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const paginations = {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        pageSize, //每页的条数
        current: currentPage, //当前页
        total: accountsWithLoadingState.accountsWithPagination.count, //总数据
        showSizeChanger: true,
        onChange: (page: number, page_size: number) => {
            setCurrentPage(page);
            setPageSize(page_size);
            refreshAccountsByCurrentParameters(page, searchParams, page_size);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current: number, onShowSizeChangedPageSize: number) => {
            setPageSize(onShowSizeChangedPageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: false, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: (total: any) => `共 ${total} 条`,
    };

    return (
        <div className="container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <Button
                        type="primary"
                        className="commonButton"
                        onClick={() => {
                            setDisplayAddAccountFormModal({
                                editMode: 'add',
                            });
                        }}
                    >
                        新增
                    </Button>
                    <div style={{ display: 'inline-block' }}>
                        <Upload
                            customRequest={options => {
                                options.onError?.(new Error('fake error'));
                            }}
                            onChange={info => {
                                const uploadFile = info.file as UploadFile;
                                if (uploadFile.status !== 'error') {
                                    return;
                                }
                                afterSelectExcel(uploadFile);
                            }}
                            multiple={false}
                            showUploadList={false}
                        >
                            <Button
                                type="default"
                                className="commonButton"
                                loading={loading}
                                style={{ marginLeft: '16px', color: color.themeColor }}
                            >
                                导入
                            </Button>
                        </Upload>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <SearchForm setSearchValue={refreshAccountsByCurrentParameters} setSearchParams={setSearchParams} />
                    <Button
                        type="default"
                        className="commonButton"
                        onClick={() => {
                            exportAccounts();
                        }}
                        style={{ marginLeft: '16px', color: color.themeColor }}
                    >
                        导出
                    </Button>
                </div>
            </div>
            <CommonTable
                dataSource={accountsWithLoadingState.accountsWithPagination.results}
                loading={accountsWithLoadingState.loading}
                columns={columns}
                rowKey={record => record.id}
                pagination={paginations}
            />
            <CommonModal
                ref={modalRef}
                onOk={() => {
                    accountEditModalRef.current?.validateFields().then(values => {
                        const { editMode, targetAccount } = displayAddAccountFormModal!;
                        // eslint-disable-next-line no-param-reassign
                        values.enterprise = extractAllIdsFromIdentifications([values.enterprise]);
                        const params = _.omitBy(
                            values,
                            (value?: any) =>
                                _.isUndefined(value) || _.isNull(value) || value === '' || _.trim(value) === ''
                        );
                        if (editMode === 'edit') {
                            modalRef.current?.startLoading();
                            const patchObject = reduceSamePropertiesFromObject(params, targetAccount!);
                            if (_.keysIn(patchObject).length === 0) {
                                message.success('编辑成功');
                                modalRef.current?.hiddenModal();
                                setDisplayAddAccountFormModal(undefined);
                                accountEditModalRef.current?.resetFields();
                                changeLoadingState(false);
                            } else {
                                modifyAccount(targetAccount!!.id, patchObject)
                                    .then(() => {
                                        message.success('编辑成功');
                                        refreshAccountsByCurrentParameters(currentPage, searchParams);
                                        modalRef.current?.hiddenModal();
                                        accountEditModalRef.current?.resetFields();
                                        setDisplayAddAccountFormModal(undefined);
                                        modalRef.current?.endLoading();
                                    })
                                    .catch(error => {
                                        dealRequestError(error);
                                        changeLoadingState(false);
                                    })
                                    .finally(() => {
                                        modalRef.current?.endLoading();
                                    });
                            }
                        } else {
                            modalRef.current?.startLoading();
                            createAccount(params)
                                .then(() => {
                                    message.success('添加成功');
                                    refreshAccountsByCurrentParameters(currentPage, searchParams);
                                    modalRef.current?.hiddenModal();
                                    accountEditModalRef.current?.resetFields();
                                    setDisplayAddAccountFormModal(undefined);
                                    modalRef.current?.endLoading();
                                })
                                .catch(dealRequestError)
                                .finally(() => {
                                    modalRef.current?.endLoading();
                                });
                        }
                    });
                }}
                title={`${displayAddAccountFormModal?.editMode === 'edit' ? '编辑' : '新增'}`}
            >
                <CompanyEditContent
                    ref={accountEditModalRef}
                    {...displayAddAccountFormModal}
                    roles={pagePreFetchData.roles}
                />
            </CommonModal>
            <ImportResultModal ref={importResultModalRef} />
        </div>
    );
};

export default observer(CompanyAccountView);
