import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import {
    createArea,
    deleteAreaById,
    fetchAllAreaTree,
    modifyArea,
    dealRequestError,
    appendChildNodeToParentNode,
    ChildNodeOperation,
    dropNodeFromTree,
    ROOT_AREA_ID,
    wrapTopArea,
    AreaChildrenType,
    ClientFieldGenerationStrategy,
    determineAreaChildrenType,
    Area,
} from '@greendev/common';
import { message } from 'antd';

type AreasWithLoadingState = {
    areas: Area.FrontEndProfile[];
    loading: boolean;
};

class AreaTreeState {
    private _areasWithLoadingState: AreasWithLoadingState = {
        areas: [],
        loading: true,
    };

    get areasWithLoadingState(): AreasWithLoadingState {
        return this._areasWithLoadingState;
    }

    /**
     * sometimes the children area will fill with local area(after create new area)
     * but all children areas under this area also include other areas.
     * so we tag this area with should reevaluate flag, then we can refresh newest data when user expand area tree.
     * @private stupid annotation~
     */
    private _areasShouldReevaluate: number[] = [];

    get areasShouldReevaluate(): number[] {
        return this._areasShouldReevaluate;
    }

    tagAreaWithShouldReevaluateFlag(parentAreaId: number) {
        if (this._areasShouldReevaluate.find(eachAreaId => eachAreaId === parentAreaId) === undefined) {
            this._areasShouldReevaluate.push(parentAreaId);
        }
    }

    clearShouldReevaluatedFlag(evaluatedAreaId: number) {
        this._areasShouldReevaluate = this._areasShouldReevaluate.filter(eachAreaId => eachAreaId !== evaluatedAreaId);
    }

    clearResource() {
        this._areasWithLoadingState = {
            areas: [],
            loading: true,
        };
        this._areasShouldReevaluate = [];
    }

    /**
     * you can call this function in different locations,
     * function internal will ensure the areas not will be init twice
     */
    initializeAreas(prefetchFirstLevelChildren?: boolean) {
        //represent areas not initialized yet
        if (this._areasWithLoadingState.areas.length === 0) {
            fetchAllAreaTree(ClientFieldGenerationStrategy.PURE_AREA_TREE).then(resultedAreas => {
                this._areasWithLoadingState = {
                    areas: resultedAreas as Area.FrontEndProfile[],
                    loading: false,
                };
                if (prefetchFirstLevelChildren) {
                    Promise.all(
                        (resultedAreas as Area.FrontEndProfile[]).map(record =>
                            this.requestChildAreaUnderTargetArea(record.id, record.children)
                        )
                    ).catch(dealRequestError);
                }
            });
        }
    }

    changeLoadingState = (loading: boolean) => {
        this._areasWithLoadingState = {
            ...this._areasWithLoadingState,
            loading,
        };
    };

    requestChildAreaUnderTargetArea = (targetAreaId: number, childrenArea?: Area.FrontEndProfile[]) =>
        new Promise<void>((resolve, reject) => {
            const childrenType = determineAreaChildrenType(childrenArea);
            if (childrenType === AreaChildrenType.NONEXEISTENT) {
                resolve();
                return;
            }
            if (childrenType === AreaChildrenType.FILLED) {
                //maybe this area is filled by local area creation
                const needEvaluate = this._areasShouldReevaluate.find(value => value === targetAreaId) !== undefined;
                if (!needEvaluate) {
                    resolve();
                    return;
                }
            }
            //request backend for children area
            this.changeLoadingState(true);
            //deep clone object ，modify child areas of target area
            const copyAreas = _.clone(this._areasWithLoadingState.areas);
            const treeWrapper = wrapTopArea(copyAreas);
            fetchAllAreaTree(ClientFieldGenerationStrategy.PURE_AREA_TREE, targetAreaId)
                .then(resultedAreas => {
                    //resulted area should merge with local area if it exist!!!
                    let mergedAreas = resultedAreas;
                    if (childrenArea === undefined) {
                        return; //robustness
                    }
                    if (childrenArea.length > 0) {
                        const areasNotExistInLocal = resultedAreas.filter(
                            eachResultedArea =>
                                childrenArea.find(
                                    eachExistLocalArea => eachExistLocalArea.id === eachResultedArea.id
                                ) === undefined
                        );
                        mergedAreas = childrenArea.concat(areasNotExistInLocal as Area.FrontEndProfile[]);
                        this.clearShouldReevaluatedFlag(targetAreaId);
                    }
                    appendChildNodeToParentNode(
                        treeWrapper,
                        targetAreaId,
                        mergedAreas as Area.FrontEndProfile[],
                        ChildNodeOperation.REPLACE
                    );
                    this._areasWithLoadingState = {
                        areas: treeWrapper.children!!,
                        loading: false,
                    };
                    resolve();
                })
                .catch(reject);
        });

    deleteChildArea = (targetArea: Area.FrontEndProfile) => {
        this.changeLoadingState(true);
        deleteAreaById(targetArea.id)
            .then(() => {
                message.success('删除成功');
                //deep clone object ，modify child areas of target area
                const copyAreas = _.clone(this._areasWithLoadingState.areas);
                const treeWrapper = wrapTopArea(copyAreas);
                dropNodeFromTree(treeWrapper, targetArea);
                this._areasWithLoadingState = {
                    areas: treeWrapper.children!!,
                    loading: false,
                };
            })
            .catch(error => {
                dealRequestError(error);
                this.changeLoadingState(false);
            });
    };

    createArea(name: string, parentAreaId?: number) {
        this.changeLoadingState(true);
        createArea(name, parentAreaId)
            .then(result => {
                message.success('创建成功');
                const copyAreas = _.clone(this._areasWithLoadingState.areas);
                const treeWrapper = wrapTopArea(copyAreas);
                const finalParentAreaId = parentAreaId === undefined ? ROOT_AREA_ID : parentAreaId;
                appendChildNodeToParentNode(treeWrapper, finalParentAreaId, [result], ChildNodeOperation.ADD);
                this._areasWithLoadingState = {
                    areas: treeWrapper.children!!,
                    loading: false,
                };
                //todo only should tag area when the area not ever have been evaluated .
                //but you know bro, the code without this logic control just working... so leave me alone ...
                this.tagAreaWithShouldReevaluateFlag(finalParentAreaId);
            })
            .catch(error => {
                dealRequestError(error);
                this.changeLoadingState(false);
            });
    }

    updateArea(name: string, targetAreaId: number, parentAreaId?: number) {
        this.changeLoadingState(true);
        modifyArea(name, targetAreaId)
            .then(result => {
                message.success('修改成功');
                const copyAreas = _.clone(this._areasWithLoadingState.areas);
                const treeWrapper = wrapTopArea(copyAreas);
                appendChildNodeToParentNode(
                    treeWrapper,
                    parentAreaId === undefined ? ROOT_AREA_ID : parentAreaId,
                    [result],
                    ChildNodeOperation.UPDATE
                );
                this._areasWithLoadingState = {
                    areas: treeWrapper.children!!,
                    loading: false,
                };
            })
            .catch(error => {
                dealRequestError(error);
                this.changeLoadingState(false);
            });
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default new AreaTreeState();
