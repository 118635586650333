import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input, Button, message } from 'antd';
import { Account, editUserInfo, getUserInfo, Login } from '@greendev/common';
import { userStore } from '@/stores/userStore';
import { GetMobileCodeButtonStyle } from '@/views/login/mobileLoginPart/mobileLoginPart';

const UserSettingView = () => {
    const [form] = Form.useForm();
    const [userInfo, setUserInfo] = useState<Login.UserInfoType>();
    const [newMobile, setNewMobile] = useState<string>('');

    const onFinished = (formData: { name: string; mobile: string; code: string }) => {
        const { name, mobile, code } = formData;
        if (name === userInfo?.name && mobile.toString() === userInfo.mobile!.toString()) {
            message.warn('请至少修改一项后再保存');
            return;
        }
        editUserInfo(userInfo?.id!, {
            name: name !== userInfo?.name ? name : undefined,
            mobile: mobile.toString() !== userInfo?.mobile?.toString() ? mobile.toString() : undefined,
            code: mobile.toString() !== userInfo?.mobile?.toString() ? code : undefined,
        })
            .then(res => {
                message.success('修改成功');
                setUserInfo(res);
                // 修改全局状态
                userStore.setUserInfo(res);
            })
            .catch(error => {
                message.error(error.response?.data?.mobile?.[0] || '修改失败，请稍后重试');
            });
        console.log(formData);
    };

    const formColProps = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    /**
     * 获取验证码
     */
    // 等待服务器响应中间状态
    const timeChange = useRef<number>();
    const [awaitCodeRes, setAwaitCodeRes] = useState<boolean>(false);
    const [time, setTime] = useState(60);
    const [isGetCode, setIsGetCode] = useState(true);
    useEffect(() => {
        clearInterval(timeChange.current);
        return () => clearInterval(timeChange.current);
    }, []);
    useEffect(() => {
        if (time > 0 && time < 60) {
            setIsGetCode(false);
        } else {
            clearInterval(timeChange.current);
            setTime(60);
            setIsGetCode(true);
        }
    }, [time]);
    const getMsgCode = async () => {
        if (!isGetCode) {
            // 倒计时未结束,不能重复点击
            return;
        }
        setAwaitCodeRes(true);
        await userStore
            .getMobileCodeFunc({ purpose: 'verify', mobile: newMobile })
            .then(res => {
                if (!res) {
                    message.error('手机号不可用');
                    setAwaitCodeRes(false);
                } else {
                    message.success('发送成功,请填写收到的验证码');
                    setAwaitCodeRes(false);
                    // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
                    timeChange.current = setInterval(
                        () =>
                            setTime(val => {
                                const cur = val - 1;
                                return cur;
                            }),
                        1000
                    ) as unknown as number;
                }
            })
            .catch(error => {
                message.error(error.response?.data?.info || '网络连接异常');
                setAwaitCodeRes(false);
            });
    };

    useEffect(() => {
        getUserInfo().then(res => {
            const currentUserInfo = res.results[0];
            setUserInfo(currentUserInfo);
            setNewMobile(currentUserInfo.mobile || '');
            form.setFieldsValue({
                name: currentUserInfo.name,
                mobile: currentUserInfo.mobile ? Number.parseInt(currentUserInfo.mobile, 10) : 0,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Form form={form} onFinish={onFinished} {...formColProps} style={{ maxWidth: '450px' }}>
                <Form.Item label="账号">{userInfo?.username || '-'}</Form.Item>
                <Form.Item label={userInfo?.category === Account.UserType.company ? '企业名称' : '公司名称'}>
                    {userInfo?.enterprise_name || '-'}
                </Form.Item>
                {userInfo?.category === Account.UserType.company ? (
                    <Form.Item label="行政区">{userInfo?.ad_region || '-'}</Form.Item>
                ) : null}
                {userInfo?.category === Account.UserType.company ? (
                    <Form.Item label="所属行业">{userInfo?.trade || '-'}</Form.Item>
                ) : null}
                <Form.Item label={userInfo?.category === Account.UserType.company ? '企业地址' : '公司地址'}>
                    {userInfo?.enterprise_location || '-'}
                </Form.Item>
                <Form.Item label="账号到期时间">{userInfo?.expire_time || '-'}</Form.Item>
                {userInfo?.category === Account.UserType.agent ? (
                    <Form.Item label="子账号限制">{userInfo?.son_count}</Form.Item>
                ) : null}
                <Form.Item label="联系人" name="name" rules={[{ required: true, message: '联系人不能为空' }]}>
                    <Input maxLength={10} />
                </Form.Item>
                <Form.Item
                    label="联系电话"
                    name="mobile"
                    rules={[
                        { required: true, message: '手机号不能为空' },
                        {
                            pattern: /^1[3-9]\d{9}$/g,
                            message: '手机号格式不正确',
                        },
                    ]}
                >
                    <Input
                        type="text"
                        maxLength={11}
                        value={newMobile}
                        onChange={val => {
                            setNewMobile(val.target.value);
                        }}
                    />
                </Form.Item>
                {newMobile !== userInfo?.mobile?.toString() && (
                    <Form.Item
                        label="验证码"
                        name="code"
                        rules={[{ required: true, message: '请输入验证码' }]}
                        extra={
                            <Button
                                onClick={isGetCode ? () => getMsgCode() : undefined}
                                className="login-getcode-button"
                                style={{
                                    color: isGetCode ? '#000' : '#464646',
                                    ...GetMobileCodeButtonStyle,
                                    right: '2px',
                                    top: '2px',
                                }}
                                disabled={awaitCodeRes}
                            >
                                {isGetCode ? '获取验证码' : `重新发送(${time}s)`}
                            </Button>
                        }
                    >
                        <Input type={'tel'} maxLength={4} />
                    </Form.Item>
                )}
                <Form.Item wrapperCol={{ ...formColProps.wrapperCol, offset: 8 }}>
                    <Button htmlType="submit" type="primary" disabled={!userInfo?.id}>
                        保存
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default observer(UserSettingView);
