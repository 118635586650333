import React from 'react';
import { Row, Col, Tag } from 'antd';
import { MyIcon } from '@/utils/iconfont';
import GlobalStyle from '@/styles';
import { DeviceStatus } from '@/config';
import { Device } from '@greendev/common';

const { color } = GlobalStyle;

const GatewayDetail: React.FC<{ detail: Device.DeviceDetailType; childDetail: Function }> = ({
    detail,
    childDetail,
}) => {
    const { status } = detail;
    let tagColor: string;
    switch (status) {
        case DeviceStatus.inactive:
            tagColor = color.themeRed;
            break;
        case DeviceStatus.online:
            tagColor = color.themeColor;
            break;
        case DeviceStatus.offline:
            tagColor = color.themeYellow;
            break;
        default:
            tagColor = color.themeBlue;
            break;
    }

    return (
        <>
            <div className="device-detail-title">
                {detail.name} <Tag color={tagColor}>{detail.status_name}</Tag>
            </div>
            <Row gutter={[24, 24]} className="device-detail-content">
                <Col span={8}>设备ID: {detail.qr_code || '-'}</Col>
                <Col span={8}>设备型号: {detail.category_id || '-'}</Col>
                <Col span={8}>所属监测点: {detail.monitoring_points_name || '-'}</Col>
                <Col span={8}>上次采集: {detail.last_time || '-'}</Col>
                <Col span={8}>所属企业: {detail.enterprise_name || '-'}</Col>
                <Col span={8}>固件版本: {detail.version || '-'}</Col>
                <Col span={8}>备注: {detail.remark || '-'}</Col>
            </Row>
            {detail.history?.length > 0 ? (
                <>
                    <div className="device-detail-title">更换历史</div>
                    <div className="device-detail-history">
                        {detail.history.map(item => {
                            const time = item.update_time.split('T');
                            return (
                                <div className="device-detail-history-item" key={item.id}>
                                    <b style={{ marginRight: '24px', color: color.contentColor }}>{item.qr_code}</b>
                                    {`${time[0]} ${time[1].split('.')[0]}`}
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}
            <div className="device-detail-title">连接设备</div>
            {detail.device_set && detail.device_set.length > 0 ? (
                detail.device_set.map(val => {
                    const { status: statusChild } = val;
                    let tagColorChild: string;
                    switch (statusChild) {
                        case '未激活':
                            tagColorChild = color.themeRed;
                            break;
                        case '在线':
                            tagColorChild = color.themeColor;
                            break;
                        case '离线':
                            tagColorChild = color.themeYellow;
                            break;
                        default:
                            tagColorChild = color.themeBlue;
                            break;
                    }
                    return (
                        <div
                            key={val.id}
                            className="device-detail-gateway-children"
                            onClick={() => {
                                childDetail(val.id);
                            }}
                        >
                            <MyIcon type="icon_tree_transformer" style={{ fontSize: '32px' }} />
                            <span className="device-detail-gateway-children-name">{val.name}</span>
                            <Tag color={tagColorChild}>{val.status}</Tag>
                        </div>
                    );
                })
            ) : (
                <div>暂无设备</div>
            )}
        </>
    );
};

export default GatewayDetail;
