import React from 'react';
import { ConfigProvider, message } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import RoutesContainer from '@/router/index';
import { RootStoreContext, stores } from '@/stores';
import zhCN from 'antd/lib/locale/zh_CN'; //antd中文转换
import { NavigateSetter } from '@/utils/HistoryWorkAround';
import AmapHelper from '@/utils/amap';
import { aMapProxyUrl, baseUrl, isDev, mockBaseUrl } from '@/config';
import { inject } from '@greendev/common';
import { userStore } from '@/stores/userStore';
import { useStorage } from '@/utils/storage';

inject({
    whenTokenExpired: () => userStore.logout(),
    message: message.error,
    mockBaseUrl,
    baseUrl,
    tokenQueryFunction: () => useStorage.get('ACCESS_TOKEN_KEY'),
    debugMode: isDev,
});

// 主题配置
ConfigProvider.config({
    theme: {
        // 主题色
        primaryColor: '#42C3AA',
        // 错误
        errorColor: '#FB887F',
        // 警告
        warningColor: '#FDA82E',
        // 成功
        // successColor: '#52C41A',
        // 信息
        // infoColor: '#1890FF',
    },
});

//inject amap proxy configuration and init it
AmapHelper.injectProxyAddress(aMapProxyUrl, amapLoadError => {
    message.error(`Amap : ${amapLoadError}`);
});

const App: React.FC = () => (
    <ConfigProvider locale={zhCN}>
        <BrowserRouter>
            <NavigateSetter />
            <RootStoreContext.Provider value={stores}>
                <RoutesContainer />
            </RootStoreContext.Provider>
        </BrowserRouter>
    </ConfigProvider>
);

export default App;
