import React, { useState, useEffect, useMemo, InputHTMLAttributes, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { map, size, omit, clone, every, includes, filter } from 'lodash';
import { Table, Input, Form, Button, Checkbox, message, Tabs } from 'antd';
import { editRole, addRole, getRoleInfo, getPermission, Role } from '@greendev/common';

const { TabPane } = Tabs;
const layout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 20,
    },
};
const inputLayout = {
    wrapperCol: {
        span: 8,
    },
};
const buttonLayout = {
    wrapperCol: {
        offset: 2,
        span: 16,
    },
};

const menusID = [0, 3, 4, 5];

export interface RolePermissionInterface extends Omit<API.Menu, 'permission'> {
    is_active?: boolean;
    list?: RolePermissionInterface[];
    key?: number;
}

const handlePermission = (data: API.Menu[]): RolePermissionInterface[] => {
    const result = map(data, item => {
        let children: RolePermissionInterface[] = [];
        // eslint-disable-next-line no-param-reassign
        if (item.permission && item.permission.length > 0 && item.permission[0].type === 2) item.type = 1;
        if (item.permission && item.permission.length > 0 && menusID.includes(item.type)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            children = handlePermission(item.permission);
        }
        if (menusID.includes(item.type)) {
            return { ...omit(item, 'permission'), is_active: false, list: children, key: item.id };
        }
        return { ...item, is_active: false, list: children, key: item.id };
    });
    return result;
};

const handlePermissionSelect = (data: RolePermissionInterface[], ids: number[]) => {
    const result: RolePermissionInterface[] = map(data, item => {
        let list: RolePermissionInterface[] = [];
        if (item.permission && size(item.permission) && menusID.includes(item.type)) {
            list = handlePermissionSelect(item.permission, ids);
        }
        const permissions = map(item.permission, per => {
            const curPer = per;
            curPer.is_active = includes(ids, per.id);
            return curPer;
        });
        if (menusID.includes(item.type)) {
            return {
                ...omit(item, 'permission'),
                list,
                permissions,
                is_active: includes(ids, item.id),
                key: item.id,
            };
        }
        return {
            ...item,
            list,
            permissions,
            is_active: includes(ids, item.id),
            key: item.id,
        };
    });
    return result;
};

/** 权限配置 */
const RightsProfile: React.FC<{
    disabled: boolean;
    originData: RolePermissionInterface[];
    setData: (data: RolePermissionInterface[]) => void;
    originButtonPermission: RolePermissionInterface[];
    setButtonPermission: React.Dispatch<React.SetStateAction<RolePermissionInterface[]>>;
    type: 'App' | 'Web';
}> = ({ disabled, originData, setData, originButtonPermission, setButtonPermission, type }) => {
    const data = originData;
    const buttonPermission = originButtonPermission;
    const columns = [
        {
            title: '类型',
            dataIndex: 'name',
            width: 150,
            render: (record: RolePermissionInterface[], item: RolePermissionInterface, idx: number) => (
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            disabled={disabled}
                            checked={item.is_active}
                            onClick={value => {
                                const currentTarget = value.target as InputHTMLAttributes<boolean>;
                                const checkboxValue = currentTarget.checked;
                                data[idx].is_active = checkboxValue;
                                if (!size(item.list)) {
                                    const resultData = checkboxValue
                                        ? [...buttonPermission, { ...item }]
                                        : filter(buttonPermission, per => per.id !== item.id);
                                    setButtonPermission(resultData);
                                } else {
                                    let resultData = checkboxValue
                                        ? [...buttonPermission, { ...item }]
                                        : filter(buttonPermission, per => per.id !== item.id);
                                    if (!checkboxValue) {
                                        map(item.list, values => {
                                            resultData = filter(resultData, per => per.id !== values.id);
                                        });
                                    }
                                    setButtonPermission(resultData);
                                }
                                if (!checkboxValue) {
                                    const list = map(data[idx].list, _item => ({ ..._item, is_active: false }));
                                    data[idx].list = list;
                                }
                                setData(clone(data));
                            }}
                        >
                            {record}
                        </Checkbox>
                    </div>
                </div>
            ),
        },
        {
            title: '权限列表',
            dataIndex: 'list',
            render: (record: RolePermissionInterface[], itemData: RolePermissionInterface[], idx: number) => {
                if (size(record)) {
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {map(record, (item, childIdx) => (
                                <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: '2px 0' }}>
                                    <Checkbox
                                        disabled={disabled}
                                        checked={item.is_active}
                                        onClick={value => {
                                            const currentTarget = value.target as InputHTMLAttributes<boolean>;
                                            const checkboxValue = currentTarget.checked;
                                            data[idx].list![childIdx].is_active = checkboxValue;
                                            let permissions = checkboxValue
                                                ? [...buttonPermission, item]
                                                : filter(buttonPermission, per => per.id !== item.id);
                                            // setButtonPermission(
                                            //     value
                                            //         ? [...buttonPermission, item]
                                            //         : filter(buttonPermission, per => per.id !== item.id),
                                            // );
                                            if (every(data[idx].list, { is_active: false })) {
                                                permissions = filter(permissions, per => per.id !== data[idx].id);
                                                data[idx].is_active = false;
                                            } else {
                                                data[idx].is_active = true;
                                            }
                                            setButtonPermission(permissions);
                                            setData(clone(data));
                                        }}
                                    >
                                        {item.name}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    );
                }
                return '-';
            },
        },
        {
            title: '全选',
            dataIndex: 'list',
            width: 90,
            render: (record: RolePermissionInterface[], item: RolePermissionInterface, idx: number) => {
                if (size(record)) {
                    const selected = record.filter(isActive => isActive.is_active === true); //是否全选状态
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: '2px 0' }}>
                                <Checkbox
                                    disabled={disabled}
                                    checked={
                                        item.is_active && record && record.length > 0
                                            ? record.length === selected.length
                                            : false
                                    }
                                    indeterminate={
                                        record && record.length === selected.length ? false : selected.length !== 0
                                    }
                                    onClick={value => {
                                        const currentTarget = value.target as InputHTMLAttributes<boolean>;
                                        const checkboxValue = currentTarget.checked;
                                        // debugger;
                                        //remove old permission first
                                        let buttonPermissionCopy = JSON.parse(JSON.stringify(buttonPermission));
                                        const recordIds = record.map(eachRecord => eachRecord.id);
                                        buttonPermissionCopy = buttonPermissionCopy.filter(
                                            (eachOldPermission: RolePermissionInterface) =>
                                                !recordIds.includes(eachOldPermission.id)
                                        );
                                        if (checkboxValue) {
                                            //if checked , add permission to button list
                                            setButtonPermission(buttonPermissionCopy.concat(record));
                                        } else {
                                            setButtonPermission(buttonPermissionCopy);
                                        }
                                        map(record, (_, childIdx) => {
                                            data[idx].list![childIdx].is_active = checkboxValue;
                                            //let permissions = value
                                            //    ? [...buttonPermission, item]
                                            //    : filter(buttonPermission, per => per.id !== item.id);
                                            if (every(data[idx].list, { is_active: false })) {
                                                // permissions = filter(buttonPermission, per => per.id !== data[idx].id);
                                                data[idx].is_active = false;
                                            } else {
                                                data[idx].is_active = true;
                                            }
                                            // setButtonPermission(
                                            //     value
                                            //     ? [...buttonPermission, ...permissions]
                                            //     : filter(buttonPermission, (per,index) => per.id !== permissions[index].id),
                                            // );
                                            setData(clone(data));
                                        });
                                    }}
                                >
                                    全选
                                </Checkbox>
                            </div>
                        </div>
                    );
                }
                return '-';
            },
        },
    ];

    const buttonColumns = [
        {
            title: '页面',
            dataIndex: 'name',
            width: 150,
        },
        {
            title: '操作权限',
            dataIndex: 'permission',
            render: (record: RolePermissionInterface[], itemData: RolePermissionInterface, idx: number) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {map(record, (item, childIdx) => (
                        <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: '2px 0' }}>
                            <Checkbox
                                disabled={disabled}
                                checked={item.is_active}
                                onClick={value => {
                                    const currentTarget = value.target as InputHTMLAttributes<boolean>;
                                    const checkboxValue = currentTarget.checked;
                                    buttonPermission[idx].permission[childIdx].is_active = checkboxValue;
                                    setButtonPermission(clone(buttonPermission));
                                }}
                            >
                                {item.name}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: '全选',
            dataIndex: 'permission',
            width: 90,
            render: (record: RolePermissionInterface[], itemData: RolePermissionInterface, idx: number) => {
                if (size(record)) {
                    const selected = record.filter(isActive => isActive.is_active === true); //是否全选状态
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div
                                key={itemData.id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '2px 0',
                                    justifyContent: 'center',
                                }}
                            >
                                <Checkbox
                                    disabled={disabled}
                                    checked={!!(record && record[0].is_active)}
                                    indeterminate={
                                        record && record.length === selected.length ? false : selected.length !== 0
                                    }
                                    onClick={value => {
                                        const currentTarget = value.target as InputHTMLAttributes<boolean>;
                                        const checkboxValue = currentTarget.checked;
                                        map(record, (item, childIdx) => {
                                            buttonPermission[idx].permission[childIdx].is_active = checkboxValue;
                                            setButtonPermission(clone(buttonPermission));
                                        });
                                    }}
                                >
                                    全选
                                </Checkbox>
                            </div>
                        </div>
                    );
                }
                return '-';
            },
        },
    ];

    useEffect(() => {
        // buttonPermission.filter(val=> val.list && val.list?.length === 0);
        // console.log('buttonPermission: ', buttonPermission);
        if (buttonPermission.find(val => val.list && val.list.length > 0)) {
            setButtonPermission(currentVal => currentVal.filter(val => val.list && val.list?.length === 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonPermission]);
    return (
        <div>
            <div style={{ fontSize: '16px', marginTop: '5px' }}>{type}权限</div>
            <Table style={{ marginTop: '20px' }} columns={columns as any} pagination={false} dataSource={data} />
            <div style={{ padding: '20px 0', fontSize: '16px' }}>操作权限</div>
            <Table columns={buttonColumns} pagination={false} dataSource={buttonPermission} />
        </div>
    );
};

const EditRole: React.FC<{
    setDataSource: any;
    type: string;
    userId: number;
    showLoading: () => void;
    hiddenLoading: () => void;
    onOk: () => void;
    refreshList: () => void;
}> = ({ setDataSource, type, userId, ...otherProps }) => {
    const [permission, setPermission] = useState<RolePermissionInterface[]>([]);
    const [appPermission, setAppPermission] = useState<RolePermissionInterface[]>([]);
    const [buttonPermission, setButtonPermission] = useState<RolePermissionInterface[]>([]);
    const [buttonAppPermission, setButtonAppPermission] = useState<RolePermissionInterface[]>([]);
    const [form] = Form.useForm();
    const oldRoleData = useRef<Role.RoleParams>({});
    const { showLoading, hiddenLoading, onOk, refreshList } = otherProps;
    // const [clientType, setClientType] = useState('2');
    useEffect(() => {
        showLoading();
        setButtonPermission([]);
        setButtonAppPermission([]);
        form.setFieldsValue({ name: '', desc: '' });
        getPermission()
            .then(result => {
                const { permission: currentPermisson, app_permission: currentAppPermission } = result;
                // setPermission(handlePermission(result.data.filter(item => item.client_type === clientType)));
                setPermission(handlePermission(currentPermisson));
                setAppPermission(handlePermission(currentAppPermission));
                hiddenLoading();
                if (type !== 'add') {
                    getRoleInfo(userId)
                        .then(permissionResult => {
                            oldRoleData.current = permissionResult;
                            const ids = permissionResult.permission;
                            const selectPermissions = clone(handlePermissionSelect(result.permission, ids));
                            const selectAppPermissions = clone(handlePermissionSelect(result.app_permission, ids));
                            const selectButtonPermission: RolePermissionInterface[] = [];
                            const selectButtonAppPermission: RolePermissionInterface[] = [];
                            const handleButtonPermission = (
                                data: RolePermissionInterface,
                                permissionType: 'app' | 'web'
                            ) => {
                                map(data, item => {
                                    if (size(item.list)) {
                                        handleButtonPermission(item.list, permissionType);
                                    } else if (item.is_active) {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                        permissionType === 'web'
                                            ? selectButtonPermission.push(item)
                                            : selectButtonAppPermission.push(item);
                                    }
                                });
                            };
                            handleButtonPermission(selectPermissions, 'web');
                            handleButtonPermission(selectAppPermissions, 'app');
                            form.setFieldsValue({ name: permissionResult.name, desc: permissionResult.desc });
                            // setPermission(selectPermissions.filter(item => item.client_type === clientType));
                            setPermission(selectPermissions);
                            setAppPermission(selectAppPermissions);
                            // console.log('selectButtonPermission ', selectButtonPermission);
                            setButtonPermission(selectButtonPermission);
                            setButtonAppPermission(selectButtonAppPermission);
                            hiddenLoading();
                        })
                        .catch();
                }
            })
            .catch();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, userId]);
    const onFinish = (values: Role.AddRoleParams) => {
        // console.log('values', values);
        // console.log('permission:', permission);
        showLoading();
        const permissions: number[] = [];
        /** 写入web权限 */
        map(permission, item => {
            if (item.is_active) {
                permissions.push(item.id);
            }
        });
        map(buttonPermission, item => {
            permissions.push(item.id);
            map(item.permission, pre => {
                if (pre.is_active) permissions.push(pre.id);
            });
        });
        /** 写入app权限 */
        map(appPermission, item => {
            if (item.is_active) {
                permissions.push(item.id);
            }
        });
        map(buttonAppPermission, item => {
            permissions.push(item.id);
            map(item.permission, pre => {
                if (pre.is_active) permissions.push(pre.id);
            });
        });
        // const { name, desc } = values;
        const name = values.name === oldRoleData.current.name ? undefined : values.name;
        const desc = values.desc === oldRoleData.current.desc ? undefined : values.desc;
        const requestPermissions = Array.from(new Set(permissions));
        // 如果未作出更改，提示
        if (requestPermissions.length === 0) {
            message.warning('请至少选择一项权限！');
            hiddenLoading();
            return;
        }
        // 权限排序
        requestPermissions.sort((a, b) => a - b);
        if (type === 'add') {
            const { name: addName } = values;
            addRole({ name: addName, desc, permission: requestPermissions })
                .then(() => {
                    hiddenLoading();
                    // setDataSource(dataSource => [result.data, ...dataSource]);
                    onOk();
                    refreshList();
                    message.success('添加成功');
                })
                .catch(() => {
                    message.error('添加失败');
                    hiddenLoading();
                });
        }
        if (type === 'edit') {
            editRole(userId, { name, desc, permission: requestPermissions })
                .then(() => {
                    hiddenLoading();
                    message.success('修改成功');
                    onOk();
                    refreshList();
                })
                .catch(() => {
                    message.error('修改失败');
                    hiddenLoading();
                });
        }
    };
    const disabled = useMemo(() => type === 'ready', [type]);
    // const callback = key => {
    //     setClientType(Number(key));
    // };
    return (
        <Form {...layout} form={form} name="basic" onFinish={onFinish} scrollToFirstError>
            <Form.Item
                {...inputLayout}
                label="角色名称"
                name="name"
                rules={[
                    {
                        required: true,
                        message: '请输入角色名称!',
                    },
                ]}
            >
                <Input disabled={disabled} />
            </Form.Item>

            <Form.Item
                {...inputLayout}
                label="角色描述"
                name="desc"
                rules={[
                    {
                        required: true,
                        message: '请输入角色描述!',
                    },
                ]}
            >
                <Input.TextArea maxLength={50} showCount disabled={disabled} autoSize={{ minRows: 4, maxRows: 6 }} />
            </Form.Item>
            <Form.Item label="权限配置" name="permission">
                <Tabs defaultActiveKey="1" onChange={() => {}} className="roleStyle">
                    <TabPane tab="Web权限" key="1">
                        <RightsProfile
                            originData={permission}
                            disabled={disabled}
                            originButtonPermission={buttonPermission}
                            setButtonPermission={setButtonPermission}
                            setData={setPermission}
                            type={'Web'}
                        />
                    </TabPane>
                    <TabPane tab="APP权限" key="2">
                        <RightsProfile
                            originData={appPermission}
                            disabled={disabled}
                            originButtonPermission={buttonAppPermission}
                            setButtonPermission={setButtonAppPermission}
                            setData={setAppPermission}
                            type={'App'}
                        />
                    </TabPane>
                </Tabs>
                {/* <RightsProfile
                    originData={permission}
                    disabled={disabled}
                    originButtonPermission={buttonPermission}
                    setButtonPermission={setButtonPermission}
                    setData={setPermission}
                /> */}
            </Form.Item>
            {!disabled && (
                <Form.Item {...buttonLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            )}
        </Form>
    );
};

export default observer(EditRole);
