import { dealRequestError, request } from "../../utils/request";
import _ from 'lodash';
import { generateIdentifiedByType, recoverInformationFromIdentify, TreeItemType } from "../../utils/treeSelectEnhanceAbility";
/* eslint-disable no-param-reassign */
export function generateClientSideFieldForCompanyObject(backendObject) {
  backendObject.isLeaf = true;
  backendObject.uniqueId = generateIdentifiedByType(TreeItemType.COMPANY, backendObject.id);
  //from [{id:1,name:"黑龙江"}] to "四川>成都市>高新区"
  backendObject.ad_region_name = backendObject.ad_region_list.map(eachRegion => eachRegion.name).join('>');
  return backendObject;
}
export function fetchAllCompany(params = {}) {
  return new Promise(resolve => {
    request({
      url: '/v1/enter/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(result => {
      if (params.get_all === true) {
        resolve(result.map(eachCompany => generateClientSideFieldForCompanyObject(eachCompany)));
      } else {
        result.results = result.results.map(eachCompany => generateClientSideFieldForCompanyObject(eachCompany));
        resolve(result);
      }
    }).catch(dealRequestError);
  });
}

/* eslint-enable no-param-reassign */

/**
 * when you play with treeSelect component which combined area object and company object
 * you will need this function to query final selected companies by selected identifies
 * see {@link TreeItemUniqueValue} !!!
 * @param identifies you must ensure each identification is valid!!!
 */
export function fetchAllCompanyIdByTreeSelectIdentifies(identifies) {
  return new Promise((resolve, reject) => {
    Promise.all(identifies.map(eachIdentify => {
      const typeWithId = recoverInformationFromIdentify(eachIdentify);
      switch (typeWithId.type) {
        case TreeItemType.AREA:
          return new Promise((queryCompanyResolved, queryCompanyReject) => {
            fetchAllCompany({
              ad_region: typeWithId.originObjectId,
              get_all: true
            })
            // fetchAllCompanyUnderArea(typeWithId.originObjectId)
            .then(result => {
              queryCompanyResolved(result.map(eachCompanyUnderThisArea => eachCompanyUnderThisArea.id));
            }).catch(queryCompanyReject);
          });
        case TreeItemType.COMPANY:
          return Promise.resolve([typeWithId.originObjectId]);
        default:
          return Promise.resolve([]);
      }
    })).then(allResult => {
      const allCompanyIds = allResult.flat(1);
      resolve(allCompanyIds);
    }).catch(reject);
  });
}

//删除账号
export function deleteCompanyById(id) {
  return request({
    url: `/v1/enter/${id}/`,
    method: 'DELETE'
  });
}
export function queryCompanyById(companyId) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/enter/${companyId}/`,
      method: 'GET'
    }).then(value => {
      resolve(generateClientSideFieldForCompanyObject(value));
    }).catch(reject);
  });
}

/**
 * 编辑企业
 * @param companyId the company you want to edit
 * @param params see {@link Company.CreateOrUpdateCompanyParameter}
 */
export function editCompanyReal(companyId, params) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/enter/${companyId}/`,
      method: 'PATCH',
      data: _.omitBy(params, _.isUndefined)
    }).then(value => {
      resolve(generateClientSideFieldForCompanyObject(value));
    }).catch(reject);
  });
}

/**
 * 添加企业
 * @param params see {@link Company.CreateOrUpdateCompanyParameter}
 */
export function addCompany(params) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/enter/',
      method: 'POST',
      data: _.omitBy(params, _.isUndefined)
    }).then(value => {
      resolve(generateClientSideFieldForCompanyObject(value));
    }).catch(reject);
  });
}

/**
 * 批量导入企业
 */
export function importCompanies(companies) {
  return request({
    url: '/v1/enter/batch_import/',
    method: 'POST',
    data: companies
  });
}

// 企业状态详情
export function getEnterDetail(params) {
  return request({
    url: '/v1/visual/index/enter/detail/',
    method: 'GET',
    params
  });
}