import _, { keysIn } from 'lodash';

/* eslint-disable no-param-reassign */

export const arrayCompareFunction = (originalObjectValue, baseMarkObjectType) => {
  const firstArray = originalObjectValue.map(value => value.toString());
  const secondArray = baseMarkObjectType.map(value => value.toString());
  return _.isEqual(firstArray.sort(), secondArray.sort());
};
/**
 * this function will not modify the original object , so use this function is safe!!!
 * @param originObject the object you want to reduce
 * @param baseMark originObject will compare with this object
 * @param compareFunctions you can custom compare function to compare two field
 * @param disableUndefinedPurge if preserve the undefined properties in originObject
 */
export function reduceSamePropertiesFromObject(originObject, baseMark, compareFunctions, disableUndefinedPurge) {
  let resultObject = _.cloneDeep(originObject);
  if (disableUndefinedPurge !== true) {
    resultObject = _.omitBy(resultObject, _.isUndefined);
  }
  for (const eachKeyInOriginalObject of keysIn(resultObject)) {
    //if there have some customize compare function , use this !
    if (compareFunctions !== undefined) {
      const targetCompareFunction = compareFunctions.find(eachCompareMetaData => {
        const currentKeyHaveCustomCompareFunction = eachCompareMetaData.keyInOriginalObject === eachKeyInOriginalObject;
        const baselineObjectHaveValueToCompare = baseMark[eachCompareMetaData.keyInBaseMarkObject] !== undefined;
        return currentKeyHaveCustomCompareFunction && baselineObjectHaveValueToCompare;
      });
      if (targetCompareFunction !== undefined) {
        //use compare function to determine if two value is same
        if (targetCompareFunction.compareFunction(resultObject[targetCompareFunction.keyInOriginalObject], baseMark[targetCompareFunction.keyInBaseMarkObject])) {
          delete resultObject[eachKeyInOriginalObject];
        }
        // eslint-disable-next-line no-continue
        continue;
      }
    }
    //if not have corresponding compare function or user not pass any compare function , use default compare method
    if (resultObject[eachKeyInOriginalObject] === baseMark[eachKeyInOriginalObject]) {
      //this properties is same, so delete it from origin object
      delete resultObject[eachKeyInOriginalObject];
    }
  }
  return resultObject;
}

/* eslint-enable no-param-reassign */