import { dealRequestError, request } from "../../utils/request";
import _ from 'lodash';
import { MonitoringPointKind } from "./enum";
export function fetchAllMonitoring(params) {
  return new Promise(resolve => {
    request({
      url: '/v1/enter/point/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(resolve).catch(dealRequestError);
  });
}

//删除监测点
export function deleteMonitoringById(id, name) {
  return request({
    url: `/v1/enter/point/${id}/`,
    method: 'DELETE',
    params: {
      name
    }
  });
}

//获取监测点详情
export function queryMonitoringById(id) {
  return request({
    url: `/v1/enter/point/${id}/`,
    method: 'GET'
  });
}

//添加监测点
export function addMonitoring(params) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/enter/point/',
      method: 'POST',
      data: params
    }).then(monitoring => resolve(monitoring)).catch(reject);
  });
}

//修改监测点
export function editMonitoring(id, params) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/enter/point/${id}/`,
      method: 'PATCH',
      data: params
    }).then(result => resolve(result)).catch(reject);
  });
}

// 查看数据
export function getCurrentData(id, params) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/enter/point//${id}/current_data/`,
      method: 'GET',
      params
    }).then(result => resolve(result)).catch(reject);
  });
}

/**
 * @description APP - 监测点查看电流时序数据
 */
export function getPointCurrentData(params) {
  return new Promise((resolve, reject) => {
    request({
      url: `/v1/visual/index/point/detail/`,
      method: 'GET',
      params
    }).then(result => resolve(result)).catch(reject);
  });
}

// 监测点类型
export const kindData = [{
  id: MonitoringPointKind.Produce,
  name: '生产'
}, {
  id: MonitoringPointKind.pollutionControl,
  name: '治污'
}];

/**
 * 批量导入监测点
 */
export function importMonitoringPoints(companies) {
  return request({
    url: '/v1/enter/point/batch_import/',
    method: 'POST',
    data: companies
  });
}

/**
 * @description APP - 监测点开启情况列表
 */
export function getCompanyMonitoringList(params) {
  const options = {
    url: `/v1/visual/index/point/static/`,
    method: 'get',
    params
  };
  return request(options);
}