import { Form, FormInstance, Input, Select } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { generateUploadFileObjectByUrl } from '@/stores/fileUploaderState';
import { fileUploaderPurposeKeyMapping, useFileUploaderState } from '@/components/fileUploader/manage';
import FileUploader from '@/components/fileUploader/FileUploader';
import { AppBannerLocation, appBannerLocationStaticData, AppManagement } from '@greendev/common';

export type CreateOrUpdateBannerContentProps = {
    originalBanner?: AppManagement.Banner;
};

//新增、编辑弹框内容显示
const CreateOrUpdateBannerContent = forwardRef<FormInstance<any>, CreateOrUpdateBannerContentProps>(
    (props: CreateOrUpdateBannerContentProps, ref) => {
        const { originalBanner } = props;

        const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

        const uploadBannerState = useFileUploaderState(fileUploaderPurposeKeyMapping.uploadBanner);

        useEffect(() => {
            if (originalBanner !== undefined) {
                if (uploadBannerState !== undefined) {
                    uploadBannerState.backfillImages([generateUploadFileObjectByUrl(originalBanner.image_url)]);
                }

                setInitialValues({
                    'title': originalBanner.title,
                    'jump-link': originalBanner.site === null ? undefined : originalBanner.site,
                    'location': originalBanner.seat.toString(10),
                });
            }
            return () => {
                if (uploadBannerState !== undefined) {
                    uploadBannerState.clearSelectedFiles();
                }
            };
        }, [originalBanner, uploadBannerState]);

        return (
            <Form
                labelCol={{ span: 6 }}
                labelAlign={'right'}
                wrapperCol={{ span: 18 }}
                ref={ref}
                initialValues={initialValues}
                key={initialValues?.title}
            >
                <Form.Item
                    name="title"
                    label="标题"
                    rules={[
                        { required: true, message: '请输入标题' },
                        {
                            pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,25}$/,
                            message: '帐号只能包含1-25位的汉字、字母、数字、下划线!',
                        },
                    ]}
                >
                    <Input placeholder="请输入标题" />
                </Form.Item>
                <Form.Item name="location" label="展示位置" rules={[{ required: true, message: '请选择展示位置' }]}>
                    <Select<AppBannerLocation> placeholder="请选择展示位置" allowClear>
                        {appBannerLocationStaticData.map(eachLocation => (
                            <Select.Option key={eachLocation.value}>{eachLocation.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="jump-link"
                    label="跳转连接"
                    rules={[
                        {
                            type: 'url',
                            len: 100,
                        },
                    ]}
                >
                    <Input placeholder="请输入跳转连接" />
                </Form.Item>
                <Form.Item
                    name="picture"
                    label="图片"
                    rules={[
                        {
                            required: true,
                            message: '请选择图片',
                            validator: () => {
                                if (uploadBannerState === undefined) {
                                    return Promise.reject(); //robustness
                                }
                                return uploadBannerState.fileList.length > 0
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Should accept agreement'));
                            },
                        },
                    ]}
                >
                    {uploadBannerState && <FileUploader maxImageCount={1} fileUploaderState={uploadBannerState} />}
                </Form.Item>
            </Form>
        );
    }
);

export default CreateOrUpdateBannerContent;
