import { request } from "../../utils/request";
import _ from 'lodash';
export function fetchAllRoles(params = {}) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/oauth/role/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(result => {
      resolve(result);
    }).catch(reject);
  });
}

/** 获取权限列表 */
export function getPermission() {
  const options = {
    url: '/v1/oauth/permission/',
    method: 'GET'
  };
  return request(options);
}
export function getMobilePermission() {
  const options = {
    url: '/v1/oauth/permission/',
    method: 'GET'
  };
  return request(options);
}

/** 获取角色列表 - 分页 */
export function getRoleList(params) {
  const options = {
    url: '/v1/oauth/role/',
    method: 'GET',
    params
  };
  return request(options);
}

/** 添加角色 */
export function addRole(data) {
  const options = {
    url: '/v1/oauth/role/',
    method: 'post',
    data
  };
  return request(options);
}

/** 获取角色信息 */
export function getRoleInfo(id) {
  const options = {
    url: `/v1/oauth/role/${id}/`,
    method: 'get'
  };
  return request(options);
}

/** 角色编辑 */
export function editRole(id, data) {
  const options = {
    url: `/v1/oauth/role/${id}/`,
    method: 'patch',
    data
  };
  return request(options);
}

/** 角色删除 */
export function deleteRole(id) {
  const options = {
    url: `/v1/oauth/role/${id}/`,
    method: 'delete'
  };
  return request(options);
}

/** 角色复制 */
export function copyRole(id) {
  const options = {
    url: `/v1/oauth/role/${id}/copy/`,
    method: 'post'
  };
  return request(options);
}