import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, message } from 'antd';
import { userStore } from '@/stores/userStore';
import { LoginButtonProps, LoginInput } from '@/views/login';
import { MyIcon } from '@/utils/iconfont';
import { useLocation, useNavigate } from 'react-router-dom';
import { Login } from '@greendev/common';

export const GetMobileCodeButtonStyle: React.CSSProperties = {
    opacity: 0.65,
    position: 'absolute',
    fontSize: '16px',
    border: 'none',
    right: '10px',
    top: '7px',
    zIndex: 99,
    height: '27px',
    padding: '0 22px',
};

export const MobileLoginPart: React.FC = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [form] = Form.useForm();

    const timeChange = useRef<number>();
    const [time, setTime] = useState(60);
    // 等待服务器响应中间状态
    const [awaitCodeRes, setAwaitCodeRes] = useState<boolean>(false);
    const [isGetCode, setIsGetCode] = useState(true);
    useEffect(() => {
        clearInterval(timeChange.current);
        return () => clearInterval(timeChange.current);
    }, []);
    useEffect(() => {
        if (time > 0 && time < 60) {
            setIsGetCode(false);
        } else {
            clearInterval(timeChange.current);
            setTime(60);
            setIsGetCode(true);
        }
    }, [time]);

    /**
     * 获取验证码
     */
    const getMsgCode = async () => {
        if (!isGetCode) {
            // 倒计时未结束,不能重复点击
            return;
        }
        setAwaitCodeRes(true);
        if (!form.getFieldValue('mobile')) {
            message.error('请先输入手机号');
            setAwaitCodeRes(false);
            return;
        }
        await userStore
            .getMobileCodeFunc({ purpose: 'login', mobile: form.getFieldValue('mobile') })
            .then(res => {
                if (!res) {
                    message.error('手机号未注册');
                    setAwaitCodeRes(false);
                } else {
                    message.success('发送成功,请填写收到的验证码');
                    setAwaitCodeRes(false);
                    // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
                    timeChange.current = setInterval(
                        () =>
                            setTime(val => {
                                const cur = val - 1;
                                return cur;
                            }),
                        1000
                    ) as unknown as number;
                }
            })
            .catch(error => {
                message.error(error.response?.data?.info || '网络连接异常');
                setAwaitCodeRes(false);
            });
    };

    const onFinished = async (formData: Login.MobileLoginParam) => {
        try {
            await userStore.mobileLogin(formData);
        } catch (error: any) {
            message.error(error.response?.data?.info);
            return;
        }
        const search = new URLSearchParams(location.search);
        // console.log('location.search', location.search);
        // @ts-ignore
        const from = location.state?.from || search.get('from') || { pathname: '/dashboard' };
        navigate(from, { replace: true });
    };

    return (
        <Form<Login.MobileLoginParam> onFinish={onFinished} className="login-page-form" form={form}>
            <Form.Item
                name="mobile"
                rules={[
                    { required: true, message: '请输入手机号！' },
                    {
                        pattern: /^1[3-9]\d{9}$/g,
                        message: '手机号格式不正确',
                    },
                ]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <LoginInput
                    placeholder="请输入手机号"
                    prefix={<MyIcon type="icon_env_login_accout" style={{ margin: '0 8px 0 6px' }} />}
                    size="large"
                    type="tel"
                    maxLength={11}
                />
            </Form.Item>
            <Form.Item
                name="code"
                rules={[
                    { required: true, message: '请输入验证码！' },
                    {
                        pattern: /^[a-z0-9A-Z]+$/g,
                        message: '验证码格式不正确',
                    },
                ]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                extra={
                    <Button
                        onClick={isGetCode ? () => getMsgCode() : undefined}
                        className="login-getcode-button"
                        style={{
                            color: isGetCode ? '#000' : '#464646',
                            ...GetMobileCodeButtonStyle,
                        }}
                        disabled={awaitCodeRes}
                    >
                        {isGetCode ? '获取验证码' : `重新发送(${time}s)`}
                    </Button>
                }
            >
                <LoginInput
                    placeholder="请输入验证码"
                    maxLength={4}
                    prefix={<MyIcon type="icon_env_login_code" style={{ margin: '0 8px 0 6px' }} />}
                    size="large"
                />
            </Form.Item>
            {children}
            <Form.Item>
                <Button htmlType="submit" type="primary" {...LoginButtonProps}>
                    登录
                </Button>
            </Form.Item>
        </Form>
    );
};
