import { NavigateFunction, NavigateOptions } from 'react-router';
import { To } from 'history';
import { useNavigate } from 'react-router-dom';

type HistoryApiProfile = {
    navigate: NavigateFunction | null;
    push: (to: To, options?: NavigateOptions) => void;
};

/**
 * This is a great workaround to use navigator outside React functional component
 * see : https://stackoverflow.com/a/70002872/11742589
 */
const HistoryApi: HistoryApiProfile = {
    navigate: null,
    push: (page: any, ...rest: any) => HistoryApi.navigate !== null && HistoryApi.navigate(page, ...rest),
};

export const NavigateSetter = () => {
    HistoryApi.navigate = useNavigate();
    return null;
};

export default HistoryApi;
