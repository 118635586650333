import { createContext, useContext } from 'react';
import { userStore } from './userStore';
import areaTreeState from '@/stores/areaTreeState';
import imagePreviewState from '@/stores/imagePreviewState';

export const stores = { userStore, areaTreeState, imagePreviewState };

export const RootStoreContext = createContext(stores);

export const useStores = () => useContext(RootStoreContext);
