import React, { useEffect, useState } from 'react';
import { Row, Col, Tag, DatePicker, Button } from 'antd';
import { Device, getTransformerData } from '@greendev/common';
import CTCharts from './CTCharts';
import moment from 'moment';
import GlobalStyle from '@/styles';
import { DeviceStatus } from '@/config';

const { color } = GlobalStyle;

const { RangePicker } = DatePicker;

const CTDetail: React.FC<{ detail: Device.DeviceDetailType }> = ({ detail }) => {
    const [startDate, setStartDate] = useState(moment().subtract(29, 'days').format('YYYY-MM-DD'));
    const [dates, setDates] = useState(moment().format('YYYY-MM-DD'));
    const [currentTransformerData, setCurrentTransformerData] = useState<Device.TransformerDataType[]>();

    const setTimeWithButton = (days: number) => {
        setStartDate(moment().subtract(days, 'days').format('YYYY-MM-DD'));
        setDates(moment().format('YYYY-MM-DD'));
    };

    useEffect(() => {
        if (moment(dates, 'YYYY-MM-DD') < moment(startDate, 'YYYY-MM-DD')) {
            setStartDate(dates);
        }
        getTransformerData(detail.id, {
            s_time: moment(dates, 'YYYY-MM-DD') < moment(startDate, 'YYYY-MM-DD') ? dates : startDate,
            e_time: moment(dates, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
        }).then(res => {
            setCurrentTransformerData(res);
            // console.log(moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'));
        });
    }, [detail.id, detail.last_time, startDate, dates]);

    const { status } = detail;
    let tagColor: string;
    switch (status) {
        case DeviceStatus.inactive:
            tagColor = color.themeRed;
            break;
        case DeviceStatus.online:
            tagColor = color.themeColor;
            break;
        case DeviceStatus.offline:
            tagColor = color.themeYellow;
            break;
        default:
            tagColor = color.themeBlue;
            break;
    }

    return (
        <>
            <div className="device-detail-title">
                {detail.name} <Tag color={tagColor}>{detail.status_name}</Tag>
            </div>
            <Row gutter={[24, 24]} className="device-detail-content">
                <Col span={8}>设备ID: {detail.qr_code || '-'}</Col>
                <Col span={8}>设备型号: {detail.category_id || '-'}</Col>
                <Col span={8}>所属监测点: {detail.monitoring_points_name || '-'}</Col>
                <Col span={8}>上次采集: {detail.last_time || '-'}</Col>
                <Col span={8}>连接网关: {detail.gateway_name}</Col>
                <Col span={8}>所属企业: {detail.enterprise_name || '-'}</Col>
                <Col span={8}>固件版本: {detail.version || '-'}</Col>
                <Col span={8}>外接互感器: {`${detail.multiple} 倍` || '-'}</Col>
                <Col span={8}>备注: {detail.remark || '-'}</Col>
            </Row>
            {detail.history.length > 0 ? (
                <>
                    <div className="device-detail-title">更换历史</div>
                    <div className="device-detail-history">
                        {detail.history.map(item => {
                            const time = item.update_time.split('T');
                            return (
                                <div className="device-detail-history-item" key={item.id}>
                                    <b style={{ marginRight: '24px', color: color.contentColor }}>{item.qr_code}</b>
                                    {`${time[0]} ${time[1].split('.')[0]}`}
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}
            <div className="device-detail-title">电流数据</div>
            <div className="device-detail-datepicker">
                <div>
                    <Button
                        type={
                            moment().format('YYYY-MM-DD') === dates && moment().format('YYYY-MM-DD') === startDate
                                ? 'primary'
                                : 'default'
                        }
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                            setTimeWithButton(0);
                        }}
                    >
                        今日
                    </Button>
                    <Button
                        type={
                            moment().format('YYYY-MM-DD') === dates &&
                            moment().subtract(6, 'days').format('YYYY-MM-DD') === startDate
                                ? 'primary'
                                : 'default'
                        }
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                            setTimeWithButton(6);
                        }}
                    >
                        近7天
                    </Button>
                    <Button
                        type={
                            moment().format('YYYY-MM-DD') === dates &&
                            moment().subtract(29, 'days').format('YYYY-MM-DD') === startDate
                                ? 'primary'
                                : 'default'
                        }
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                            setTimeWithButton(29);
                        }}
                    >
                        近30天
                    </Button>
                </div>
                <RangePicker
                    value={[moment(startDate, 'YYYY-MM-DD'), moment(dates, 'YYYY-MM-DD')]}
                    disabledDate={current =>
                        // 可选今天以前时间跨度30天以内的日期。
                        !(
                            current < moment().endOf('day') &&
                            current >= moment(dates).subtract(29, 'days') &&
                            current <= moment(startDate).add(29, 'days')
                        )
                    }
                    allowClear={false}
                    onCalendarChange={currentdate => {
                        setStartDate(value =>
                            currentdate && currentdate[0] ? moment(currentdate[0]).format('YYYY-MM-DD') : value
                        );
                        setDates(value =>
                            currentdate && currentdate[1] ? moment(currentdate[1]).format('YYYY-MM-DD') : value
                        );
                    }}
                />
            </div>
            <CTCharts data={currentTransformerData!} timeSplit={dates === startDate ? 2 : 1} />
        </>
    );
};

export default CTDetail;
