import { Industry, queryIndustryById } from '@greendev/common';
import { Form, FormInstance, Input } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';

export type IndustryEditContentProps = {
    targetIndustry?: Industry.FrontEndProfile;
};

//新增、编辑弹框内容显示
const IndustryEditContent = forwardRef<FormInstance<any>, IndustryEditContentProps>(
    (props: IndustryEditContentProps, ref) => {
        const { targetIndustry } = props;
        const onFinish = (values: any) => {
            console.log('Success:', values);
        };

        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };

        const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

        useEffect(() => {
            if (targetIndustry !== undefined) {
                queryIndustryById(targetIndustry.id).then(() => {
                    //notice : if you don't want to send a request to query name , you can get the area name from ad_region_name field
                    setInitialValues({
                        name: targetIndustry.name,
                        desc: targetIndustry.desc,
                    });
                });
            }
        }, [targetIndustry]);

        return (
            <Form
                layout="horizontal"
                ref={ref}
                labelAlign={'right'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues}
                key={initialValues?.name}
            >
                <Form.Item
                    label="行业名称"
                    name="name"
                    rules={[
                        { required: true, message: '请输入行业名称' },
                        {
                            pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,10}$/,
                            message: '行业名称只能包含1-10位的汉字、字母、数字、下划线!',
                        },
                        {
                            whitespace: true,
                            message: '不允许输入空格',
                        },
                    ]}
                >
                    <Input placeholder="请输入行业名称" />
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="desc"
                    rules={[
                        { required: false, message: '请输入备注' },
                        {
                            pattern: /^.{0,20}$/,
                            message: '备注长度为20位!',
                        },
                        {
                            pattern: /^(?!\s)(?!.*\s$)/,
                            message: '备注不允许有空格!',
                        },
                    ]}
                >
                    <Input placeholder="请输入备注" />
                </Form.Item>
            </Form>
        );
    }
);

export default IndustryEditContent;
