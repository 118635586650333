import axios from 'axios';
import StatusCode from 'status-code-enum';
import { isArray } from 'lodash';
let externalProcessFunction;
let service;
export function inject(etc) {
  externalProcessFunction = etc;
  service = axios.create({
    // baseURL: baseApiUrl,
    timeout: etc.debugMode ? 8000 : undefined,
    // `validateStatus` defines whether to resolve or reject the promise for a given
    // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
    // or `undefined`), the promise will be resolved; otherwise, the promise will be
    // rejected.
    validateStatus(status) {
      return status >= 200 && status < 300; // default
    }
  });
  service.interceptors.request.use(config => {
    const currentConfig = config;
    const token = externalProcessFunction.tokenQueryFunction?.();
    if (token && currentConfig.headers) {
      currentConfig.headers.Authorization = `GREEN ${token}`;
    }
    return currentConfig;
  });
  service.interceptors.response.use(response =>
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  response, response => {
    //403 represent expired token
    if (response.status === StatusCode.ClientErrorUnauthorized) {
      //todo call logout function to clear expired toke , or refresh token ...
    }
    return Promise.reject(response);
  });
}
const {
  CancelToken
} = axios;
let source = CancelToken.source();

/**
 * use this function let all request before call this function cancel
 * Does not affect requests after call this function
 * @param message
 */
function cancelAllRequest(message) {
  source.cancel(message);
  source = CancelToken.source();
}
const request = async (config, options = {}) => {
  const currentConfig = config;
  currentConfig.cancelToken = source.token;
  return new Promise((resolve, reject) => {
    const {
      baseUrlOfCurrentRequest = externalProcessFunction.baseUrl,
      isGetDataDirectly = true,
      useRawUrl = false
    } = options;
    const fullUrl = `${baseUrlOfCurrentRequest + currentConfig.url}`;
    // eslint-disable-next-line no-useless-escape
    currentConfig.url = fullUrl.replace(/[\/]+[\/]/g, '/').replace(':/', '://');
    // 格式化url
    if (!useRawUrl && externalProcessFunction.formatUrl) currentConfig.url = externalProcessFunction.formatUrl(currentConfig.url);
    service.request(currentConfig).then(result => {
      // console.log('request res: ', result);
      // 登录部分错误处理
      if (result.status === 203) {
        if (result.data?.info) externalProcessFunction.message?.(result.data?.info);
      }
      resolve(isGetDataDirectly ? result.data : result);
    }).catch(res => {
      // token失效
      if (res.response?.status === 401) {
        externalProcessFunction.message?.('身份验证失败，请重新登录！');
        externalProcessFunction.whenTokenExpired?.();
        return;
      }
      reject(res);
    });
  });
};
const mockRequest = async (config, options = {}) => request(config, {
  ...options,
  baseUrlOfCurrentRequest: externalProcessFunction.mockBaseUrl
});
function returnErrorMessageIfErrorIsDetailError(error) {
  return error.response?.data?.detail;
}
function returnErrorMessageIfErrorIsNoFieldsError(error) {
  if (error.response === undefined) {
    return undefined;
  }
  const noFieldArray = error.response.data.non_field_errors;
  if (noFieldArray === undefined) {
    return undefined;
  }
  return noFieldArray[0];
}

/**
 * todo consider validate the passed error, or limit the invoke order of this function
 * ps : {name: ['名称最长为10个字符。']}
 *      {name: '名称最长为10个字符。'}
 * @param error
 */
function returnErrorMessageIfErrorIsFieldValidateError(error) {
  try {
    if (error.response === undefined) {
      return undefined;
    }
    const responseObject = error.response.data;
    const firstFieldValidationErrorArray = Object.values(responseObject)[0];
    return isArray(firstFieldValidationErrorArray) ? firstFieldValidationErrorArray[0] : firstFieldValidationErrorArray;
  } catch (e) {
    //robustness
    return undefined;
  }
}

/**
 * there have two condition :
 * 1 : [{"wrong_field":["Too long"]},{"wrong_field2":["Too long","Don't read"]}]
 * 2 : ["error message"]
 * for the condition one, we can use {@link returnErrorMessageIfErrorIsFieldValidateError} to process it
 * @param error
 */
function returnErrorMessageIfErrorIsArrayError(error) {
  if (error.response === undefined) {
    return undefined;
  }
  if (isArray(error.response.data)) {
    const stringOrObjectError = error.response.data[0];
    if (typeof stringOrObjectError === 'string') {
      return stringOrObjectError;
    }
    // eslint-disable-next-line no-param-reassign
    error.response.data = stringOrObjectError;
    return returnErrorMessageIfErrorIsFieldValidateError(error);
  }
  return undefined;
}

/**
 * default error handler behavior , you can pass this function to .catch() function directly
 * and this function should play happy with backend framework(django) !
 * @param error
 */
const dealRequestError = error => {
  if (typeof error === 'string') {
    externalProcessFunction.message?.(error);
    return;
  }
  if (typeof error.response?.data === 'string' && (error.response?.data.startsWith('<!DOCTYPE html>') || error.response?.data.startsWith('<html>'))) {
    //represent Django response with html
    //todo report whole response object to analyze service
    externalProcessFunction.message?.('发生了一些小意外，请联系管理员');
    return;
  }
  //todo deal others error message format
  //console.log('error body : '+JSON.stringify(error.response?.data))
  const arrayErrorMessage = returnErrorMessageIfErrorIsArrayError(error);
  if (arrayErrorMessage !== undefined) {
    externalProcessFunction.message?.(arrayErrorMessage);
    return;
  }
  const detailErrorMessage = returnErrorMessageIfErrorIsDetailError(error);
  if (detailErrorMessage !== undefined) {
    externalProcessFunction.message?.(detailErrorMessage);
    return;
  }
  const noFieldsErrorMessage = returnErrorMessageIfErrorIsNoFieldsError(error);
  if (noFieldsErrorMessage !== undefined) {
    externalProcessFunction.message?.(noFieldsErrorMessage);
    return;
  }
  const FieldsValidationErrorMessage = returnErrorMessageIfErrorIsFieldValidateError(error);
  if (FieldsValidationErrorMessage !== undefined) {
    externalProcessFunction.message?.(FieldsValidationErrorMessage);
    return;
  }
  externalProcessFunction.message?.(error.message);
};
export { request, mockRequest, dealRequestError, cancelAllRequest };