import React from 'react';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { observer } from 'mobx-react-lite';
import * as echarts from 'echarts';

export type EChartGroupBarChartProps = {
    xAxisData: any;
    yAxisData: any;
    unit: string | undefined;
    colors: { color0: string; color1: string; pictorialBarBottom: string; pictorialBarTop: string; fzColor: string };
};

const EchartBar = (props: EChartGroupBarChartProps) => {
    const { xAxisData, yAxisData, unit, colors } = props;
    const barChartOption: EChartsOption = {
        grid: {
            top: '25%',
            left: '5%',
            bottom: '0%',
            right: '2%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'item',
        },
        dataZoom: {
            type: 'inside', //inside slider
            show: true, //flase直接隐藏图形
            xAxisIndex: [0],
            start: 0, //滚动条的起始位置
            end: xAxisData.length < 8 ? 100 : Math.floor(800 / xAxisData.length), //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            orient: 'vertical',
            zoomOnMouseWheel:false, //滚轮不触发缩放
            moveOnMouseMove:true,   //鼠标移动触发平移
            moveOnMouseWheel:true,  //鼠标滚轮触发平移
        },
        calculable: true,
        xAxis: [
            {
                type: 'category',
                show: true,
                data: xAxisData,
                axisTick: {
                    alignWithLabel: true,
                },
                axisLine: {
                    lineStyle: {
                        color: colors.fzColor,
                    },
                },
                axisLabel: {
                    textStyle: {
                        color: colors.fzColor,
                    },
                    margin: 15,
                    // rotate: -18,
                    color: colors.fzColor,
                    interval: 0,
                    align: 'center',
                },
                interval: 1,
            },
        ],
        yAxis: [
            {
                show: true,
                type: 'value',
                name: `单位:(${unit})`,
                textStyle: {
                    fontStyle: 'normal',
                    color: '#9EA5AE',
                    fontweight: 'normal',
                    fontstretch: 'normal',
                    fontSize: 12,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#f0f2f5',
                    },
                },
                axisLabel: {
                    color: colors.fzColor,
                },
            },
        ],
        series: [
            {
                name: '上部圆',
                type: 'pictorialBar',
                silent: true,
                symbolSize: [15, 10],
                symbolOffset: [0, -6],
                symbolPosition: 'end',
                z: 12,
                label: {
                    normal: {
                        show: false,
                        position: 'top',
                        fontSize: 12,
                    },
                },
                color: colors.pictorialBarTop,
                data: yAxisData,
            },
            {
                name: '底部圆',
                type: 'pictorialBar',
                silent: true,
                symbolSize: [15, 10],
                symbolOffset: [0, 3],
                z: 12,
                color: colors.pictorialBarBottom,
                data: yAxisData,
            },
            // {
            //     name: '内层波浪',
            //     type: 'pictorialBar',
            //     silent: true,
            //     symbolSize: [30, 15],
            //     symbolOffset: [0, 12],
            //     z: 10,
            //     itemStyle: {
            //         normal: {
            //             color: 'transparent',
            //             borderColor: colors.color1,
            //             borderType: 'solid',
            //             borderWidth: 8,
            //         },
            //     },
            //     data: yAxisData,
            // },
            // {
            //     name: '外层波浪',
            //     type: 'pictorialBar',
            //     silent: true,
            //     symbolSize: [40, 20],
            //     symbolOffset: [0, 16],
            //     z: 10,
            //     itemStyle: {
            //         normal: {
            //             color: 'transparent',
            //             borderColor: colors.borderColor,
            //             borderType: 'solid',
            //             borderWidth: 5,
            //         },
            //     },
            //     data: yAxisData,
            // },
            {
                name: '监测点',
                type: 'bar',
                barWidth: '15',
                barGap: '5%', // Make series be overlap
                barCateGoryGap: '5%',
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: colors.color0,
                            },
                            {
                                offset: 1,
                                color: colors.color1,
                            },
                        ]),
                    },
                },
                data: yAxisData,
            },
        ],
    };
    return (
        <ReactEcharts
            option={barChartOption}
            style={{ width: '100%', height: '80%', backgroundColor: 'transparent' }}
        />
    );
};

export default observer(EchartBar);
