import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, Col, FormInstance, Input, message, Row, Select, Table, Typography, Upload } from 'antd';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import AddCompanyContent, {
    ModifyOrDeleteCompanyContentInfo,
} from '@/views/common/basicDataManage/companyManageView/AddCompanyContent';
import { AreaTreeSelect, useAreaInitialize } from '@/views/common/basicDataManage/areaManageView/AreaFormTreeSelect';
import {
    Company,
    dealRequestError,
    fetchAllCompany,
    fetchAllIndustryTree,
    importCompanies,
    Industry,
    PaginationWrapper,
    purgedAreaCompanyTreeState,
} from '@greendev/common';
import { ColumnsType } from 'antd/es/table';
import { usePagination } from '@/utils/typeWrapper';
import AMapHelper from '@/utils/amap';
import handleFormValueWithDuplicationCheck, {
    momentFieldScheme,
    RequestValueType,
    stringFieldScheme,
} from '@/utils/formUploadHandler';
import _ from 'lodash';
import { UploadFile } from 'antd/es/upload/interface';
import { ExcelKeyReadableMapping, exportExcel, useExcelImporter } from '@/utils/excel';
import GlobalStyle from '@/styles';
import { ImportResultModal, ImportResultModalAction } from '@/components/importResult/ImportResultModal';

const searchButtonId = 'search-button-in-company-management';

const CompanyManagementView = () => {
    const { color } = GlobalStyle;
    useAreaInitialize();
    const pagePreFetchData = useLocalObservable(() => ({
        industries: [] as Industry.FrontEndProfile[],
        fetchAllIndustry() {
            fetchAllIndustryTree()
                .then(result => {
                    this.industries = result;
                })
                .catch(dealRequestError);
        },
        clearIndustry() {
            this.industries = [];
        },
    }));
    useEffect(() => {
        pagePreFetchData.fetchAllIndustry();
        return () => {
            pagePreFetchData.clearIndustry();
        };
    }, [pagePreFetchData]);

    const modalRef = useRef<CommonModalApi>(null);
    const addCompanyFormRef = useRef<FormInstance<any>>(null);
    const [displayAddCompanyFormModal, setDisplayAddCompanyFormModal] = useState<{
        display: boolean;
        modifyOrDeleteInfo?: ModifyOrDeleteCompanyContentInfo;
    }>({
        display: false,
    });
    const modalTitle = useMemo(() => {
        if (displayAddCompanyFormModal.modifyOrDeleteInfo === undefined) {
            return '新增企业';
        }
        return displayAddCompanyFormModal.modifyOrDeleteInfo.action === 'modify' ? '编辑企业' : '你确定删除企业吗？';
    }, [displayAddCompanyFormModal]);
    useEffect(() => {
        if (displayAddCompanyFormModal.display) {
            modalRef.current?.showModal();
        }
    }, [displayAddCompanyFormModal]);

    const [searchCompanyName, setSearchCompanyName] = useState<string>('');
    const whenInputSearchCompanyName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchCompanyName(event.target.value);
    };

    const [selectedArea, setSelectedArea] = useState<number | undefined>(undefined);
    const whenSelectArea = (value: number) => {
        setSelectedArea(value);
    };

    const [selectedIndustryId, setSelectedIndustryId] = useState<string | undefined>(undefined);
    const whenSelectIndustry = (value: string) => {
        setSelectedIndustryId(value);
    };

    const [tableDataWithLoadingState, , refreshTableData, pagination, resetToFirstPage] =
        usePagination<Company.BackendProfile>(
            (page, pageSize) =>
                fetchAllCompany({
                    name: searchCompanyName.trim().length === 0 ? undefined : searchCompanyName,
                    ad_region: selectedArea,
                    trade: selectedIndustryId === undefined ? undefined : Number(selectedIndustryId),
                    page,
                    page_size: pageSize,
                }) as Promise<PaginationWrapper<Company.BackendProfile>>
        );

    const refreshTableDataAndAccountsStatus = () => {
        refreshTableData();
    };

    const columns: ColumnsType<Company.BackendProfile> = [
        {
            title: '企业名称',
            dataIndex: 'name',
            key: 'id',
            width: 150,
        },
        {
            title: '行政区',
            dataIndex: 'ad_region_name',
            key: 'id',
            width: 180,
        },
        {
            title: '所属行业',
            dataIndex: 'trade_name',
            key: 'id',
            width: 100,
            render: (text: string | null) => <Typography.Text>{text === null ? '-' : text}</Typography.Text>,
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'id',
            width: 200,
        },
        {
            title: '服务到期时间',
            dataIndex: 'expire_date',
            key: 'id',
            width: 120,
        },
        {
            title: '添加时间',
            dataIndex: 'add_time',
            key: 'id',
            width: 160,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 180,
            render: (item, record) => (
                <div className="opera">
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setDisplayAddCompanyFormModal({
                                display: true,
                                modifyOrDeleteInfo: {
                                    originCompany: record,
                                    action: 'modify',
                                },
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <i
                        className="iconfont iconshanchu1"
                        title={'删除'}
                        onClick={() => {
                            setDisplayAddCompanyFormModal({
                                display: true,
                                modifyOrDeleteInfo: {
                                    originCompany: record,
                                    action: 'delete',
                                },
                            });
                        }}
                        style={{
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            margin: '0 0.6rem',
                        }}
                    />
                </div>
            ),
        },
    ];

    const importResultModalRef = useRef<ImportResultModalAction>(null);
    const keyMapping: ExcelKeyReadableMapping<Company.ExcelExposeProfile> = {
        companyName: '企业名称',
        areaNamePath: '行政区',
        industryName: '所属行业',
        address: '地址',
        lng: '经度',
        lat: '纬度',
        expiredTime: '到期时间',
        remark: '备注',
    };
    const exportCompanies = () => {
        fetchAllCompany({
            get_all: true,
        }).then(companies => {
            if ((companies as Company.BackendProfile[]).length === 0) {
                message.warn('暂无企业');
                return;
            }
            const companiesForExcel: Company.ExcelExposeProfile[] = (companies as Company.BackendProfile[]).map(
                eachCompany => ({
                    companyName: eachCompany.name,
                    areaNamePath: eachCompany.ad_region_name,
                    industryName: eachCompany.trade_name,
                    address: eachCompany.address,
                    lng: eachCompany.lng,
                    lat: eachCompany.lat,
                    expiredTime: eachCompany.expire_date,
                    remark: eachCompany.desc,
                })
            );
            exportExcel(keyMapping, companiesForExcel, '企业管理列表');
        });
    };
    const excelUploader = useCallback(recordsInSheet => {
        try {
            /* eslint-disable */
            const backendProfiles: Company.ExcelImportProfile[] = recordsInSheet.map(
                (eachRecord: { [x: string]: any }) =>
                    _.omitBy(
                        {
                            name: eachRecord[keyMapping['companyName']],
                            ad_region: eachRecord[keyMapping['areaNamePath']],
                            trade: eachRecord[keyMapping['industryName']],
                            address: eachRecord[keyMapping['address']],
                            lng: Number(eachRecord[keyMapping['lng']]),
                            lat: Number(eachRecord[keyMapping['lat']]),
                            expire_date: eachRecord[keyMapping['expiredTime']],
                            desc: eachRecord[keyMapping['remark']],
                        },
                        _.isNull
                    ) as Company.CreateOrUpdateCompanyParameter
            );
            /* eslint-enable */
            //request backend to import records
            importCompanies(backendProfiles)
                .then(result => {
                    document.getElementById(searchButtonId)?.click();
                    importResultModalRef.current?.displayResult({
                        result,
                        onClickExportFailItems: index => {
                            const companiesForExcel: Company.ExcelExposeProfile[] = backendProfiles
                                .filter((value, eachIndex) => index.includes(eachIndex + 1))
                                .map(eachCompany => ({
                                    companyName: eachCompany.name,
                                    areaNamePath: eachCompany.ad_region,
                                    industryName: eachCompany.trade,
                                    address: eachCompany.address,
                                    lng: eachCompany.lng.toString(),
                                    lat: eachCompany.lat.toString(),
                                    expiredTime: eachCompany.expire_date,
                                    remark: eachCompany.desc,
                                }));
                            exportExcel(keyMapping, companiesForExcel, '企业管理列表(导入失败)');
                        },
                    });
                })
                .catch(dealRequestError)
                .finally(() => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    afterUploadExcel();
                });
        } catch (e) {
            message.error('格式错误');
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            afterUploadExcel();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [afterSelectExcel, afterUploadExcel, loading] = useExcelImporter(excelUploader);

    return (
        <div className="container">
            <Row align={'middle'} className={'commonButton'} gutter={16}>
                <Col span={3} xxl={2}>
                    <div className={'flex-horizontal-space-between'}>
                        <Button
                            style={{ height: '100%', width: '45%' }}
                            className={'flex-horizontal-center'}
                            type="primary"
                            onClick={() => {
                                setDisplayAddCompanyFormModal({
                                    display: true,
                                });
                            }}
                        >
                            新增
                        </Button>
                        <div style={{ height: '100%', width: '45%' }}>
                            <Upload
                                customRequest={options => {
                                    options.onError?.(new Error('fake error'));
                                }}
                                onChange={info => {
                                    const uploadFile = info.file as UploadFile;
                                    if (uploadFile.status !== 'error') {
                                        return;
                                    }
                                    afterSelectExcel(uploadFile);
                                }}
                                multiple={false}
                                showUploadList={false}
                            >
                                <Button
                                    style={{ height: '100%', width: '100%', color: color.themeColor }}
                                    className={'flex-horizontal-center'}
                                    type="default"
                                    loading={loading}
                                >
                                    导入
                                </Button>
                            </Upload>
                        </div>
                    </div>
                </Col>
                <Col span={3} xxl={8} />
                <Col span={5} xxl={4}>
                    <div className={'flex-horizontal-center'}>
                        <Typography.Text style={{ marginRight: 8 }}>名称 : </Typography.Text>
                        <Input
                            onChange={whenInputSearchCompanyName}
                            value={searchCompanyName}
                            allowClear
                            style={{ flex: 1 }}
                        />
                    </div>
                </Col>
                <Col span={5} xxl={4}>
                    <div className={'flex-horizontal-center'}>
                        <Typography.Text style={{ marginRight: 8 }}>行政区 : </Typography.Text>
                        <AreaTreeSelect
                            label={'行政区'}
                            onChange={whenSelectArea}
                            value={selectedArea}
                            style={{ flex: 1 }}
                        />
                    </div>
                </Col>
                <Col span={5} xxl={4}>
                    <div className={'flex-horizontal-center'}>
                        <Typography.Text style={{ marginRight: 8 }}>行业 : </Typography.Text>
                        <Select
                            placeholder="请选择所属行业"
                            allowClear
                            style={{ flex: 1 }}
                            onChange={whenSelectIndustry}
                            value={selectedIndustryId}
                        >
                            {pagePreFetchData.industries.map(eachIndustry => (
                                <Select.Option key={eachIndustry.id}>{eachIndustry.name}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </Col>
                <Col span={3} xxl={2}>
                    <div className={'flex-horizontal-space-between'}>
                        <Button
                            id={searchButtonId}
                            style={{ height: '100%', width: '45%' }}
                            className={'flex-horizontal-center'}
                            type="primary"
                            onClick={() => {
                                resetToFirstPage();
                            }}
                        >
                            搜索
                        </Button>
                        <Button
                            style={{ height: '100%', width: '45%', color: color.themeColor }}
                            className={'flex-horizontal-center'}
                            type="default"
                            onClick={() => {
                                exportCompanies();
                            }}
                        >
                            导出
                        </Button>
                    </div>
                </Col>
            </Row>

            <Table<Company.BackendProfile>
                pagination={pagination}
                dataSource={tableDataWithLoadingState.paginationData.results}
                loading={tableDataWithLoadingState.loading}
                columns={columns}
                rowKey={record => record.id}
                scroll={{ x: '100%', y: 'calc(100vh - 305px)' }}
            />

            {displayAddCompanyFormModal.display && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        const formHandler = addCompanyFormRef.current;
                        if (formHandler === null) {
                            return;
                        }
                        const clearContentAndHideModal = (action?: 'delete' | 'modify') => {
                            formHandler.resetFields();
                            if (action === undefined) {
                                message.success('添加成功');
                            } else {
                                message.success(action === 'delete' ? '删除成功' : '编辑成功');
                            }
                            modalRef.current?.hiddenModal();
                            setDisplayAddCompanyFormModal({
                                display: false,
                            });
                        };

                        formHandler.validateFields().then(() => {
                            if (displayAddCompanyFormModal.modifyOrDeleteInfo?.action === 'delete') {
                                addCompanyFormRef.current
                                    ?.validateFields()
                                    .then(() => {
                                        //delete company
                                        purgedAreaCompanyTreeState
                                            .deleteCompany(
                                                displayAddCompanyFormModal.modifyOrDeleteInfo!!.originCompany
                                            )
                                            .then(() => {
                                                clearContentAndHideModal(
                                                    displayAddCompanyFormModal.modifyOrDeleteInfo?.action
                                                );
                                                refreshTableDataAndAccountsStatus();
                                            })
                                            .catch(dealRequestError)
                                            .finally(() => {
                                                modalRef.current?.endLoading();
                                            });
                                    })
                                    .catch(dealRequestError)
                                    .finally(() => {
                                        modalRef.current?.endLoading();
                                    });
                                return;
                            }
                            const isEditMode = displayAddCompanyFormModal.modifyOrDeleteInfo !== undefined;
                            const address = addCompanyFormRef.current?.getFieldValue('address');
                            AMapHelper.queryLngLatByAddress(
                                address,
                                (lnglat: any) => {
                                    let finalLnglat = lnglat;
                                    if (isEditMode) {
                                        //if user not change the address , cover the string format to object, so you can process it as same as add mode
                                        if (typeof lnglat === 'string') {
                                            const lnglatArray = lnglat.split(',');
                                            finalLnglat = {
                                                lng: lnglatArray[0],
                                                lat: lnglatArray[1],
                                            };
                                        }
                                    }
                                    const { lng, lat } = finalLnglat;
                                    handleFormValueWithDuplicationCheck<
                                        Company.CreateOrUpdateCompanyParameter,
                                        Company.BackendProfile
                                    >(
                                        formHandler,
                                        [
                                            stringFieldScheme('name', 'name'),
                                            stringFieldScheme(
                                                'area',
                                                'ad_region',
                                                RequestValueType.NUMERICAL,
                                                false,
                                                (areaValueInForm: string | number) =>
                                                    new Promise<number>(resolve => {
                                                        if (isEditMode && typeof areaValueInForm === 'string') {
                                                            //represent user not reselect the area
                                                            resolve(
                                                                displayAddCompanyFormModal.modifyOrDeleteInfo!!
                                                                    .originCompany.ad_region
                                                            );
                                                        }
                                                        resolve(areaValueInForm as number);
                                                    })
                                            ),
                                            stringFieldScheme('industry', 'trade', RequestValueType.NUMERICAL),
                                            stringFieldScheme('address', 'address'),
                                            stringFieldScheme(
                                                'lnglat',
                                                'lng',
                                                RequestValueType.LITERAL,
                                                true,
                                                undefined,
                                                {
                                                    keyInOriginalObject: 'lng',
                                                    keyInBaseMarkObject: 'lng',
                                                    compareFunction: (originalObjectValue, baseMarkObjectValue) =>
                                                        Number(originalObjectValue) === Number(baseMarkObjectValue),
                                                },
                                                () => Promise.resolve(lng.toString())
                                            ),
                                            stringFieldScheme(
                                                'lnglat',
                                                'lat',
                                                RequestValueType.LITERAL,
                                                true,
                                                undefined,
                                                {
                                                    keyInOriginalObject: 'lat',
                                                    keyInBaseMarkObject: 'lat',
                                                    compareFunction: (originalObjectValue, baseMarkObjectValue) =>
                                                        Number(originalObjectValue) === Number(baseMarkObjectValue),
                                                },
                                                () => Promise.resolve(lat.toString())
                                            ),
                                            momentFieldScheme('expired-time', 'expire_date'),
                                            stringFieldScheme('remark', 'desc', RequestValueType.LITERAL, true),
                                        ],
                                        (
                                            purgedModifyParamsOrAddParams,
                                            isModifyMode,
                                            requestObject,
                                            originalRecord
                                        ) => {
                                            (isModifyMode
                                                ? purgedAreaCompanyTreeState.updateCompany(
                                                      originalRecord!!.id,
                                                      purgedModifyParamsOrAddParams
                                                  )
                                                : purgedAreaCompanyTreeState.createCompany(
                                                      purgedModifyParamsOrAddParams
                                                  )
                                            )
                                                .then(() => {
                                                    clearContentAndHideModal(
                                                        displayAddCompanyFormModal.modifyOrDeleteInfo?.action
                                                    );
                                                    refreshTableDataAndAccountsStatus();
                                                })
                                                .catch(dealRequestError)
                                                .finally(() => {
                                                    modalRef.current?.endLoading();
                                                });
                                        },
                                        () => {
                                            clearContentAndHideModal(
                                                displayAddCompanyFormModal.modifyOrDeleteInfo?.action
                                            );
                                        },
                                        displayAddCompanyFormModal.modifyOrDeleteInfo?.originCompany
                                    );
                                },
                                () => {
                                    message.error('请输入合法地址');
                                    modalRef.current?.endLoading();
                                }
                            );
                        });
                    }}
                    onCancel={() => {
                        addCompanyFormRef.current?.resetFields();
                        setDisplayAddCompanyFormModal({
                            display: false,
                        });
                    }}
                    title={modalTitle}
                >
                    <AddCompanyContent
                        ref={addCompanyFormRef}
                        industries={pagePreFetchData.industries}
                        modifyOrDeleteInfo={displayAddCompanyFormModal.modifyOrDeleteInfo}
                    />
                </CommonModal>
            )}
            <ImportResultModal ref={importResultModalRef} />
        </div>
    );
};

export default observer(CompanyManagementView);
