import { Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { observer, Observer } from 'mobx-react-lite';
import FileUploaderState, { getImageValidationState } from '@/stores/fileUploaderState';
import imagePreviewState from '@/stores/imagePreviewState';

export type FileUploaderProps = {
    maxImageCount: number;
    fileUploaderState: FileUploaderState;
};

const FileUploader = observer((props: FileUploaderProps) => {
    const { maxImageCount, fileUploaderState } = props;
    return (
        <Observer>
            {() => (
                <Upload<UploadFile>
                    className="avatar-uploader"
                    listType="picture-card"
                    fileList={fileUploaderState.fileList}
                    onChange={({ file }) => {
                        //file represent current operating file
                        switch (file.status) {
                            case 'uploading':
                                fileUploaderState.imageUploading(file);
                                break;
                            case 'done':
                                fileUploaderState.imageDone(file);
                                break;
                            case 'removed':
                                fileUploaderState.imageRemove(file);
                                break;
                            default:
                                //do noting for these state
                                break;
                        }
                    }}
                    onPreview={file => {
                        imagePreviewState.displayImagePreview(file);
                    }}
                    showUploadList={{
                        showRemoveIcon: true,
                        showPreviewIcon: true,
                        showDownloadIcon: false,
                    }}
                    beforeUpload={getImageValidationState(2)}
                    customRequest={options => {
                        options.onSuccess?.('intercept upload success!');
                    }}
                >
                    {fileUploaderState.fileList.length >= maxImageCount ? null : (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上传</div>
                        </div>
                    )}
                </Upload>
            )}
        </Observer>
    );
});

export default FileUploader;
