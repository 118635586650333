import { request } from "../../utils/request";
/**
 * @description 登录
 * @param {LoginParams} data
 * @returns
 */
export function login(data) {
  const options = {
    url: '/v1/oauth/login/password/',
    method: 'post',
    data
  };
  return request(options, {
    isGetDataDirectly: false
  });
}
/**
 * @description 获取短信验证码
 */
export function getMobileCode(params) {
  const options = {
    url: '/v1/oauth/mobile/code/',
    method: 'get',
    params
  };
  return request(options, {
    isGetDataDirectly: false
  });
}

/**
 * @description 短信登录
 */
export function mobileLogin(data) {
  const options = {
    url: '/v1/oauth/login/code/',
    method: 'post',
    data
  };
  return request(options, {
    isGetDataDirectly: false
  });
}

/**
 * @description 忘记密码
 */
export function forgetPassword(data) {
  const options = {
    url: '/v1/oauth/forget/password/',
    method: 'patch',
    data
  };
  return request(options);
}

/**
 * @description 重置
 */
export function resetPassword(id, data) {
  const options = {
    url: `/v1/oauth/user/${id}/reset/password/`,
    method: 'patch',
    data
  };
  return request(options);
}

/**
 * @description 获取用户信息
 */
export function getUserInfo() {
  const options = {
    url: 'v1/system/personal/',
    method: 'get'
  };
  return request(options);
}