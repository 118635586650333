import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PieSeriesOption } from 'echarts';
import 'echarts-gl';
import EChartsReact from 'echarts-for-react';
import PartTitle from '../../components/PartTitle';
import ContentContainer from '../../components/ContentContainer';
import { getPie3D, PieChartDataItem } from '@/views/bigScreen/parts/companyPart/threeDimensionPieChartUtil';
import light from '@/assets/images/light.png';
import NoData from '../../components/NoData';

export type BigScreenCompanyPartProps = {
    areaAnalysisData: PieChartDataItem[];
    industryAnalysisData: PieChartDataItem[];
    scale: number;
};

const Light = () => (
    <div
        style={{ position: 'absolute', left: '10%', right: '10%', width: '80%', height: '100%', pointerEvents: 'none' }}
    >
        <img src={light} width={'100%'} height={'100%'} alt={''} style={{ pointerEvents: 'none' }} />
    </div>
);

/** 展示大屏-企业统计组件 */
const BigScreenCompanyPart = (props: BigScreenCompanyPartProps) => {
    const { areaAnalysisData, industryAnalysisData, scale } = props;
    const [areaAnalysis, setAreaAnalysis] = useState<PieSeriesOption | undefined>(undefined);
    const [industryAnalysis, setIndustryAnalysis] = useState<PieSeriesOption | undefined>(undefined);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current === null) {
            return;
        }
        const echartWidth = containerRef.current.clientWidth * 0.48;
        // const areaAnalysisOption = getPie3D(
        //     [
        //         {
        //             name: '公司11111',
        //             value: 100,
        //         },
        //         {
        //             name: '公司222222',
        //             value: 50,
        //         },
        //         {
        //             name: '公司33333333',
        //             value: 80,
        //         },
        //         {
        //             name: '公司44444',
        //             value: 500,
        //         },
        //         // {
        //         //     name: '公司5',
        //         //     value: 100,
        //         // },
        //     ],
        //     0.83,
        //     echartWidth
        // );
        // const areaAnalysisOption = getPie3D(_.orderBy(areaAnalysisData, ['value'], ['desc']), 0.83, echartWidth);
        const areaAnalysisOption = getPie3D(areaAnalysisData, 0.83, echartWidth);
        setAreaAnalysis(areaAnalysisOption);
        const industryAnalysisOption = getPie3D(industryAnalysisData, 0.83, echartWidth);
        setIndustryAnalysis(industryAnalysisOption);
    }, [areaAnalysisData, industryAnalysisData]);

    return (
        <div ref={containerRef} style={{ width: '100%', padding: '14px', overflow: 'hidden', flex: 1 }}>
            <PartTitle>企业统计</PartTitle>
            <ContentContainer
                style={{
                    height: '90%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                {areaAnalysisData.length !== 0 || industryAnalysisData.length !== 0 ? (
                    <>
                        <div style={{ width: '48%', height: '100%' }}>
                            <div className={'flex-center expandable'} style={{ position: 'relative' }}>
                                {areaAnalysis && (
                                    <EChartsReact
                                        style={{ height: `${200 * scale}px` }}
                                        option={areaAnalysis}
                                        className={'expandable'}
                                    />
                                )}
                                {areaAnalysis && <Light />}
                            </div>
                        </div>
                        <div style={{ width: '48%', height: '100%' }}>
                            <div className={'flex-center expandable'} style={{ position: 'relative' }}>
                                {industryAnalysis && (
                                    <EChartsReact
                                        style={{ height: `${200 * scale}px` }}
                                        option={industryAnalysis}
                                        className={'expandable'}
                                    />
                                )}
                                {areaAnalysis && <Light />}
                            </div>
                        </div>
                    </>
                ) : (
                    <NoData isCharts />
                )}
            </ContentContainer>
        </div>
    );
};

export default observer(BigScreenCompanyPart);
