import React, { useState, useImperativeHandle, useMemo, Children, cloneElement } from 'react';
import { Modal, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export type GlobalModalApi = {
    showModal: () => void;
    showLoading: () => void;
    hiddenModal: () => void;
    hiddenLoading: () => void;
};

const GlobalModal: React.FC<{
    childRef: React.ForwardedRef<GlobalModalApi>;
    onCancel?: () => any;
    onOk?: () => any;
    title: string;
    mask?: boolean;
    footer?: React.ReactNode;
    height?: string | number;
    confirmLoading?: boolean;
}> = ({ children, childRef, onCancel, onOk, title, mask, footer, height, confirmLoading }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const showModal = () => {
        setVisible(true);
    };
    const hiddenModal = () => {
        setVisible(false);
    };
    const showLoading = () => {
        setLoading(true);
    };
    const hiddenLoading = () => {
        setLoading(false);
    };
    useImperativeHandle(childRef, () => ({
        showModal,
        showLoading,
        hiddenModal,
        hiddenLoading,
    }));
    const addPropsChildren = useMemo(
        () =>
            // eslint-disable-next-line consistent-return
            Children.map(children, child => {
                if (child) {
                    // @ts-ignore
                    return cloneElement(child, {
                        // @ts-ignore
                        ...child.props,
                        showLoading,
                        hiddenLoading,
                        onCancel: () => {
                            hiddenModal();
                            if (onCancel) onCancel();
                        },
                        onOk: () => {
                            if (onOk) onOk();
                            hiddenModal();
                        },
                    });
                }
            }),
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [visible]
    );
    return (
        <Modal
            getContainer={false}
            // forceRender={true}
            bodyStyle={{ padding: '0' }}
            closable={false}
            title={null}
            style={{
                height: '100vh',
                top: 0,
                margin: 0,
                padding: 0,
                maxWidth: '100vw',
                left: '200px',
            }}
            mask={mask}
            width="calc(100vw - 200px)"
            visible={visible}
            footer={footer}
            onOk={() => {
                if (onOk) onOk();
            }}
            onCancel={() => {
                if (onCancel) onCancel();
                setVisible(false);
            }}
            confirmLoading={confirmLoading}
            className="GlobalModal"
        >
            <Spin spinning={loading} tip="加载中.....">
                <div style={{ height: height || '100vh', padding: '10px', overflow: 'hidden' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '40px',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #eaedf3',
                        }}
                    >
                        <div style={{ fontSize: '24px', marginLeft: '50px' }} className="globalModal-title">{title || ''}</div>
                        <div
                            onClick={() => {
                                if (onCancel) onCancel();
                                setVisible(false);
                            }}
                            style={{
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                cursor: 'pointer',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CloseOutlined style={{ fontSize: '24px' }} />
                        </div>
                    </div>
                    <div style={{ padding: '10px 0', height: 'calc(100vh - 40px)', overflow: 'auto' }}>
                        {addPropsChildren}
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default GlobalModal;
