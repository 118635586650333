import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input, Button, Radio, FormItemProps, InputProps, message, RadioChangeEvent } from 'antd';
import { resetPassword, forgetPassword, Login } from '@greendev/common';
import { userStore } from '@/stores/userStore';
import './index.scss';

export const GetMobileCodeButtonStyle: React.CSSProperties = {
    opacity: 0.65,
    position: 'absolute',
    border: 'none',
    top: '2px',
    right: '2px',
    zIndex: 99,
    height: '27px',
    padding: '0 22px',
};

const PasswordItem: React.FC<{ itemProps?: FormItemProps; inputProps?: InputProps }> = ({ itemProps, inputProps }) => (
    <Form.Item
        rules={[
            { required: true, message: '此项不能为空！' },
            {
                pattern: /^[\w\W]{6,}$/,
                message: '密码长度为6 - 16位',
            },
        ]}
        getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
        {...itemProps}
    >
        <Input.Password maxLength={16} minLength={6} {...inputProps} />
    </Form.Item>
);

const PassWordSettingView = () => {
    const [form] = Form.useForm();

    const [passwordMethod, setPasswordMethod] = useState<string>('password');
    const timeChange = useRef<number>();
    // 等待服务器响应中间状态
    const [awaitCodeRes, setAwaitCodeRes] = useState<boolean>(false);
    const [time, setTime] = useState(60);
    const [isGetCode, setIsGetCode] = useState(true);
    useEffect(() => {
        clearInterval(timeChange.current);
        return () => clearInterval(timeChange.current);
    }, []);
    useEffect(() => {
        if (time > 0 && time < 60) {
            setIsGetCode(false);
        } else {
            clearInterval(timeChange.current);
            setTime(60);
            setIsGetCode(true);
        }
    }, [time]);

    const formColProps = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    /**
     * 获取验证码
     */
    const getMsgCode = async () => {
        if (!isGetCode) {
            // 倒计时未结束,不能重复点击
            return;
        }
        setAwaitCodeRes(true);
        await userStore
            .getMobileCodeFunc({ purpose: 'reset', mobile: userStore.mobile })
            .then(res => {
                if (!res) {
                    message.error('手机号未注册');
                    setAwaitCodeRes(false);
                } else {
                    message.success('发送成功,请填写收到的验证码');
                    setAwaitCodeRes(false);
                    // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
                    timeChange.current = setInterval(
                        () =>
                            setTime(val => {
                                const cur = val - 1;
                                return cur;
                            }),
                        1000
                    ) as unknown as number;
                }
            })
            .catch(error => {
                message.error(error.response?.data?.info || '网络连接异常');
                setAwaitCodeRes(false);
            });
    };

    const onChange = (e: RadioChangeEvent) => {
        setPasswordMethod(e.target.value);
    };

    // 重置密码
    const onFinishResetPsw = (formData: Login.ResetPasswordParam) => {
        if (formData.new_password !== formData.confirm_password) {
            message.warning('重复密码与新密码不一致，请重试！');
            return;
        }
        resetPassword(userStore.userID, formData)
            .then(res => {
                if (res.token) userStore.setToken(res.token);
                message.success('密码修改成功！');
            })
            .catch(error => {
                message.error(error.response?.data?.info || error.response?.data?.non_field_errors || '网络连接异常');
            });
    };

    // 验证码重置密码
    const onFinishCodePsw = (formData: Login.ForgetPasswordParam) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { code, new_password, confirm_password } = formData;
        if (formData.new_password !== formData.confirm_password) {
            message.warning('重复密码与新密码不一致，请重试！');
            return;
        }
        forgetPassword({ mobile: userStore.mobile, code, new_password, confirm_password })
            .then(res => {
                if (res.token) userStore.setToken(res.token);
                message.success('密码修改成功！');
            })
            .catch(error => {
                message.error(error.response?.data?.info || error.response?.data?.non_field_errors || '网络连接异常');
            });
    };

    return (
        <div className="container">
            <Radio.Group onChange={onChange} defaultValue={passwordMethod} style={{ marginBottom: '24px' }}>
                <Radio.Button value="password">通过密码</Radio.Button>
                <Radio.Button value="code">通过验证码</Radio.Button>
            </Radio.Group>
            <Form
                name="form_psw"
                onFinish={onFinishResetPsw}
                {...formColProps}
                style={{ maxWidth: '450px' }}
                hidden={passwordMethod !== 'password'}
            >
                <PasswordItem
                    itemProps={{ name: 'old_password', label: '旧密码' }}
                    inputProps={{ placeholder: '请输入旧密码' }}
                />
                <PasswordItem
                    itemProps={{ name: 'new_password', label: '新密码' }}
                    inputProps={{ placeholder: '请输入6-16位新密码' }}
                />
                <PasswordItem
                    itemProps={{ name: 'confirm_password', label: '重复新密码' }}
                    inputProps={{ placeholder: '请重复新密码' }}
                />
                <Form.Item wrapperCol={{ ...formColProps.wrapperCol, offset: 8 }}>
                    <Button htmlType="submit" type="primary">
                        修改
                    </Button>
                </Form.Item>
            </Form>
            <Form
                form={form}
                onFinish={onFinishCodePsw}
                className="user-reset-psw-form"
                name="form_code"
                {...formColProps}
                style={{ maxWidth: '450px' }}
                hidden={passwordMethod !== 'code'}
            >
                <Form.Item label="手机号">
                    <div>{userStore.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}</div>
                </Form.Item>
                <Form.Item
                    name="code"
                    label="验证码"
                    rules={[
                        { required: true, message: '请输入验证码！' },
                        {
                            pattern: /^[a-z0-9A-Z]+$/g,
                            message: '验证码格式不正确',
                        },
                    ]}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                    extra={
                        <Button
                            onClick={isGetCode ? () => getMsgCode() : undefined}
                            className="login-getcode-button"
                            style={{
                                color: isGetCode ? '#000' : '#464646',
                                ...GetMobileCodeButtonStyle,
                            }}
                            disabled={awaitCodeRes}
                        >
                            {isGetCode ? '获取验证码' : `重新发送(${time}s)`}
                        </Button>
                    }
                >
                    <Input placeholder="请输入验证码" maxLength={4} />
                </Form.Item>
                <PasswordItem
                    itemProps={{ name: 'new_password', label: '新密码' }}
                    inputProps={{ placeholder: '请输入6-16位新密码' }}
                />
                <PasswordItem
                    itemProps={{ name: 'confirm_password', label: '重复新密码' }}
                    inputProps={{ placeholder: '请重复新密码' }}
                />
                <Form.Item wrapperCol={{ ...formColProps.wrapperCol, offset: 8 }}>
                    <Button htmlType="submit" type="primary">
                        修改
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default observer(PassWordSettingView);
