import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Button, Table, Input, Space, Switch, TableColumnsType, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
    getAlertSettingList,
    getAlertReceiver,
    editAlertSettingList,
    editAlertReceiver,
    Alert,
    Account,
} from '@greendev/common';
import { userStore } from '@/stores/userStore';
import GlobalStyle from '@/styles';
import './index.scss';

const { color } = GlobalStyle;

/** 通知方式 */
const NoticeSetting: React.FC<{ tableData: Alert.AlertListItem[]; refresh: () => void; isCompany: boolean }> = ({
    tableData,
    refresh,
    isCompany,
}) => {
    /** 多选框格式 */
    // const options: CheckboxOptionType[] = [
    //     {
    //         label: '短信',
    //         value: 'message',
    //     },
    //     {
    //         label: '电话',
    //         value: 'phone',
    //     },
    // ];

    /** 表格格式 */
    const columns: TableColumnsType<Alert.AlertListItem> = [
        {
            title: '报警类型',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: '短信通知',
            dataIndex: 'message_notify',
            key: 'message_notify',
            render: (_, record) => (
                <Switch
                    key={`${record.id}_message`}
                    size="small"
                    checkedChildren="开启"
                    unCheckedChildren="关闭"
                    disabled={isCompany}
                    checked={record.is_message}
                    onClick={val => {
                        if (userStore.category === Account.UserType.company) return;
                        const currentValue = val;
                        editAlertSettingList(record.id, { is_message: currentValue })
                            .then(() => {
                                message.success(`已${currentValue ? '打开' : '关闭'}${record.category}短信通知`);
                                refresh();
                            })
                            .catch(() => {
                                message.error('修改失败');
                            });
                    }}
                />
            ),
        },
        {
            title: '电话通知',
            dataIndex: 'mobile_notice',
            key: 'mobile_notice',
            render: (_, record) => (
                <Switch
                    key={`${record.id}_mobile`}
                    size="small"
                    checkedChildren="开启"
                    unCheckedChildren="关闭"
                    disabled={isCompany}
                    checked={record.is_phone}
                    onClick={val => {
                        if (userStore.category === Account.UserType.company) return;
                        const currentValue = val;
                        editAlertSettingList(record.id, { is_phone: currentValue })
                            .then(() => {
                                message.success(`已${currentValue ? '打开' : '关闭'}${record.category}电话通知`);
                                refresh();
                            })
                            .catch(() => {
                                message.error('修改失败');
                            });
                    }}
                />
            ),
        },
    ];

    return <Table rowKey={record => record.category} columns={columns} dataSource={tableData} />;
};

/** 通知对象 */
const NoticeReceiver: React.FC = () => (
    <Form.List name="recipient">
        {(fields, { add, remove }) => (
            <>
                {fields.map(field => (
                    <div key={field.key} className="recipient-item">
                        <Space align="baseline" style={{ width: '100%' }}>
                            <Form.Item
                                shouldUpdate={(prevValues, curValues) =>
                                    prevValues.name !== curValues.name || prevValues.mobile !== curValues.mobile
                                }
                            >
                                {() => (
                                    <Form.Item
                                        {...field}
                                        key={`field_name_${field.key}`}
                                        label="姓名"
                                        name={[field.name, 'name']}
                                        rules={[{ required: true, message: '请输入联系人姓名' }]}
                                    >
                                        <Input type="text" />
                                    </Form.Item>
                                )}
                            </Form.Item>
                            <Form.Item
                                {...field}
                                key={`field_mobile_${field.key}`}
                                label="联系方式"
                                name={[field.name, 'mobile']}
                                rules={[
                                    { required: true, message: '请输入联系方式' },
                                    {
                                        pattern: /^1[3-9]\d{9}$/g,
                                        message: '手机号格式不正确',
                                    },
                                ]}
                            >
                                <Input type="tel" maxLength={11} />
                            </Form.Item>

                            <MinusCircleOutlined
                                style={{ fontSize: '16px', color: color.themeRed }}
                                onClick={() => remove(field.name)}
                            />
                        </Space>
                    </div>
                ))}
                {fields.length < 20 ? (
                    <Form.Item className="recipient-add-button" wrapperCol={{ span: 6, offset: 1 }}>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            添加联系人
                        </Button>
                    </Form.Item>
                ) : null}
            </>
        )}
    </Form.List>
);

const HiddenDangerSettingView = () => {
    const [form] = Form.useForm();
    const [noticeList, setNoticeList] = useState<Alert.AlertListItem[]>([]);
    const [receivers, setRecivers] = useState<Alert.AlertReceiverItem>();
    const currentID = useRef<number>();

    // 获取报警设置列表
    const getAlertSetting = () => {
        if (![Account.UserType.company, Account.UserType.agent].includes(userStore.category)) return;
        getAlertSettingList()
            .then(res => {
                setNoticeList(res.results);
            })
            .catch(error => {
                message.error(error.response?.data?.[0]);
            });
    };
    // 获取联系人列表
    const getReceiver = () => {
        // 企业账号才获取此项
        if (![Account.UserType.company, Account.UserType.agent].includes(userStore.category)) return;
        getAlertReceiver().then(res => {
            currentID.current = res.results[0].id;
            setRecivers(res.results[0]);
            form.setFieldsValue(res.results[0]);
        });
    };

    useEffect(() => {
        if (userStore.category !== -1) {
            getAlertSetting();
            getReceiver();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.category]);

    const onFinish = (values: Alert.EditReceiverParam) => {
        let data = values;
        // 为空时添加空数组
        if (!values.recipient || values.recipient.length === 0) {
            data = { recipient: [] };
        }
        // 判断是否有变更
        if (JSON.stringify(values.recipient) === JSON.stringify(receivers?.recipient)) {
            message.warning('您还没有修改联系人');
            return;
        }
        const mobileList: string[] = [];
        for (const item of values.recipient) {
            if (mobileList.includes(item.mobile)) {
                message.warning('有手机号码重复，请删除重复号码！');
                return;
            }
            mobileList.push(item.mobile);
        }

        editAlertReceiver(currentID.current || -1, data)
            .then(res => {
                setRecivers(res);
                message.success('保存联系人成功');
            })
            .catch(() => {
                message.error('保存联系人失败');
            });
    };

    return (
        <div className="container">
            <div className="hidden-danger-container">
                <div style={{ fontSize: '16px', marginTop: '24px', marginBottom: '24px', fontWeight: 'bold' }}>
                    通知设置
                </div>
                <NoticeSetting
                    tableData={noticeList}
                    refresh={getAlertSetting}
                    isCompany={userStore.category === Account.UserType.company}
                />
                {/* 企业及代理用户用户显示此项 */}
                {[Account.UserType.company, Account.UserType.agent].includes(userStore.category) ? (
                    <>
                        <div style={{ fontSize: '16px', marginTop: '24px', marginBottom: '24px', fontWeight: 'bold' }}>
                            通知对象
                        </div>
                        <Form className="recipient-form" form={form} onFinish={onFinish}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <NoticeReceiver />
                            </div>
                            <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
                                <Button htmlType="submit" type="primary">
                                    保存
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default observer(HiddenDangerSettingView);
