import { TreeSelectProps } from 'antd/lib/tree-select';
import { Area, fetchAllCompanyIdByTreeSelectIdentifies, purgedAreaCompanyTreeState } from '@greendev/common';
import { Observer } from 'mobx-react-lite';
import { Form, TreeSelect } from 'antd';
import React from 'react';
import _ from 'lodash';

type PurgedAreaCompanyTreeSelectProps = Pick<
    TreeSelectProps<string, Area.FrontEndProfile>,
    | 'value'
    | 'onChange'
    | 'style'
    | 'treeCheckable'
    | 'onSelect'
    | 'loadData'
    | 'onTreeLoad'
    | 'allowClear'
    | 'disabled'
> & {
    /**
     * indicate this component if only can select company(leaf node)
     * default can select all areas and companies
     */
    onlyCanSelectCompany?: boolean;
    label: string;
};

/**
 * step1:    usePurgedAreaCompanyState();
 * step2:
 * <PurgedAreaCompanyTreeSelect
 label="所属企业"
 value={
                                    searchCompanyId === undefined
                                        ? undefined
                                        : generateIdentifiedByType(TreeItemType.COMPANY, searchCompanyId)
                                }
 onChange={(itemIdentification?: string) => {
                                    if (itemIdentification === undefined) {
                                        whenInputSearchCompany(undefined);
                                        return;
                                    }
                                    whenInputSearchCompany(
                                        recoverInformationFromIdentify(itemIdentification).originObjectId
                                    );
                                }}
 treeCheckable={false}
 onlyCanSelectCompany
 style={{ flex: 1 }}
 />
 * @param props
 * @constructor
 */
export const PurgedAreaCompanyTreeSelect = (props: PurgedAreaCompanyTreeSelectProps) => {
    const { label, onlyCanSelectCompany } = props;
    // resolution : expand the area of small triangle to whole row:https://itcn.blog/p/1648376769899163.html
    return (
        <Observer>
            {() => (
                <TreeSelect<string, any>
                    treeData={
                        onlyCanSelectCompany === true
                            ? purgedAreaCompanyTreeState.areasWithCombinedChildrenLoadingStateWithStemDisable
                                  .areaCompanyTree
                            : purgedAreaCompanyTreeState.areasWithCombinedChildrenLoadingState.areaCompanyTree
                    }
                    dropdownClassName={'resource-tree-select'}
                    fieldNames={{
                        value: 'uniqueId',
                        label: 'name',
                    }}
                    allowClear
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 350, overflow: 'auto' }}
                    placeholder={`请选择${label}`}
                    {...props}
                />
            )}
        </Observer>
    );
};

/**
 * use this component when you want to select area in a form
 * notice : you should always call {@link useAreaInitialize} in root of your screen, so the initialize areas will be fetch and this component can display them
 * @param props some configuration of Form.Item
 * @constructor
 */
export const PurgedAreaCompanyTreeSelectUsedInForm = (
    props: PurgedAreaCompanyTreeSelectProps & {
        required: boolean;
    }
) => {
    const { required, ...rest } = props;
    return (
        <Form.Item name="enterprise" label={rest.label} rules={[{ required, message: `请选择${rest.label}` }]}>
            <PurgedAreaCompanyTreeSelect {...rest} />
        </Form.Item>
    );
};

/**
 * this function is wrapper of {@link fetchAllCompanyIdByTreeSelectIdentifies} !!!!!
 * @param combinedIdentifications undefined / [{"type":0,originObjectId:1},{"type":1,originObjectId:2}]
 */
export function fetchAllCompanyIdByTreeNodeIdentifies(combinedIdentifications?: string[]) {
    let allCompanyIdFetchPromise;
    if (combinedIdentifications === undefined) {
        allCompanyIdFetchPromise = Promise.resolve([]);
    } else {
        allCompanyIdFetchPromise = fetchAllCompanyIdByTreeSelectIdentifies(combinedIdentifications);
    }
    return allCompanyIdFetchPromise.then(allCompanyIds => _.uniq(allCompanyIds));
}
