import { dealRequestError, request } from "../../utils/request";
import _ from "lodash";
export function fetchAllOperationLog(params) {
  return new Promise(resolve => {
    request({
      url: '/v1/system/log/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(result => resolve(result)).catch(dealRequestError);
  });
}

// 终端类型
export const terminal = [{
  id: 1,
  name: 'app'
}, {
  id: 2,
  name: 'web'
}];

// 操作类型
export const action = [{
  id: 1,
  name: '登录'
}, {
  id: 2,
  name: '批量导入'
}, {
  id: 3,
  name: '增加'
}, {
  id: 4,
  name: '修改'
}, {
  id: 5,
  name: '删除'
}];