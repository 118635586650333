import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import PartTitle from '../../components/PartTitle';
import ContentContainer from '../../components/ContentContainer';
import { BigScreen } from '@greendev/common';

/** 展示大屏-监测点统计组件 */
const BigScreenProductLinePart: React.FC<{ data: BigScreen.ProductRuntimeType }> = ({ data }) => {
    // 图片当前大小，imgSize = [宽, 高]
    const [imgSize, setImgSize] = useState<number[]>([547, 256]);
    // 获取背景图片元素
    const imgRef = useRef<HTMLImageElement>(null);
    const [hideText, setHideText] = useState<boolean>(true);

    const setImgWidthOnResize = () => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        if (imgRef.current?.width) setImgSize([imgRef.current?.width, imgRef.current?.height]);
    };
    useEffect(() => {
        // 延迟计算宽度，直接获取可能会失败
        setTimeout(() => {
            setImgWidthOnResize();
            setTimeout(() => {
                setHideText(false);
            }, 500);
        }, 500);
        window.addEventListener('resize', setImgWidthOnResize);
        return () => {
            window.removeEventListener('resize', setImgWidthOnResize);
        };
    }, []);

    return (
        <div style={{ flex: 1, padding: '14px', overflow: 'hidden' }}>
            <PartTitle>监测点统计</PartTitle>
            <ContentContainer className="product-line-part">
                <div className="product-line-part-img-container" style={{opacity: hideText ? 0 : 1}}>
                    <img
                        src={require('@/assets/images/product_line_bg.png')}
                        alt="product-line-part img"
                        ref={imgRef}
                    />
                    <div
                        className="product-line-part-product"
                        style={{
                            fontSize: `${Math.floor((imgSize[0] || 547) / 30)}px`,
                            left: Math.floor((imgSize[0] || 547) / 9),
                            bottom: Math.floor((imgSize[1] || 256) / 4.7),
                        }}
                    >
                        生产线{' '}
                        <span
                            className="italic-text"
                            style={{ fontSize: `${Math.floor((imgSize[0] || 547) / 30) + 2}px` }}
                        >
                            {data.production}条
                        </span>
                    </div>
                    <div
                        className="product-line-part-total"
                        style={{
                            fontSize: `${Math.floor((imgSize[0] || 547) / 30)}px`,
                            left: Math.floor((imgSize[0] || 547) / 2.53),
                            bottom: Math.floor((imgSize[1] || 256) / 1.67),
                        }}
                    >
                        总量{' '}
                        <span
                            className="italic-text"
                            style={{
                                fontSize: `${Math.floor((imgSize[0] || 547) / 30) + 2}px`,
                                marginLeft: `${Math.floor((imgSize[0] || 547) / 30)}px`,
                            }}
                        >
                            {data.total}条
                        </span>
                    </div>
                    <div
                        className="product-line-part-pollution"
                        style={{
                            fontSize: `${Math.floor((imgSize[0] || 547) / 30)}px`,
                            left: Math.floor((imgSize[0] || 547) / 1.25),
                            bottom: Math.floor((imgSize[1] || 256) / 2.15),
                        }}
                    >
                        治污线{' '}
                        <span
                            className="italic-text"
                            style={{ fontSize: `${Math.floor((imgSize[0] || 547) / 30) + 2}px` }}
                        >
                            {data.pollution}条
                        </span>
                    </div>
                </div>
            </ContentContainer>
        </div>
    );
};

export default observer(BigScreenProductLinePart);
