import React, { useEffect, useRef, useState } from 'react';
import { Device, getDeviceDetail } from '@greendev/common';

import GatewayDetail from './Devices/GatewayDetail';
import CTDetail from './Devices/CTDetail';
import { MyIcon } from '@/utils/iconfont';
import { message } from 'antd';

const DeviceDetailPart: React.FC<{
    id: number;
    showLoading: () => void;
    hiddenLoading: () => void;
}> = ({ id, ...otherProps }) => {
    const { showLoading, hiddenLoading } = otherProps;
    const [deviceDetail, setDeviceDetail] = useState<Device.DeviceDetailType>();
    const [childId, setChildId] = useState<number>();
    const isLoading = useRef<boolean>(false);

    const refreshData = () => {
        if(isLoading.current) return;
        showLoading();
        isLoading.current = true;
        getDeviceDetail(childId || id)
            .then(res => {
                setDeviceDetail(res);
                // console.log(res);
                hiddenLoading();
                isLoading.current = false;
            })
            .catch(() => {
                hiddenLoading();
                message.error('获取数据失败');
                isLoading.current = false;
            });
    };
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, childId]);

    return (
        <>
            <div className="refresh-button" onClick={() => refreshData()}>
                <MyIcon type="iconrefresh-1-copy" style={{ fontSize: 24, color: '#666666' }} />
            </div>
            <div className="device-detail-container">
                {/* 网关 */}
                {deviceDetail && deviceDetail.category_id === 'F001' ? (
                    <GatewayDetail detail={deviceDetail} childDetail={setChildId} />
                ) : null}
                {/* CT */}
                {deviceDetail && deviceDetail.category_id === 'FB00' ? <CTDetail detail={deviceDetail} /> : null}
            </div>
        </>
    );
};

export default DeviceDetailPart;
