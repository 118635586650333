// js 遍历数组取出字符串用逗号拼接
export const getTextByJs = (array: [{ name: string }]) => {
    let str = '';
    for (let i = 0; i < array.length; i++) {
        str += `${array[i].name}，`;
    }
    //去掉最后一个逗号(如果不需要去掉，就不用写)
    if (str.length > 0) {
        str = str.substr(0, str.length - 1);
    }
    return str;
};
//输出结果: hhh,dddd,121212

//保留两位小数
// eslint-disable-next-line consistent-return
export const peakvalue = (power: any) => {
    if (typeof power !== 'undefined' && power !== null) {
        // let max_power = parseInt(power * 100) / 100;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const max_power = Math.round(power * 100) / 100;
        return max_power;
    }
};

//格式化时间
// eslint-disable-next-line consistent-return
export const peakTime = (timeDate: string) => {
    if (timeDate) {
        const date = timeDate.slice(0, 10);
        const time = timeDate.slice(11, 19);
        // eslint-disable-next-line no-param-reassign
        timeDate = `${date} ${time}`;
        return timeDate;
    }
};
