import { message } from 'antd';
import { makeAutoObservable } from 'mobx';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import {
    generateOssClientByUploadToken,
    requestUploadKey,
    UploadSituation,
    generateUniqueNameFromImageName,
    normallyExtractFileNameFromUrl,
} from '@greendev/common';

/**
 * use this function to get validation function for image upload component
 * @param maxSize
 */
export function getImageValidationState(maxSize: number) {
    return (file: RcFile, rcFiles: RcFile[]): boolean => {
        for (const rcFile of rcFiles) {
            const isJpgOrPng =
                rcFile.type === 'image/jpeg' || rcFile.type === 'image/png' || rcFile.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('您只能上传JPG/JPEG/PNG 文件!');
                return false;
            }
            const isLt2M = rcFile.size / 1024 / 1024 < maxSize;
            if (!isLt2M) {
                message.error(`图片大小必须小于${maxSize}MB!`);
                return false;
            }
        }
        return true;
    };
}

export function generateUploadFileObjectByUrl(url: string) {
    const fileName = normallyExtractFileNameFromUrl(url);
    return {
        uid: fileName,
        name: fileName,
        url,
        thumbUrl: url,
    } as UploadFile;
}

export type UploadedImageResult = {
    wholeUrl: string;
    relativeUrl: string;
};

function isUploadFileIsFromRemote(targetFile: UploadFile) {
    return targetFile.url !== undefined;
}

class FileUploaderState {
    /**
     * consist of some images generated from remote file(have {@link UploadFile.url} field)  and   some selected from user local machine
     * @private
     */
    private _fileList: UploadFile[] = [];

    get fileList(): UploadFile[] {
        return this._fileList;
    }

    /**
     * when you need backfill images to store , call this function
     * and you must ensure the {@link UploadFile.thumbUrl} and {@link UploadFile.url} not be null
     * you maybe want to use {@link generateUploadFileObjectByUrl} to generate right object for this function
     */
    backfillImages = (backfilledImages: UploadFile[]) => {
        this._fileList = backfilledImages;
    };

    imageUploading = (targetFile: UploadFile) => {
        if (this._fileList.find(eachStoredFile => eachStoredFile.uid === targetFile.uid) === undefined) {
            //this file not stored yet , store it
            this._fileList.push(targetFile);
        }
    };

    imageDone = (targetFile: UploadFile) => {
        this._fileList = this._fileList.map(eachStoredFile => {
            if (eachStoredFile.uid === targetFile.uid) {
                //replace done file from stored files
                return targetFile;
            }
            return eachStoredFile;
        });
    };

    imageRemove = (targetFile: UploadFile) => {
        this._fileList = this._fileList.filter(eachStoredFile => eachStoredFile.uid !== targetFile.uid);
    };

    uploadSelectedFiles = (situation: UploadSituation) =>
        new Promise<UploadedImageResult[]>((resolve, reject) => {
            if (this._fileList.length === 0) {
                resolve([]);
                return;
            }
            if (this._fileList.find(eachImage => !isUploadFileIsFromRemote(eachImage)) === undefined) {
                //user not selected image from local, in another word , all images is from remote server, so just return them
                //notice : this is a trick,just return the original url and we can drop it from request body when send request!!!
                resolve(
                    this._fileList.map(value => {
                        //https://testfinsiot.oss-cn-chengdu.aliyuncs.com/banner/1650524820700-1000.png  ->  banner/1650524820700-1000.png
                        const strings = value.url!!.split('/');
                        strings.shift();
                        strings.shift();
                        strings.shift();
                        const relativeUrl = strings.join('/');
                        return {
                            wholeUrl: value.url!!,
                            relativeUrl,
                        };
                    })
                );
            } else {
                //request upload key from oss
                requestUploadKey(situation)
                    .then(uploadTokenProfile => {
                        const ossClient = generateOssClientByUploadToken(uploadTokenProfile);
                        //generate all upload promise( the promise will be fake if the file is upload from remote!!! )
                        const allImagePromises: Promise<{
                            url: string;
                        }>[] = this._fileList.map(eachImage => {
                            if (isUploadFileIsFromRemote(eachImage)) {
                                return Promise.resolve({
                                    url: eachImage.url,
                                });
                            }
                            return ossClient.put(
                                `${uploadTokenProfile.folder}/${generateUniqueNameFromImageName(eachImage.name)}`,
                                eachImage && eachImage.originFileObj
                            );
                        });
                        //resolve after promise
                        Promise.all(allImagePromises)
                            .then(allImageUrlPromise => {
                                resolve(
                                    allImageUrlPromise.map(value => ({
                                        wholeUrl: value.url,
                                        relativeUrl: `${uploadTokenProfile.folder}/${normallyExtractFileNameFromUrl(
                                            value.url
                                        )}`,
                                    }))
                                );
                            })
                            .catch(reject);
                    })
                    .catch(reject);
            }
        });

    /**
     * generally speaking , you should call this function when upload successful or screen unmount
     */
    clearSelectedFiles = () => {
        this._fileList = [];
    };

    constructor() {
        makeAutoObservable(this);
    }
}

export default FileUploaderState;
