import React from 'react';
import ReactEcharts, { EChartsReactProps } from 'echarts-for-react';
import { observer } from 'mobx-react-lite';
import GlobalStyle from '@/styles';
import { Device } from '@greendev/common';

const CTCharts: React.FC<{ data: Device.TransformerDataType[]; timeSplit?: 1 | 2 }> = ({ data, timeSplit }) => {
    const { color } = GlobalStyle;
    const timeArr: string[] = [];
    const valueArr: number[] = [];
    if (data) {
        data.forEach(val => {
            timeArr.push(timeSplit ? val.time.split(' ')[timeSplit - 1] : val.time);
            valueArr.push(val.current);
        });
    }

    const option: EChartsReactProps['option'] = {
        xAxis: {
            type: 'category',
            data: timeArr,
        },
        yAxis: {
            name: '单位(A)',
            type: 'value',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: color.themeBlue,
                },
            },
        },
        series: [
            {
                data: valueArr,
                symbol: 'none',
                type: 'line',
                smooth: true,
                color: color.themeColor,
            },
        ],
    };
    return <ReactEcharts option={option} />;
};

export default observer(CTCharts);
