import React, { FC } from 'react';
import { Spin } from 'antd';

interface FallbackMessageProps {
    message: string;
    description?: string;
}

export const SuspendFallbackLoading: FC<FallbackMessageProps> = () => (
    // <Spin tip="加载中...">
    //     <Alert message={message} description={description} type="info" />
    // </Spin>
    <Spin tip="加载中..." />
);

export default SuspendFallbackLoading;
