import { Button, Table } from 'antd';
import moment, { Moment } from 'moment';

import 'moment/locale/zh-cn';
//let Datepicker display chinese character
moment.locale('zh-cn');

//This file used to define some default props to components of Ant Design , so that we can use them purely in feature

Table.defaultProps = {
    ...(Table.defaultProps || {}),
    bordered: true,
    scroll: { x: '100%' },
    locale: {
        emptyText: '暂无数据',
    },
    pagination: false,
};

Button.defaultProps = {
    ...(Button.defaultProps || {}),
    // className: 'flex-horizontal-center',
};

export const dataFormatByHideYear = (value: Moment) => `每年: ${value.format('MM/DD')}`;

// Can not select days before today
export const disableDaysBeforeToday = (current: Moment) => current < moment().startOf('day');

// You cannot select a date before tomorrow
export const disableDaysAfterToday = (current: Moment) => current < moment().startOf('day');

//可以选择今日以及今日之前的日期
export const disabledDaysBeforeToday = (current: Moment) => current && current >= moment().endOf('day');

//只能选择近30天的日期
export const disabledDay30 = (current: Moment) =>
    current <= moment().subtract(30, 'days') || current > moment().endOf('day');

//can not select time exceed current time by one year
export const disableDaysExceedOneYear = (current: Moment) => current > moment().add(1, 'y').endOf('day');

export const disableDaysOutSideCurrentToOneYear = (currentDate: Moment) =>
    disableDaysBeforeToday(currentDate) || disableDaysExceedOneYear(currentDate);

export const disableDaysBeforeThisYear = (current: Moment) => current < moment().startOf('year');

export const disableDaysAfterThisYear = (current: Moment) => current > moment().endOf('year');

export const disableDaysOutSideThisYear = (currentDate: Moment) =>
    disableDaysBeforeThisYear(currentDate) || disableDaysAfterThisYear(currentDate);

export const retrieveRightIdFromDifferentMode = (valueFromForm: string, isEditMode: boolean, originId?: number) => {
    if (typeof valueFromForm !== 'string') {
        throw new Error('Please check the type of result value in Item');
    }
    if (isEditMode) {
        //the result always is string , so determine the string if is number string , if is, means user change the input in form
        const isnumber = /^\d+$/.test(valueFromForm);
        if (isnumber) {
            return Number(valueFromForm);
        }
        return originId!!;
    }
    return Number(valueFromForm);
};
