import React from 'react';
import './index.scss';

const LoginContainer: React.FC = ({ children }) => (
    <div className="login-container">
        <div className="login-content">{children}</div>
    </div>
);

export default LoginContainer;
