import { request } from "../../utils/request";
import _ from 'lodash';
import moment from 'moment';
/* eslint-disable no-param-reassign */

export function generateClientFieldForAccountObject(object) {
  // @ts-ignore
  if (moment(object.expire_time).isBefore(moment())) {
    object.is_expired = true;
  }
  return object;
}

/**
 * @deprecated
 * todo see the second function invoke , and tell lipei to call {@link fetchAccounts}
 * @param isAgent
 * @param params
 */
export function fetchAllAgentAccounts(isAgent, params) {
  return new Promise((resolve, reject) => {
    request({
      url: isAgent ? '/v1/oauth/user/' : '/v1/oauth/user/enterprise/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(accounts => {
      const accountsWithTypeInformation = accounts.results;
      resolve(isAgent ? accountsWithTypeInformation.map(value => generateClientFieldForAccountObject(value)) : accountsWithTypeInformation.map(value => generateClientFieldForAccountObject(value)));
    }).catch(reject);
  });
}
export function fetchAccounts(params = {}) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/oauth/user/enterprise/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(accounts => {
      if (params.get_all) {
        resolve(accounts);
      } else {
        const agentAccountsWithWrapper = accounts;
        agentAccountsWithWrapper.results = agentAccountsWithWrapper.results.map(value => generateClientFieldForAccountObject(value));
        resolve(agentAccountsWithWrapper);
      }
    }).catch(reject);
  });
}
export function fetchAgentAccounts(params = {}) {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/oauth/user/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(accounts => {
      if (params.get_all) {
        resolve(accounts);
      } else {
        const agentAccountsWithWrapper = accounts;
        agentAccountsWithWrapper.results = agentAccountsWithWrapper.results.map(value => generateClientFieldForAccountObject(value));
        resolve(agentAccountsWithWrapper);
      }
    }).catch(reject);
  });
}

/* eslint-enable no-param-reassign */

export function createAccount(params) {
  return request({
    url: `/v1/oauth/user/enterprise/`,
    method: 'POST',
    data: params
  });
}
export function deleteAccountById(accountId) {
  return request({
    url: `/v1/oauth/user/enterprise/${accountId}/`,
    method: 'DELETE'
  });
}
export function modifyAccount(userId, params) {
  return request({
    url: `/v1/oauth/user/enterprise/${userId}/`,
    method: 'PATCH',
    data: params
  });
}
export function queryAccountById(userId) {
  return request({
    url: `/v1/oauth/user/enterprise/${userId}/`,
    method: 'GET'
  });
}
export function createAgentAccount(params) {
  return request({
    url: `/v1/oauth/user/`,
    method: 'POST',
    data: _.omitBy(params, _.isUndefined)
  });
}
export function deleteAgentAccountById(accountId) {
  return request({
    url: `/v1/oauth/user/${accountId}/`,
    method: 'DELETE'
  });
}
export function modifyAgentAccount(agentAccountId, params) {
  return request({
    url: `/v1/oauth/user/${agentAccountId}/`,
    method: 'PATCH',
    data: _.omitBy(params, _.isUndefined)
  });
}
export function queryAgentAccountByIdUseAdmin(userId) {
  return request({
    url: `/v1/oauth/user/${userId}/`,
    method: 'GET'
  });
}
export function importCompanyAccounts(accounts) {
  return request({
    url: `/v1/oauth/user/enterprise/batch_import/`,
    method: 'POST',
    data: accounts
  });
}