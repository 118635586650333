import { dealRequestError, request } from "../../utils/request";
import _ from 'lodash';
export function fetchAllPollutionControl(params = {}) {
  return new Promise(resolve => {
    request({
      url: '/v1/scheme/relation/',
      method: 'GET',
      params: _.omitBy(params, _.isUndefined)
    }).then(result => {
      if (params.get_all === true) {
        resolve(result);
      } else {
        resolve(result);
      }
    }).catch(dealRequestError);
  });
}

//生产治污关联详情
export function queryPollutionControlById(id) {
  return request({
    url: `/v1/scheme/relation/${id}/`,
    method: 'GET'
  });
}
export function deletePollutionControlById(id) {
  return request({
    url: `/v1/scheme/relation/${id}/`,
    method: 'DELETE'
  });
}
export function modifyPollutionControl(id, params) {
  return request({
    url: `/v1/scheme/relation/${id}/`,
    method: 'PATCH',
    data: _.omitBy(params, _.isUndefined)
  });
}
export function createPollutionControl(params) {
  return request({
    url: '/v1/scheme/relation/',
    method: 'POST',
    data: _.omitBy(params, _.isUndefined)
  });
}