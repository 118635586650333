import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'antd';
import { polling, getEnterProduct, BigScreen } from '@greendev/common';
import PartTitle from '../../components/PartTitle';
import ContentContainer from '../../components/ContentContainer';
import { userStore } from '@/stores/userStore';

/** 展示大屏-生产统计组件 */
const BigScreenProductPart: React.FC = () => {
    const [data, setData] = useState<BigScreen.EnterProductType>({ normal: 0, abnormal: 0 });
    const timer = useRef<NodeJS.Timer>();
    const setTimer = (newtimer: NodeJS.Timer) => {
        timer.current = newtimer;
    };
    useEffect(() => {
        if (userStore.menuList.length > 0) {
            polling(getEnterProduct, setData, setTimer);
        }
        return () => {
            clearTimeout(timer.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.menuList]);

    return (
        <div style={{ flex: 1, padding: '14px', overflow: 'hidden' }}>
            <PartTitle>生产统计</PartTitle>
            <ContentContainer>
                <Row className="BigScreenProductPartRow">
                    <Col span={12}>
                        <img src={require('@/assets/images/normal.png')} alt={'normal img'} />
                        <p>
                            正常<span>{data?.normal}</span>家
                        </p>
                    </Col>
                    <Col span={12}>
                        <img src={require('@/assets/images/abnormal.png')} alt={'abnormal img'} />
                        <p>
                            异常<span>{data?.abnormal}</span>家
                        </p>
                    </Col>
                </Row>
            </ContentContainer>
        </div>
    );
};

export default observer(BigScreenProductPart);
