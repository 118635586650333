import React, { useEffect, useRef, useState } from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Button, FormInstance, Popconfirm, Table } from 'antd';
import CommonModal, { CommonModalApi } from '@/components/modal/CommoModal';
import { ColumnsType } from 'antd/es/table';
import AreaEditContent, { AreaEditContentProps } from '@/views/common/basicDataManage/areaManageView/AreaEditContent';
import areaTreeState from '@/stores/areaTreeState';
import { Area, dealRequestError, purgedAreaCompanyTreeState, usePurgedAreaCompanyState } from '@greendev/common';
import { useAreaInitialize } from '@/views/common/basicDataManage/areaManageView/AreaFormTreeSelect';
import { autorun } from 'mobx';

// 行政区域管理页面
const AreaManagementView = () => {
    useAreaInitialize();
    usePurgedAreaCompanyState();

    useEffect(() => {
        purgedAreaCompanyTreeState.refreshPurgedAreaCompanyTree();
    }, []);

    const modalRef = useRef<CommonModalApi>(null);
    const areaEditModalRef = useRef<FormInstance<any>>(null);
    const [areaEditModalState, setAreaEditModalState] = useState<AreaEditContentProps | undefined>(undefined);
    useEffect(() => {
        if (areaEditModalState !== undefined) {
            modalRef.current?.showModal();
        }
    }, [areaEditModalState]);

    const columns: ColumnsType<Area.FrontEndProfile> = [
        {
            title: '区域名称',
            dataIndex: 'name',
            key: 'id',
            align: 'left',
        },
        {
            title: '创建时间',
            dataIndex: 'add_time',
            key: 'id',
            width: '12%',
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 180,
            render: (item, record) => (
                <div className="opera">
                    <i
                        className="iconfont icona-zu6914"
                        title={'编辑'}
                        onClick={() => {
                            setAreaEditModalState({
                                editMode: true,
                                targetArea: record,
                            });
                        }}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                    <Popconfirm
                        title={record.children !== undefined ? '子区域将会被一并删除?' : '确认要删除吗？'}
                        onConfirm={() => {
                            areaTreeState.deleteChildArea(record);
                        }}
                    >
                        <i
                            className="iconfont iconshanchu1"
                            title={'删除'}
                            style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                margin: '0 0.6rem',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
    useEffect(
        () =>
            autorun(() => {
                if (expandedRowKeys.length !== 0) {
                    return;
                }
                const firstLevelKeys = areaTreeState.areasWithLoadingState.areas.map(value => value.id);
                if (firstLevelKeys.length === 0) {
                    //if there not have area in remote, don't update local state, otherwise this will trigger unlimited loop
                    return;
                }
                setExpandedRowKeys(firstLevelKeys);
            }),
        [expandedRowKeys]
    );

    return (
        <div className="container">
            <Button
                type="primary"
                className="commonButton"
                onClick={() => {
                    setAreaEditModalState({
                        editMode: false,
                    });
                }}
            >
                新增
            </Button>
            <Observer>
                {() => (
                    <Table
                        dataSource={areaTreeState.areasWithLoadingState.areas}
                        loading={areaTreeState.areasWithLoadingState.loading}
                        columns={columns}
                        rowKey={record => record.id}
                        expandable={{
                            indentSize: 25,
                            rowExpandable: record => record.children !== undefined,
                            expandedRowKeys,
                            onExpandedRowsChange: expandedKeys => {
                                setExpandedRowKeys([...expandedKeys]);
                            },
                            onExpand: (expanded, record) => {
                                areaTreeState
                                    .requestChildAreaUnderTargetArea(record.id, record.children)
                                    .catch(dealRequestError);
                            },
                        }}
                        scroll={{ x: '100%', y: '67.5vh' }}
                    />
                )}
            </Observer>

            {areaEditModalState && (
                <CommonModal
                    ref={modalRef}
                    onOk={() => {
                        //verify input in content of modal,then dismiss modal
                        areaEditModalRef.current?.validateFields().then(() => {
                            console.log('校验成功');
                            modalRef.current?.hiddenModal();
                            if (!areaEditModalState.editMode) {
                                //add area
                                const targetAreaId = areaEditModalRef.current?.getFieldValue('area');
                                const name = areaEditModalRef.current?.getFieldValue('name');
                                areaTreeState.createArea(name, targetAreaId as number | undefined);
                                areaEditModalRef.current?.resetFields();
                            } else {
                                //edit area
                                const { targetArea } = areaEditModalState;
                                if (targetArea === undefined) {
                                    return; //robustness
                                }
                                const name = areaEditModalRef.current?.getFieldValue('name');
                                areaTreeState.updateArea(
                                    name,
                                    targetArea.id,
                                    targetArea.pid === null ? undefined : targetArea.pid
                                );
                                areaEditModalRef.current?.resetFields();
                            }
                            setAreaEditModalState(undefined);
                        });
                    }}
                    onCancel={() => {
                        areaEditModalRef.current?.resetFields();
                    }}
                    title={`${areaEditModalState.editMode ? '编辑' : '新增'}`}
                >
                    <AreaEditContent ref={areaEditModalRef} {...areaEditModalState} />
                </CommonModal>
            )}
        </div>
    );
};

export default observer(AreaManagementView);
