// 项目配置文件

/**
 * 开发环境判断
 */
type EnvTypes = 'production' | 'development' | 'test';
const CURRENT_ENV = process.env.REACT_APP_ENV! as EnvTypes;
export const isDev = CURRENT_ENV !== 'production';

//service-address-xdl : http://192.168.110.86:8888/
//service-address-dh : http://192.168.110.110:8080/

/**
 * 环境url配置
 */
const urlList: { [key: string]: string } = {
    production: `${window.location.origin}/`,
    // development: 'http://greendev.finsiot.com/',
    development: 'http://192.168.110.86:8888/',
    test: 'http://greendev.finsiot.com/',
};

const aMapProxyList: { [key: string]: string } = {
    production: `${window.location.origin}/`,
    development: 'http://amap.finsiot.com/',
    test: 'http://amap.finsiot.com/',
};

export const baseUrl = urlList[CURRENT_ENV];
export const aMapProxyUrl = aMapProxyList[CURRENT_ENV];
export const mockBaseUrl = 'http://yapi.finsiot.com/mock/76/';

/** 设备状态 */
export enum DeviceStatus {
    /** 未激活 */
    inactive = 1,
    /** 在线 */
    online,
    /** 离线 */
    offline,
}
