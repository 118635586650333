import { Col, Image, Row, Typography } from 'antd';
import React from 'react';
import { AppManagement } from '@greendev/common';

export type FeedbackDetailContentProps = {
    displayedFeedback?: AppManagement.Feedback;
};

//新增、编辑弹框内容显示
const FeedbackDetailContent = (props: FeedbackDetailContentProps) => {
    const { displayedFeedback } = props;
    const images = displayedFeedback?.image_urls || [];

    return (
        <Row gutter={[8, 24]}>
            <Col span={4}>
                <Typography>标题 : </Typography>
            </Col>
            <Col span={20}>
                <Typography>{displayedFeedback?.title || ''}</Typography>
            </Col>
            <Col span={4}>
                <Typography>内容 : </Typography>
            </Col>
            <Col span={20}>
                <Typography>{displayedFeedback?.content || ''}</Typography>
            </Col>
            <Col span={4}>
                <Typography>联系方式 : </Typography>
            </Col>
            <Col span={20}>
                <Typography>{displayedFeedback?.mobile || '暂无'}</Typography>
            </Col>
            <Col span={4}>
                <Typography>图像 : </Typography>
            </Col>
            {images.length === 0 && (
                <Col span={4}>
                    <Typography>无</Typography>
                </Col>
            )}
            <Col span={20}>
                <Row gutter={[8, 8]}>
                    {images.map(imageUrl => (
                        <Col span={8}>
                            <Image src={imageUrl} width={'100%'} height={75} style={{ objectFit: 'cover' }} />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default FeedbackDetailContent;
